import React, { Component } from 'react';
import { Modal, Button, Card, Col } from 'antd'
import ModalDrag from '@/components/Common/ModalDrag'
import DescriptionList from '@/components/Common/DescriptionList'
import { formatDate } from 'utils/Date'
import UpdateStaffAccount from './UpdateStaffAccount';
import UpdateStaffUser from './UpdateStaffUser';
import UpdateStaffPosition from './UpdateStaffPosition';
import UpdateStaffSalaryAttr from './UpdateStaffSalaryAttr';

const { Description } = DescriptionList
class QueryStaffDetail extends Component {
    constructor() {
        super();
        this.state = {
            
            visiable: false,
            paymethod: "",
            contractype: "",
            outher_contractype: "",
            salarystatus: "",
            state: "",

        }
    }

    handlerCancel() {
        this.setState({
            visiable: false
        })
    }

    handlerModalShow() {
        this.setState({
            visiable: true
        })
    }


    componentWillMount() {

        //发薪方式
        let paymethod = this.props.record.userjoinenterprise.paymethod;
        if ("00" === paymethod) {
            this.setState({
                paymethod: "记账户"
            })
        }
        if ("01" === paymethod) {
            this.setState({
                paymethod: "银行卡"
            })
        }

        //与直系亲属关系
        let contractype = this.props.record.contractype;
        if ("00" === contractype) {
            this.setState({
                contractype: "配偶"
            })
        }
        if ("01" === contractype) {
            this.setState({
                contractype: "父母"
            })
        }
        if ("02" === contractype) {
            this.setState({
                contractype: "兄弟姐妹"
            })
        }
        if ("03" === contractype) {
            this.setState({
                contractype: "子女"
            })
        }
        //与其他联系人关系
        let outher_contractype = this.props.record.outher_contractype;
        if ("04" === outher_contractype) {
            this.setState({
                outher_contractype: "同事"
            })
        }
        if ("05" === outher_contractype) {
            this.setState({
                outher_contractype: "同学"
            })
        }
        if ("06" === outher_contractype) {
            this.setState({
                outher_contractype: "朋友"
            })
        }
        //薪酬关系
        let salarystatus = this.props.record.userjoinenterprise.salarystatus;
        if ("00" === salarystatus) {
            this.setState({
                salarystatus: "发放"
            })
        }
        if ("01" === salarystatus) {
            this.setState({
                salarystatus: "不发放"
            })
        }
        if ("02" === salarystatus) {
            this.setState({
                salarystatus: "待邀请"
            })
        }
        //就职类型
        let state = this.props.record.userjoinenterprise.state;
        if ("00" === state) {
            this.setState({
                state: "正式"
            })
        }
        if ("01" === state) {
            this.setState({
                state: "试用"
            })
        }
        if ("02" === state) {
            this.setState({
                state: "实习"
            })
        }
        if ("03" === state) {
            this.setState({
                state: "兼职"
            })
        }
        if ("04" === state) {
            this.setState({
                state: "离职"
            })
        }
    }

    desensitization(str) {
        if (typeof str == 'string') { //参数为字符串类型
            let ruten = str.substring(4, str.length - 4); //提取字符串下标之间的字符。
            return str.replace(ruten, '*****'); //字符串中用字符替换另外字符，或替换一个与正则表达式匹配的子串。
        }

    }

    render() {
        const title = <ModalDrag title={this.props.record.username} />;
        return (
            <div>
                <span className="span-click" onClick={this.handlerModalShow.bind(this)}>{this.props.record.username}</span>
                <Modal
                    style={{ top: '5%' }}
                    onCancel={this.handlerCancel.bind(this)}
                    visible={this.state.visiable}
                    footer={null}
                    title={title}
                    width={1200}
                >
                    <div style={{ wordBreak: 'break-word' }}>
                        {/* <Card title="开户信息" bordered={false} size="small" >
                            <DescriptionList size="large" col="2" style={{ marginBottom: 32 }} >
                                <Col span={12}>
                                    <Description term="姓名">{this.props.record.username}</Description>
                                    <Description term="银行卡号">{this.desensitization(this.props.record.bankcardinfo.cardNo)}</Description>
                                    <Description term="证件起始日">{this.props.record.certinfo.certStart === null ? "" : formatDate(new Date(this.props.record.certinfo.certStart), 'yyyy/MM/dd')}</Description>
                                    <Description term="发薪方式">{this.state.paymethod}</Description>
                                </Col>
                                <Col span={12}>
                                    <Description term="身份证号">{this.desensitization(this.props.record.certinfo.certNo)}</Description>
                                    <Description term="银行预留手机号">{this.props.record.mobile}</Description>
                                    <Description term="证件终止日">{this.props.record.certinfo.certEnd === null ? "" : formatDate(new Date(this.props.record.certinfo.certEnd), 'yyyy/MM/dd')}</Description>
                                </Col>
                            </DescriptionList>
                        </Card>
                        <Card title="个人信息" bordered={false} size="small" >
                            <DescriptionList size="large" col="2" style={{ marginBottom: 32 }} >
                                <Col span={12}>
                                    <Description term="邮箱">{this.props.record.email}</Description>
                                    <Description term="直系亲属联系人">{this.props.record.contractperson}</Description>
                                    <Description term="与直系亲属关系">{this.state.contractype}</Description>
                                    <Description term="直系亲属联系电话">{this.props.record.contractphone}</Description>
                                </Col>
                                <Col span={12}>
                                    <Description term="住址">{this.props.record.certinfo.certAddr}</Description>
                                    <Description term="其他紧急联系人">{this.state.outher_contractperson}</Description>
                                    <Description term="与其他联系人关系">{this.state.outher_contractype}</Description>
                                    <Description term="其他关系联系电话">{this.props.record.outher_contractphone}</Description>
                                </Col>
                            </DescriptionList>
                        </Card>
                        <Card title="职位信息" bordered={false} size="small" >
                            <DescriptionList size="large" col="2" style={{ marginBottom: 32 }} >

                                <Col span={12}>
                                    <Description term="工号">{this.props.record.userjoinenterprise.jobNumber}</Description>
                                    <Description term="薪酬关系">{this.state.salarystatus}</Description>
                                    <Description term="项目">{this.props.record.department.dname}</Description>
                                    <Description term="试用工资">{this.props.record.userjoinenterprise.trainingsalary}</Description>
                                    <Description term="转正时间">{this.props.record.userjoinenterprise.qualifiedDate === null ? "" : formatDate(new Date(this.props.record.userjoinenterprise.qualifiedDate), 'yyyy/MM/dd')}</Description>
                                </Col>
                                <Col span={12}>
                                    <Description term="就职类型">{this.state.state}</Description>
                                    <Description term="职位">{this.props.record.userjoinenterprise.position}</Description>
                                    <Description term="转正工资">{this.props.record.userjoinenterprise.regularsalary}</Description>
                                    <Description term="合同到期日">{this.props.record.userjoinenterprise.contractTerminationDate === null ? "" : formatDate(new Date(this.props.record.userjoinenterprise.contractTerminationDate), 'yyyy/MM/dd')}</Description>
                                    <Description term="离职时间">{this.props.record.userjoinenterprise.leavetime === null ? "" : formatDate(new Date(this.props.record.userjoinenterprise.leavetime), 'yyyy/MM/dd')}</Description>
                                </Col>
                            </DescriptionList>
                        </Card> */}

                        <UpdateStaffAccount record={this.props.record} refreshSubmit={this.props.refreshSubmit} detailFlag={true} attendList={this.props.attendList}/>
                        <UpdateStaffUser record={this.props.record} refreshSubmit={this.props.refreshSubmit} detailFlag={true}/>
                        <UpdateStaffSalaryAttr record={this.props.record} refreshSubmit={this.props.refreshSubmit} detailFlag={true}/>
                        <UpdateStaffPosition record={this.props.record} refreshSubmit={this.props.refreshSubmit} detailFlag={true} attendList={this.props.attendList}/>
                        <Button type="primary" style={{ marginLeft: '40%', marginBottom: 10 }} onClick={this.handlerCancel.bind(this)} >关闭</Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default QueryStaffDetail;