import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'
import NoMatch from '../views/error/NoMatch'
import Async from '../components/Async'

import historyQuery from '../views/finance/HistoryQuery'
import releaseDetail from '../views/finance/ReleaseDetail'
import createPayRollDetail from '@/components/payrollmanage/CreatePayRollDetail'
import showCorePayRoll from '@/components/CoreSalary/ShowCorePayRoll'
import createCorePayRollDetail from '@/components/CoreSalary/CreateCorePayRollDetail'
import departStaffInfo from '@/components/department/DepartStaffInfo'
import departStaffInfoSite from '@/components/department/DepartStaffInfoSite'
import departStaffUncheckInfo from '@/components/department/DepartStaffUncheckInfo'
import departIncrease from '../views/department/departIncrease'
import userIncrease from '../views/user/UserIncrease'
import roleIncrease from '../views/role/RoleIncrease'
import simplerDepartStaffInfo from '../views/simpler/simplerPay/department/SimplerDepartStaffInfo'
import simplerDepartStaffInfoSite from '../views/simpler/simplerPay/department/SimplerDepartStaffInfoSite'
import simplerDepartStaffUncheckInfo from '../views/simpler/simplerPay/department/SimplerDepartStaffUncheckInfo'
import simplerDepartIncrease from '../views/simpler/simplerPay/department/SimplerDepartIncrease'
import simplerCreatePayRollDetail2 from '@/components/payrollmanage/SimplerCreatePayRollDetail2'
import simplerHistoryQuery from '../views/finance/SimplerHistoryQuery'
import simplerReleaseDetail from '../views/finance/SimplerReleaseDetail'
import simplerPayRecordDetail from '@/components/finance/SimplerPayRecordDetail'
import createSupplierPayRollDetail from '@/components/supplierManage/SupplierPayRoll/CreateSupplierPayRollDetail'

import wkattendGroupAdd from '../views/attendance/attendgroup/AddAttend'
import wkattendGroupEdit from '../views/attendance/attendgroup/EditAttend'


class HomeRoutes extends Component {


    render() {
        const RouteMap = this.props.RoutesLink.map((menu,index) =>{
            return <Route key={index} exact path={menu.path} component = {Async(() => import('../views/'+menu.view))} />
        })
        if(RouteMap.length !== 0){
            RouteMap.push([<Route component={NoMatch} />]);
        }

        return (
            <Switch>
                <Route exact path="/wkattendGroupAdd" component = {wkattendGroupAdd} />
                <Route exact path="/wkattendGroupEdit" component = {wkattendGroupEdit} />

                <Route exact path="/historyQuery" component = {historyQuery} />
                <Route exact path="/releaseDetail" component = {releaseDetail} />
                <Route exact path="/createPayRollDetail" component = {createPayRollDetail} />
                <Route exact path="/showCorePayRoll" component = {showCorePayRoll} />
                <Route exact path="/createCorePayRollDetail" component = {createCorePayRollDetail} />
                <Route exact path="/departStaffInfo" component = {departStaffInfo} />
                <Route exact path="/departStaffInfoSite" component = {departStaffInfoSite} />
                <Route exact path="/departStaffUncheckInfo" component = {departStaffUncheckInfo} />
                <Route exact path="/departIncrease" component = {departIncrease} />
                <Route exact path="/userIncrease" component = {userIncrease} />
                <Route exact path="/roleIncrease" component = {roleIncrease} />
                <Route exact path="/simplerDepartStaffInfo" component = {simplerDepartStaffInfo} />
                <Route exact path="/simplerDepartStaffInfoSite" component = {simplerDepartStaffInfoSite} />
                <Route exact path="/simplerDepartStaffUncheckInfo" component = {simplerDepartStaffUncheckInfo} />
                <Route exact path="/simplerDepartIncrease" component = {simplerDepartIncrease} />
                <Route exact path="/simplerCreatePayRollDetail2" component = {simplerCreatePayRollDetail2} />
                <Route exact path="/simplerHistoryQuery" component = {simplerHistoryQuery} />
                <Route exact path="/simplerReleaseDetail" component = {simplerReleaseDetail} />
                <Route exact path="/simplerPayRecordDetail" component = {simplerPayRecordDetail} />
                <Route exact path="/createSupplierPayRollDetail" component = {createSupplierPayRollDetail} />
                



                {
                    RouteMap
                }
            </Switch>
        );
    }
}

export default HomeRoutes;