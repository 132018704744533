import EncryptAES from '@/utils/EncryptAES'
import { Message } from 'antd'
const _storageKey ='qAhjmkrGYftlqBokESTwMDxxLi7FtVwt';

global.pageInfo={
    sizeOptions : ['10', '20', '50', '100', '500'],
    bankFlowSizeOptions : ['10', '20', '30']
}

global._sessionStorage= {
    setItem:function(key,value,cb){
        let val = JSON.stringify(value);
        val = val || '' ;
        sessionStorage.setItem(`${key}`,`${EncryptAES.encrypt(val, _storageKey)}`); 
        return val;
    },
    getItem:function(key,cb){
        let value = sessionStorage.getItem(key) || '' ;
        if(!value){
            return null;
        }
        const decryptVal = EncryptAES.decrypt(value, _storageKey)
        if(!decryptVal){
            return null;
        }
        return JSON.parse(decryptVal);
    },
    removeItem:function(key,cb){
        sessionStorage.removeItem(key);
    },
    clear:function(cb){
        sessionStorage.clear();
    }
}