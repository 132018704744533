import React, { Component } from 'react';
import { Form, Row, Col, Input, Button, DatePicker ,Select,Cascader,Message } from 'antd';
import moment from 'moment';
import CreatePayRollUpload from './CreatePayRollUpload'

const { MonthPicker} = DatePicker;
const { Option } = Select;

class SimplerCreatePayRollSearch2 extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    refreshSubmit(e){
        this.handlerSubmit(e);
    }

    handlerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                if(params.salarydate){
                    params.salarydate = moment(params.salarydate).format('YYYY-MM');
                }
                // params.cyclecate='week';
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset(){
        this.props.form.resetFields();
        this.props.resetForm();
    }

    

    render() {
        const { getFieldDecorator } = this.props.form;
        // const dateFormat = 'YYYY-MM';
        return (
            <div>
                <Form className="ant-search-form" onSubmit={this.handlerSubmit.bind(this)} >
                <Row gutter={24} >
                    <Col  lg={5} xxl={4}>
                        <Form.Item label="发放月份" >
                        { getFieldDecorator("salarydate",{
                            // initialValue : [moment('2019/01/01', dateFormat), moment(new Date())]
                        })(
                            <MonthPicker />
                        ) }
                        </Form.Item>
                    </Col>
                    <Col span={8}  lg={7} xxl={4} >
                        <Form.Item label="发放单批次编号" >
                            { getFieldDecorator("batchcode")(
                                <Input allowClear placeholder="发放单批次编号" ></Input>
                            ) }
                        </Form.Item>
                    </Col> 
                    
                    <Col span={8}  lg={5} xxl={4} >
                        <Form.Item label="审核状态" >
                            { getFieldDecorator("status")(
                                <Select placeholder='审核状态'>
                                    <Option value=''>全部</Option>
                                    <Option value='07'>已发放</Option>
                                    <Option value='01'>审核通过</Option>
                                    <Option value='05'>审核拒绝</Option>
                                    <Option value='00'>待审核</Option>
                                    <Option value='03'>已提交</Option>
                                    <Option value='06'>正在发放</Option>
                                    <Option value='04'>未提交</Option>
                                    <Option value='08'>发放失败</Option>
                                </Select>
                            ) }
                        </Form.Item>
                    </Col> 
                    
                    <Col  lg={7} xxl={4} >
                        <Form.Item >
                            <Button type="primary" htmlType="submit" style={{marginLeft : 10}}  loading={this.props.loading} >查询</Button>
                            <Button style={{marginLeft : 5}} onClick={this.handlerReset.bind(this)} >重置</Button>
                        </Form.Item>
                    </Col>                   
                </Row>
            </Form>
            <div style={{marginTop : 10}} >
                <Row gutter={24}>
                     <Col span={4}><Button type="primary" onClick={this.refreshSubmit.bind(this)} >刷新</Button></Col>
                     
                     <Col span={20} >
                        <div style ={{float:'right'}}>
                            <CreatePayRollUpload createPayUpload={this.props.createPayUpload}/>
                        </div>
                    </Col>
                </Row>
            </div>  
            </div>
        );
    }
}

SimplerCreatePayRollSearch2 = Form.create()(SimplerCreatePayRollSearch2) 
export default SimplerCreatePayRollSearch2;