import React, { Component } from 'react';
import { Form, Input, Button, Select, Row, Message, Card, Col, Modal } from 'antd'
import { formatDate } from 'utils/Date'
import { editeAccount, updateAppUserPaymethod, checkWxMobile, selectDept, updateAppUserWithDepartment } from '../../api/staff';
import { queryDeptInitGroup } from '../../api/enDepart';
const { Option } = Select;

class UpdateStaffAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankCode: JSON.parse(global._sessionStorage.getItem('user')).bankCode,
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            uid: this.props.record.id,
            mobileRequired:true,
            mobileDisable:true,
            paymethodList:[{"key":"00","value":"记账户"},{"key":"01","value":"银行卡"}],
            paymethod:"",
            lastPaymethod:"",
            loading:false,
            msgVisible:false,
            cardFlag : true,
            accountState:"",
            sexList : [{ "key": "1", "value": "男" }, { "key": "2", "value": "女" }],
            department: [],
            cardNoChangeFlag : false,
            paymentodChangeFlag : false,
            mobileChangeFlag : false,
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({
                    loading: true,
                })

                params.id = this.state.uid;
                params.eid = this.state.enterpriseid;
                params.oldPaymethod = this.props.record.userjoinenterprise.paymethod;

                let accountState = this.state.accountState;
                if(this.state.cardNoChangeFlag || this.state.mobileChangeFlag || this.state.paymentodChangeFlag ){
                    console.log(111)
                    if("00"===accountState){
                        let value = params.paymethod;
                        if(value !=="02"){
                            if(this.props.record.accountState === "00" && this.props.record.cardBindState === "00" && this.props.record.userjoinenterprise.paymethod !== "02"){
                                params.cardFlag="0"
                            }
                            updateAppUserPaymethod(params).then(res => {
                                let { statusCode,message } = res.data;
                                if("200" === statusCode){
                                    Message.success("修改开户信息成功");
                                    this.props.refreshSubmit();
                                    this.props.visibleChange();
                                    this.setState({
                                        cardNoChangeFlag : false,
                                        paymentodChangeFlag : false,
                                        mobileChangeFlag : false,
                                    })
                                }else{
                                    Message.error(message);
                                }
                                this.setState({
                                    loading: false,
                                })
                            })
                        }else{
                            editeAccount(params).then(res => {
                                let { statusCode,message } = res.data;
                                if("200" === statusCode){
                                    Message.success("修改开户信息成功");
                                    this.props.refreshSubmit();
                                    this.setState({
                                        cardNoChangeFlag : false,
                                        paymentodChangeFlag : false,
                                        mobileChangeFlag : false,
                                    })
                                }else{
                                    Message.error(message);
                                }
                                this.setState({
                                    loading: false,
                                })
                            })
                        }
                    }else{
                        console.log(222)
                        editeAccount(params).then(res => {
                            let { statusCode,message } = res.data;
                            if("200" === statusCode){
                                Message.success("修改开户信息成功");
                                this.props.refreshSubmit();
                                this.setState({
                                    cardNoChangeFlag : false,
                                    paymentodChangeFlag : false,
                                    mobileChangeFlag : false,
                                })
                            }else{
                                Message.error(message);
                            }
                            this.setState({
                                paymethodList:[{"key":"00","value":"记账户"},{"key":"01","value":"银行卡"}],
                                loading: false,
                            })
                        })
                    }
                }else{
                    console.log(333)
                    updateAppUserWithDepartment(params).then(res => {
                        let { statusCode,message } = res.data;
                        if("200" === statusCode){
                            Message.success("修改信息成功");
                            this.props.refreshSubmit();
                            this.setState({
                                cardNoChangeFlag : false,
                                paymentodChangeFlag : false,
                                mobileChangeFlag : false,
                            })
                        }else{
                            Message.error(message);
                        }
                        this.setState({
                            paymethodList:[{"key":"00","value":"记账户"},{"key":"01","value":"银行卡"}],
                            loading: false,
                        })
                    })
                }
            }
        })

    }

    desensitization(str) {
        if (str && str != '' && typeof str == 'string') { //参数为字符串类型
            let ruten = str.substring(4, str.length-4); //提取字符串下标之间的字符。
            return str.replace(ruten, '*****'); //字符串中用字符替换另外字符，或替换一个与正则表达式匹配的子串。
        }

    }

    paymentodChanged(value){
        this.setState({
            paymethod:value
        })
        if("02"===this.state.paymethod){
            if("02" === value){
                this.setState({
                    mobileRequired:false,
                    mobileDisable:true,
                    lastPaymethod:value
                })
            }else{
                if(this.props.record.mobile){
                    this.setState({
                        mobileRequired:true,
                    })
                }else{
                    this.setState({
                        mobileRequired:false,
                    })
                }
                this.setState({
                    mobileDisable:false,
                    msgVisible:true
                })
            }
        }else{
            this.setState({
                lastPaymethod:value
            })
        }
        if(this.props.record.userjoinenterprise.paymethod !== value){
            console.log(1111)
            this.setState({
                paymentodChangeFlag: true
            })
        }else{
            console.log(2222)
            this.setState({
                paymentodChangeFlag: false
            })
        }
    }
    componentWillMount() {
        if(this.state.bankCode==='0001'){
            this.setState({
                cardFlag : false,
            //    paymethodList : [{ "key": "00", "value": "记账户" }]
            })
        }else{
            this.setState({
                cardFlag : true,
               // paymethodList:  [{ "key": "00", "value": "记账户" },{ "key": "01", "value": "银行卡" }],
            })
            
        }
        if(this.props.record.accountState === "00" && this.props.record.cardBindState === "00" && this.props.record.userjoinenterprise.paymethod !== "02"){
            if(this.props.record.mobile){
                this.setState({
                    mobileRequired:true,
                })
            }else{
                this.setState({
                    mobileRequired:false,
                })
            }
            this.setState({
                mobileDisable:false,
            })
        }

        this.setState({
            paymethod:this.props.record.userjoinenterprise.paymethod,
            accountState:this.props.record.accountState,
        })
        if("02" === this.props.record.userjoinenterprise.paymethod){
            this.setState({
                mobileRequired:false,
                lastPaymethod:this.props.record.userjoinenterprise.paymethod,
                paymethodList:[{"key":"00","value":"记账户"},{"key":"01","value":"银行卡"},{"key":"02","value":"白名单发放"}]
            })
        }else if ( (!this.props.record.bankcardinfo.cardNo) &&"0001" ===this.state.bankCode ){
            
            this.setState({
                paymethodList:  [{ "key": "00", "value": "记账户" }],
            })

        }else {
            this.setState({
                paymethodList:  [{ "key": "00", "value": "记账户" },{ "key": "01", "value": "银行卡" }],
            })
           
        }
        
        //初始化项目
        let params = {
            eid: this.state.enterpriseid,
        }
        selectDept(params).then(res => {
            this.setState({
                department: res.data
            })
        });
    }

    handleCancel(){
        this.setState({
            paymethod:this.state.lastPaymethod,
            msgVisible:false,
            cardNoChangeFlag : false,
            paymentodChangeFlag : false,
            mobileChangeFlag : false,
        })
        this.props.form.setFieldsValue({ ["paymethod"]: this.state.lastPaymethod });
    }

    handleOk(){
        this.setState({
            lastPaymethod:this.state.paymethod,
            msgVisible:false,
        })
        this.props.form.setFieldsValue({ ["paymethod"]: this.state.paymethod });
    }

    wxMobileValidator = (rule, val, callback) => {
        let params = {
            eid: this.state.enterpriseid,
            uid: this.props.record.id,
            wxMobile: val
        }
        checkWxMobile(params).then(res =>{
            let {errorCode,errorMessage} = res.data;
            if(errorCode === '0000'){
                callback();
            }else{
                callback(errorMessage);
            }
        })
    }

    departmentChange(values) {
        let params = {
            id : values
        }
        queryDeptInitGroup(params).then(res => {
            let { errorCode, message, groupExportId } = res.data;
            if (errorCode === "0000") {
                this.props.form.setFieldsValue({
                    groupExportId: groupExportId,
                });
            } else {
                Message.error(message);
            }
        })
    }

    cardNoChange(value){
        if(this.desensitization(this.props.record.bankcardinfo.cardNo) !== value){
            this.setState({
                cardNoChangeFlag: true
            })
        }else{
            this.setState({
                cardNoChangeFlag: false
            })
        }
    }

    mobileChange(value){
        if(this.props.record.mobile !== value){
            this.setState({
                mobileChangeFlag: true
            })
        }else{
            this.setState({
                mobileChangeFlag: false
            })
        }
    }

    render() {
        console.log(this.state.paymentodChangeFlag)
        console.log(this.state.cardNoChangeFlag)
        console.log(this.state.mobileChangeFlag)
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 12 }
        };
        const paymethodOptions = this.state.paymethodList.map(paymethod => {
            return <Option key={paymethod.key} value={paymethod.key} >{paymethod.value}</Option>
        });

        const departmentOptions = this.state.department.map(department => {
            return <Option key={department.did} value={department.did} >{department.dname}</Option>
        });
        const attendOptions = Array.isArray(this.props.attendList) ? this.props.attendList.map((item) => {
            return <Option key={item.exportId} value={item.exportId} >{item.groupName}</Option>
        }) : '';
        const sexOptions = this.state.sexList.map(type => {
            return <Option key={type.key} value={type.key} >{type.value}</Option>
        })
        return (
            <div>
                <Card title="开户信息" bordered={false} size="small" >
                    <Form {...formItemLayout} onSubmit={this.handleSubmit.bind(this)}>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="姓名">
                                    {getFieldDecorator('username', {
                                        initialValue: this.props.record.username,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入姓名！',
                                            }
                                        ]
                                    })(<Input placeholder='姓名' disabled />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="身份证号">
                                    {
                                        getFieldDecorator('certNo', {
                                            initialValue: this.desensitization(this.props.record.certinfo.certNo),
                                            rules: [
                                                {

                                                    required: true,
                                                    message: '请输入身份证号！',
                                                }
                                            ]
                                        })(<Input placeholder='身份证号' disabled />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="性别">
                                    {getFieldDecorator('sex', {
                                        initialValue: this.props.record.sex+"",
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入性别！',
                                            }
                                        ]
                                    })(<Select style={{ width: '100%' }} placeholder="性别" disabled>
                                            {sexOptions}
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="年龄">
                                    {
                                        getFieldDecorator('age', {
                                            initialValue: this.props.record.age,
                                            rules: [
                                                {
                                                    required: true,
                                                    pattern: new RegExp(/^(?:[1-9][0-9]?|1[01][0-9]|200)$/, "g"),
                                                    message: '请输入年龄！',
                                                }
                                            ]
                                        })(<Input placeholder='年龄' disabled />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="银行卡号">
                                    {getFieldDecorator('cardNo', {
                                        initialValue: this.desensitization(this.props.record.bankcardinfo.cardNo),
                                        rules: [
                                            {
                                                required: this.state.cardNoChangeFlag,
                                                message: '请输入银行卡号！',
                                            }
                                        ]
                                    })(<Input placeholder='银行卡号' disabled={this.props.detailFlag || !(this.props.record.accountState === "00" && this.props.record.cardBindState === "00" && this.props.record.userjoinenterprise.paymethod !== "02")} 
                                        onChange = {this.cardNoChange.bind(this)}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="银行预留手机号">
                                    {
                                        getFieldDecorator('mobile', {
                                            initialValue: this.props.record.mobile,
                                            rules: [
                                                {

                                                    required: this.state.mobileRequired && this.state.mobileChangeFlag,
                                                    message: '请输入银行预留手机号！',
                                                }
                                            ]
                                        })(<Input placeholder='银行预留手机号' disabled={this.props.detailFlag || this.state.mobileDisable} 
                                            onChange = {this.mobileChange.bind(this)}/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="证件起始日">
                                    {getFieldDecorator('certStart', {
                                        initialValue: this.props.record.certinfo.certStart === null ? "" : formatDate(new Date(this.props.record.certinfo.certStart), 'yyyy/MM/dd'),
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入证件起始日！',
                                            }
                                        ]
                                    })(<Input placeholder='证件起始日' disabled />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="证件终止日">
                                    {
                                        getFieldDecorator('certEnd', {
                                            initialValue: this.props.record.certinfo.certEnd === null ? "" : formatDate(new Date(this.props.record.certinfo.certEnd), 'yyyy/MM/dd'),
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入证件终止日！',
                                                }
                                            ]
                                        })(<Input placeholder='证件终止日' disabled />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="发放方式">
                                    {
                                        getFieldDecorator('paymethod', {
                                            initialValue: this.props.record.userjoinenterprise.paymethod,
                                            rules: [
                                                {

                                                    required: this.state.paymentodChangeFlag,
                                                    message: '请输入发放方式！',
                                                }
                                            ]
                                        })(<Select style={{ width: '100%' }} placeholder="发放方式" onChange={this.paymentodChanged.bind(this)}  disabled={this.props.detailFlag}>
                                            {paymethodOptions}
                                        </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="常用手机号">
                                    {
                                        getFieldDecorator('wxMobile', {
                                            initialValue: this.props.record.wxMobile,
                                            rules: [
                                                {
                                                    pattern: new RegExp(/^0?(1)[0-9]{10}$/, "g"),
                                                    message: '请输入正确格式手机号码！',
                                                }
                                            ]
                                        })(<Input placeholder='常用手机号'  disabled={this.props.detailFlag} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row> 
                            <Col span={12}>
                                <Form.Item label="项目">
                                    {getFieldDecorator('department', {
                                        initialValue: this.props.record.department === null ? "" : this.props.record.department.did,
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入项目！',
                                            }
                                        ]
                                    })(<Select style={{ width: '100%' }} placeholder="项目" disabled={this.props.detailFlag} onChange={this.departmentChange.bind(this)}>
                                        {departmentOptions}
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="考勤组">
                                    {getFieldDecorator('groupExportId', {
                                        initialValue: this.props.record.userjoinenterprise.groupExportId,
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入考勤组！',
                                            }
                                        ]
                                    })(<Select style={{ width: '100%' }} placeholder="考勤组" disabled={this.props.detailFlag} >
                                        {attendOptions}
                                    </Select>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align='middle'>
                            <Col span={1} offset={10}>
                                    <Button type="primary" icon="form" htmlType="submit" loading={this.state.loading} hidden={this.props.detailFlag}>保存修改</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>

                <Modal
                    title="温馨提示"
                    visible={this.state.msgVisible}
                    onCancel={this.handleCancel.bind(this)}
                    onOk={this.handleOk.bind(this)}
                    closable={true}
                    destroyOnClose={true}
                    width={700}
                    bodyStyle={{overflow: 'auto',height: '120px'}}
                >
                    <span><b>修改发放方式由白名单发放修改为银行卡或记账户，请确认员工四要素信息<font color="red">（姓名、身份证号、银行卡号、手机号）</font>是否正确，如有误则无法完成员工开户及绑卡操作，将影响员工资金发放。</b><br/></span>
                </Modal>
            </div>
        );
    }
}

UpdateStaffAccount = Form.create()(UpdateStaffAccount);
export default UpdateStaffAccount;