import React, { Component } from 'react';
import { Tabs, Icon } from 'antd';
import StaffAddOne from '../../components/Staff/StaffAddOne';
import StaffAddBatch from '../../components/Staff/StaffAddBatch';
import StaffBindCard from './staffBindCard';
import StaffAddBatchQuery from '../../components/Staff/StaffAddBatchQuery/StaffAddBatchQuery';
import SupplierStaffQuery from '@/views/supplierManage/supplierStaffQuery';
const { TabPane } = Tabs;

class simplerStaffAdd extends Component {

    constructor() {
        super();
        this.state = {
            bankCode: JSON.parse(global._sessionStorage.getItem('user')).bankCode,
            key : '1'
        }
    }

    handleChange(activeKey ){
        this.setState({
            key : activeKey
        })
    }


    render() {
        return (
            <span>
                {
                    
                        <div style={{ width: '100%', marginTop: 20}}>
                            <Tabs activeKey={this.state.key} 
                            onChange ={this.handleChange.bind(this)}
                            >
                                <TabPane tab={<span><Icon type="usergroup-add" />员工信息批量录入</span>} key="1">
                                    {
                                        this.state.key === '1' && <StaffAddBatch />
                                    }
                                </TabPane>
                                <TabPane tab={<span>
                                    <Icon type="user-add" />员工信息单笔录入</span>} key="2">
                                    {
                                        this.state.key === '2' && <StaffAddOne />
                                    }
                                </TabPane>
                                {this.state.bankCode === '0001' && 
                                <TabPane tab={<span><Icon type="idcard" />员工绑卡</span>} key="3">
                                    {
                                        this.state.key === '3' && <StaffBindCard />
                                    }
                                </TabPane>
                                }
                                <TabPane tab={<span>
                                    <Icon type="team" />员工批量录入查询</span>} key="4">
                                    {
                                        this.state.key === '4' && <StaffAddBatchQuery />
                                    }
                                </TabPane>
                                <TabPane tab={<span>
                                    <Icon type="team" />员工关系管理</span>} key="5">
                                    {
                                        this.state.key === '5' && <SupplierStaffQuery />
                                    }
                                </TabPane>
                            </Tabs>
                        </div> 


                }</span>


        );

    }
}

export default simplerStaffAdd;