import React, { Component } from 'react';
import { Form, Input, Button, Message, Row, Col } from 'antd';
import { staffInviteOne} from '../../api/staff';

class StaffInviteOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            buttonLoading: false
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({
                    buttonLoading: true,
                })

                params.enterpriseid = this.state.enterpriseid;
                staffInviteOne(params).then(res => {
                    let { statusCode,message } = res.data;
                    if("200" === statusCode){
                        Message.success(message);
                        this.setState({
                            buttonLoading:false,
                        })
                        this.handlerReset();
                        this.props.refreshSubmit();
                    }else{
                        Message.error(message);
                        this.setState({
                            buttonLoading:false,
                        })
                    }
                });

            }
        })
    }

    handlerReset() {
        this.props.form.resetFields();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 12 }
        };
        return (
            <div style={{ width: '100%', marginTop: 20 }}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit.bind(this)}>
                    <Row>
                        <Col>
                            <Form.Item label="姓名">
                                {getFieldDecorator('inName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入姓名！',
                                        }
                                    ]
                                })(<Input placeholder='姓名' />)}
                            </Form.Item>
                            <Form.Item label="身份证号">
                                {
                                    getFieldDecorator('inCertNO', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入正确格式的身份证号！',
                                            }
                                        ]
                                    })(<Input placeholder='身份证号' />)}
                            </Form.Item>
                            {/* <Form.Item label="工号">
                                {getFieldDecorator('inCode', {
                                    rules: [
                                        {
                                           
                                            message: '请输入工号！',
                                        }
                                    ]
                                })(<Input placeholder='工号' />)}
                            </Form.Item> */}
                            <Form.Item label="手机号">
                                {
                                    getFieldDecorator('inPhone', {
                                        rules: [
                                            {

                                                required: true,
                                                message: '请输入手机号！',
                                            }
                                        ]
                                    })(<Input placeholder='手机号' />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} offset={8}>
                            <Button type="primary" icon="form" htmlType="submit" loading={this.state.buttonLoading}>确定</Button>
                            <Button type="primary" icon="redo" style={{ marginLeft: 20 }}  onClick={this.handlerReset.bind(this)}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

StaffInviteOne = Form.create()(StaffInviteOne);

export default StaffInviteOne;