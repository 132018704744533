import React, { Component } from 'react';

import UserIncreInfo from '@/components/User/UserIncreInfo'

class UserIncrease extends Component {
    render() {
        return (
            <div style={ {width : '80%', marginTop : 50, marginLeft : 30 } } >
                <UserIncreInfo/>
            </div>
            
        );
    }
}

export default UserIncrease;