import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import { Card, Button, Icon, Tooltip, Dropdown, Menu, Input, modal, message,Radio,Alert,Empty } from 'antd'
import Highlighter from 'react-highlight-words';
import SearchForm from './SearchForm'
import TableList from './TableList'
import styles from './index.m.less'
import ColumnSetup from './ColumnSetup'
import {DrawerWrapper} from 'wrapper'
import NoData from '@/components/Common/NoData';

const { confirm } = modal;

class index extends Component {

    constructor(){
        super();
        this.state={
            size : 'default',
            filters : [],
            pageOptions : ['10','20','50','100'],
            searchText: '',
            searchedColumn: '',
            selectedRowKeys : [],
            fixedCols : [],
            visible : false,
            searchCache :undefined,
            dataTypeGroupValue : '',
            showAlertInfo :'',
        }
    }

    filterCols = (filters) =>{
        this.setState({
            filters,
            fixedCols : []
        })
    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => {
                  return this.handleSearch(selectedKeys, confirm, dataIndex)}}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>{
          return this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          )},
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
        if(this.props.onSelectChange){
            this.setState({
                showAlertInfo : this.props.onSelectChange(selectedRowKeys),
            })
        }
    };

    onFixed = (key, fixed) =>{
        let fixedCols = this.state.fixedCols.filter(item => item.key !== key);
        if(fixed){
            fixedCols.push({key, value : fixed});
        }
        this.setState({
            fixedCols
        })
    }

    onSubmitValidate = (e) => {
        e && e.preventDefault();
        const {form} =  this.props;
        form.validateFields((err, values) => {
            if(values.dataTypeGroupValue_XINHAI){
                form.setFieldsValue({
                    dataTypeGroupValue_XINHAI : this.state.dataTypeGroupValue
                })
            }
            
            if (err) return;
            this.onSubmit(values)
        });
    }

    searchSubmit = (e) => {
        e && e.preventDefault();
        const {form} =  this.props;
        form.validateFields((err, values) => {
            if(values.dataTypeGroupValue_XINHAI){
                form.setFieldsValue({
                    dataTypeGroupValue_XINHAI : this.state.dataTypeGroupValue
                })
            }
            
            if (err) return;
            values.searchFlag = true
            this.onSubmit(values)
        });
    }

    resetSubmit = (e) => {
        e && e.preventDefault();
        const {form} =  this.props;
        form.validateFields((err, values) => {
            if(values.dataTypeGroupValue_XINHAI){
                form.setFieldsValue({
                    dataTypeGroupValue_XINHAI : this.state.dataTypeGroupValue
                })
            }
            
            if (err) return;
            this.onSubmit(values)
        });
    }

    onSubmit = (values) => {
        const {submit, pagination} =  this.props;
        if(pagination){
            values.currentPage = values.currentPage || 1;
            values.pageSize = values.pageSize || 10;
        }

        this.setState({
            searchCache : values,
        })
        submit(values);
        this.setState({
            selectedRowKeys : [],
        })
    }

    onDrawer = (record) =>{
        const {drawer : {onRecord}} =this.props;
        onRecord(record);
        this.setState({
            visible : true,
            record : record
        })
    }

    closeDrawer = () =>{
        const {drawer : {onRecord}} =this.props;
        onRecord(undefined);
        this.setState({
            visible : false,
            record : undefined
        })
    }

    componentDidMount(){
        const {isLoad, form : { resetFields}} =  this.props;
        resetFields();
        if(isLoad){
            this.onSubmitValidate();
        }
        if(this.props.onRef){
            this.props.onRef(this);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const {searchCache} = this.state;
        if(searchCache && Number(searchCache.currentPage) > 1){
            const totalPage = Math.ceil(Number(nextProps.total) / Number(searchCache.pageSize));
            if(searchCache.currentPage > totalPage){
                searchCache.currentPage = totalPage;
                this.setState({
                    searchCache : {
                        ...searchCache,
                        currentPage : totalPage,
                    }
                }, () => {
                    this.onRefresh();
                })
            }
          
        }
      }
    
    onRefresh = (force) => {
        const { searchCache } =this.state;
        const {submit} = this.props;
        const params = searchCache  || {currentPage : '1' , pageSize : '10'}
        if(force){
            this.setState({
                searchCache : {...params, currentPage : '1'},
            })
        }

        
        submit(force ? {...params, currentPage : '1'} : params);
        this.setState({
            visible : false,
            record : undefined,
            selectedRowKeys : [],
        })
    }

    onChange =(e) => {
        const {tools,form} = this.props;
        if(tools && tools.radioGroup && tools.radioGroup.props){
            form.setFieldsValue({
                dataTypeGroupValue_XINHAI : e.target.value
            },() => {
                this.onSubmitValidate();
            })
            this.setState({
                dataTypeGroupValue :  e.target.value,
            }, () => {
                tools.radioGroup.props.onChange(form.getFieldsValue());
            }) 
        }
    }

    render() {
        const { size, filters, pageOptions, selectedRowKeys, fixedCols, visible, searchCache,showAlertInfo } = this.state;
        const { data, columns, total, batchload, tools, loading, scroll, rowSelect, submit, drawer, search, rowKey, getCheckboxProps} = this.props;
        const list = data.map((item, index) => {
            return {...item, _key: index}
        });
        const onClick = ({ key }) => {
            this.setState({
                size : key,
            })
        };
        const menu =(
            <Menu onClick={(key) => onClick(key)}>
                <Menu.Item key="default">默认</Menu.Item>
                <Menu.Item key="middle">中等</Menu.Item>
                <Menu.Item key="small">紧凑</Menu.Item>
            </Menu>
        )

        let moreButtons = undefined;
        if(tools && tools.operations){
            if(tools.operations.length === 1){
                const btn = tools.operations[0];
                const children =   <Button onClick={() => {
                                                    confirm({
                                                        title: `确定继续当前操作：${btn.name}？`,
                                                        content : `当前操作影响记录数：${selectedRowKeys.length}`,
                                                        icon : <Icon type="exclamation-circle" theme="twoTone" />,
                                                        onOk : () => {
                                                            btn.onClick(selectedRowKeys).then(res => {
                                                                if(!res){
                                                                    this.setState({selectedRowKeys:[]})
                                                                }
                                                            })
                                                        },
                                                        onCancel : () => {
                                                            message.success('已取消操作');
                                                        },
                                                    });
                                    }} loading={batchload} >
                                    {btn.name}
                                </Button>;

                moreButtons =  children
            }else{
                const menuOperate = <Menu >
                        {tools.operations.map((item, index) => {
                            
                                return <Menu.Item key={item.key} onClick={() => {
                                                confirm({
                                                    title: `确定继续当前操作：${item.name}？`,
                                                    content : `当前操作影响记录数：${selectedRowKeys.length}`,
                                                    icon : <Icon type="exclamation-circle" theme="twoTone" />,
                                                    onOk : () => {
                                                        item.onClick(selectedRowKeys).then(res => {
                                                            if(!res){
                                                                this.setState({selectedRowKeys:[]})
                                                        }})
                                                    },
                                                    onCancel : () => {
                                                        message.success('已取消操作');
                                                    },
                                                });
                                    }} >{item.name}</Menu.Item>
                           
                        })}
                    </Menu>;

                moreButtons = <span><Dropdown overlay={menuOperate}>
                            <Button loading={batchload}>
                            批量操作 <Icon type="down" />
                            </Button>
                        </Dropdown></span>
            }

        }

        

        const cols = columns.map(item => {
            let record = item;
            
            if(item.filter){
                record = {
                    ...record,
                    ...this.getColumnSearchProps(item.dataIndex),
                }
            }
            if(!item.align){
                record.align = "center"
            }
            if(item.drawer){
                if(!item.render){
                    record.render = (text, record, index) => { 
                        return <div className={!item.ellipsis ?"span-click" : classNames('span-click', styles.ellipsis)} onClick={() => this.onDrawer(record)} >{text}</div>
                    }
                }
            }
            return record;
        })
        let CheckboxStatus = record => ({
            disabled: record.disabled,
        })
        if(getCheckboxProps){
            CheckboxStatus = getCheckboxProps;
        }

        const rowSelection = rowSelect ? {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: CheckboxStatus
        } : null;
        const _this = this;

        const pagination ={
            current : (searchCache && parseInt(searchCache.currentPage)) || 1,
            total: total,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : pageOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize){
                _this.onSubmit({...searchCache,currentPage : current, pageSize});
            },
            onChange(current){
                _this.onSubmit({...searchCache,currentPage : current});
            }
        }

        const onRow=(record) => {
            return {
              onClick: (visible && (!drawer.isRow || drawer.isRow(record))) ? () => this.onDrawer(record) : null, // 点击行
            };
        }

        const toolDiv = !tools ? null : <div className={styles.tools}>
                <div>
                    {
                        tools && tools.buttons && tools.buttons.length > 0 &&
                        tools.buttons.map((item, index) =>{
                            
                            return <Button key={index}  {...item.props} >
                                    {item.label}
                                </Button>
                        })
                    }
                    {selectedRowKeys.length > 0 && moreButtons }
                    {
                        tools && tools.a && tools.a.length > 0 &&
                        tools.a.map((item, index) =>{
                            
                            return <a key={index}  {...item.props} style={{marginLeft:'10px'}} >
                                    {item.label}
                                </a>
                        })
                    }
                </div>
                <div className={styles.operate}>

                    {
                    tools && tools.radioGroup && tools.radioGroup.radioButtons.length > 0 ?
                         <Radio.Group style={{marginRight:40}} onChange={this.onChange}  defaultValue={tools.radioGroup.defaultValue}  buttonStyle="solid">
                             {tools.radioGroup.radioButtons.map((item, index) =>{
                                    return <Radio.Button style={{width:80,textAlign:'center'}} key={index} value={item.value}>{item.label}</Radio.Button>
                                })
                            }
                        </Radio.Group>
                    :
                         ""
                    }
                    {total !==0 && <span style={{marginLeft : '8px'}} className={styles["tool-icon"]}  >
                        <Dropdown overlay={menu} trigger={['click']} >
                        <Tooltip placement="top" title="密度">
                            <Icon type="column-height" />
                        </Tooltip>
                        </Dropdown>
                    </span>}
                    {total !==0 && <span className={styles["tool-icon"]} 
                    // onClick={tools && tools.refresh ? this.onRefresh : null}
                    onClick={() => submit(searchCache || {currentPage : '1' , pageSize : '10'})}
                    >
                        <Tooltip placement="top" title="刷新">
                            <Icon type="reload" />
                        </Tooltip>
                    </span>}
                    {total !==0 && <ColumnSetup list={columns} filterCols={this.filterCols}
                    fixedCols={fixedCols}
                    onFixed={this.onFixed}
                    >
                        <Tooltip placement="top" title="列设置">
                            <Icon type="setting" className={styles["tool-icon"]} />
                        </Tooltip>
                    </ColumnSetup>}
                </div>
            </div>

        const alertInfo = !showAlertInfo ? null :<div><Alert message={this.state.showAlertInfo} type="info" showIcon /></div>

        const restProps = {
            rowKey : record => record._key
        }

        if(rowKey){
            restProps.rowKey = rowKey
        }

        return (
            <div>
                {
                    search && <SearchForm {...this.props} onSubmit={this.searchSubmit} resetSubmit={this.resetSubmit}/>
                }
                
                <Card bordered={false} bodyStyle={{padding : 0}}>
                    {
                        toolDiv
                    }
                    {
                        alertInfo
                    }
                    
                    { total!==0 ?
                    <TableList 
                    {...restProps}
                    onRow={onRow}
                    loading = {loading}
                    data={list}
                    columns={cols}
                    size={size} 
                    rowSelection={rowSelection}
                    filters={filters} 
                    pagination={pagination} 
                    fixedCols={fixedCols}
                    scroll={scroll}
                    />
                    :
                    <NoData message="暂无数据" />
                    }
            </Card>

            {
                drawer && <DrawerInfo visible={visible} title={drawer.title} onClose={this.closeDrawer} children={drawer.component} />
            }
            
            </div>
        );
    }
}

index.propTypes = {
    tools : PropTypes.oneOfType([
        PropTypes.object, PropTypes.bool
    ]).isRequired,
};



const DrawerInfo = ({
    title, visible, onClose, children
}) => {

    const restProps={
        bodyStyle : {
            maxHeight : 'calc(100vh - 140px)',
            overflowY : 'auto',
        }
    }

    return (
        <DrawerWrapper visible={visible} onClose={onClose} title={title} restProps={restProps} >
            <div style={ title ? {} : {paddingTop : '30px'}}  >
                {children}
            </div>
        </DrawerWrapper>
    );
};

DrawerInfo.propTypes = {
    title : PropTypes.string,
    visible : PropTypes.bool,
    onClose : PropTypes.func,
};

export default index;