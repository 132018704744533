import {http} from '../utils/http'

export function queryUser(data){
    return http.post("/api/userRelated/queryUser",data);
}

export function queryRelatedEnterpris(data){
    return http.post("/api/userRelated/queryRelatedEnterpris",data);
}

export function addUserRelation(data){
    return http.post("/api/userRelated/addUserRelation",data);
}

export function addUserRelationByChoose(data){
    return http.post("/api/userRelated/addUserRelationByChoose",data);
}

export function addUserRelationByExcel(data){
    return http.post("/api/userRelated/addUserRelationByExcel",data);
}

export function queryOpenAccountUser(data){
    return http.post("/api/userRelated/queryOpenAccountUser",data);
}