import React, { Component } from 'react';
import { Modal, Form, Message,Input, Select, Button,DatePicker,Upload,Icon } from 'antd'
import ModalDrag from '../Common/ModalDrag'
import moment from 'moment';
import {uploadSalarys, querySalaryAccount} from 'api/weeksalary'
const { MonthPicker} = DatePicker;
const { Option } = Select;

class CreatePayRollUpload extends Component {
    constructor(props){
        super(props);
        this.state={
            visible : false,
            loading : false,
            fileList: [],
            fileListc: [],
            roundupstate:true,
            payChannelFlag:false,
            ortherChannelFlag:false,
            ortherPayChannel: [],
        }
    }
    
    componentWillMount(){ 
        let params = {
            enterpriseid : JSON.parse(global._sessionStorage.getItem("user")).enterpriseid ,
        };
        querySalaryAccount(params).then(res => {
            let { errorCode, data } =res.data;
            if(errorCode === '0000'){
                if(data && data.length > 0){
                    this.setState({              
                        payChannelFlag : true,          
                        ortherPayChannel : data,
                    })
                }
            }
        })
    }
   
    handlerModalShow(){
        this.setState({
            visible : true,
        })
    }

    handlerCancel(){
        this.props.form.resetFields();
        this.setState({
            visible : false,
            fileList: [],
            fileListc: [],
            roundupstate:true,
            ortherChannelFlag:false,
        })
    }

    handlerReset(e){
        this.setState({
            fileList: [],
            fileListc: [],
            roundupstate:true,
            ortherChannelFlag:false,
        })
        e.preventDefault();
        this.props.form.resetFields();
    }

    handlerSubmit(e){
        const _this=this;
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                let formData = new FormData();
                formData.append("file", this.state.fileList[0]);
                formData.append("file1", this.state.fileListc[0]);
                formData.append("salarydate", moment(params.salarydate).format('YYYY-MM'));
                let user = JSON.parse(global._sessionStorage.getItem("user"));
                formData.append("enterpriseid",user.enterpriseid);
                formData.append("createUser",user.userId);
                formData.append("roundup","07"===params.roundup?params.roundupother:params.roundup);
                formData.append("payChannel",params.payChannel);
                params.otherChannelId && formData.append("otherChannelId", params.otherChannelId);
                this.setState({ loading : true });
                this.props.createPayUpload(formData);
                this.setState({
                    loading : false,
                    visible :false,
                    fileList: [],
                     fileListc: [],
                     roundupstate:true,
                     ortherChannelFlag:false,
                })
                
                
            }
        })
    }

    handleChangeroundup(value){
        if("07" === value){
            this.setState({
                roundupstate : false
            })
        }else{
            this.setState({
                roundupstate : true
            })
        }
    }

    changePayChannel(value){
        this.setState({
            ortherChannelFlag: value==='2'
        })
    }

    render() {
        const formItemStyle = {
            labelCol : { span : 6 },
            wrapperCol : { span : 14 }
        }
        const title = <ModalDrag title="创建发放单数据" />;
        const otherChannelOptions = this.state.ortherPayChannel.map( type =>{
            return <Option key={type.id} value={type.id} >{type.bankName}</Option>
        } )
        // const dateFormat = 'YYYY-MM';
        const _this = this;
        const { fileList,fileListc } = this.state;
        const { getFieldDecorator } = this.props.form;
        const props = {
            accept: ".xls,.xlsx",
            multiple: false,
            beforeUpload: (file) => {
                if (_this.state.fileList.length != 0) {
                    Message.error("仅支持单个文件上传！");
                    return false;
                    _this.setState({
                        fileList: []
                    });
                    
                }
                _this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            onRemove: (file) => {
                _this.setState({
                    fileList: []
                });
            },
            fileList:_this.state.fileList,
        }
        const propsc = {
            accept: ".xls,.xlsx",
            multiple: false,
            beforeUpload: (file) => {
                if (_this.state.fileListc.length != 0) {
                    Message.error("仅支持单个文件上传！");
                    return false;
                    _this.setState({
                        fileListc: []
                    });
                    
                }
                _this.setState(state => ({
                    fileListc: [...state.fileListc, file]
                }));
                return false;
            },
            onRemove: (file) => {
                _this.setState({
                    fileListc: []
                });
            },
            fileList:_this.state.fileListc,
        }
        const roundupOtherRequired = this.state.roundupstate ? {} : { required: true, pattern: new RegExp('^.{0,20}$', "g"), message: '请输入摘要信息，长度20字以内' };
        return (
            <span>
                <Button style={{marginLeft : 70}} type="primary" onClick={this.handlerModalShow.bind(this)} >创建发放单数据</Button>
                {
                    this.state.visible
                    && 
                    <Modal
                        visible={this.state.visible}
                        title={title}
                        onCancel={this.handlerCancel.bind(this)}
                        footer={null}
                        >
                        <div className="user-modify-form" >
                            <Form { ...formItemStyle } onSubmit={this.handlerSubmit.bind(this)} >
                                <Form.Item label="发放月份" >
                                    { getFieldDecorator("salarydate",{
                                        rules : [
                                            { required: true, message: '请输入发放月份' }
                                        ]
                                    })(
                                        <MonthPicker/>
                                    ) }
                                </Form.Item>
                                <Form.Item label="个人薪酬文件" >
                                    { (<Upload {...props}>
                                            <Button>
                                                <Icon type="upload" /> 上传文件
                                            </Button>
                                        </Upload>)
                                    }
                                </Form.Item>
                                <Form.Item label="企业薪酬文件" >
                                    { (<Upload {...propsc}>
                                            <Button>
                                                <Icon type="upload" /> 上传文件
                                            </Button>
                                        </Upload>)
                                    }
                                </Form.Item>
                                <Form.Item label="摘要" >
                                    { getFieldDecorator("roundup",{
                                       rules : [
                                            { required: true, message: '请选择摘要' }
                                       ]
                                        })(
                                        <Select placeholder="请选择摘要" style={{width:"65%"}} onChange={this.handleChangeroundup.bind(this)}>
                                            <Option value='工资'>工资</Option>
                                            <Option value='差旅费'>差旅费</Option>
                                            <Option value='福利费'>福利费</Option>
                                            <Option value='办公费'>办公费</Option>
                                            <Option value='通信费'>通信费</Option>
                                            <Option value='小车费用'>小车费用</Option>
                                            <Option value='业务招待费'>业务招待费</Option>
                                            <Option value='07'>其他费用</Option>
                                        </Select>
                                    ) }
                                </Form.Item>
                                <div hidden={this.state.roundupstate}>
                                    <Form.Item style={{marginLeft : 120,width:"65%"}}>
                                        { getFieldDecorator("roundupother",{
                                            rules : [
                                                roundupOtherRequired
                                            ]
                                        })(
                                            
                                            <Input allowClear placeholder="请输入其他费用" ></Input>
                                            
                                        ) }
                                    </Form.Item>
                                </div>
                                <Form.Item label="发放平台" >
                                    { getFieldDecorator("payChannel",{
                                       rules : [
                                            { required: true, message: '请选择发放平台' }
                                       ]
                                        })(
                                        <Select placeholder="发放平台" style={{width:"65%"}} onChange={this.changePayChannel.bind(this)}>
                                            <Option value='1'>薪企链</Option>
                                            {this.state.payChannelFlag && <Option value='2'>其他平台</Option>}
                                        </Select>
                                    ) }
                                </Form.Item>
                                <div hidden={!this.state.ortherChannelFlag}>
                                    <Form.Item  label="其他平台">
                                        { getFieldDecorator("otherChannelId",{
                                            rules : [
                                                { required: this.state.ortherChannelFlag, message: '请选择平台'}
                                            ]
                                        })(     
                                            <Select placeholder="发放平台" style={{width:"65%"}}>
                                                {otherChannelOptions}
                                            </Select>
                                        ) }
                                        <div style={{lineHeight: '20px'}}>
                                            {this.state.ortherChannelFlag && <span style={{color: 'red'}}>华夏银行：平台发放完成后，资金将发放至华夏银行对公账户，请登录“华夏银行-放薪管家”平台完成后续发放操作。</span>}
                                        </div>
                                    </Form.Item>
                                </div>
                                <div style={{ marginTop:20 }} >
                                        <Button type="primary" onClick={this.handlerReset.bind(this)} style={{marginLeft : 100}} >重置</Button>
                                        <Button type="primary" onClick={this.handlerSubmit.bind(this)} loading={this.state.loading} style={{marginLeft : 50}}  >提交</Button>
                                 </div>
                            </Form>
                        </div>
                    </Modal> 
                }
            </span>
        );
    }
}

CreatePayRollUpload = Form.create()(CreatePayRollUpload)
export default CreatePayRollUpload;