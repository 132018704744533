import React, { Component } from 'react';
import { Message } from 'antd'
import StaffAddBatchQueryForm from './StaffAddBatchQueryForm';
import StaffAddBatchQueryResult from './StaffAddBatchQueryResult';
import { selectUserAddBatch } from '../../../api/staff';

class StaffAddBatchQuery extends Component {
    constructor(){
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            totalNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            loading : false,
            resultLoad : false,
            searchParam : {},
            sourceData : [],
            partnerDetailInfoList: [],
            category: [],
            businessName:"",
        }
    }

    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage : currentPage,
            searchParam : params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params){
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam : params
        })
        this.searchPost(params);
    }

    searchPost(params){
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.eid = user.enterpriseid;
        this.setState({
            resultLoad : true
        })
    
        selectUserAddBatch(params).then(res => {
            let {errorCode, errorMessage, list,totalNum} = res.data;
            if(errorCode === '0000'){
                this.setState({
                    sourceData : list,
                    totalNum : totalNum
                })
            }else{
                Message.error(errorMessage);
            }
            this.setState({
                resultLoad : false
            })
        }).catch( err => {
            Message.error("服务异常");
            this.setState({
                resultLoad : false
            })
        })
    }

    refreshSubmit(){
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    resetForm(){
        this.setState({
            searchParam : {}
        })
    }

    componentWillMount(){
        this.searchFormSubmit({});
    }

    render() {
        return (
            <div>
                <StaffAddBatchQueryForm loading={this.state.loading} searchFormSubmit={this.searchFormSubmit.bind(this)} 
                    resetForm={this.resetForm.bind(this)} businessName={this.state.businessName} category={this.state.category} partnerDetailInfoList={this.state.partnerDetailInfoList}/>
                <StaffAddBatchQueryResult resultData={this.state.sourceData} totalNum={this.state.totalNum} refreshSubmit={this.refreshSubmit.bind(this)} 
                currentPageChange={this.currentPageChange.bind(this)} pageSizeChange={this.pageSizeChange.bind(this)} currentPage={this.state.currentPage}
                businessName={this.state.businessName} category={this.state.category} partnerDetailInfoList={this.state.partnerDetailInfoList}
                pageSizeOptions={this.state.pageSizeOptions} pageSize={this.state.pageSize}
                />
            </div>
        );
    }
}

export default StaffAddBatchQuery;