import React, { Component } from 'react';
import { Form, Row, Col, Input, Button,Modal} from 'antd'
import moment from 'moment';
import MakeSalaryTemp from './MakeSalaryTemp';

class SalaryTempForm extends Component {
    constructor() {
        super();
        this.state = {
            showMakeSalaryTemp:false
        }
    }

    refreshSubmit(e) {
        this.handlerSubmit(e);
    }

    handlerSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset() {
        this.props.form.resetFields();
        this.props.resetForm();
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const dateFormat = 'YYYY-MM-DD';
        return (
            <div>
                <Form className="ant-search-form" onSubmit={this.handlerSubmit.bind(this)} >
                    <Row gutter={12} >
                        <Col span={5} >
                            <Form.Item label="模板名称" >
                                {getFieldDecorator("search", {
                                })(
                                    <Input placeholder="模板名称"></Input>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" style={{ marginLeft: 50 }} loading={this.props.loading} >查询</Button>
                                <Button style={{ marginLeft: 30 }} onClick={this.handlerReset.bind(this)} >重置</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div style={{ marginTop: 10 }} >
                    <MakeSalaryTemp refreshSubmit={this.props.refreshSubmit}/>
                </div>
            </div >
        );
    }
}

SalaryTempForm = Form.create()(SalaryTempForm)

export default SalaryTempForm;