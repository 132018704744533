/* eslint-disable no-unreachable */
import React from 'react';
import { Input, Checkbox, Radio, DatePicker, Row, Col, Icon, Button } from 'antd';
import styles from './index.m.less'

const { TextArea } = Input;

const fields = [
    {id : 'label', content : "标识名", tooltip : '标识名不能为空', required : true},
    {id : 'tip', content : "提示文字", tooltip : '显示在标识名右侧的说明文字'},
]

const moreFields = {
    'select' : [{id : 'select', content : "选项设置（默认勾选）", action : '添加选项'}],
    'checkbox' : [{id : 'checkbox', content : "选项设置（默认勾选）", action : '添加选项'}],
    'date' : [{id : 'date', content : "默认值"}],
    'date-time' : [{id : 'date-time', content : "默认值"}],
}

const defaultFields = [
    {id : 'value', content : "默认值"},
]

const changeOptions = (key, option, options) => {
    return options.map((item, index) => key === index ? option : item)
}

const deleteOptions = (key, options) => {
    return options.filter((item, index) => key !== index)
}
const pushOptions = (option, options) => {
    let op = [].concat(options);
    op.push(option);
    return op;
}

const Attribute = ({
    item,
    changeItem
}) => {
    
    const data = fields.concat(moreFields[item.box] || defaultFields);
    const renderField = (it, index) => {
        const style = {
            width : '100%'
        }
        
        return (
                <div key={index} className={styles["field-item"]} >
                    <div className={styles["item-label"]}>{it.content}
                    {
                        it.action && 
                        <Button size='small' type="link" 
                        onClick={() => changeItem({...item, options : pushOptions(`选项${item.opIndex + 1}`, item.options), opIndex : item.opIndex + 1})}
                        >{it.action}</Button>
                    }
                    </div>
                    <div>
                        {(()=>{
                            switch(it.id){
                                case "label":return <Input {...style} value={item.label} onChange={(e) => changeItem({...item, label : e.target.value})} />; break;
                                case "tip":return <TextArea rows={2} {...style} value={item.tip} onChange={(e) => changeItem({...item, tip : e.target.value})} />; break;
                                case "value": return <Input {...style} value={item.value} onChange={(e) => changeItem({...item, value : e.target.value})} />;break;
                                case "select": 
                                            const optionStyle = {width : '50%'};
                                            const options = item.options.map((op,key) => 
                                                <Row key={key} style={{marginBottom : '5px'}} >
                                                    <Radio value={op}>
                                                        <Input {...optionStyle} value={op} 
                                                        onChange={(e) => changeItem({...item, options : changeOptions(key, e.target.value, item.options), value : op === item.value ? '' : item.value})} />
                                                    </Radio>
                                                    <Icon type="minus-circle" theme="twoTone" style={{marginLeft : '25px'}} 
                                                        onClick={() => changeItem({...item, options : deleteOptions(key, item.options), value : op === item.value ? '' : item.value})} />
                                                </Row>);
                                            return <Radio.Group {...style} value={item.value} onChange={(e) => changeItem({...item, value : e.target.value})} >{options}</Radio.Group>; break;
                                case "checkbox": 
                                            const checkOptions = item.options.map((op,key) => 
                                                <Row key={key} style={{marginBottom : '5px'}} type="flex" justify="space-around" align="middle" >
                                                    <Col span={3}>
                                                        <Checkbox value={op}></Checkbox>
                                                    </Col>
                                                    <Col span={20}>
                                                        <Input style={{width : '100%'}} value={op} 
                                                            onChange={(e) => changeItem({...item, options : changeOptions(key, e.target.value, item.options), value : op === item.value ? '' : item.value})} />
                                                    </Col>
                                                    <Col span={1}>
                                                        <Icon type="minus-circle" theme="twoTone" style={{marginLeft : '25px'}} 
                                                            onClick={() => changeItem({...item, options : deleteOptions(key, item.options), value : op === item.value ? '' : item.value})} />
                                                    </Col>
                                                </Row>);
                                            return <Checkbox.Group value={item.value} onChange={(val) => changeItem({...item, value : val})} >{checkOptions}</Checkbox.Group>; break;
                                case "date": return <DatePicker onChange={(date, dateString) => changeItem({...item, value : dateString})} allowClear />;break;
                                case "date-time": return <DatePicker showTime onChange={(date, dateString) => changeItem({...item, value : dateString})} allowClear />;break;
                                default:return null;
                            }
                        })()}
                    </div>
                    {
                        it.tooltip && <div className={styles["item-tip"]}>
                            <span className={styles["item-tip-required"]}>*</span><span style={ it.required && !item[it.id] ? {color : 'red'} : {}} >{it.tooltip}</span>
                        </div>
                    }
                </div>
            )
    }
    return (
        <div>
            <div className={styles["area-field-topic"]}>
                <span>字段属性</span>
            </div>
            <div className={styles["area-field-content"]}>
                {
                    data.map((item, i) => renderField(item, i))
                }
                <div className={styles["field-item-checkbox"]}>
                    <div>
                        <Checkbox checked={item.required} onChange={(e) => changeItem({...item, required : e.target.checked})} >设为必填</Checkbox>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Attribute;