import React, { Component } from 'react';
import { Tabs, Icon } from 'antd';
import SimplerSalaryPay from './simplerSalaryPay';
import SimplersalaryPayFail from './SimplersalaryPayFail';
const { TabPane } = Tabs;

class simplerSalaryPayTabs extends Component {

    constructor() {
        super();
        this.state = {
            key : '1'
        }
    }

    handleChange(activeKey ){
        this.setState({
            key : activeKey
        })
    }

    render() {
        return (
            <div style={{ width: '100%', marginTop: 18 }}>
                <Tabs activeKey={this.state.key} onChange ={this.handleChange.bind(this)}>
                    <TabPane tab={<span><Icon type="user-add" />审核发放</span>} key="1">
                        {
                            this.state.key === '1' && <SimplerSalaryPay currentFlag = {this.props.currentFlag} history = {this.props.history} 
                            salaryBatchCode={this.props.salaryBatchCode}/>
                        }
                    </TabPane>
                    <TabPane tab={<span><Icon type="usergroup-add" />发放失败处理</span>} key="2">
                        {
                            this.state.key === '2' && <SimplersalaryPayFail />
                        }
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default simplerSalaryPayTabs;