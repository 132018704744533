import React, { Component } from 'react';
import { Table, Alert,Switch,Message} from 'antd'
import QueryStaffDetail from '@/components/Staff/QueryStaffDetail';
import { setSiteManager } from 'api/staff';
class DepartStaffResultSite extends Component {
    constructor() {
        super();    
        this.state = {
            user: JSON.parse(global._sessionStorage.getItem("user"))
        }
    }
     handleSetStatus = (checked,record) =>{
        let params={};
        params.eid =   this.state.user.enterpriseid;
        params.uid =record.id;
        params.did =record.department.did;
        params.siteManager = checked === true ? "0" : "1";
        setSiteManager(params).then(res => {
             this.props.refreshSubmit()
         }).catch(err => {
             Message.error("服务异常");
         })
     }
    render() {
        const _this = this;
        const columns = [
            {
                title: '姓名', dataIndex: 'username', key: 'username', align: 'center',
                render(text, record, index) {
                    return <QueryStaffDetail record={record} />
                }
            },
            {
                title: '性别', dataIndex: 'sex', key: 'sex', align: 'center',
                render(val) {
                    if (val === 0) {
                        return '未知';
                    } else if (val === 1) {
                        return '男';
                    } else if (val === 2) {
                        return '女';
                    } 
                    return "";
                }
            },
            { title: '年龄', dataIndex: 'age', key: 'age', align: 'center' },
            {
                title: '手机号', dataIndex: 'mobile', key: 'mobile', align: 'center'
            },
            { title: '项目', dataIndex: 'department.dname', key: 'department.dname', align: 'center' },
            {
                title: '在职状态', dataIndex: 'userjoinenterprise.state', key: 'userjoinenterprise.state', align: 'center',
                render(val) {
                    if (val === '00') {
                        return '正式';
                    } else if (val === '01') {
                        return '试用';
                    } else if (val === '02') {
                        return '实习';
                    } else if (val === '03') {
                        return '兼职';
                    } else if (val === '04') {
                        return '离职';
                    }
                    return "";
                }
            },
            {
                title: '关联关系', dataIndex: 'userjoinenterprise.unionstatus', key: 'userjoinenterprise.unionstatus', align: 'center',
                render(val) {
                    if (val === '00') {
                        return '已关联';
                    } else if (val === '01') {
                        return '未关联';
                    } else if (val === '02') {
                        return '邀请绑定';
                    } else if (val === '03') {
                        return '过期';
                    } else if (val === '04') {
                        return '已取消';
                    };
                    return "";
                }
            },
            {
                title: '发放方式', dataIndex: 'userjoinenterprise.paymethod', key: 'userjoinenterprise.paymethod', align: 'center',
                render(val) {
                    if (val == '00') {
                        return '记账户';
                    } else if (val === '01') {
                        return '银行卡';
                    } else if (val === '02') {
                        return '白名单发放';
                    }
                    return "";
                }
            },
            {
                title: '开户状态', dataIndex: 'accountState', key: 'accountState', align: 'center',
                render(val) {
                    if (val === '00') {
                        return '未开户';
                    } else if (val === '01') {
                        return '已开户';
                    } else if (val === '02') {
                        return '开户中';
                    }
                    return "";
                }
            },
            {
                title: '绑卡状态', dataIndex: 'cardBindState', key: 'cardBindState', align: 'center',
                render(val) {
                    if (val === '00') {
                        return '未绑卡';
                    } else if (val === '01') {
                        return '已绑卡';
                    } else if (val === '02') {
                        return '绑卡失败';
                    } else if (val === '03') {
                        return '绑卡中';
                    }else if (val === '04') {
                        return '解绑中';
                    }
                    return "";

                }
            },
            {
                title: '现场管理员', dataIndex: 'userjoinenterprise.siteManager', key: 'userjoinenterprise.siteManager', align: 'center',
                render(text, record, index) {
                    
                    return <Switch checkedChildren="是" unCheckedChildren="否" checked={text==='0'?true: false} onChange={(checked) => _this.handleSetStatus(checked,record)} />;

                }
            },
            
        ]
        const pagination = {
            current: parseInt(this.props.currentPage),
            total: this.props.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.props.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }
        return (
            <div style={{ marginTop: '20px' }}>
                <Alert style={{ marginTop: '10px' }} message={(
                    <span>查询结果</span>
                )} type="info" showIcon />
                <Table style={{ marginTop: '20px' }}
                    rowKey={record => record.id}
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData}
                    columns={columns}
                    bordered
                    pagination={pagination}
                >
                </Table>

            </div>
        );
    }
}

export default DepartStaffResultSite;