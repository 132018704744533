import React, { Component } from 'react';
import { Form, Modal, Input, Radio, Checkbox, Button, message,Message, Table, Card,Row,Divider  } from 'antd';
import { toFixed } from 'utils/Number'
import EmployeeItem from '@/components/FormWrapper/EmployeeItem'
import MapComment from './MapComment'
import styles from './editAttend.m.less';
import { Link } from "react-router-dom";
import {queryAttendShiftInfoList,attendUpdate,queryAttendInfo} from 'api/workattend'

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};


@Form.create()
class EditAttend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(global._sessionStorage.getItem("user")),
            loading : false,
            value: 1,
            outClockCheck: true,
            flexibleFlag:false,
            mapVisible: false,
            mapData: [],
            attendanceGroup: {},
            attendEmp: [],
            isSecond : false,

            mapData: [],
            shfitList:[],
            gotoLinkUrl : '/wkattendGroup',
            gotoLinkParms : 0,
            weekDay: ["星期一","星期二","星期三","星期四","星期五","星期六","星期日"],
            typeState:'1',
        }
    }
    onChangeTypeState = e => {
        this.setState({
            typeState: e.target.value
        });
  };
    showMapModal = () => {
        this.setState({
            mapVisible: true,
        });
    };
    closeMapModal = () => {
        this.setState({ mapVisible: false });
    };


    onChange = targetKeys => {
        this.setState({ targetKeys });
    };

    onOutClockChange = e => {
        this.setState({
            outClockCheck: e.target.checked,
        });
    };

    onFlexiableFlagChange = e => {
        this.setState({
            flexibleFlag: e.target.checked,
        });
    };

    handlerSubmit(e) {
        e.preventDefault();
        var query=this.props.location.state;//url.parse(this.props.location.search,true).query;
        this.props.form.validateFields((err, params) => {
            if(params.groupName ===undefined){
                Modal.error({title: '考勤组名称不能为空'})
                return;
            }
            if (params.attendEmp === undefined || params.attendEmp.length === 0) {
                Modal.error({ title: '请选择参与考勤人员' })
                return;
            }
            if("1" === params.type){
                if (params.shiftId === undefined) {
                    Modal.error({ title: '请选择班次' })
                    return;
                }
            }else{
                if (params.shiftIds === undefined || params.shiftIds.length === 0) {
                    Modal.error({ title: '请选择班次' })
                    return;
                }else{
                    params.shiftId=params.shiftIds.join();
                }
            }
            if (this.state.mapData.length == 0) {
                Modal.error({ title: '请选择考勤地点' })
                return;
            }
            if (!params.startWeek) {
                Modal.error({ title: '请选择每周开始日' })
                return;
            }
            if (!params.napWeek) {
                Modal.error({ title: '每周休息日至少选一天' })
                return;
            }

            let user = this.state.user;
            params.eid = user.enterpriseid;
            params.operatorId = user.userId;
            params.mapData = this.state.mapData;
            params.exportId =query.exportId;
            params.startWeek=params.startWeek;
            params.napWeek=params.napWeek.join();
            this.setState({
                loading : true
            })

            attendUpdate(params).then(res => {
                if(res.data.errorCode==='0000'){
                    message.success('考勤组更新成功');
                    this.props.history.push({ pathname: this.state.gotoLinkUrl,state: {current: this.state.gotoLinkParms } });
                }else{
                    message.success(res.data.errorMessage);
                }
                this.setState({
                    loading : false
                })
            }).catch(error => {
                Message.error('error');
                this.setState({
                    loading : false
                })
            });

        })
    }

    getMapTableList = (mapData) => {
        this.setState({
            mapData: mapData,
        })
    }

    editMapTableList = (selectValue) => {
        if(selectValue){
            this.setState({
                mapData: this.state.mapData.map((item,key)=>item.id == selectValue.id?{
                    id : selectValue.id,
                    lngLat : selectValue.lngLat,                    //坐标位置
                    siteType : selectValue.siteType,                  //地址范围形状(1:圆形;2:多边形)
                    centerPoint : selectValue.centerPoint,            //中心点的位置
                    poisName : selectValue.poisName,               //位置名称
                    siteName : selectValue.siteName,                  //详细地址
                    scope : selectValue.scope                        //半径
                }:item)
              });
        }
    }

    deleteMap = (delV) => {
        const mapData = [...this.state.mapData];
        this.setState({ mapData: mapData.filter(item => item !== delV) });
    }

    componentDidMount(){
        let user = this.state.user;
        if(user.versions && user.versions==="0"){
            this.setState({
                gotoLinkUrl : '/simplerAttendance',
            })
        }
        let params = {
            eid : user.enterpriseid,
            operatorId: user.userId
        }
        //查询班次信息
        queryAttendShiftInfoList(params).then(res => {
            this.setState({
                shfitList : res.data.data.wkShiftList,
            })
        }).catch(error => {
            Message.error('error');
        });

        //查询考勤组信息
        let params1 = {
            eid : user.enterpriseid,
            operatorId: user.userId,
            attendExportId:this.props.location.state.exportId,
        }
        queryAttendInfo(params1).then(res => {
            let wkAttendInfo = res.data.data;
            if(res.data.errorCode==='0000'){
                //考勤地址处理
                let mapData = []
                const attendanceSiteList = wkAttendInfo.attendanceGroup.attendanceSiteList
                attendanceSiteList && attendanceSiteList.forEach(item => {
                    mapData.push(
                        {
                            id:item.id,
                            centerPoint: item.latitude + "," + item.longitude,   //中心点位置
                            siteName: item.siteAddress,                                 //详细地址
                            scope: item.scope,                                          //距中心点位置
                            poisName: item.siteName,                                     //位置名称
                            lngLat: item.endonLatlng,                                   //多边形端点位置
                            siteType: item.siteType,                                    //围栏形状
                        }
                    )

                })
                //项目人员处理
                const departList = wkAttendInfo.departmentList
                let attendEmp = [];
                departList && departList.forEach(item => {
                    let children = [];
                    if (!item.checkedAttend && item.appUserList.length > 0) {
                        const empList = item.appUserList;
                        empList && empList.forEach(itemEmp => {
                            children.push({
                                key: itemEmp.id,
                                label: itemEmp.username,
                                level: "2",
                                parent: item.did,
                            })
                        })
                    }

                    attendEmp.push({
                        key: item.did,
                        label: item.dname,
                        level: "1",
                        parent: undefined,
                        checked: item.checkedAttend,
                        autoJoin: true,
                        children,
                    });

                })

                this.setState({
                    attendanceGroup: wkAttendInfo.attendanceGroup,
                    mapData: mapData,
                    attendEmp: attendEmp,
                    outClockCheck : wkAttendInfo.attendanceGroup.outClock === "1" ? true : false,
                    flexibleFlag : wkAttendInfo.attendanceGroup.flexibleFlag === "1" ? true : false,
                    isSecond : true,
                    typeState:wkAttendInfo.attendanceGroup.type ==="1" ? "1":"2",
                })

            }else{
                message.success(res.data.errorMessage);
            }
        }).catch(error => {
            Message.error('网络异常,请刷新重试');
        });

    }

    onRef = (ref) => {
        this.child = ref
    }
    //编辑地图
    editMap = (record) => {
        this.child.onModal(true,record)
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { value, mapData, attendEmp,isSecond,weekDay } = this.state;
        const { shfitList } = this.state;
        const _this = this;
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        const columns = [
            {
                title: '考勤地址',
                dataIndex: 'siteName',
                key: 'siteName',
                render(text, record, index) {
                    return (
                        <div><a onClick={() => _this.editMap(record)}>{record.poisName} </a><br />
                            {record.siteName}
                        </div>
                    )
                },
            },
            {
                title: '围栏形状',
                dataIndex: 'siteType',
                key: 'siteType',
                render(text, record, index) {
                    if(record.siteType==='1'){
                        return '圆形(半径:'+ toFixed(record.scope,2) +'米)';
                    }else if(record.siteType==='2'){
                        return '多边形';
                    }else{
                        return '未知';
                    }
                },
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                width: '15%',
                align: 'center',
                render(text, record) {
                    return (
                        <div>
                            <a onClick={() => _this.deleteMap(record)}>删除</a>
                            <Divider type="vertical" />
                            <a onClick={() => _this.editMap(record)}>编辑</a>
                        </div>
                    )
                }
            },
        ];

        const attr = {
            initialValue: attendEmp,

        }
        
        return (
            <div className={styles['body-content']}>
                <div className={styles.grid}>
                    <Card bordered={false} bodyStyle={{ padding: '24px 24px 10px 24px' }} className={styles['card-body']} >
                        <Form {...layout} onSubmit={this.handlerSubmit.bind(this)}>
                            <Form.Item label="考勤组名称">
                                {getFieldDecorator("groupName", {
                                    initialValue: this.state.attendanceGroup.groupName
                                })(
                                    <Input placeholder="请输入考勤组名称" style={{ width: 180 }}/>
                                )}
                            </Form.Item>

                            <Form.Item label="参与考勤人员">
                                {isSecond ?
                                <EmployeeItem id='attendEmp' form={this.props.form} attr={attr}  />
                                :""
                                 }
                                
                            </Form.Item>
                            <Form.Item label="考勤类型">
                                {getFieldDecorator("type", {
                                    initialValue: this.state.attendanceGroup.type,
                                })(
                                    <Radio.Group style={{ width: '100%' }} onChange={this.onChangeTypeState}>
                                        <Radio value="1">固定班制 (每天考勤时间一样)</Radio>
                                        <Radio value="2" >灵活班制 (多班次)</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            { "1"===this.state.typeState && <Form.Item label="班次选择">
                                {getFieldDecorator("shiftId", {
                                    initialValue: parseInt(this.state.attendanceGroup.shiftId),
                                })(
                                    <Radio.Group onChange={this.onChange} >
                                        {
                                            shfitList !== null ?
                                                shfitList.map((v, i) => {
                                                    return <Radio style={radioStyle}  key={i} value={v.id}>
                                                                {v.shiftName}  {v.addendTime}
                                                            </Radio>
                                                }) : ""
                                        }

                                    </Radio.Group>
                                )}
                            </Form.Item>}
                            { "2"===this.state.typeState && <Form.Item label="班次选择">
                                {getFieldDecorator("shiftIds", {
                                    initialValue:this.state.attendanceGroup.shiftId && this.state.attendanceGroup.shiftId.split(",").map(val => Number(val)),
                                })(
                                    <Checkbox.Group >
                                        {shfitList !== null ?
                                            shfitList.map((v, i) => {
                                                return <Checkbox style={radioStyle} key={i} value={v.id}>
                                                            {v.shiftName}  {v.addendTime}
                                                        </Checkbox>
                                            }) :""
                                        }
                                    </Checkbox.Group>
                                )}
                            </Form.Item>}

                            <Form.Item label="考勤地点添加">
                                <MapComment id="addr" 
                                    onRef={this.onRef} 
                                    mapData={mapData} 
                                    getMapTableList={this.getMapTableList} 
                                    editMapTableList = {this.editMapTableList}  
                                    form={this.props.form} />
                                <Table pagination={false} dataSource={mapData} rowKey='poisName' columns={columns} />
                            </Form.Item>

                            <Form.Item label="每周开始日">
                                {getFieldDecorator("startWeek", {
                                    initialValue: parseInt(this.state.attendanceGroup.startWeek)
                                 })(
                                    <Radio.Group style={{ width: '100%' }}>
                                        <Row>
                                            {
                                                weekDay.map((v, i) => {
                                                    return <Radio  key={i} value={i+1}>
                                                                {v}
                                                            </Radio>
                                                })
                                            }
                                        </Row>
                                    </Radio.Group>
                                )}
                            </Form.Item>

                            <Form.Item label="每周休息日">
                                {getFieldDecorator("napWeek", {
                                    initialValue: this.state.attendanceGroup.napWeek && this.state.attendanceGroup.napWeek.split(",").map(val => Number(val))
                                    })(
                                    <Checkbox.Group style={{ width: '100%' }}>
                                        <Row>
                                            {
                                                weekDay.map((v, i) => {
                                                    return <Checkbox  key={i} value={i+1}>
                                                        {v}
                                                    </Checkbox>
                                                })
                                            }
                                        </Row>
                                    </Checkbox.Group>
                                )}  
                            </Form.Item>

                            <Form.Item label="是否允许外勤打卡">
                                {getFieldDecorator("outClock", {
                                    initialValue: this.state.outClockCheck,
                                })(
                                    <Checkbox value="1" onChange={this.onOutClockChange} checked={this.state.outClockCheck} />
                                )}
                            </Form.Item>
                            <Form.Item label="是否支持灵活用工打卡">
                                {getFieldDecorator("flexibleFlag", {
                                    initialValue: this.state.flexibleFlag,
                                })(
                                    <Checkbox value="1" onChange={this.onFlexiableFlagChange} checked={this.state.flexibleFlag} />
                                )}
                            </Form.Item>
                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
                                <Button type="primary" loading={this.state.loading} htmlType="submit">保存设置</Button>
                                <Button style={{ marginLeft: '8%' }} type="primary" >
                                    <Link to={{ pathname: this.state.gotoLinkUrl, state: {current: this.state.gotoLinkParms } }}>返回上一级 </Link>
                                </Button>
                            </Form.Item>

                        </Form>
                    </Card>
                </div>
            </div>
        );


    }
}

export default EditAttend;