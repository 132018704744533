import React, { Component } from 'react';
import { Form, Row, Col, Input, Button ,Select} from 'antd';
import { baseHttpUrl } from '../../assets/settings';
import { getTokenStr } from '../../utils/http'
import axios from 'axios';
const { Option } = Select;

class StaffBindCardQuery extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem("user")).enterpriseid,
            userName:"",
            phoneNo:"",
            certNo:"",
            cardBindState:"",
        }
    }

    refreshSubmit(e) {
        this.handlerSubmit(e);
    }

    handlerSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({
                    userName:params.name,
                    phoneNo:params.phoneNo,
                    certNo:params.certNo,
                    cardBindState:params.cardBindState,
                })
                 this.props.searchFormSubmit(params);
            }
        })
    }
    
    handlerReset() {
        this.props.form.resetFields();
        this.props.resetForm();
        this.setState({
            userName:"",
            phoneNo:"",
            certNo:"",
            cardBindState:"",
        })
    }

    export(){
        let params = {
            eid: this.state.enterpriseid,
            userName:this.state.userName,
            phoneNo:this.state.phoneNo,
            certNo:this.state.certNo,
            cardBindState:this.state.cardBindState,
        }
        
        axios({
            method: 'GET',
            url: baseHttpUrl+'/api/enBindCard/exportBindCardInfo',
            headers: {
                'Token': getTokenStr(),
            },
            params: params,
            responseType: 'blob'
        }).then(function(res) {
            const content = res.data
            const blob = new Blob([content],{type: "application/x-msdownload"})
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = res.headers['filename'];
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
       
        return (
            <div>
                <Form className="ant-search-form" onSubmit={this.handlerSubmit.bind(this)} >
                    <Row gutter={24} >
                        {/* <Col span={4} >
                            <Form.Item label="工号" >
                                {getFieldDecorator("jobNumber", {
                                })(
                                    <Input placeholder="工号"></Input>
                                )}
                            </Form.Item>
                        </Col> */}
                        <Col span={4} >
                            <Form.Item label="姓名" >
                                {getFieldDecorator("name", {
                                })(
                                    <Input placeholder="姓名"></Input>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={4} >
                            <Form.Item label="身份证号" >
                                {getFieldDecorator("certNo")(
                                    <Input placeholder="身份证号"></Input>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={4} >
                            <Form.Item label="手机号" >
                                {getFieldDecorator("phoneNo")(
                                    <Input placeholder="手机号"></Input>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={4} >
                            <Form.Item label="绑卡状态" >
                                {getFieldDecorator("cardBindState",{
                                    initialValue : '00'
                                })(
                                  <Select>
                                     <Option value="all">全部</Option>
                                     <Option value="00">未绑卡</Option>
                                     <Option value="01">已绑卡</Option>
                                     <Option value="02">绑卡失败</Option>
                                     <Option value="03">绑卡中</Option>
                                     <Option value="04">解绑中</Option>
                                 </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" style={{ marginLeft: '5%' }} loading={this.props.loading} >查询</Button>
                                <Button style={{ marginLeft: '5%' }} onClick={this.handlerReset.bind(this)} >重置</Button>
                                <Button type="primary" style={{ marginLeft: '5%' }} onClick={this.export.bind(this)} >导出</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {/* <div style={{ marginTop: 10 }} >
                    <Button type="primary" onClick={this.refreshSubmit.bind(this)} >刷新</Button>
                </div> */}
            </div >
        );
    }
}
StaffBindCardQuery = Form.create()(StaffBindCardQuery)
export default StaffBindCardQuery;