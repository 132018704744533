import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import DragTypes from '../DragTypes';
const style = {
  cursor: 'move',
}
const ItemArea = ({ id, text, index, moveCard }) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    //定义拖拽的类型
    accept: DragTypes.MOVE,    
    hover(item, monitor) {
      //异常处理判断
      if (!ref.current) { 
        return
      }
      //拖拽目标的Index
      const dragIndex = item.index;
      //放置目标Index
      const hoverIndex = index; 
      // 如果拖拽目标和放置目标相同的话，停止执行
      if (dragIndex === hoverIndex) { 
        return
      }
      //如果不做以下处理，则卡片移动到另一个卡片上就会进行交换，下方处理使得卡片能够在跨过中心线后进行交换.
      //获取卡片的边框矩形
      const hoverBoundingRect = ref.current.getBoundingClientRect();    
      //获取X轴中点
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2; 
      //获取拖拽目标偏移量
      const clientOffset = monitor.getClientOffset();   
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // 从上往下放置
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {  
        return
      }
      // 从下往上放置
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {  
        return
      }
      moveCard(dragIndex, hoverIndex);  //调用方法完成交换
      item.index = hoverIndex;  //重新赋值index，否则会出现无限交换情况
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: DragTypes.MOVE, id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0.4 : 1
  drag(drop(ref))
  return (
    <div ref={ref} style={{ ...style, opacity }}>
      <div >{text}</div>
    </div>
  )
}
export default ItemArea