import {http} from '../utils/http'

// export function mobileSend(data){
//     return http.post("/login/sendLoginSMS",data)
// }

export function mobileSend(data){
    return http.post("/verify",data)
}

export function login(data){
    return http.post("/api/login",data)
}

// export function login(data){
//     return http.post("/login/",data)
// }

export function synchTime(data){
    return http.post("/login/synchTime",data)
}

export function sendVerificationCode(data){
    return http.post("/api/loginCollective/sendVerificationCode",data)
}

export function loginCollective(data){
    return http.post("/api/loginCollective/login",data)
}

export function getUserInfo(data){
    return http.post("/api/loginCollective/getUserInfo",data)
}

export function updateLoginBusiness(data){
    return http.post("/api/loginCollective/updateLoginBusiness",data)
}