import React, { Component } from 'react';
import { Icon, Message, Modal, Table, Alert } from 'antd'
import ModalDrag from '@/components/Common/ModalDrag'
import { querySalaryBatchDetailList } from 'api/weeksalary'
import numeral from 'numeral';

class SupplierPayRecordDetail extends Component {
    constructor(props){
        super(props);
        this.state={
            visible : false,
            searchParam : {},
            totalNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            resultData : [],
            loading : false,
        }
    }

    searchPost(params){
        this.setState({
            loading : true
        })
        querySalaryBatchDetailList(params).then(res => {
            this.setState({
                totalNum : Number(res.data.totalNum),                   
                resultData : res.data.list,
                loading : false
            })
        })
        .catch(error => {
            this.setState({
                loading : false
            })
            Message.error('error');
        });
    }

    searchFormSubmit(params){
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage:"1",
            searchParam : params
        })
        this.searchPost(params);
    }

    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage,
            searchParam : params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchPost(params);
    }

    handlerModalShow(){
        let params = {
            enterpriseId : this.props.record.enterpriseId,
            batchNo : this.props.record.salaryBatchCode,
            detailType : 'P',
            currentPage : '1',
            pageSize : '10'
        }
        this.setState({
            visible : true,
            searchParam : params
        })
        this.searchPost(params);
    }

    handlerCancel(){
        this.setState({
            visible : false,
            currentPage : '1',
            pageSize : '10',
        })
    }

    render() {
        const _this = this;
        let columns = [];
        columns = [
            { title : '姓名', dataIndex : 'username', key : 'username', align : 'center'},
            { title : '身份证', dataIndex : 'certNo', key : 'certNo', align : 'center',
                render(val){
                    if (val && val !== '') { 
                        let ruten =val.substring(4,val.length-4);  //提取字符串下标之间的字符。
                        return val.replace(ruten, '*****');  //字符串中用字符替换另外字符，或替换一个与正则表达式匹配的子串。
                    }else{
                        return val;
                    }
                }
            },
            { title : '卡号', dataIndex : 'acctNo', key : 'acctNo', align : 'center',
                render(val){
                    if (val && val !== '') { 
                        let ruten =val.substring(4,val.length-4);  //提取字符串下标之间的字符。
                        return val.replace(ruten, '*****');  //字符串中用字符替换另外字符，或替换一个与正则表达式匹配的子串。
                    }else{
                        return val;
                    }
                    }
            },
            { title : '手机号', dataIndex : 'mobileNo', key : 'mobileNo', align : 'center',},
            { title : '项目', dataIndex : 'department', key : 'department', align : 'center',},
            { title : '实发工资', dataIndex : 'realSalary', key : 'realSalary', align : 'center',
                render(val){
                    return numeral(val).format('0,0.00');
                }
            },
            { title: '校验状态', dataIndex: 'checkStatus', key: 'checkStatus', align: 'center',
                render(val){
                    return val==='0' ? "成功" : "失败";
                }
            },
            { title : '校验失败原因', dataIndex : 'checkErrorMsg', key : 'checkErrorMsg', align : 'center',},
        ]
        

        const pagination ={
            current : parseInt(this.state.currentPage),
            total: parseInt(this.state.totalNum),
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : this.state.pageSizeOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.currentPageChange(current);
            }
        }
        const title = <ModalDrag title="文件信息详情" />;
        return (
            <span>
            {
                this.props.disabled ? <span className="span-click-disabled" ><Icon type="profile" theme="twoTone" twoToneColor="#d9d9d9" /></span> 
                    : <span className="span-click" onClick={this.handlerModalShow.bind(this)} ><Icon type="profile" theme="twoTone" /></span>
            }
            {
                this.state.visible
                && 
                <Modal
                    visible={this.state.visible}
                    title={title}
                    onCancel={this.handlerCancel.bind(this)}
                    footer={null}
                    width={'80%'}
                    >
                    <div style={{ marginTop: '20px' }}>
                        <Alert style={{ marginTop: '10px' }} message={(
                            <span>查询结果</span>
                        )} type="info" showIcon />
                        <Table style={{ marginTop: '20px' }}
                            rowKey={record => record.detailId}
                            loading={this.state.loading}
                            size="middle "
                            dataSource={this.state.resultData}
                            columns={columns}
                            bordered
                            pagination={pagination}
                        >
                        </Table>
                    </div>
                </Modal> 
            }
            </span>
        );
    }
}

export default SupplierPayRecordDetail;