import React from 'react';
import PropTypes from 'prop-types';
import BraftEditor from 'braft-editor';
import classNames from 'classnames';
import 'braft-editor/dist/index.css'
import styles from './index.m.less'
import {Form} from 'antd'

const FormItem = Form.Item

const RichText = (props) =>{
    const {id, label, attr, form : {getFieldDecorator, setFieldsValue}, restProps, labelProps } = props;
    const excludeControls = [
        'undo', 'redo', 'separator', 'code', 'emoji', 'blockquote', 'strike-through', 
        'letter-spacing',
        'line-height',
        'clear',
        'headings',
        'list-ol',
        'list-ul',
        'remove-styles',
        'superscript',
        'subscript',
        'hr', 'text-align', 'media', 
    ];

    const changeText = (val) => {
        let selectValue = {};
        selectValue[id] = val;
        setFieldsValue(selectValue);
    }
    return (
        <FormItem label={label} {...labelProps} >
        {
            getFieldDecorator(id, {...attr, initialValue : BraftEditor.createEditorState(attr.initialValue)}
                )(<BraftEditor
                    {...restProps}
                    className={styles['_rich-text']}
                    contentClassName={classNames(styles['_rich-text-editor'], 'scroll')}
                    onChange={(val) => changeText(val)}
                    excludeControls={excludeControls}
                    placeholder="请输入正文内容"
                />)
        }
          </FormItem>
    );
};

RichText.propTypes = {
    id : PropTypes.string.isRequired,
    label : PropTypes.string.isRequired,
};

export default RichText;