import React, { Component } from 'react';
import { Table,Message} from 'antd'
import { uploadSalaryQuery2 } from 'api/RechargeController'
import {publicDesensitization} from '../../utils/String'

class ReleaseDetailResult extends Component {

    constructor(){
        super();
        this.state = {
            loading : false,
            searchParam : {},
            sourceData : [],
            totalNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            columns :[],
            tableWidth : 110,
            user : JSON.parse(global._sessionStorage.getItem("user")),

        }
    }

    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage,
            searchParam : params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params){
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam : params
        })
        this.searchPost(params);
    }

    searchPost(params){
        this.setState({
            loading : true
        })
        params.enterpriseid = this.state.user.enterpriseid;
        
        uploadSalaryQuery2(params).then(res => {
            let {errorCode, errorMessage} = res.data;
            if(errorCode === '0000'){
                if(res.data.enterprise != null && res.data.enterprise.joinentid === this.state.user.enterpriseid){
                    this.props.showcoreEnName("推送发放单商户号/名称:"+res.data.enterprise.id+"/"+res.data.enterprise.companyname);
                }
                let columns=[];
                if(res.data.listhead){
                    const _this = this;
                    res.data.listhead.map((head)=>{
                            columns.push(
                                {
                                    title: head.salaryname,
                                    dataIndex: head.salaryentity,
                                    key: head.salaryentity,
                                    align : 'center',
                                    render(text, record, index){
                                        if(head.salaryentity === 'identityid'){
                                            return publicDesensitization(record.identityid);
                                        }else if(head.salaryentity === 'busvid'){
                                            return publicDesensitization(record.busvid);
                                        }
                                        if(head.salaryentity === 'joinentid' && res.data.enterprise && res.data.enterprise.joinentid === _this.state.user.enterpriseid){
                                            return res.data.enterprise.id;
                                        }
                                        return text;
                                    }
                                }
                            )
                    })
                    columns.push(
                        { title : '发放属性', width : '10%', dataIndex : 'salaryCycleCateEnum', key : 'salaryCycleCateEnum', align : 'center'}
                    )
                    columns.push(
                        { title : '发放单状态', width : '10%', dataIndex : 'status', key : 'status', align : 'center',
                            render(val,record){
                                if (record.status == '00') {
                                    return '已发放';
                                } else if (record.status == '01') {
                                    return '未发放';
                                } else if (record.status == '02') {
                                    return '发放失败';
                                } else {
                                    return '发放中';
                                }
                            }
                        }
                    )
                    columns.push(
                        { title : '响应信息', width : '10%', dataIndex : 'respmsg', key : 'respmsg', align : 'center'}
                    )
                    this.setState({
                        sourceData : res.data.list,
                        totalNum : res.data.totalRow,
                        columns : columns,
                        tableWidth : columns.length * 100,
                    });
                    
                }

            }else{
                Message.error(errorMessage);
            }
            this.setState({
                loading : false
            })
        })
    }


    componentDidMount(){
        let params = this.state.searchParam;
        params.bustype = this.props.bustype;
        params.id = this.props.orderNo;
        this.setState({
            searchParam : params
        });

        this.searchFormSubmit(params);
    }


    render() {

        const _this = this;
        const pagination ={
            current : parseInt(this.state.currentPage),
            total: this.state.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : this.state.pageSizeOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.currentPageChange(current);
            }
        }


        const columns = this.state.columns;
        return (
            <div>
                <Table style={{marginTop : '20px'}} 
                    rowKey = { record => record.id }
                    loading={this.state.loading}
                    size="middle "
                    dataSource={this.state.sourceData} 
                    columns={columns}
                    bordered
                    pagination={pagination}
                    scroll={{ x: this.state.tableWidth }}
                >
                </Table>
            </div>
        );
    }
}

export default ReleaseDetailResult;