import React, { Component } from 'react';
import { Divider } from 'antd'
import RoleInfoCommon from './Common/RoleInfoCommon'

class IncreaseBaseInfo extends Component {
    constructor(props){
        super(props);
        const roleForm = this.props.roleForm;
        this.state={
            departData : [],
            checkedKeys : [],
            roleForm : ''  || roleForm,
            roleTypeList : [],
            roleRermissList :[]
        }
    }

    formSubmit(roleForm){
        this.props.stepClick(roleForm);
    }

    render() {
        const formItemStyle = {
            labelCol : { 
                xs: { span: 8 },
                sm: { span: 4 }, 
            },
            wrapperCol : { 
                xs: { span: 12 },
                sm: { span: 10 }, 
            }
        }
        return (
            <div>
                <div>
                    <span className="span-title" >角色信息</span>
                </div>
                <Divider />
                <div className="role-modify-form" >
                <RoleInfoCommon formItemStyle={formItemStyle} departData={this.state.departData}  roleForm={this.props.roleForm} submitText="下一步" resetText="重置"
                        roleInfoSubmit={this.formSubmit.bind(this)} />
                </div>
            </div>
        );
    }
}

export default IncreaseBaseInfo;