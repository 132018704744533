import React, { Component } from 'react';
import { List, Avatar, Button, Typography } from 'antd';
import messageNeed from 'assets/img/message-need.png';
import messageOk from 'assets/img/message-ok.png'
import { formatDate } from 'utils/Date'

const ButtonGroup = Button.Group;

class SimplerNoticeItem extends Component {
    constructor() {
        super();
        this.state = {
            message: ''
        }
    }

    noticeDelete(key) {
        this.props.handlerNoticeDelete(key);
    }

    changeStatus(key) {
        this.props.handlerChangeStatus(key);
        const type = JSON.parse(global._sessionStorage.getItem("type"));
        if(type === "0"){
            this.props.history.push({pathname:"/simplerPay",state : { current : 4, salaryBatchCode:key   }});
        }
        if(type === "1"){
            this.props.history.push({pathname:"/simplerFacPay",state : { current : 3, salaryBatchCode:key }});
        }
        if(type === null || type === "" || type === undefined){
            global._sessionStorage.setItem('type', JSON.stringify("0"));
            this.props.history.push({pathname:"/simplerPay",state : { current : 4, salaryBatchCode:key }});
        }

    }


    render() {
        return (
            <div>
                <div style={{ height: 300, overflowY: 'auto' }} >
                    <List
                        itemLayout="horizontal"
                        dataSource={this.props.noticeLists}
                        renderItem={item => {
                            return (
                                <List.Item key={item.id}>
                                    <Typography.Text mark>
                                        <div style={{ marginRight: 20, marginLeft: 20 }} >
                                            {item.infoOpen ? <Avatar src={messageOk} shape="square" /> : <Avatar src={messageNeed} shape="square" />}
                                            <div>
                                                <span className="boldFont">{formatDate(new Date(item.date), 'MM/dd')}</span>
                                            </div>
                                        </div>
                                    </Typography.Text>
                                    <List.Item.Meta
                                        title={item.infoOpen ?
                                            <div><span className="span-click-disable" >{item.title}</span></div> :
                                            <div><span onClick={() => this.changeStatus(item.title)} className="boldFont span-click" >{item.title}</span></div>}
                                        description={item.infoOpen ?
                                            <span >{item.collapsed ? item.body : item.bodyShow}</span> :
                                            <span className="boldFont" >{item.collapsed ? item.body : item.bodyShow}</span>}
                                    />
                                </List.Item>
                            )
                        }}
                    />
                </div>
                <div>
                </div>

            </div>

        );
    }
}

export default SimplerNoticeItem;