import {http} from '../utils/http'

export function queryUserInfo(data){
    return http.post("/api/appUser/queryUserInfo",data);
}

export function editeAccount(data){
    return http.post("/api/appUser/updateAppUserWithEnterprise",data);
}

export function editeUser(data){
    return http.post("/api/appUser/updateAppUserWithUser",data);
}

export function editeEnterprise(data){
    return http.post("/api/appUser/updateAppUserWithEnterprise",data);
}

export function updateAppUserWithDepartment(data){
    return http.post("/api/appUser/updateAppUserWithDepartment",data);
}

export function selectDept(data){
    return http.post("/api/appUser/selectDept",data);
}

export function selectDeptWithError(data){
    return http.post("/api/appUser/selectDeptWithError",data);
}

export function checkCertNo(data){
    return http.post("/api/appUser/checkCertNo",data);
}

export function addAppUser(data){
    return http.post("/api/appUser/addAppUser",data);
}

export function openAccountBatch(data){
    return http.post("/api/appUser/openAccountBatch",data);
}

export function queryInvites(data){
    return http.post("/api/appUser/invites",data);
}

export function invite(data){
    return http.post("/api/appUser/invite",data);
}

export function inviteCancel(data){
    return http.post("/api/appUser/inviteCancel",data);
}

export function staffInviteOne(data){
    return http.post("/api/appUser/inviteAppUser",data);
}

export function staffInviteBatch(data){
    return http.post("/api/appUser/inviteAppUsers",data);
}

export function bindCardMsgListQuery(data){
    return http.post("/api/enBindCard/bindCardMsgListQuery",data);
}

export function bindCard(data){
    return http.post("/api/enBindCard/bindCard",data);
}

export function updateAppUserPaymethod(data){
    return http.post("/api/appUser/updateAppUserPaymethod",data);
}
export function queryUserInfoByBranch(data){
    return http.post("/api/appUser/queryUserInfoByBranch",data);
}
export function inviteList(data){
    return http.post("/api/appUser/inviteList",data);
}
export function selectUserEnterFailList(data){
    return http.post("/api/appUser/selectUserEnterFailList",data);
}
export function softDeleteUserEnterFail(data){
    return http.post("/api/appUser/softDeleteUserEnterFail",data);
}
export function downloadUserFailDetail(data){
    return http.post("/api/appUser/downloadUserFailDetail",data);
}
export function failUserOpenAccountBatch(data){
    return http.post("/api/appUser/failUserOpenAccountBatch",data);
}
export function failUserOpenAccount(data){
    return http.post("/api/appUser/failUserOpenAccount",data);
}
export function staffPaymethodUpload(data){
    return http.post("/api/appUser/staffPaymethodBatch",data);
}
export function selectUserAddBatch(data){
    return http.post("/api/appUser/selectUserAddBatch",data);
}
export function selectUserAddDetail(data){
    return http.post("/api/appUser/selectUserAddDetail",data);
}

export function changeCardMsgQuery(data){
    return http.post("/api/enChangeCard/changeCardMsgQuery",data);
}

export function changeCard(data){
    return http.post("/api/enChangeCard/changeCard",data);
}

export function changeCardHisMsgQuery(data){
    return http.post("/api/enChangeCard/changeCardHisMsgQuery",data);
}

export function queryAllAttendInfoList(data){
    return http.post("/api/workattend/queryAllAttendInfoList",data);
}

export function updateSalarymethodBatch(data){
    return http.post("/api/appUser/updateSalarymethodBatch",data);
}

export function checkWxMobile(data){
    return http.post("/api/appUser/checkWxMobile",data);
}
export function setSiteManager(data){
    return http.post("/api/appUser/setSiteManager",data);
}
//修改员工薪酬信息
export function updateAppUserSalaryMethod(data){
    return http.post("/api/appUser/updateAppUserSalaryMethod",data);
}