import React, { Component } from 'react';
import CreatePayRecordSearch from '@/components/payrollmanage/CreatePayRecordSearch';
import CreatePayRecordResult from '@/components/payrollmanage/CreatePayRecordResult';
import { querySalaryBatchRecordList } from 'api/weeksalary'
import { Message } from 'antd';

class CreatePayRecord extends Component {
    constructor(props) {
        super(props);
        this.state={
            loading : false,
            searchParam : {},
            resultData : [],         
            totalNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            visible: false,
            errorMessage: undefined
        }
    }

    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage :currentPage,
            searchParam : params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params){
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            searchParam : params,
            currentPage : "1"
        })
        this.searchPost(params);
    }

    resetForm(){
        this.setState({
            searchParam : {}
        })
    }

    refreshSubmit(){
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    searchPost(params){
        this.setState({
            loading : true
        })
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseId = user.enterpriseid;
        querySalaryBatchRecordList(params).then(res => {
            this.setState({
                totalNum : Number(res.data.totalNum),                   
                resultData : res.data.list,
                loading : false
            })
        })
        .catch(error => {
            this.setState({
                loading : false
            })
            Message.error('error');
        });
    }

    componentWillMount(){ 
        let params = {
            currentPage : this.state.currentPage,
            pageSize : this.state.pageSize
        };
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseId = user.enterpriseid;
        this.setState({
            searchParam : params
        })
        console.info(JSON.stringify(params));
        this.searchPost(params);
    }

    handleCancel(){
        this.setState({
            visible:false,
            errorMessage:''
        })
    }


    render() {
        return (
            <div>
                <CreatePayRecordSearch 
                    searchFormSubmit={this.searchFormSubmit.bind(this)} 
                    resetForm={this.resetForm.bind(this) }
                    departList={this.state.departList}
                />
                <CreatePayRecordResult 
                    resultData={this.state.resultData} 
                    totalNum={this.state.totalNum} 
                    currentPage={this.state.currentPage} 
                    loading={this.state.loading} 
                    pageSizeOptions={this.state.pageSizeOptions} 
                    currentPageChange={this.currentPageChange.bind(this)}
                    pageSizeChange={this.pageSizeChange.bind(this)} 
                    refreshSubmit={this.refreshSubmit.bind(this)} 
                    currentFlag = {this.props.currentFlag}
                    pageSize={this.state.pageSize}
                />
            </div>
        );
    }
}

export default CreatePayRecord;