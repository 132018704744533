import React, { Component } from 'react';
import { Breadcrumb } from 'antd';

class HomeCrumb extends Component {
    render() {
        const crumb = this.props.RoutesLink.map(item =>{
            if(this.props.currentPath === item.path){
                return item.crumbs.map(crumb =>{
                    return <Breadcrumb.Item key={crumb} >
                            {crumb}
                        </Breadcrumb.Item>
                })
            }
            return true;
        });

        return (
            <Breadcrumb>
                {crumb}
            </Breadcrumb>
          
        );
    }
}

export default HomeCrumb;