import React, { Component } from 'react';
import { Row, Col, Icon,Button, Spin, Upload, Message, Divider,Modal } from 'antd'
import { openAccountBatch } from '../../api/staff';
import { baseHttpUrl } from '../../assets/settings';
const Dragger = Upload.Dragger;

class StaffAddBatch extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            bankCode: JSON.parse(global._sessionStorage.getItem('user')).bankCode,
            modalShow: false,
            uploadDisabled: false,
            confirmLoading: false,
            fileList: [],
            spinning: false,
            visible: false,
            errorMessage: undefined,
            headMessage:"",
            fileName : '',
        }
    }

    handlerReset() {
        this.setState({
            fileList: [],
            uploadDisabled: false,
            confirmLoading: false,
        })
    }
    uploadSubmit() {
        if (this.state.fileList.length === 0) {
            Message.error("请先上传文件！");
            return;
        }
        this.setState({
            spinning:true,
        })
        let formData = new FormData();
        formData.append("file", this.state.fileList[0]);
        formData.append("eid", this.state.enterpriseid);
        openAccountBatch(formData).then(res => {
            let data = res.data;
            if(data.code === "200"){
                // if(data.totalNum === data.successNum){
                //     Message.success(data.totalNum+"条数据开户成功！");
                // }else{
                //     let headMessage = "共录入"+data.totalNum+"条数据，"+data.successNum+"条成功，"+data.failNum+"条失败，"+data.noSure+"条处理中，错误信息如下：";
                //     let temp = res.data.errorInfo.map( (item, index) => {
                //         return <span key={index} >{item} <br/></span> ;
                //     })
                //     this.setState({
                //         visible:true,
                //         errorMessage:temp,
                //         headMessage:headMessage
                //     })
                // }
                Message.success(data.msg);
            }else{
                Message.error(data.msg);
            }

            this.setState({
                modalShow: false,
                uploadDisabled: false,
                confirmLoading: false,
                fileList: [],
                spinning: false,
            }) 
        })

    }

    handleCancel(){
        this.setState({
            visible:false,
            errorMessage:''
        })
    }

    componentWillMount() {

        if(this.state.bankCode==='0001'){
            this.setState({
                fileName : '/openAccountBatch.xlsx'
            })
        }else{
            this.setState({
                fileName : '/openAccountBatch1.xlsx'
            })
            
        }

    }

    render() {
        const _this = this;
        const { fileList } = this.state;
        const props = {
            accept: ".xls,.xlsx",
            multiple: false,
            beforeUpload: (file) => {
                if (this.state.fileList.length !== 0) {
                    Message.error("仅支持单个文件上传！");
                    _this.setState({
                        fileList: [],
                        uploadDisabled: false,
                        confirmLoading: false,
                    });
                    return false;
                }
                _this.setState(state => ({
                    fileList: [...state.fileList, file],
                    uploadDisabled: true,
                }));
            },
            onRemove: (file) => {
                _this.setState({
                    fileList: [],
                    uploadDisabled: false,
                    confirmLoading: false,
                });
            },
            fileList,
        };
        return (
            <div style={{ width: '100%', marginTop: 50, marginLeft: 30 }}>
                <Spin tip="数据解析中..." spinning={this.state.spinning}>

                    <div style={{ width: '90%', marginTop: 50 }}>
                        <Dragger {...props} >
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">单击或拖动文件到此区域</p>
                            <p className="ant-upload-hint">仅支持单个文件</p>
                        </Dragger>
                    </div>
                    <Divider />
                    <b>温馨提示：</b>
                    <p></p>    
                    <p>1.请<a href={baseHttpUrl+this.state.fileName}><Icon type="download"/>点击此处</a>下载标准模板；</p>
                    <p>2.标红字段为必填字段，请准确填写；</p>
                    <p>3.时间格式: yyyy-MM-dd(例：2019-05-01)。</p>
                </Spin>
                <div style={{ width: '100%', marginTop: 50}}>
                    <Row>
                        <Col span={8} offset={9}>
                            <Button type="primary" icon="redo" onClick={this.handlerReset.bind(this)}>重置</Button>
                            <Button type="primary" icon="form" onClick={this.uploadSubmit.bind(this)} style={{ marginLeft: 50 }}>上传</Button>
                        </Col>
                    </Row>
                </div>

                <Modal
                    title="员工批量录入"
                    visible={this.state.visible}
                    onCancel={this.handleCancel.bind(this)}
                    closable={true}
                    destroyOnClose={true}
                    footer={null}
                    width={700}
                    bodyStyle={{overflow: 'auto',height: '250px'}}
                >
                    <span><b>{this.state.headMessage}</b><br/></span>
                    <p></p>    
                    {this.state.errorMessage}
                </Modal>
            </div>

        );
    }
}

export default StaffAddBatch;