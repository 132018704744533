import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import styles from './index.m.less'
import { fastDsfUrl } from '@/assets/settings'
import ModalWrapper from '@/components/ModalWrapper'

const Index = ({ 
    srcType,
    src,
    alt,
    srcSet,
    className,
    restProps,
}) => {

    const [	visible, onVisible ] = useState(false);

    const onPreview = (visible, index) => {
        onVisible(visible);
    }

    return (
        <div {...restProps} className={classNames(styles.main, className)} >
            <img src={srcType === 'fastdfs' ? `${fastDsfUrl}${src}` : src} alt={alt} srcSet={srcSet} width='100%' height='100%' onClick={() => onPreview(true) } />
            <PreviewImage visible={visible} onClose={onPreview} src={srcType === 'fastdfs' ? `${fastDsfUrl}${src}` : src} alt={alt} srcSet={srcSet} />
        </div>
    );
};

Index.propTypes = {
    srcType : PropTypes.oneOf(['fastdfs']),
    src : PropTypes.string.isRequired,
    alt : PropTypes.string,
    srcSet : PropTypes.string,
    className : PropTypes.string,
    restProps : PropTypes.object,
};


const PreviewImage = (props) => {
    const { visible, onClose, src, alt, srcSet} = props;

    return (
        <div>
            <ModalWrapper
            bodyStyle={{ padding: '0px'}}
            className={styles.preview}
            childrenClassName={styles['preview-child']}
            destroyOnClose
            visible={visible}
            footer={false}
            onCancel={() => onClose(false)}
            >
                <img src={src} alt={alt} srcSet={srcSet} width='100%' height='100%' />
            </ModalWrapper>
        </div>
    );
};

export default Index;