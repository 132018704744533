import {http} from '../utils/http'


export function queryCoreSalaryBatch(data){
    return http.post("/api/salary/queryCoreSalaryBatch",data);
}

export function downloadCoreSalaryTmp(data){
    return http.post("/api/salary/downloadCoreSalaryTmp",data);
}

export function uploadCoreSalarys(data){
    return http.post("/api/salary/uploadCoreSalarys",data);
}


export function getJoinList(data){
    return http.post("/api/salary/getJoinList",data);
}

export function coreSalaryQuery(data){
    return http.post("/api/salary/coreSalaryQuery",data);
}

export function submitCoreSalaryBatchList(data){
    return http.post("/api/salary/submitCoreSalaryBatchList",data);
}

export function queryWords(data){
    return http.post("/api/salary/queryWords",data);
}

export function queryCoreEnSalaryBatchRecordList(data){
    return http.post("/api/salary/queryCoreEnSalaryBatchRecordList",data);
}
