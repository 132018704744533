import React, { Component } from 'react';
import { Table, Alert, Button, Modal, Tabs, Icon } from 'antd'
import ModalDrag from '../Common/ModalDrag'

class StaffInviteListCommit extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    handlerCancel() {
        this.props.cancelCommit();
    }

    handlerModalShow() {    
        this.props.showCommit(); 
    }

    render() {
        const _this = this;
        const columns = [
            {
                title: '姓名', width: '200px', dataIndex: 'username', key: 'username', align: 'center',
            },
            { title: '身份证号', width: '200px', dataIndex: 'certinfo.certNo', key: 'certinfo.certNo', align: 'center', }

        ]
        const pagination ={
            total: this.props.resultData.length,
            pageSize: 20,
            showTotal(total){
                return `共 ${total} 条数据`
            },
        }

        const title = <ModalDrag title="提交详情" />; 
        return (

            <span>
                <Button type="primary" disabled = {this.props.buttonFlag}  onClick={this.handlerModalShow.bind(this)} loading={this.props.commitloading}>提交邀请</Button>
                {
                    this.props.showCommitFlag
                    && 
                    <Modal
                        visible={this.props.showCommitFlag}
                        title={title}
                        onCancel={this.handlerCancel.bind(this)}
                        footer={null}
                        >
                        <div>
                    <Table
                        scroll={{ y: 240 }}
                        style={{ marginTop: '20px' }}
                        rowKey={record => record.id}
                        size="middle"
                        dataSource={this.props.resultData}
                        columns={columns}
                        pagination={pagination}
                        bordered
                    >
                    </Table>

                    <Button type="primary" style={{ marginLeft: '30%', marginBottom: 10 }} onClick={this.handlerCancel.bind(this)} >关闭</Button>
                    <Button type="primary" style={{ marginLeft: '15%', marginBottom: 10 }} onClick={this.props.commit.bind(this)} >确定</Button>
                </div>
                    </Modal> 
                }
            </span>



            // <Modal
            //     style={{ top: '5%' }}
            //   //  visible={this.state.showCommitFlag}
            //     title={title}
            //     onCancel={this.handlerCancel.bind(this)}
            //     footer={null}
            //     width={800}
            // >
            //     <div>
            //         <Table
            //             // scroll={{ x: 1800}} 
            //             style={{ marginTop: '20px' }}
            //             rowKey={record => record.id}
            //             size="middle"
            //             dataSource={this.props.resultData}
            //             columns={columns}
            //             bordered
            //         >
            //         </Table>

            //         <Button type="primary" style={{ marginLeft: '35%', marginBottom: 10 }} onClick={this.handlerCancel.bind(this)} >关闭</Button>
            //         <Button type="primary" style={{ marginLeft: '15%', marginBottom: 10 }} onClick={this.props.commit.bind(this)} >确定</Button>
            //     </div>
            // </Modal>


        );
    }
}

export default StaffInviteListCommit;