import React, { Component } from 'react';
import { Layout, Steps, Card, Affix } from 'antd'
import SimplerStaffAdd from 'views/staff/simplerStaffAdd'
import SalaryTempManage from 'views/salaryTemp/salaryTempManage'
import SimplerCreatePayTabs from 'views/payrollmanage/SimplerCreatePayTabs'
import SimplerSalaryPayTabs from 'views/finance/simplerSalaryPayTabs'
import SimplerStaffInviteManage from 'views/staff/simplerStaffInviteManage'


import 'assets/css/payCommon.less'


const { Header } = Layout

const { Step } = Steps

class SimplerPay extends Component {

    constructor(){
        super();
        this.state = {
            current : 0,
            roleForm : {},
            expandedKeys : [],
            salaryBatchCode:"",
        }
    }


    componentDidMount(){
        this.setState({
            current : this.props.location&&this.props.location.state&&this.props.location.state.current ?this.props.location.state.current : 0,
            salaryBatchCode:this.props.location&&this.props.location.state&&this.props.location.state.salaryBatchCode ?this.props.location.state.salaryBatchCode : "",
        })
    }

    onChange = current => {
        this.setState({ current });
      };


    handlerShowSiderChild(){
        let key = this.state.current;
        switch(key){
            case 0 : return (<SimplerStaffAdd  />);
            case 1 : return (<SimplerStaffInviteManage history={this.props.history} currentFlag = {0}/> );
            case 2 : return (<SalaryTempManage /> );
            case 3 : return (<SimplerCreatePayTabs history={this.props.history} currentFlag = {0}/>);
            case 4 : return (<SimplerSalaryPayTabs  history={this.props.history} currentFlag = {0} salaryBatchCode={this.state.salaryBatchCode} />);
            default : return ( <div></div> );
        }

    }
    
    render() {
        const stepStyle = {
            // marginBottom: 60,
            // boxShadow: '0px -1px 0 0 #e8e8e8 inset',
            lineHeight: 0,
            textAlign:'center',
          };
        return (
            <Layout className="pay-layout">
            <Affix offsetTop={90} >
                <Header className='pay-steps-menu' style={{  zIndex: 9, width:'100%',textAlign:'center',marginTop:'-5px' }}>
                    <div>
                    <Steps  direction="horizontal" current={this.state.current} type="navigation" onChange={this.onChange} style={stepStyle} >
                        <Step title="录入员工信息" description={ <div><br/><br/></div> } className='pay-step'/>
                        <Step title="员工邀请" description={ <div><br/><br/></div> } className='pay-step'/>
                        <Step title="定制发放单模板" description={ <div><br/><br/></div> } className='pay-step'/>
                        <Step title="创建发放单" description={ <div><br/><br/></div> } className='pay-step'/>
                        <Step title="薪资审核发放" description={ <div><br/><br/></div> } className='pay-step'/>
                    </Steps>
                    </div>
                </Header>
                </Affix>
                <Layout className="pay-content-layout" >
                    <Card className="pay-content" >
                        {this.handlerShowSiderChild()}
                    </Card>
                </Layout>
                
            </Layout>
        );
    }
}

export default SimplerPay;