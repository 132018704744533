import React, { Component } from 'react';
import { Table, Alert} from 'antd'
import {Link} from 'react-router-dom' 
import SimplerSalaryPayOperateCheck from '@/components/finance/SimplerSalaryPayOperateCheck'
import SimplerSalaryPayOperate from '@/components/finance/SimplerSalaryPayOperate'
import numeral from 'numeral';


class SimplerSalaryPayQueryResult extends Component {
    render() {
        const user = JSON.parse(global._sessionStorage.getItem("user"));
        const _this = this;
        const columns = [
            { title : '批次', width : 150, dataIndex : 'salarybatchcode', key : 'salarybatchcode', align : 'center',
                render(text, record, index){
                    return (
                        <div>
                            <Link to={{ pathname : '/simplerReleaseDetail' , state : {historyFlag:false, currentFlag:_this.props.currentFlag, record: record }}} >{record.salarybatchcode}</Link>
                        </div>
                    )
                }
            },
            { title : '发放日期', width : 80, dataIndex : 'salarydate', key : 'salarydate', align : 'center'},
            { title : '总条数', width : 60, dataIndex : 'employeenum', key : 'employeenum', align : 'center'},
            { title : '总金额', width : 100, dataIndex : 'totalnum', key : 'totalnum', align : 'center'},
            { title : '已划款金额', width : 100, dataIndex : 'transferAmount', key : 'transferAmount', align : 'center',
                render(val){
                    return numeral(val).format('0,0.00');
                }
            },
            { title : '发放单状态', width : 90, dataIndex : 'checkStatusTxt', key : 'checkStatusTxt', align : 'center',},
            { title : '发放时间', width : 170,dataIndex : 'releasetime', key : 'releasetime', align : 'center'},
            { title : '是否申请融资',  dataIndex : 'factorstatus', key : 'factorstatus', align : 'center',
                render(val,record){
                    if(record.factorstatus=='04' || record.factorstatus=='01'){
						return '已申请';
					}else{
						return '未申请';
					}
                }
            },
            { title : '提交时间',width : 170, dataIndex : 'submittime', key : 'submittime', align : 'center'},
            { title : '发放属性', width : 80, dataIndex : 'salaryCycleCateEnum', key : 'salaryCycleCateEnum', align : 'center'},
            { title : '摘要', width : 70, dataIndex : 'roundup', key : 'roundup', align : 'center',ellipsis:true, },
            { title : '发放平台', width : 80, dataIndex : 'payChannel', key : 'payChannel', align : 'center',
                render(val){
                    if(val==='1' ){
                        return '薪企链';
                    }else{
                        return '虎符发放';
                    }
                } 
            },
            { title : '项目',  dataIndex : 'dname', key : 'dname', align : 'center',ellipsis:true},
            { title : '关联商户号/关联商户名称', dataIndex : 'joinentid', key : 'joinentid', align : 'center',ellipsis:true,
                render(val,record){
                    if(record.joinentid){
                        if(record.joinentid === user.enterpriseid){
                            return record.enterpriseid+"/"+record.enname;
                        }else{
                            return record.joinentid+"/"+record.joinentname;
                        }
                    }else{
                        return '';
                    }
                }
            },
            { title : '操作', width : 100, align : 'center',fixed: 'right',
                render(text, record, index){
                    return (
                        <div>
                            {_this.props && _this.props.checkState === '0' ?
                                <SimplerSalaryPayOperateCheck 
                                record={record}
                                refreshSubmit={_this.props.refreshSubmit}
                                history={_this.props.history}
                                batchCode={_this.props.batchCode}
                                />
                                :
                                <SimplerSalaryPayOperate 
                                record={record}
                                refreshSubmit={_this.props.refreshSubmit}
                                history={_this.props.history}
                                batchCode={_this.props.batchCode}
                                currentFlag={_this.props.currentFlag}
                                />
                            }
                            
                        </div>
                    )
                }
            },
        ]

        const pagination ={
            current : parseInt(this.props.currentPage),
            total: this.props.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : this.props.pageSizeOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }
        
        return (
            <div style={{marginTop : '20px'}}>
                <Alert style={{marginTop : '10px'}} message={(
                                    <span>查询结果</span>
                                )} type="info" showIcon />
                <Table style={{marginTop : '20px'}} 
                    rowKey = { record => record.id }
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData} 
                    columns={columns}
                    bordered
                    pagination={pagination}
                    scroll={{ x: 1500 }}
                >
                </Table>
                   
            </div>
        );

    }
}

export default SimplerSalaryPayQueryResult;