import React, { Component } from 'react';
import { Checkbox, Row, Col, Input, Button, Tag, Radio, Modal,Message } from 'antd'
import { querySalaryParts, createSalaryTmp } from '../../api/salaryTemp';

let allInfoMap = new Map();
let chooseCheck = [];
class MakeSalaryTemp extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem("user")).enterpriseid,
            username: JSON.parse(global._sessionStorage.getItem("user")).username,
            buttonLoading: false,
            showMakeSalaryTemp: false,
            tempName: "",
            bustype: "",
            baseInfo: [],
            plusInfo: [],
            subInfo: [],
            extInfo: [],
            showTag: [],
            firstShowTag:[],
            endTag:[],
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({
                    buttonLoading: true,
                })


            }
        })
    }

    tempNameChange(e) {
        this.setState({
            tempName: e.target.value,
        })
    }

    bustypeChange(e) {
        chooseCheck = [];
        this.setState({
            baseInfo: [],
            plusInfo: [],
            subInfo: [],
            extInfo: [],
            showTag: [],
            firstShowTag:[],
            endTag:[],
        })

        let bustype = e.target.value;
        this.setState({
            bustype: bustype,
        })
        let salarytype = "";
        if ("P" === bustype) {
            salarytype = "bus";
        }
        if ("C" === bustype) {
            salarytype = "base";
        }
        let params = {
            salarytype: salarytype,
        }
        querySalaryParts(params).then(res => {
            let result = [];
            result = res.data;
            let baseInfo = [];
            let plusInfo = [];
            let subInfo = [];
            let extInfo = [];
            for (let i = 0; i < result.length; i++) {
                if (result[i].salarytype === "base") {
                    baseInfo.push(result[i]);
                    allInfoMap.set(result[i].salaryentity, result[i]);
                } else if (result[i].salarytype === "bus") {
                    baseInfo.push(result[i]);
                    allInfoMap.set(result[i].salaryentity, result[i]);
                } else if (result[i].salarytype === "plus") {
                    plusInfo.push(result[i]);
                    allInfoMap.set(result[i].salaryentity, result[i]);
                } else if (result[i].salarytype === "sub") {
                    subInfo.push(result[i]);
                    allInfoMap.set(result[i].salaryentity, result[i]);
                } else if (result[i].salarytype === "ext") {
                    extInfo.push(result[i]);
                    allInfoMap.set(result[i].salaryentity, result[i]);
                }
            }

            // for (let i = 0; i < result.length; i++) {
            //     allInfoMap.set(result[i].salaryentity, result[i]);
            // }

            let firstShowTag = [];
            if (bustype === "C") {
                firstShowTag.push(allInfoMap.get("busvid").salaryname);
                firstShowTag.push(allInfoMap.get("busvname").salaryname);
            }
            if (bustype === "P") {
                firstShowTag.push(allInfoMap.get("name").salaryname);
                firstShowTag.push(allInfoMap.get("identityid").salaryname);
            }

            let endTag = [];
            endTag.push(allInfoMap.get("realsalary").salaryname);
            this.setState({
                baseInfo: baseInfo,
                plusInfo: plusInfo,
                subInfo: subInfo,
                extInfo: extInfo,
                firstShowTag: firstShowTag,
                endTag:endTag
            });
        });
    }

    checkedOnChanged(e) {
        let salaryentity = e.target.value;
        let checked = e.target.checked;
        let showTag = [];
        if (checked === true) {
            chooseCheck.push(salaryentity);
        }
        if (checked === false) {
            for (let i = 0; i < chooseCheck.length; i++) {
                if (chooseCheck[i] === salaryentity) {
                    chooseCheck.splice(i, 1);
                }
            }
        }
        for (let i = 0; i < chooseCheck.length; i++) {
            showTag.push(allInfoMap.get(chooseCheck[i]).salaryname);
        }

        this.setState({
            showTag: showTag,
        })
    }

    submit() {
        if(this.state.tempName === ""){
            Message.error("请输入模板名称");
            return;
        }
        if(this.state.bustype === ""){
            Message.error("请选择对公对私");
            return;
        }
        this.setState({
            buttonLoading: true,
        })

        let tmpInfos =[];
        let tmp1 = {};
        let tmp2 = {};
        if(this.state.bustype === "P"){
            tmp1.salaryname=allInfoMap.get("name").salaryname;
            tmp1.salaryentity=allInfoMap.get("name").salaryentity;
            tmp1.salarypartid=allInfoMap.get("name").salarypartid;

            tmp2.salaryname=allInfoMap.get("identityid").salaryname;
            tmp2.salaryentity=allInfoMap.get("identityid").salaryentity;
            tmp2.salarypartid=allInfoMap.get("identityid").salarypartid;
        }
        if(this.state.bustype === "C"){
            tmp1.salaryname=allInfoMap.get("busvid").salaryname;
            tmp1.salaryentity=allInfoMap.get("busvid").salaryentity;
            tmp1.salarypartid=allInfoMap.get("busvid").salarypartid;

            tmp2.salaryname=allInfoMap.get("busvname").salaryname;
            tmp2.salaryentity=allInfoMap.get("busvname").salaryentity;
            tmp2.salarypartid=allInfoMap.get("busvname").salarypartid;
        }
        tmpInfos.push(tmp1);
        tmpInfos.push(tmp2);

        for(let i=0;i<chooseCheck.length;i++){
            let tmp = {};
            tmp.salaryname=allInfoMap.get(chooseCheck[i]).salaryname;
            tmp.salaryentity=allInfoMap.get(chooseCheck[i]).salaryentity;
            tmp.salarypartid=allInfoMap.get(chooseCheck[i]).salarypartid;
            tmpInfos.push(tmp);
        }
        let tmp3 = {};
        tmp3.salaryname=allInfoMap.get("realsalary").salaryname;
        tmp3.salaryentity=allInfoMap.get("realsalary").salaryentity;
        tmp3.salarypartid=allInfoMap.get("realsalary").salarypartid;
        tmpInfos.push(tmp3);

        let params = {
            mbname:this.state.tempName,
            bustype:this.state.bustype,
            enterpriseid:this.state.enterpriseid,
            username:this.state.username,
            tmpInfos:tmpInfos,
        }
        createSalaryTmp(params).then(res => {
            let statusCode = res.data.statusCode;
            if(statusCode === "200"){
                Message.success(res.data.message);
                chooseCheck=[];
                this.setState({
                    showMakeSalaryTemp: false,
                    buttonLoading: false,
                    tempName: "",
                    bustype: "",
                    baseInfo: [],
                    plusInfo: [],
                    subInfo: [],
                    extInfo: [],
                    showTag: [],
                    firstShowTag:[],
                    endTag:[],
                })
                this.props.refreshSubmit();
            }else{
                Message.error(res.data.message);
            }

        }).catch(err => {
            Message.error("创建工资单失败，请稍后再试！");
        })
    }

    cancel() {
        this.setState({
            buttonLoading: false,
            tempName: "",
            bustype: "",
            baseInfo: [],
            plusInfo: [],
            subInfo: [],
            extInfo: [],
            showTag: [],
            firstShowTag:[],
            endTag:[],
        })
    }

    makeSalaryTemp() {
        this.setState({
            showMakeSalaryTemp: true
        })
    }

    showMakeSalaryTempCancel() {
        this.setState({
            showMakeSalaryTemp: false,
            buttonLoading: false,
            tempName: "",
            bustype: "",
            baseInfo: [],
            plusInfo: [],
            subInfo: [],
            extInfo: [],
            showTag: [],
            firstShowTag:[],
            endTag:[],
        })
    }
    render() {
        const _this = this;
        const baseCheckedOption = this.state.baseInfo.map(item => {
            if (item.salarytype === "base") {
                if (item.salaryentity === "name") {
                    return <Col span={8}><Checkbox value={item.salaryentity} checked disabled>{item.salaryname}</Checkbox></Col>
                } else if (item.salaryentity === "identityid") {
                    return <Col span={8}><Checkbox value={item.salaryentity} checked disabled>{item.salaryname}</Checkbox></Col>
                }
            }
            if (item.salarytype === "bus") {
                if (item.salaryentity === "busvid") {
                    return <Col span={8}><Checkbox value={item.salaryentity} checked disabled >{item.salaryname}</Checkbox></Col>
                } else if (item.salaryentity === "busvname") {
                    return <Col span={8}><Checkbox value={item.salaryentity} checked disabled>{item.salaryname}</Checkbox></Col>
                }
            }
            return <Col span={8}><Checkbox value={item.salaryentity} onChange={_this.checkedOnChanged.bind(this)}>{item.salaryname}</Checkbox></Col>
        });
        const plusCheckedOption = this.state.plusInfo.map(item => {
            return <Col span={8}><Checkbox value={item.salaryentity} onChange={_this.checkedOnChanged.bind(this)}>{item.salaryname}</Checkbox></Col>
        });
        const subCheckedOption = this.state.subInfo.map(item => {
            return <Col span={8}><Checkbox value={item.salaryentity} onChange={_this.checkedOnChanged.bind(this)}>{item.salaryname}</Checkbox></Col>
        });
        const extCheckedOption = this.state.extInfo.map(item => {
            if (item.salarytype === "ext") {
                if (item.salaryentity === "realsalary") {
                    return <Col span={8}><Checkbox value={item.salaryentity} checked disabled >{item.salaryname}</Checkbox></Col>
                }
            }
            return <Col span={8}><Checkbox value={item.salaryentity} onChange={_this.checkedOnChanged.bind(this)}>{item.salaryname}</Checkbox></Col>
        });
        const fitsTtagOptions = this.state.firstShowTag.map(item => {
            return <Tag color="blue">{item}</Tag>
        });
        const tagOptions = this.state.showTag.map(item => {
            return <Tag color="blue">{item}</Tag>
        });
        const enTtagOptions = this.state.endTag.map(item => {
            return <Tag color="blue">{item}</Tag>
        });

        return (
            <div>
                <Button type="primary" icon="plus-circle" onClick={this.makeSalaryTemp.bind(this)} >定制模板</Button>
                <Modal
                    title="定制模板"
                    visible={this.state.showMakeSalaryTemp}
                    onCancel={this.showMakeSalaryTempCancel.bind(this)}
                    footer={false}
                    width={1000}
                >
                    <div>
                        <Row>
                            <Col span={4} offset={3}>
                                <h4>定义模板名称：</h4>
                            </Col>
                            <Col span={12}>
                                <Input placeholder='输入模板名称' onChange={this.tempNameChange.bind(this)} value={this.state.tempName}/>
                            </Col>
                        </Row>
                        <Row>
                            <div style={{ width: '100%', marginTop: 20 }}>
                                <Col span={4} offset={3}>
                                    <h4>发放单格式：</h4>
                                </Col>
                                <Col span={12}>
                                    {fitsTtagOptions}
                                    {tagOptions}
                                    {enTtagOptions}
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <div style={{ width: '100%', marginTop: 20 }}>
                                <Col span={4} offset={3}>
                                    <h4>对私对公：</h4>
                                </Col>
                                <Col span={12}>
                                    <Radio.Group onChange={this.bustypeChange.bind(this)} value={this.state.bustype}>
                                        <Radio value={"P"}>对私</Radio>
                                        <Radio value={"C"}>对公</Radio>
                                    </Radio.Group>
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <div style={{ width: '100%', marginTop: 20 }}>
                                <Col span={4} offset={3}>
                                    <h4>基本信息：</h4>
                                </Col>
                                <Col span={12}>
                                    {baseCheckedOption}
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <div style={{ width: '100%', marginTop: 50 }}>
                                <Col span={4} offset={3}>
                                    <h4>加项：</h4>
                                </Col>
                                <Col span={12}>
                                    {plusCheckedOption}
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <div style={{ width: '100%', marginTop: 50 }}>
                                <Col span={4} offset={3}>
                                    <h4>减项：</h4>
                                </Col>
                                <Col span={12}>
                                    {subCheckedOption}
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <div style={{ width: '100%', marginTop: 50 }}>
                                <Col span={4} offset={3}>
                                    <h4>其他项：</h4>
                                </Col>
                                <Col span={12}>
                                    {extCheckedOption}
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <Col span={8} offset={9}>
                                <div style={{ width: '100%', marginTop: 50 }}>
                                    <Button type="primary" icon="form" loading={this.state.buttonLoading} onClick={this.submit.bind(this)}>确定</Button>
                                    <Button type="primary" icon="redo" style={{ marginLeft: 50 }} onClick={this.cancel.bind(this)}>重置</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default MakeSalaryTemp;