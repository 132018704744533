import React, { Component } from 'react';
import { Form, Input,Col } from 'antd'

const EditableContext = React.createContext();

class EditableCell extends Component {

    constructor(){
        super();
        this.state = {
            editing: false,
            bankCode: JSON.parse(global._sessionStorage.getItem('user')).bankCode,
        };
    }
        
    
      toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
          if (editing) {
            this.input.focus();
          }
        });
      };
    
      save = e => {
        const { record, handleSave, form , columns} = this.props;
        form.validateFields((error, values) => {
          if (error && error[e.currentTarget.id]) {
            return;
          }
          this.toggleEdit();
          let row = { ...record, ...values}
          handleSave(row);
        });
      };


      renderCell = () => {
        const { children, dataIndex, record, title, form, required } = this.props;
        const { editing } = this.state;
        return editing ? (
          <Form.Item style={{ margin: 0 }}>
            {form.getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: this.state.bankCode === '0001' && title==='银行卡号'?  false: true,
                  message: `${title} is required.`,
                },
              ],
              initialValue: record[dataIndex],
            })(<Input ref={node => (this.input = node)}  onPressEnter={this.save} onBlur={this.save} />)}
          </Form.Item>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{ paddingRight: 24 }}
            onClick={this.toggleEdit}
          >
            {children}
          </div>
        );
      };

    //   renderCell = () => {
    //     const { children, dataIndex, record, title, form, required } = this.props;
    //     const { editing } = this.state;
    //     return editing ? (
       
    //       <Form.Item style={{ margin: 0,width:200}}>
    //         { required ? form.getFieldDecorator(dataIndex, {
    //             rules: [
    //             {
    //               required: true,
    //               message: `${title}`,
    //             },
    //           ],
    //           initialValue: record[dataIndex],
    //         })(          
    //         <Input  ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save}  />
    //         )   
    //         :
    //         form.getFieldDecorator(dataIndex, {
    //           initialValue: record[dataIndex],
    //         })(<Input  ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save}  />)
    //         }
    //       </Form.Item> 
    //     ) : (
    //       <div
    //         className="editable-cell-value-wrap"
    //         onClick={this.toggleEdit}
    //       >
    //         <span>
    //           {children[2] ? children :   required ? <span style={{color : 'red'}} > 请输入</span> : <span > </span> }
    //         </span>
            
    //       </div>
    //     );
    //   };
    
    //   renderCell = form => {
    //     this.form = form;
    //     const { children, dataIndex, record, title } = this.props;
    //     const { editing } = this.state;
    //     return editing ? (
    //       <Form.Item style={{ margin: 0 }}>
    //         {form.getFieldDecorator(dataIndex, {
    //           rules: [
    //             {
    //               required: true,
    //               message: `${title} is required.`,
    //             },
    //           ],
    //           initialValue: record[dataIndex],
    //         })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
    //       </Form.Item>
    //     ) : (
    //       <div
    //         className="editable-cell-value-wrap"
    //         style={{ paddingRight: 24 }}
    //         onClick={this.toggleEdit}
    //       >
    //         {children}
    //       </div>
    //     );
    //   };
    
         
    render() {
        const {
          editable,
          dataIndex,
          title,
          record,
          index,
          handleSave,
          selectedRowKeys,
          children,
          ...restProps
        } = this.props;

        let able = editable; 
        if(selectedRowKeys !== undefined){
          selectedRowKeys.forEach((item) =>{
            if(item === record.id){
              able = false;
            }
          })
        }

        return (
          <td {...restProps}>
            {able===true ? (
              <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
            ) : (
              children
            )}
          </td>
        );
      }

}
EditableCell = Form.create()(EditableCell)
export default EditableCell;