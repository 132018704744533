import React, { Component } from 'react';
import { Modal, Icon} from 'antd'
import PassModify from 'coms/UserInfo/PassModify'

class ForceChangePassword extends Component {

    render() {
        const title = <span>密码修改</span>;
        const formItemStyle = {
            labelCol : {  span: 6 },
            wrapperCol : { span: 10 }
        }
        return (
            <div>
                
                
                <Modal
                visible={this.props.visible}
                title={title}
                closable={this.props.closable}
                onCancel={this.props.handleCancel.bind(this)}
                maskClosable={false}
                keyboard={false}
                width={500}
                footer={null} 
                >
                <PassModify formItemStyle={formItemStyle} {...this.props} />
            </Modal>
            </div>
            
        );
    }
}

export default ForceChangePassword;