import {http} from '../utils/http'

export function queryEmpWeekList(data){
    return http.post("/api/empweek/queryEmpWeekList",data);
}
export function employeeComplete(data){
    return http.post("/api/empweek/completionEmployeeWeek",data);
}
export function approveEmployeeWeek(data){
    return http.post("/api/empweek/approveEmployeeWeek",data);
}
export function completionEmployeeWeek(data){
    return http.post("/api/empweek/completionEmployeeWeek",data);
}
export function queryEmpContractList(data){
    return http.post("/api/empweek/queryEmpContractList",data);
}
export function contranctManagePreview(data){
    return http.post("/api/empweek/preview",data);
}
export function getContractContent(data){
    return http.post("/api/empweek/getContractContent",data);
}
export function querySalaryBatch(data){
    return http.post("/api/salary/querySalaryBatch",data);
}
export function deleteSalaryBatch(data){
    return http.post("/api/salary/deleteSalaryBatch",data);
}
export function operateSalaryBatch(data){
    return http.post("/api/salary/operateSalaryBatch",data);
}
export function salaryBatchQuery(data){
    return http.post("/api/salary/salaryBatchQuery",data);
}
export function uploadSalaryQuery(data){
    return http.post("/api/salary/uploadSalaryQuery",data);
}
export function uploadBusSalaryQuery(data){
    return http.post("/api/salary/uploadBusSalaryQuery",data);
}
export function submitSalaryBatch(data){
    return http.post("/api/salary/submitSalaryBatch",data);
}
export function uploadSalarys(data){
    return http.post("/api/salary/uploadSalarys",data);
}
export function queryEmpWeekInfo(data){
    return http.post("/api/empweek/queryEmpWeekInfo",data);
}
export function querySalaryBatchRecordList(data){
    return http.post("/api/salary/querySalaryBatchRecordList",data);
}
export function querySalaryBatchDetailList(data){
    return http.post("/api/salary/querySalaryBatchDetailList",data);
}
export function querySalaryBatchList(data){
    return http.post("/api/salary/querySalaryBatchList",data);
}
export function querySalaryAccount(data){
    return http.post("/api/pay/querySalaryAccount",data);
}
