import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import Image from './Image'

const index = ({ 
    className,
    restProps,
    children
}) => {
    return (
        <div {...restProps} className={classNames(className)} >
            {children}
        </div>
    );
};

index.propTypes = {
    className : PropTypes.string,
    restProps : PropTypes.object,
};

index['Image'] = Image

export default index;