import React, { Component } from 'react';
import { Form, Row, Col, Input, Select, Button, DatePicker, Message } from 'antd'
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Option } = Select;

class StaffAddBatchQueryForm extends Component {
    constructor() {
        super();
        this.state = {
            operator: JSON.parse(global._sessionStorage.getItem('user')).username,
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
        }
    }

    refreshSubmit(e) {
        this.handlerSubmit(e);
    }

    handlerSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                if (params.createTime && params.createTime.length > 0) {
                    params.startTime = moment(params.createTime[0]).format('YYYY-MM-DD');
                    params.endTime = moment(params.createTime[1]).format('YYYY-MM-DD');
                }
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset() {
        this.props.form.resetFields();
        this.props.resetForm();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const dateFormat = 'YYYY-MM-DD';
        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
        };
        return (
            <div>
                <Form {...formItemLayout} className="ant-search-form" onSubmit={this.handlerSubmit.bind(this)} >
                    <Row gutter={24} >
                        <Col span={6} >
                            <Form.Item label="文件名">
                                {getFieldDecorator('fileName')(
                                    <Input placeholder="请输入文件名，支持模糊查询"></Input>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="录入批次号">
                                {getFieldDecorator('batchId')(
                                    <Input placeholder="录入批次号"></Input>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={5} >
                            <Form.Item label="录入状态" >
                                {getFieldDecorator("state")(
                                    <Select  placeholder="导入状态">
                                        <Option value="0">成功</Option>
                                        <Option value="1">失败</Option>
                                        <Option value="2">解析中</Option>
                                        <Option value="3">部分成功</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={7} >
                            <Form.Item label="录入日期" >
                                {
                                    getFieldDecorator('createTime')(
                                        <RangePicker format={dateFormat} />
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} >
                        <Col span={24} style={{textAlign: "right"}}>
                            <Form.Item >
                                <Button type="primary" htmlType="submit" style={{ marginRight: '1%' }} loading={this.props.loading} >查询</Button>
                                <Button style={{ marginRight: '1%' }} onClick={this.handlerReset.bind(this)} >重置</Button>
                                <Button type="primary" onClick={this.refreshSubmit.bind(this)} >刷新</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

StaffAddBatchQueryForm = Form.create()(StaffAddBatchQueryForm)

export default StaffAddBatchQueryForm;