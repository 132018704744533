import {http} from '../utils/http'

//考勤组管理查询
export function attendQueryList(data){
    return http.post("/api/workattend/attendQueryList",data);
}
//新增考勤组
export function attendSave(data){
    return http.post("/api/workattend/attendSave",data);
}
//修改考勤组
export function attendUpdate(data){
    return http.post("/api/workattend/attendUpdate",data);
}
//删除考勤组
export function attendDelete(data){
    return http.post("/api/workattend/attendDelete",data);
}
//查询班次列表
export function queryAttendShiftInfoList(data){
    return http.post("/api/workattend/queryAttendShiftInfoList",data);
}
//查询考勤组信息
export function queryAttendInfo(data){
    return http.post("/api/workattend/queryAttendInfo",data);
}
//查询员工明细
export function queryEmpInfoList(data){
    return http.post("/api/workattend/queryEmpInfoList",data);
}
//查考勤组列表
export function queryAllAttendInfoList(data){
    return http.post("/api/workattend/queryAllAttendInfoList",data);
}
//查考勤组列表
export function queryDepartEmpList(data){
    return http.post("/api/workattend/queryDepartEmpList",data);
}

//查询腾讯地图数据
export function searchTMap(data){
    return http.post("/api/workattend/searchTMap",data);
}
//逆地址解析（坐标位置描述）
export function geocoderTMapAddr(data){
    return http.post("/api/workattend/geocoderTMapAddr",data);
}
//IP定位
export function getTMapLocationByIp(data){
    return http.post("/api/workattend/getTMapLocationByIp",data);
}