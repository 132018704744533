import React from "react";
import emptyImg from "assets/img/emptyImg.png";

const emptyStyle = {
  margin: "auto",
  display: "block",
  textAlign: "center",
  fontSize: "16px",
  fontFamily: "PingFangSC-Regular, PingFang SC",
  fontWeight: "400",
  color: "#333333",
  lineHeight: "22px",
};

const NoData = ({ message }) => (
  <div style={{paddingTop: '10%'}}>
    <img
      style={{ width: "254px", margin: "auto", display: "block" }}
      src={emptyImg}
    />
    <div>
      <span style={emptyStyle}>{message}</span>
    </div>
  </div>
);

export default NoData;
