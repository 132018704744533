import React, { Component } from 'react';
import { Dropdown, Badge, Icon, Tabs } from 'antd'
import NoticeItem from './NoticeItem'

const TabPane = Tabs.TabPane;
class HomeMessage extends Component {

    constructor(){
        super();
        this.state={
            visible :false,
            noticeCount : 0,
            noticeLists : []
        }
    }

    handleVisibleChange(flag){
    this.setState({ visible: flag });
    }

    handlerNoticeDelete(key){
        const data = this.state.noticeLists.filter( item => item.id !== key );
        this.setState({
            noticeLists : data,
            noticeCount : data.length
        })

    }
    handlerChangeStatus(key){
        const data = this.state.noticeLists.map( item => {
            if(item.id === key){
                item.infoOpen = true;
                item.collapsed = !item.collapsed;
            }
            return item;
        });
        
        let length = this.state.noticeLists.filter( item => !item.infoOpen).length;
        this.setState({
            noticeLists : data,
            noticeCount : length
        })
    }

    componentDidMount(){
        // fetch("https://jsonplaceholder.typicode.com/posts")
        // .then(res => res.json())
        // .then(data => {
        //     let arr =[
        //         {
        //             "id": 1,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "sunt aut facere repel optio reprehenderit",
        //             "body": "quia et suscipit\nsuscipit riae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto"
        //           },
        //           {
        //             "id": 2,
        //             "date" : "03/05",
        //             "infoOpen" : true,
        //             "title": "qui est esse",
        //             "body": "est rerum tempore viae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
        //           },
        //           {
        //             "id": 3,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "ea molestias qusit aut",
        //             "body": "杀就杀撒啊合适的克拉珀i你是大家阿斯利康看来大家老阿嫂就阿斯顿撒"
        //           },
        //           {
        //             "id": 4,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "eum et est occaecati",
        //             "body": "ullam et saepe reiciendis voluptatem"
        //           },
        //           {
        //             "id": 5,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "nesciunt quas odio",
        //             "body": "repudiandae ved\nalias aut fugiat sit autem sed est\nvoluptatem omnis possimus esse voluptatibus quis\nest aut tenetur dolor neque"
        //           },
        //           {
        //             "id": 6,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "dolorem eum magni eos aperiam quia",
        //             "body": "ut aspernatur corporis harum ccusantium quas\nvoluptate dolores velit et doloremque molestiae"
        //           },
        //           {
        //             "id": 7,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "magnam facilis autem",
        //             "body": "dolore placeat quibusdam ea quo vitae\nmagni quis enat excepturi ut quia\nsunt ut sequi eos ea sed quas"
        //           },
        //           {
        //             "id": 8,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "dolorem dolore est ipsam",
        //             "body": "dignissimos aperiam dolorem qui eum\nfacilis quibusdam animi sint suscipit qui sint possimus cum\nqudolor voluptatum modi aut vitae"
        //           },
        //           {
        //             "id": 9,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "nesciunt iure omnis dolorem tempora et accusantium",
        //             "body": "consectetur animi nesciunt iure dolore\nenim quia ad\nveniam autem ut quam aut nobis\net est aut quod aut provident voluptas autem voluptas"
        //           },
        //           {
        //             "id": 10,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "optio molestias id quia eum",
        //             "body": "quo et expedita modi cum officia vel magni\ndoloribus qui repudiandae\nvero nisi sit\nquos ven error"
        //           },
        //           {
        //             "id": 11,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "et ea vero quia laudantium autem",
        //             "body": "delectus reiciendis  qui neque voluptates ut commodi qui incidunt\nut animi commodi"
        //           },
        //           {
        //             "id": 12,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "in quibusdam tempore odit est dolorem",
        //             "body": "itaque id aut magnam\npraesentium quia et ea odit et ea voluptas et\nsapiente quia nihil  odio"
        //           },
        //           {
        //             "id": 13,
        //             "date" : "11/12",
        //             "infoOpen" : false,
        //             "title": "dolorum ut in vimi",
        //             "body": "aut dicta possimus sint mollitia voluptas commodi quo doloremque\niste corrupti reiciendis voluptatem eius rerum\nsit cumque quod eligendi laborum minima\nperferendis recusandae assumenda consectetur porro architecto ipsum ipsam"
        //           },
        //           {
        //             "id": 14,
        //             "date" : "03/01",
        //             "infoOpen" : false,
        //             "title": "voluptatem eligendi optio",
        //             "body": "fuga et accusamus dolorum perferendis ut voluptas totam sit illum"
        //           },
        //     ];
        //     let arrList = arr.map( item =>{
        //         let bodyShow = item.body;
        //         if(item.body.length > 40){
        //             bodyShow = bodyShow.substring(0,20) + '...'
        //         }
        //         return {...item, bodyShow, collapsed : false }
        //     } );
        //     let length = arr.filter( item => !item.infoOpen).length;
        //     this.setState({
        //         noticeLists : arrList,
        //         noticeCount : length
        //     })
        // });


    }
      

    render() {
        const messageList = (
            <div className="header-message">
                <Tabs defaultActiveKey="1"  >
                    <TabPane tab={ <span>公告({this.state.noticeCount})</span> } key="1">
                        <NoticeItem noticeLists={this.state.noticeLists} handlerNoticeDelete={this.handlerNoticeDelete.bind(this)} 
                            handlerChangeStatus={this.handlerChangeStatus.bind(this)} />
                    </TabPane>
                    {/* <TabPane tab="消息" key="2">消息</TabPane>
                    <TabPane tab="代办事项" key="3">代办事项</TabPane> */}
                </Tabs>

            </div>
            
        )
        
        return (

            <Dropdown  overlay={messageList} trigger={['click']} 
            visible ={this.state.visible} 
            onVisibleChange={this.handleVisibleChange.bind(this)}
            placement="bottomRight"
            >
                <div style={{height:'50px'}}>
                    <Badge count={this.state.noticeCount} overflowCount={10} offset={[5,-5]} >
                        <span style={{marginLeft:20}} > <Icon type="bell" /> </span>
                    </Badge>
                </div>
            </Dropdown>
            
        );
    }
}

export default HomeMessage;