import React, { Component } from 'react';
import { salaryBatchQuery,submitSalaryBatch} from 'api/weeksalary';
import {coreSalaryQuery} from 'api/coresalary';
import { Table,Message,Tabs,Col,Button} from 'antd';
import ShowSingleTips from './ShowSingleTips';



const { TabPane } = Tabs;
class CreateCorePayRollDetail extends Component {
    constructor(){
        super();
        this.state = {
            loading : false,
            searchParam : {},
            sourceData : [],
            totalNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            columns :[],
            submitbutton :'',
            salarydate :'',
            employeenum :'',
            totaltext:'',
            batchid:'',
            words:'',
            operations1 :"",
        }
    }
    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage,
            searchParam : params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params){
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam : params
        })
        this.searchPost(params);
    }

    searchPost(params){
        this.setState({
            loading : true
        })
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        
        coreSalaryQuery(params).then(res => {
            // let {errorCode, errorMessage} = res.data;
            // if(errorCode === '0000'){
                let columns=[];
        //         console.log(res.data);
                if(res.data.listhead){
                    res.data.listhead.map((head)=>{
                        columns.push(
                            {
                                title: head.salaryname,
                                dataIndex: head.salaryentity,
                                key: head.salaryentity,
                                width: '10%',
                                align : 'center',
                            }
                        )
                    })
                    columns.push(
                        { title : '发放单状态', width : '10%', dataIndex : 'status', key : 'status', align : 'center',
                            render(val,record){
                                if (record.status == '00') {
                                    return '已发放';
                                } else if (record.status == '01') {
                                    return '未发放';
                                } else if (record.status == '02') {
                                    return '发放失败';
                                } else if (record.status == '99') {
                                    return '正在发放';
                                }
                            }
                        }
                    )
                  
                    this.setState({
                        sourceData : res.data.list,
                        totalNum : res.data.totalRow,
                        columns : columns,
                    });
                }

            // }else{
            //     Message.error(errorMessage);
            // }
            this.setState({
                loading : false
            })
        })
    }

    batchQuery(params){


        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        salaryBatchQuery(params).then(res =>{
            this.setState({
                totaltext:res.data.salarydate+" "+"已上传："+res.data.employeenum+"条记录" ,
                submitbutton : res.data.submitbutton

            });
        })
    }

    submitSalaryData(){  
        let params = {};
        params.id = this.state.batchid;
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        params.phone=user.username;
        submitSalaryBatch(params).then(res =>{
            if(res.data.result=="true"){
                this.props.history.push('/corePayRollTabs');
            }else{
                Message.error(res.data.message);
            }
        })
    }

    submitbuttonCheck(){        
        return <ShowSingleTips history={this.props.history} batchid={ this.props.location.state.batchid}  ></ShowSingleTips>;      
    }

    componentDidMount(){

        let params = this.state.searchParam;
        params.id = this.props.location.state.batchid;
        this.setState({
            searchParam : params,
            batchid : this.props.location.state.batchid
        });
        this.batchQuery(params);
        this.searchFormSubmit(params);
    }




    render() {
        const _this = this;
        const pagination ={
            current : parseInt(this.state.currentPage),
            total: this.state.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : this.state.pageSizeOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.currentPageChange(current);
            }
        }


        const columns = this.state.columns;
       
        
        const operations = <Button type="primary" disabled={this.state.submitbutton != 'true'} onClick={_this.submitSalaryData.bind(this)}>提交数据</Button>;
        const  {operations1} = <ShowSingleTips history={this.props.history} batchid={ this.props.location.state.batchid} submitbutton={this.state.submitbutton} word={this.state.word} ></ShowSingleTips>;  
        return (
            <div>
              {/* <Tabs tabBarExtraContent={this.submitbuttonCheck()} defaultActiveKey="P"> */}
                <Tabs tabBarExtraContent={this.submitbuttonCheck()} defaultActiveKey="P">
                    <TabPane tab={this.state.totaltext} key="P">
                    <Col offset={10}>发放单明细</Col>
                        <Table style={{marginTop : '20px'} } 
                            rowKey = { record => record.id }
                            loading={this.state.loading}
                            size="middle "
                            dataSource={this.state.sourceData} 
                            columns={columns}
                            bordered
                            pagination={pagination}
                        >
                        </Table>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default CreateCorePayRollDetail;