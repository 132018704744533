import React, { Component } from 'react';
import { Table, Message, Tabs, Col, Button } from 'antd';
import ShowTips from './ShowTips';
import {Link} from 'react-router-dom' ;


const { TabPane } = Tabs;


class ShowCorePayRoll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            searchParam: {},
            sourceData: [],
            totalNum: '',
            pageSizeOptions: global.pageInfo.sizeOptions,
            currentPage: '1',
            pageSize: '10',
            columns: [],
            submitbutton: '',
            employeenum: '',
            totaltext: '',
            batchid: '',
            list: this.props.location.state.sourceData,
        }
    }

    // submitSalaryData(){
    //     let params = {};
    //     params.list =  this.props.location.state.sourceData;
    //     let user = JSON.parse(global._sessionStorage.getItem("user"));
    //     params.enterpriseid = user.enterpriseid;
    //     params.phone=user.phone;
    //     submitCoreSalaryBatchList(params).then(res =>{
    //         if(res.data.result=="true"){
    //             this.setState({
    //                 submitbutton : 'false',
    //             });
    //            this.props.history.push('/payrollmanage');
    //         }else{
    //             Message.error(res.data.message);
    //         }
    //     })
    // }

    componentDidMount() {
        this.setState({
            totaltext: this.props.location.state.salarydate + " " + "已上传："
                + this.props.location.state.batchNum +
                "个批次，共" + this.props.location.state.totalNum + "条记录，总金额：" +
                this.props.location.state.totalAmt
        })
    }

    render() {
        const _this = this;
        const columns = [
            {
                title: '关联企业商户号/名称', dataIndex: 'joinId', key: 'joinId', align: 'center',
                render(text, record, index) {
                    return record.joinName + "/" + record.joinId
                }
            },
            {
                title: '批次号', dataIndex: 'batchCode', key: 'batchCode', align: 'center',
                render(text, record, index){
                    return (
                        <div>
                            <Link to={{ pathname : '/createCorePayRollDetail' , state : { batchid: record.batchId  }}} >{record.batchCode}</Link>
                        </div>
                    )
                }
            },
            {
                title: '总笔数', dataIndex: 'totalNum', key: 'totalNum', align: 'center',

            },
            {
                title: '总金额', dataIndex: 'totalAmt', key: 'totalAmt', align: 'center',

            },
            {
                title: '关联企业发薪账号', dataIndex: 'joinAcc', key: 'joinAcc', align: 'center',

            },
            {
                title: '关联企业发薪户名', dataIndex: 'joinAccName', key: 'joinAccName', align: 'center',

            },

            {
                title: '状态', dataIndex: 'state', key: 'state', align: 'center',
                render(text, record, index) {
                    let status = record.state;
                    if (status == "00") {
                        status = "待审核";
                    } else if (status == "01") {
                        status = "审核通过";
                    } else if (status == "03") {
                        status = "已提交";
                    } else if (status == "04") {
                        status = "未提交";
                    } else if (status == "05") {
                        status = "审核拒绝";
                    } else if (status == "06") {
                        status = "正在发放";
                    } else if (status == "07") {
                        status = "已发放";
                    } else {
                        status = "";
                    }
                    return status;
                }
            },


        ]
        //     const operations = <Button type="primary" disabled={ this.props.location.state.submitbutton != 'true'} onClick={_this.submitSalaryData.bind(this)}>提交数据</Button>
        //    ;
        const operations1 = <ShowTips history={this.props.history} list={this.state.list} submitbutton={this.props.location.state.submitbutton} word={this.props.location.state.word} ></ShowTips>;
        return (
            <div>
                <Tabs tabBarExtraContent={operations1} defaultActiveKey="A">
                    <TabPane tab={this.state.totaltext} key="A">
                        <Col offset={10}>发放单明细</Col>
                        <Table style={{ marginTop: '20px' }}
                            rowKey={record => record.batchId}
                            loading={this.state.loading}
                            size="middle "
                            dataSource={this.props.location.state.sourceData}
                            columns={columns}
                            bordered
                        >
                        </Table>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default ShowCorePayRoll;