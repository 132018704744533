import React, { Component } from 'react';
import { Tabs, Icon } from 'antd';
import SimplerCreatePayRoll from 'views/payrollmanage/SimplerCreatePayRoll'
import CreatePayRecord from 'views/payrollmanage/CreatePayRecord'
import CreateSupplierPayRoll from '@/components/supplierManage/SupplierPayRoll/CreateSupplierPayRoll'
import SupplierPayRecord from '@/components/supplierManage/SupplierPayRoll/SupplierPayRecord'
// import CreateWeekPayRoll from '@/components/week/WeekPayRoll/CreateWeekPayRoll'
// import WeekPayRecord from '@/components/week/WeekPayRoll/WeekPayRecord'
import CreateTransPayRoll from '@/components/TransPlatform/CreateTransPayRoll'
import TransPayRecord from '@/components/TransPlatform/TransPayRecord'
const { TabPane } = Tabs;

class SimplerCreatePayTabs extends Component {
    constructor() {
        super();
        this.state = {
            key : '1',
        }
    }

    handleChange(activeKey ){
        this.setState({
            key : activeKey
        })
    }

    render() {
        return (
            <div style={{ width: '100%', marginTop: 20 }}>
                <Tabs activeKey={this.state.key} onChange ={this.handleChange.bind(this)} tabBarGutter={2} > 
                    <TabPane tab={<span><Icon type="upload" />创建发放单</span>} key="1">
                        {
                            this.state.key === '1' && <SimplerCreatePayRoll history={this.props.history} currentFlag = {this.props.currentFlag} />
                        }
                    </TabPane>
                    <TabPane tab={<span><Icon type="search" />创建记录查询</span>} key="2">
                        {
                            this.state.key === '2' && <CreatePayRecord />
                        }
                    </TabPane>
                    <TabPane tab={<span><Icon type="file-excel" />创建关联发放单</span>} key="3">
                        {
                            this.state.key === '3' && <CreateSupplierPayRoll />
                        }
                    </TabPane>
                    <TabPane tab={<span><Icon type="deployment-unit" />关联发放单记录查询</span>} key="4">
                        {
                            this.state.key === '4' && <SupplierPayRecord />
                        }
                    </TabPane>
                    <TabPane tab={<span><Icon type="table" />创建交易平台发放单</span>} key="5">
                        {
                            this.state.key === '5' && <CreateTransPayRoll />
                        }
                    </TabPane>
                    <TabPane tab={<span><Icon type="file-search" />交易平台发放单记录查询</span>} key="6">
                        {
                            this.state.key === '6' && <TransPayRecord />
                        }
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default SimplerCreatePayTabs;