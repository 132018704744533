import React, { Component } from 'react';
import { Tree } from 'antd'


const { TreeNode } = Tree;

class RoleMenuCommon extends Component {

    // 展开
    onExpand(expandedKeys) {
        this.props.onExpand(expandedKeys);
    }

    // 勾选
    onCheck(checkedKeys,info) {
        const checkedData = info.checkedNodes.filter(node => !node.props.children ).map(node => {
            return node.key;
        })
        this.props.onCheck(checkedData,info.halfCheckedKeys)
    }

    renderTreeNodes = data => data.map((item) => {
        if (item.children) {
          return (
            <TreeNode title={item.title} key={item.key} dataRef={item}>
              {this.renderTreeNodes(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode {...item} />;
    })

    render() {
        return (
            <div>
                {
                    <Tree 
                        checkable
                        onExpand={this.onExpand.bind(this)}
                        expandedKeys={this.props.expandedKeys}
                        autoExpandParent={this.props.autoExpandParent}
                        onCheck={this.onCheck.bind(this)}
                        checkedKeys={this.props.checkedKeys}
                    >
                        {this.renderTreeNodes(this.props.sourceData)}
                    </Tree>
                 
                }
                
            </div>
        );
    }
}

export default RoleMenuCommon;