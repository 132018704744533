import React, { Component } from 'react';
import {Icon, Tooltip } from 'antd'
import axios from 'axios';
import { baseHttpUrl } from '../../../assets/settings';
import { getTokenStr } from '../../../utils/http';

class StaffAddBatchQueryDownload extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
        }
    }

    fileDownload(){
        let params = {};
        params.eid = this.state.enterpriseid;
        params.batchId = this.props.record.batchId;
        
        axios({
            method: 'GET',
            url: baseHttpUrl+'/api/appUser/exportUserDetail',
            headers: {
                'Token': getTokenStr(),
            },
            params: params,
            responseType: 'blob'
        }).then(function(res) {
            const content = res.data
            const blob = new Blob([content],{type: "application/x-msdownload"})
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = res.headers['filename'];
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })
    }

    render() {
        return (
            <span style={{ display: 'inline-flex' }} >
                    {
                        this.props.disabled ?
                        <span className="span-click-disabled" ><Icon type="download"/></span>
                        :<Tooltip placement="top" title="下载" ><span className="span-click"  onClick={ this.fileDownload.bind(this)} ><Icon type="download"/></span> </Tooltip>

                    }
            </span>
        );
    }
}

export default StaffAddBatchQueryDownload;