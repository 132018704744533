/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, Checkbox, Icon } from 'antd'
import styles from './column.m.less'

const CheckboxGroup = Checkbox.Group;

class ColumnSetup extends Component {

    constructor(props){
        super(props);
        this.state = {
            checkedList: props.list.map(item => item.dataIndex),
            indeterminate: false,
            checkAll: true,

        }
    }

    onChange = checkedList => {
        this.setState({
          checkedList,
          indeterminate: !!checkedList.length && checkedList.length < this.props.list.length,
          checkAll: checkedList.length === this.props.list.length,
        });
        const uncheckedList = this.props.list.map(item => item.dataIndex).filter(val => checkedList.indexOf(val) === -1 )
        this.filterCols(uncheckedList);
    };



    onCheckAllChange = e => {
        const values = this.props.list.map(item => item.dataIndex);
        const checkedList = e.target.checked ? values : [];
        const uncheckedList = !e.target.checked ? values : [];
        this.setState({
            checkedList,
            indeterminate: false,
            checkAll: e.target.checked,
        });
        this.filterCols(uncheckedList);
    }

    filterCols = (list) => {
        const { filterCols } = this.props;
        filterCols(list);
    }

    resetCols = () =>{
        const checkedList = this.props.list.map(item => item.dataIndex);
        this.setState({
            checkedList,
            indeterminate: false,
            checkAll: true,
        });
        this.filterCols([]);
    }

    renderColums = (items) => {
        // const {onFixed} = this.props
        return items.map( (item, index) => {
            return <div key={index} className={styles.column} >
                        <Checkbox
                        value={item.dataIndex}
                        >
                            {item.title}
                            {
                                item['fixed-left'] && 
                                <Icon type="pushpin" rotate={-90} style={{marginLeft : 10}} theme="twoTone"  />
                            } 
                            {
                                item['fixed-right'] && 
                                <Icon type="pushpin" style={{marginLeft : 10}} theme="twoTone"  />
                            } 
                        </Checkbox> 
                        {/* <div className={styles.icons} >
                        {
                            item['fixed-left'] ?  
                            <Tooltip placement="top" title="取消固定">
                                <Icon type="pushpin" rotate={-90} style={{marginRight : 10}} theme="twoTone" onClick={() => onFixed(item.key)} /> 
                            </Tooltip>
                            :
                            <Tooltip placement="top" title="固定到左边">
                                <Icon type="pushpin" rotate={-90} style={{marginRight : 10}} onClick={() => onFixed(item.key, 'L')} />
                            </Tooltip>

                        }
                        {
                            item['fixed-right'] ?  
                            <Tooltip placement="top" title="取消固定">
                                <Icon type="pushpin" theme="twoTone" onClick={() => onFixed(item.key)} /> 
                            </Tooltip>
                            :
                            <Tooltip placement="top" title="固定到左边">
                                <Icon type="pushpin" onClick={() => onFixed(item.key, 'R')} />
                            </Tooltip>
                        }
                        </div> */}
                        
                    </div>
        })
    }

    render() {
        const {children, list, fixedCols} = this.props
        const cols = list.map(item => {
            let fixedLeft = false;
            let fixedRight = false;
            fixedCols.forEach(val => {
                if(val.key === item.key && val.value === 'L'){
                    fixedLeft = true;
                }else if(val.key === item.key && val.value === 'R'){
                    fixedRight = true
                }
            })
            
            return {...item, 'fixed-left': fixedLeft, 'fixed-right': fixedRight}
        })
        return (
            <Popover placement="bottomRight" 
                title={
                    <div className={styles.column} >
                        <Checkbox
                            indeterminate={this.state.indeterminate}
                            onChange={this.onCheckAllChange}
                            checked={this.state.checkAll}
                        >
                            全选
                        </Checkbox> 
                        <a onClick={() =>this.resetCols()}>重置</a> 
                    </div>
                } 
                content={
                    <div className={styles["column-body"]}>
                        <CheckboxGroup
                        value={this.state.checkedList}
                        onChange={this.onChange}
                        >
                            {this.renderColums(cols)}
                        </CheckboxGroup>
                    </div>} 
                trigger="click">
                    
                    {children}
            </Popover>
        );
    }
}

ColumnSetup.propTypes = {
    list : PropTypes.array.isRequired,
    filterCols : PropTypes.func.isRequired,
};



export default ColumnSetup;