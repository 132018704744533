import React, { Component } from 'react';
import { Layout, Row, Col, Icon, Menu, Button, Dropdown, Select } from 'antd'
import ForceChangePassword from 'coms/Home/ForceChangePassword'
import 'assets/css/indexSimpler.less'
import logoSimpler from 'assets/img/logo-simpler.png'
import downIcon from 'assets/img/downIcon.png'
import userIcon from 'assets/img/userIcon.png'
import userSetIcon from 'assets/img/userSetIcon.png'
import jiantou1 from 'assets/img/jiantou1.png'
import SimplerHomeMessage from '../components/Home/SimplerHomeMessage';
import { getUserInfo, updateLoginBusiness } from '../api/login';

import { Redirect } from 'react-router-dom'

const { Option } = Select;
const { Sider, Header, Content, Footer } = Layout;
const salaryStyle = { cursor: 'pointer', lineHeight: '4.3', height: '162px', width: '380px', display: 'inline-block', background: "url(" + require("assets/img/salary-back1.png") + ") center center / cover no-repeat " }
const salaryStyle_l = { cursor: 'pointer', lineHeight: '4.3', height: '165px', width: '380px', display: 'inline-block', background: "url(" + require("assets/img/salary-back1.png") + ") center center / cover no-repeat " }
const financeStyle = { cursor: 'pointer', lineHeight: '4.3', marginLeft: '13%', height: '162px', width: '380px', display: 'inline-block', background: "url(" + require("assets/img/salary-back2.png") + ") center center / cover no-repeat " }
const financeStyle_l = { cursor: 'pointer', lineHeight: '4.3', marginLeft: '13%', height: '165px', width: '380px', display: 'inline-block', background: "url(" + require("assets/img/salary-back2.png") + ") center center / cover no-repeat " }

class IndexSimpler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            current: '',
            username: JSON.parse(global._sessionStorage.getItem("user")).username,
            RoutesMenu: [],
            RoutesLink: [],
            changePassVisible: false,
            changePassClosable: false,
            businessName: JSON.parse(global._sessionStorage.getItem("user")).companyname,
            salary_Style: {},
            finance_Style: {},
            businessCode: '',
            businessList: [],
        }
    }
    getRoutesLink(items, crumbArr = []) {
        items.map(item => {
            let crumbArrTemp = [];
            crumbArrTemp.push(...crumbArr);
            crumbArrTemp.push(item.name);
            if (item.children) {
                this.getRoutesLink(item.children, crumbArrTemp);
            } else {
                item.crumbs = crumbArrTemp;
                this.state.RoutesLink.push(item);
            }
            return true;
        })

    }

    toggleCollapsed() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    handleCancel() {
        this.setState({
            changePassVisible: false,
            changePassClosable: false
        });
    }
    componentWillMount() {
        this.setState({
            salary_Style: salaryStyle,
            finance_Style: financeStyle,
        })
        window._ROUTER_ = this.props.history;
        const sessionUser = JSON.parse(global._sessionStorage.getItem("user"));
        if (sessionUser !== null) {
            // let loginParam = { }
            // getUserInfo(loginParam).then(res => {
            //     let { errorCode, data } = res.data;
            //     if (errorCode === "0000") {
            //         global._sessionStorage.setItem('userHeadId', data.userHeadId);
            //         // global._sessionStorage.setItem('loginBusiness', data.loginBusiness);
            //         global._sessionStorage.setItem('businessList', JSON.stringify(data.businessList));
            //         global._sessionStorage.setItem('loginInfoMap', JSON.stringify(data.loginInfoMap));
            //         let loginBusiness = global._sessionStorage.getItem("loginBusiness");
            //         if (data.loginInfoMap[loginBusiness]) {
            //             let loginInfo = data.loginInfoMap[loginBusiness];
            //             loginParam.enterpriseid = loginBusiness;
            //             loginParam.userId = loginInfo.userId;
            //             loginParam.username = sessionUser.mobile;
            //             loginParam.isChangePass = data.isChangePass;
            //             loginParam.lastLoginTime = data.lastLoginTime;
            //             // loginParam.personalSign = loginInfo.personalSign;
            //             // loginParam.companySign = loginInfo.companySign;
            //             loginParam.companyname = loginInfo.companyname;
            //             loginParam.bankCode = loginInfo.bankCode;
            //             loginParam.versions = loginInfo.versions;
            //             global._sessionStorage.setItem('user', JSON.stringify(loginParam));
            //             global._sessionStorage.setItem('businessEncrypt',loginInfo.businessEncrypt);
            //             global._sessionStorage.setItem('menuList', JSON.stringify(loginInfo.menuTrees));
            //             global._sessionStorage.setItem('menuSimplerPay', JSON.stringify(loginInfo.menuSimplerPay));
            //             global._sessionStorage.setItem('menuSimplerFac', JSON.stringify(loginInfo.menuSimplerFac));
            //             global._sessionStorage.setItem('menuSimplerSet', JSON.stringify(loginInfo.menuSimplerSet));

                        let user = sessionUser;
                        let menuList = JSON.parse(global._sessionStorage.getItem("menuList"));
                        let type = JSON.parse(global._sessionStorage.getItem("type"));
                        let menuSimplerPay = JSON.parse(global._sessionStorage.getItem("menuSimplerPay"));
                        let menuSimplerFac = JSON.parse(global._sessionStorage.getItem("menuSimplerFac"));
                        let menuSimplerSet = JSON.parse(global._sessionStorage.getItem("menuSimplerSet"));
                        let menuSimplerNoCardFac = JSON.parse(global._sessionStorage.getItem("menuSimplerNoCardFac"));
                        if (user.versions === '1') {
                            this.setState({
                                username: user.username,
                                RoutesMenu: menuList,
                                businessName: user.companyname
                            });
                            this.getRoutesLink(menuList);
                        } else {
                            if (type === '0') {
                                this.setState({
                                    username: user.username,
                                    RoutesMenu: menuSimplerPay,
                                    businessName: user.companyname
                                });
                                this.getRoutesLink(menuSimplerPay);
                            } else if (type === '1') {
                                this.setState({
                                    username: user.username,
                                    RoutesMenu: menuSimplerFac,
                                    businessName: user.companyname
                                });
                                this.getRoutesLink(menuSimplerFac);
                            } else if (type === '2') {
                                this.setState({
                                    username: user.username,
                                    RoutesMenu: menuSimplerSet,
                                    businessName: user.companyname
                                });
                                this.getRoutesLink(menuSimplerSet);
                            }else if (type === '3') {
                                this.setState({
                                    username: user.username,
                                    RoutesMenu: menuSimplerNoCardFac,
                                    businessName: user.companyname
                                });
                                this.getRoutesLink(menuSimplerNoCardFac);
                            }
                        }

                        if (user.isChangePass && user.isChangePass === '00') {
                            this.setState({
                                changePassVisible: true
                            })
                        }
                    // }
            //     }
            // })
        }

        this.setState({
            businessCode: global._sessionStorage.getItem("loginBusiness"),
            businessList: JSON.parse(global._sessionStorage.getItem("businessList")),
        })
    }

    businessChange(value) {
        global._sessionStorage.setItem('loginBusiness', value);
        let loginParam = JSON.parse(global._sessionStorage.getItem("user"));
        let loginInfoMap = JSON.parse(global._sessionStorage.getItem("loginInfoMap"));
        if (loginInfoMap[value]) {
            let loginInfo = loginInfoMap[value];
            loginParam.businessCode = value;
            loginParam.enterpriseid = value;
            loginParam.userId = loginInfo.userId;
            // loginParam.personalSign = loginInfo.personalSign;
            // loginParam.companySign = loginInfo.companySign;
            loginParam.companyname = loginInfo.companyname;
            loginParam.bankCode = loginInfo.bankCode;
            loginParam.versions = loginInfo.versions;
            global._sessionStorage.setItem('user', JSON.stringify(loginParam));
            global._sessionStorage.setItem('businessEncrypt',loginInfo.businessEncrypt);
            global._sessionStorage.setItem('menuList', JSON.stringify(loginInfo.menuTrees));
            global._sessionStorage.setItem('menuSimplerPay', JSON.stringify(loginInfo.menuSimplerPay));
            global._sessionStorage.setItem('menuSimplerFac', JSON.stringify(loginInfo.menuSimplerFac));
            global._sessionStorage.setItem('menuSimplerSet', JSON.stringify(loginInfo.menuSimplerSet));
            global._sessionStorage.setItem('menuSimplerNoCardFac', JSON.stringify(loginInfo.menuSimplerNoCardFac));

            this.setState({
                username: loginParam.username,
                RoutesMenu: loginInfo.menuTrees,
                RoutesLink: [],
                current: '/index'
            }, () => {
                this.getRoutesLink(loginInfo.menuTrees);
                if (loginInfo.versions === '1') {
                    this.props.history.push('/index');
                    // this.refs.setOpenKeys.setOpenKeys();
                } else {
                    this.props.history.push('/indexSimpler');
                }
            });
        }

        let lastBusinessParam = {
            mobile: loginParam.username,
            loginBusiness: value,
        }
        updateLoginBusiness(lastBusinessParam).then(res => {

        })

    }

    userInfo() {
        this.setState({
            changePassVisible: true,
            changePassClosable: true
        });
        return (
            <ForceChangePassword visible={this.state.changePassVisible} closable={this.state.changePassClosable} handleCancel={this.handleCancel.bind(this)}  {...this.props} />
        )
    }


    loginOut(e) {
        global._sessionStorage.removeItem('user');
        global._sessionStorage.removeItem('token');
        global._sessionStorage.removeItem('menuList');
        global._sessionStorage.removeItem('menuSimplerFac');
        global._sessionStorage.removeItem('menuSimplerPay');
        global._sessionStorage.removeItem('menuSimplerSet');
        global._sessionStorage.removeItem('menuSimplerNoCardFac');
        this.props.history.push('/login');
    }

    userSet() {
        global._sessionStorage.setItem('type', JSON.stringify("2"));
        this.props.history.push('/simplerSetUser');
    }
    toSalary() {
        global._sessionStorage.setItem('type', JSON.stringify("0"));
        this.props.history.push('/noCardSalary');
    }
    tofinance() {
        global._sessionStorage.setItem('type', JSON.stringify("1"));
        this.props.history.push('/simplerFacPay');
    }
    tofactoring() {
        global._sessionStorage.setItem('type', JSON.stringify("3"));
        this.props.history.push('/createFactorSalary');
    }
    onMouseOverSalary() {
        this.setState({
            salary_Style: salaryStyle_l,
        })
    }
    onMouseOutSalary() {
        this.setState({
            salary_Style: salaryStyle,
        })
    }
    onMouseOverFinance() {
        this.setState({
            finance_Style: financeStyle_l,
        })
    }
    onMouseOutFinance() {
        this.setState({
            finance_Style: financeStyle,
        })
    }

    render() {
        let user = global._sessionStorage.getItem("user");
        if (!user || "/login" === this.props.location.pathname) {
            global._sessionStorage.removeItem("user");
            global._sessionStorage.removeItem("token");
            global._sessionStorage.removeItem("menuList");
            global._sessionStorage.removeItem('menuSimplerFac');
            global._sessionStorage.removeItem('menuSimplerPay');
            global._sessionStorage.removeItem('menuSimplerSet');
            global._sessionStorage.removeItem('menuSimplerNoCardFac');
            return <Redirect to="/login" />
        }
        if ("/" === this.props.location.pathname) {
            return <Redirect to="/indexSimpler" />
        }

        const userMenu = (
            <Menu style={{ width: '150px' }}>
                <Menu.Item key="user"> <span><Icon style={{ marginRight: 10 }} type="user" />{this.state.username}</span> </Menu.Item>
                <Menu.Item onClick={this.userInfo.bind(this)} key="passmodify"> <span><Icon style={{ marginRight: 10 }} type="setting" />修改密码</span> </Menu.Item>
                <Menu.Item onClick={this.loginOut.bind(this)} key="login"> <span><Icon style={{ marginRight: 10 }} type="logout" />退出 </span> </Menu.Item>
            </Menu>
        );

        const businessListOptions = this.state.businessList.map((type, index) => {
            return <Option key={index} value={type.id}>{type.companyname}</Option>
        })

        return (
            <Layout style={{height:'100vh'}}>
                <Header className="index-header">
                    <div>
                        <Row type="flex" justify="space-around" align="middle" style={{ marginTop: "5px" }}>
                            <Col lg={14} xxl={14} >
                                <img style={{ marginLeft: '10px', height: '60px', marginBottom: '13px' }} src={logoSimpler} alt="企业logo" />
                                {/* <span style={{lineHeight:'5.5', marginLeft: '20px', color: '#fff', fontSize: '20px', borderLeftWidth: '2px', borderLeftColor: '#fff', borderLeftStyle: 'solid' }} >
                                {'   ' + this.state.businessName}
                            </span> */}
                                <span style={{ lineHeight: '5.5', marginLeft: '20px', color: '#fff', fontSize: '20px', borderLeftWidth: '2px', borderLeftColor: '#fff', borderLeftStyle: 'solid' }}></span>
                                <Select style={{ color: '#fff', lineHeight: '5.5', fontSize: '20px', minWidth: '30%', maxWidth: '60%' }}
                                    className="simpleBusinessChange"
                                    bordered={false} size="large"
                                    defaultValue={this.state.businessCode}
                                    onChange={this.businessChange.bind(this)} >
                                    {businessListOptions}
                                </Select>
                            </Col>
                            <Col offset={2} lg={4} xxl={4} >
                                <div style={{ lineHeight: '3.5', cursor: 'pointer', marginRight: '25px', textAlign: 'right' }} onClick={this.userSet.bind(this)}>
                                    <span style={{ color: '#fff', fontSize: '20px' }} >
                                        <img style={{ marginRight: '10px', height: '30px' }} src={userSetIcon} alt="用户设置" />
                                        {'用户设置'}
                                    </span>
                                </div>
                            </Col>
                            <Col lg={1} xxl={1}>
                                <div style={{ lineHeight: '3.5', cursor: 'pointer', textAlign: 'left', marginLeft: '-10px' }} >
                                    <SimplerHomeMessage history={this.props.history} />
                                </div>
                            </Col>
                            <Col lg={3} xxl={3} >
                                <Dropdown overlay={userMenu} placement="bottomCenter" >
                                    <div style={{ lineHeight: '3.5', width: '100%', cursor: 'pointer', marginRight: '10px' }} >
                                        <span className="ant-dropdown-link" style={{ color: '#fff', fontSize: '20px' }} >
                                            <img style={{ marginRight: '10px', height: '20px' }} src={userIcon} alt="用户" />
                                            {/* <Icon style={{ marginRight: 10 }} type="user" /> */}
                                            {this.state.username.length <= 5 ? this.state.username : this.state.username.substring(0, 5) + '...'}
                                            <img style={{ marginLeft: '10px', height: '10px' }} src={downIcon} alt="下拉" />
                                            {/* <Icon type="down" style={{ marginLeft: 10, height: '10px' }} /> */}
                                        </span>
                                    </div>
                                </Dropdown>
                            </Col>
                        </Row>
                    </div>
                </Header>
                <Content style={{flex:1,background:'#FFFFFF',position: 'relative',minHeight:'200px'}}>
                    <div className="index-content">
                        <Row gutter={0} align="middle" >
                            <div style={{ textAlign: 'center' }}>
                                <Col span={5} >
                                </Col>
                                <Col span={6} >
                                <div className="contentCard" onClick={this.toSalary.bind(this)}
                                    style={{background: 'linear-gradient(90deg, #40BBAD 0%, #45C8AC 48%, #47D3AA 100%)'}}>
                                    <span className="contentSalary">我要发薪</span>
                                </div>
                                </Col>
                                {/* <Col span={6} offset={1} >
                                <div className="contentCard" onClick={this.tofinance.bind(this)} 
                                    style={{background: 'linear-gradient(90deg, #34ABDA 0%, #34B2DA 48%, #35BDDA 100%)'}}>
                                    <span className="contentSalary" >发薪/融资 (旧版)</span>
                                </div>
                                </Col> */}
                                <Col span={6} offset={2} >
                                <div className="contentCard" onClick={this.tofactoring.bind(this)}
                                    style={{background: 'linear-gradient(90deg, #34ABDA 0%, #34B2DA 48%, #35BDDA 100%)'}}>
                                    <span className="contentSalary" >融资发薪 (新版)</span>
                                    <span className="recommendUse">
                                        推荐使用
                                    </span>                                
                                </div>
                                </Col>
                                <Col span={5}  >
                                </Col>
                            </div>
                        </Row>
                    </div>
                    <div  className="old">
                        <Button type="link" onClick={this.tofinance.bind(this)} style={{ display:'flex',alignItems: 'center'}}>
                            <span style={{ fontSize: 20}}>发薪/融资（旧版）</span>
                            <img style={{ width:'20px',height:'20px'}} src={jiantou1} />
                        </Button>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center',background: '#EDF0F6' }}>
                    薪企链平台版权 © Created by 薪海科技
                        </Footer>
                <ForceChangePassword visible={this.state.changePassVisible} closable={this.state.changePassClosable} handleCancel={this.handleCancel.bind(this)} {...this.props} />
            </Layout>

        );
    }
}

export default IndexSimpler;