/* eslint-disable no-unreachable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DropTarget } from 'react-dnd';
import { Button, Icon, Checkbox } from 'antd'
import styles from './index.m.less'
import DragTypes from "../DragTypes"
import ItemArea from './ItemArea'
import Attribute from './Attribute'

const type = DragTypes.FORM;

const spec = {
	drop(props, monitor, component) {
        // 这里 return 出去的对象属性自行选择，这里只是用 id 作为演示
        const item = monitor.getItem()
        // const dropResult = monitor.getDropResult()
        // 如果 drop 结果存在，就弹出 alert 提示
		if (item) {
            component.pushItem(item.item)
        }
		return { name: 'Dustbin'}
    },
    // drop: () => ({ name: 'Dustbin' }),
 
	// hover(props, monitor, component) {
	// },
 
	// canDrop(props, monitor) {
    //     console.log("canDrop")
    //     const item = monitor.getItem()
	// 	// 拖拽元素放下时，drop 结果
    //     const dropResult = monitor.getDropResult()
    //     console.log(item)
	// 	// 如果 drop 结果存在，就弹出 alert 提示
	// 	if (dropResult) {
	// 		alert(`You dropped ${item.name} into ${dropResult.name}!`)
	// 	}
    // },
}
 
const collect = (connect, monitor) => {
    
    return ({
	/// 包裹住 DOM 节点，使其可以接收对应的拖拽组件
    connectDropTarget: connect.dropTarget(),
    // drag source是否在 drop target 区域
    isOver: monitor.isOver(),
    // 是否可以被放置
    canDrop: monitor.canDrop(),
})}


@DropTarget(type, spec, collect)
class TargetArea extends Component {

    constructor(props){
        super(props);
        this.state = {
            items : [],
            selected : 0,
        }
    }

    pushItem = (item) => {
        let {items} = this.state;
        // items.push(item)
        const id = `${item.id}|${Date.now()}`;
        items.push({
            ...item,
            id,
        });
        this.setState({
            items ,
            selected : id
        })
    }

    handlePreviewList = (items) => {
        this.setState({
            items
       })
    }

    selectItem = (selected) => {
        this.setState({
            selected
        })
    }

    deleteItem = ( e, key) => {
        e.stopPropagation()
        const items = this.state.items.filter(item => item.id !== key);
        this.setState({
            items : items,
            selected : items.length === 0 ? 0 : items[0].id
        })
    }

    changeItem = (item) => {
        const {items} = this.state;
        const arr = items.map(it =>{
            if(it.id === item.id){
                return item;
            }else{
                return it;
            }
        })
        this.setState({
            items : arr
        })
    }

    render() {
        const { connectDropTarget, className } = this.props;
        const {items, selected} = this.state;
        const handleDND = (dragIndex, hoverIndex) => {
            let tmp = items[dragIndex] //临时储存文件
            this.selectItem(tmp.id);
            items.splice(dragIndex, 1) //移除拖拽项
            items.splice(hoverIndex, 0, tmp) //插入放置项
            this.handlePreviewList(items);
        };
        const renderItem = (item, index) => {
            const text = <div key={item.id} className={styles["target-item"]} select={ item.id === selected ? "true" : "false"} onClick={() => this.setState({selected : item.id})} >
                            <div className={styles["target-item-label"]}>{item.label} {item.required && <span style={{color : 'red', marginLeft : '2px'}} >*</span> }
                            { item.tip && <span className={styles["target-item-label-tip"]}>{`（${item.tip}）`}</span> }
                            </div>
                            {(()=>{
                                const claName = classNames(styles["target-item-box"], styles[item.box]);
                                switch(item.box){
                                    case "text":return <div className={claName}>{item.value}</div>; break;
                                    case "text-area":return <div className={claName}>{item.value}</div>; break;
                                    case "select":return <div className={claName}>{item.value || '请选择'}<Icon style={{float :' right', marginTop : '13px', marginRight : '10px'}} type="down" /></div>; break;
                                    case "checkbox":return <div className={claName}><Checkbox.Group options={item.options} value={item.value} /></div>; break;
                                    default:return <div className={claName}>{item.value}</div>;
                                }
                            })()}
                            {
                                item.id === selected &&
                                <div className={styles["target-item-delete"]} ><Icon theme="twoTone" onClick={(e) => this.deleteItem(e, item.id)} type="delete" /></div>
                            }
                        </div>
            return (
                <ItemArea
                    key={item.id}
                    index={index}
                    id={item.id}
                    text={text}
                    moveCard={handleDND}
                />
            )
        }
        let item = undefined;
        items.forEach(i => {
            if(i.id === selected){
                item= i
            }
        })
        const btnStyle = {
            marginLeft : '10px',
        }
        return connectDropTarget && connectDropTarget(
                <div className={className}>
                    <div className={styles["target-area"]}>
                        <div className={styles["target-area-topic"]}>
                            <Button type="primary" size="small" >保存</Button>
                            <Button style={btnStyle} size="small" >取消</Button>
                            <Button type="link" style={btnStyle}  size="small" >预览</Button>
                        </div>
                        <div className={styles["target-area-content"]} >
                            {
                                items.map((item, i) => renderItem(item, i))
                            }
                        </div>
                        
                    </div>
                    <div className={styles["target-area-field"]}>
                        {
                            item && <Attribute item={item} changeItem={this.changeItem} />
                        }
                        
                    </div>
                </div>
        );
    }
}

TargetArea.propTypes = {
    className : PropTypes.string,

};

export default TargetArea;