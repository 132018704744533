import React, { Component } from 'react';
import { Button, Tree, Divider, Message } from 'antd'
import RoleMenuCommon from './Common/RoleMenuCommon'
import { queryMenuList, saveRole } from 'api/role'


const { TreeNode } = Tree;

class IncreaseMenuInfo extends Component {

    constructor(props){
        super(props);
        const checkedKeys = this.props.checkedKeys;
        this.state={
            visible : false,
            loading : false,
            menuData : [],
            expandedKeys: [],
            autoExpandParent: false,
            checkedKeys: checkedKeys.length===0 ? [] : checkedKeys,
            halfCheckedKeys : []
        }
    }

    componentDidMount(){
        let param ={ "eid" : JSON.parse(global._sessionStorage.getItem('user')).enterpriseid };
        queryMenuList(param).then(res => {
            let data = res.data;
            if(data.errorCode==='0000'){
                this.setState({
                    menuData : data.list,
                    visible : true
                })
            }
        })
    }



    handlerOnCheck(checkedKeys,halfCheckedKeys) {
        this.setState({ checkedKeys,halfCheckedKeys });
    }

    handlerOnExpand(expandedKeys) {
        this.setState({ 
            expandedKeys
        });
    }

    prevClick(){
        const checkedKeys =this.state.checkedKeys;
        this.props.prevClick(checkedKeys);
    }

    resetClick(){
        this.setState({
            checkedKeys: [],
            selectedKeys: []
        })
    }

    submitClick(){
        let user = JSON.parse(global._sessionStorage.getItem('user'));
        const { roleName, roleDescr } = this.props.roleForm
        let params = {
            enterpriseid : user.enterpriseid,
            role_name : roleName,
            remarks : roleDescr,
            createuser : user.username,
            // menuList : this.state.checkedKeys.concat(this.state.halfCheckedKeys)
            menuList : this.state.checkedKeys
        }
        this.setState({
            loading : true
        })
        saveRole(params).then(res => {
            let data = res.data;
            if(data.errorCode==='0000'){
                this.props.stepClick(this.state.expandedKeys, this.state.checkedKeys)
            }else{
                this.setState({
                    loading : false
                })
                Message.error(data.errorMessage);
            }
        })




        
    }

    renderTreeNodes = data => data.map((item) => {
        if (item.children) {
          return (
            <TreeNode title={item.title} key={item.key} dataRef={item}>
              {this.renderTreeNodes(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode {...item} />;
      })

    render() {
        return (
            <div>
                <div>
                    <span className="span-title" >菜单分配</span>
                </div>
                <Divider />
                
                {
                    this.state.visible
                    && 
                    <div>
                        <div className="menu-tree" >
                        <RoleMenuCommon checkedKeys={this.state.checkedKeys} autoExpandParent={this.state.autoExpandParent} expandedKeys={this.state.expandedKeys}
                                onCheck={this.handlerOnCheck.bind(this)} onExpand={this.handlerOnExpand.bind(this)} sourceData={this.state.menuData} />
                        </div>
                        <div className="menu-btn-group" >
                        <Button type="primary" onClick={this.prevClick.bind(this)}  >上一步</Button>
                        <Button type="primary" onClick={this.resetClick.bind(this)}  >重置</Button>
                        <Button type="primary" loading={this.state.loading} onClick={this.submitClick.bind(this)}  >提交</Button>

                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default IncreaseMenuInfo;