import React, { Component } from 'react';
import CreatePayRollDelete from '@/components/weeksalary/CreatePayRollDelete';
import CreatePayRollOperate from '@/components/weeksalary/CreatePayRollOperate';
import { Table, Alert,Divider } from 'antd';
import {Link} from 'react-router-dom' ;
import CreateSupplierPayRollSubmit from './CreateSupplierPayRollSubmit';
import CreateSupplierPayRollBatch from './CreateSupplierPayRollBatch';


class CreateSupplierPayRollResult extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        const _this = this;
        const columns = [

            {
                title: '批次', dataIndex: 'salarybatchcode', key: 'salarybatchcode', align: 'center',
                render(text, record, index){
                    return <CreateSupplierPayRollBatch record={record} refreshSubmit={_this.props.refreshSubmit}/>
                }
            },
            {
                title: '用工企业商户名称/商户号', dataIndex: 'joinentid', key: 'joinentid', align: 'center',
                render(text, record, index) {
                    return _this.props.employerList.filter( state => record.joinentid===state.upstreamCorporate ).map(state => state.upCompanyName+"/"+state.upstreamCorporate);
                }
            },
            {
                title: '发放月份', dataIndex: 'salarydate', key: 'salarydate', align: 'center',

            },
            {
                title: '创建时间', dataIndex: 'createtime', key: 'createtime', align: 'center',

            },
            {
                title: '总金额', dataIndex: 'totalnum', key: 'totalnum', align: 'center',

            },
            {
                title: '总笔数', dataIndex: 'employeenum', key: 'employeenum', align: 'center',

            },
            { title : '发放平台', dataIndex : 'payChannel', key : 'payChannel', align : 'center',
                render(val){
                    if(val==='1' ){
                        return '薪企链';
                    }else{
                        return '虎符发放';
                    }
                } 
            },
            {
                title: '状态', dataIndex: 'checkStatusTxt', key: 'checkStatusTxt', align: 'center',

            },
            {
                title: '操作', align: 'center',
               render(text, record, index) {
                    let status=record.checkstatus;
                    if(status==='00' ||status==='05'){
                        return (
                            <div>
                                   <CreatePayRollDelete record={record} refreshSubmit={_this.props.refreshSubmit}/> 
                            </div>
                        ) 
                    }else if(status==='04'){
                        return (
                            <div>  
                                   <CreateSupplierPayRollSubmit record={record} refreshSubmit={_this.props.refreshSubmit} />
                                   <Divider type="vertical" />
                                   <CreatePayRollDelete record={record} refreshSubmit={_this.props.refreshSubmit}/> 
                            </div>
                        ) 
                    }else if(status==='03'){
                        return (
                            <div>  
                                   <CreatePayRollOperate record={record} refreshSubmit={_this.props.refreshSubmit}/>
                                   <Divider type="vertical" />
                                   <CreatePayRollDelete record={record} refreshSubmit={_this.props.refreshSubmit}/> 
                            </div>
                        ) 
                    }

                    return (
                        <div>

                        </div>
                    ) 
               }
            },
        ]  
        const pagination ={
            current : parseInt(this.props.currentPage),
            total: this.props.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : this.props.pageSizeOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        } 
        return (
            <div style={{marginTop : '20px'}}>

                <Alert style={{marginTop : '10px'}} message={(
                                    <span>查询结果</span>
                                )} type="info" showIcon />
                <Table scroll={{ x: '100%'}} style={{marginTop : '20px'}} 
                    rowKey = { record => record.salarybatchcode }
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData} 
                    columns={columns}
                    bordered
                    pagination={pagination}
                >
                </Table>
                   
            </div>
        );
    }
}

export default CreateSupplierPayRollResult;