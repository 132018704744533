import React, { Component } from 'react';
import { Popover, Button } from 'antd'

class ConfirmPrpover extends Component {

    constructor(){
        super();
        this.state = {
            visible : false
        }
    }

    cancleClick(){
        this.setState({
            visible : false
        })
    }

    submitClick(){
        this.setState({
            visible : false
        })
        this.props.confirmSubmit(this.props.record.id);
    }

    handleVisibleChange(visible){
        this.setState({
            visible
        })
    }


    render() {
        return (
            <Popover placement={this.props.placement} title={
                <div className="popover-confirm-title" >
                    <span >{this.props.title}</span>
                </div>
            } content={
                <div>
                    <div className="popover-confirm-content" >
                        <span>{this.props.content}</span>
                    </div>
                    <div className="popover-confirm-btn" >
                        <Button onClick={this.cancleClick.bind(this)} >{this.props.cancelText}</Button>
                        <Button onClick={this.submitClick.bind(this)} >{this.props.confirmText}</Button>
                    </div>
                </div>
            } trigger="click" 
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange.bind(this)}
            >
                {this.props.htmlText}
            </Popover>
        );
    }
}

export default ConfirmPrpover;