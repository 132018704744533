import React, { Component } from 'react';
import { Form, Input, Button, Message, Select, Switch,Radio  } from 'antd'

import { saveDepart} from 'api/enDepart'

const { Option } = Select; 

class EnDepartIncreInfo extends Component {

    constructor(){
        super();
        this.state={
            enterpriseid : JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            companyname : JSON.parse(global._sessionStorage.getItem('user')).companyname,
            loading : false,
            supportShow:"",
            // standardShow:""
        }
    }

    handlerReset(){
        this.props.form.resetFields();
        this.setState({
            supportShow:"",
            standardShow:""
        });
    }

    handerSubmit(e){
        this.setState({
            loading : true
        })
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                params.enterpriseid = this.state.enterpriseid;
                params.companyname = this.state.companyname;
                this.setState({
                    loading : false
                })
                saveDepart(params).then(res =>{
                    let data = res.data;
                    if( data.errorCode === '0000' ){
                        this.setState({
                            loading : false
                        })
                        Message.success('添加成功');
                        this.handlerReset();
                    }else{
                        this.setState({
                            loading : false
                        })
                        Message.error(data.errorMessage);
                    }
                })
            }else{
                this.setState({
                    loading : false
                })
            }
        })
    }

    validateCert = (rule, value, callback) =>{
        callback();

    }
    onChangeSupport = e => {
        this.setState({
            supportShow: e.target.value,
        });
      };
    // onChangeStandard = e => {
    //     this.setState({
    //         standardShow: e.target.value,
    //     });
    //   };
    render() {
        const formItemStyle = {
            labelCol : { span : 6 },
            wrapperCol : { span : 8 }
        }
        const { getFieldDecorator } = this.props.form;

        return (
            <Form { ...formItemStyle } onSubmit={this.handerSubmit.bind(this)} >
                <Form.Item label="项目名称"  >
                    { getFieldDecorator("dname",{
                        rules : [
                            { required: true, message: '请输入项目名称' }
                        ],
                    })(
                        <Input placeholder="项目名称" />
                    )}
                </Form.Item>
                <Form.Item label="是否支持日薪"  >
                    { getFieldDecorator("supportFlag",{
                        rules : [
                            { required: true, message: '请选择是否支持日薪' }
                        ],
                    })(
                        <Radio.Group onChange={this.onChangeSupport}>
                            <Radio value="1">支持</Radio>
                            <Radio value="2">不支持</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                {("1"===this.state.supportShow)&&<Form.Item label="工时单价"  >
                    { getFieldDecorator("hourUnitPrice",{
                        rules : [
                            { required: true, message: '请输入工时单价' },
                            { pattern: new RegExp('^(([1-9][0-9]*)|([0]\\.[1-9])|([0]\\.[0-9][1-9])|([1-9][0-9]*\\.\\d{1,2}))$', "g"), message: '请输入正确的金额' },
                        ],
                    })(
                        <Input placeholder="工时单价" />
                    )}
                </Form.Item>}
                <Form.Item label="人员审核"  >
                    { getFieldDecorator("auditMethod",{
                        rules : [
                            { required: true, message: '请选择人员审核类型' }
                        ],
                    })(
                        <Radio.Group>
                            <Radio value="2">系统审核</Radio>
                            <Radio value="1">人工审核</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                {/* {("1"===this.state.supportShow)&&<Form.Item label="日薪申请标准"  >
                    { getFieldDecorator("wageStandard",{
                        // rules : [
                        //     { required: true, message: '请选择是否支持日薪' }
                        // ],
                    })(
                        <Radio.Group onChange={this.onChangeStandard}>
                            <Radio value="1">按工时</Radio>
                            <Radio value="2">按天</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>}
                {("1"===this.state.standardShow)&&<Form.Item label="工时数(/小时)"  >
                    { getFieldDecorator("wageByHour",{
                        rules : [
                            { pattern: new RegExp('^([1-9][0-9]*)$', "g"), message: '请输入正确的日薪申请标准工时数，类型为整数' },
                        ],
                    })(
                        <Input placeholder="工时数" />
                    )}
                </Form.Item>}
                {("2"===this.state.standardShow)&&<Form.Item label="天数(/天)"  >
                    { getFieldDecorator("wageByDay",{
                        rules : [
                            { pattern: new RegExp('^([1-9][0-9]*)$', "g"), message: '请输入正确的日薪申请标准天数，类型为整数' },
                        ],
                    })(
                        <Input placeholder="天数" />
                    )}
                </Form.Item>} */}
                    <div style={{ marginTop:20 }} >
                        <Button type="primary" onClick={this.handlerReset.bind(this)} style={{marginLeft : 300}} >重置</Button>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} style={{marginLeft : 50}}  >提交</Button>
                    </div>
                
            </Form>
        );
    }
}

EnDepartIncreInfo = Form.create()(EnDepartIncreInfo);

export default EnDepartIncreInfo;