import React, { Component } from 'react';
import img404 from 'assets/img/404.png'

class NoMatch extends Component {
    render() {
        return (
            <div>
                <img src={img404} alt="404 Not Found" style={{width:'100%',overflow:'hidden'}}/>
            </div>
        );
    }
}

export default NoMatch;