import React, { Component } from 'react';
import { Form, Input, Button, DatePicker, Select, Row, Message, Card, Col } from 'antd'
import { editeEnterprise, selectDept } from '../../api/staff';
import { queryDeptInitGroup } from '../../api/enDepart';
import moment from 'moment';
const { Option } = Select;

class UpdateStaffPosition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            uid: this.props.record.id,
            loading: false,
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({
                    loading: true,
                })

                params.id = this.state.uid;
                params.eid = this.state.enterpriseid;
                params.wxMobile = this.props.record.wxMobile;
                editeEnterprise(params).then(res => {
                    let { statusCode, message } = res.data;
                    if ("200" === statusCode) {
                        Message.success("修改职位信息成功");
                        this.props.refreshSubmit();
                    } else {
                        Message.error(message);
                    }
                    this.setState({
                        loading: false,
                    })
                })
            }
        })

    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 12 }
        };
        return (
            <div>
                <Card title="职位信息" bordered={false} size="small" >
                    <Form {...formItemLayout} onSubmit={this.handleSubmit.bind(this)}>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="工号">
                                    {getFieldDecorator('job_number', {
                                        initialValue: this.props.record.userjoinenterprise.jobNumber === null ? "" : this.props.record.userjoinenterprise.jobNumber,
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入工号！',
                                            }
                                        ]
                                    })(<Input placeholder='工号' disabled={this.props.detailFlag} />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="就职类型">
                                    {
                                        getFieldDecorator('state', {
                                            initialValue: this.props.record.userjoinenterprise.state === null ? "" : this.props.record.userjoinenterprise.state,
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入就职类型！',
                                                }
                                            ]
                                        })(<Select style={{ width: '100%' }} placeholder="发放方式" disabled={this.props.detailFlag}>
                                            <Option value="00">正式</Option>
                                            <Option value="01">试用</Option>
                                            <Option value="02">实习</Option>
                                            <Option value="03">兼职</Option>
                                            <Option value="04">离职</Option>
                                        </Select>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="薪酬关系">
                                    {getFieldDecorator('salarystatus', {
                                        initialValue: this.props.record.userjoinenterprise.salarystatus,
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入薪酬关系！',
                                            }
                                        ]
                                    })(<Select style={{ width: '100%' }} placeholder="薪酬关系" disabled={this.props.detailFlag}>
                                        <Option value="00">发放</Option>
                                        <Option value="01">不发放</Option>
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="职位">
                                    {
                                        getFieldDecorator('position', {
                                            initialValue: this.props.record.userjoinenterprise.position === null ? "" : this.props.record.userjoinenterprise.position,
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入职位！',
                                                }
                                            ]
                                        })(<Input placeholder='职位' disabled={this.props.detailFlag} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="试用工资">
                                    {
                                        getFieldDecorator('trainingsalary', {
                                            initialValue: this.props.record.userjoinenterprise.trainingsalary === null ? "" : this.props.record.userjoinenterprise.trainingsalary,
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入试用工资！',
                                                }
                                            ]
                                        })(<Input style={{ width: '100%' }} placeholder="试用工资" disabled={this.props.detailFlag} />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="转正工资">
                                    {
                                        getFieldDecorator('regularsalary', {
                                            initialValue: this.props.record.userjoinenterprise.regularsalary === null ? "" : this.props.record.userjoinenterprise.regularsalary,
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入转正工资！',
                                                }
                                            ]
                                        })(<Input placeholder='转正工资' disabled={this.props.detailFlag} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="转正时间">
                                    {getFieldDecorator('qualified_date', {
                                        initialValue: this.props.record.userjoinenterprise.qualifiedDate === null ? null : moment(new Date(this.props.record.userjoinenterprise.qualifiedDate), 'yyyy-MM-dd'),
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入转正时间！',
                                            }
                                        ]
                                    })(<DatePicker placeholder='转正时间' style={{ width: '100%' }} disabled={this.props.detailFlag} />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="合同到期日">
                                    {getFieldDecorator('Contract_termination_date', {
                                        initialValue: this.props.record.userjoinenterprise.contractTerminationDate === null ? null : moment(new Date(this.props.record.userjoinenterprise.contractTerminationDate), 'yyyy-MM-dd'),
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入合同到期日！',
                                            }
                                        ]
                                    })(<DatePicker placeholder='合同到期日' style={{ width: '100%' }} disabled={this.props.detailFlag} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="离职时间">
                                    {
                                        getFieldDecorator('leavetime', {
                                            initialValue: this.props.record.userjoinenterprise.leavetime === null ? null : moment(new Date(this.props.record.userjoinenterprise.leavetime), 'yyyy-MM-dd'),
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入离职时间！',
                                                }
                                            ]
                                        })(<DatePicker placeholder='离职时间' style={{ width: '100%' }} disabled={this.props.detailFlag} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align='middle'>
                            <Col span={1} offset={10}>
                                <Button type="primary" icon="form" htmlType="submit" loading={this.state.loading} hidden={this.props.detailFlag}>保存修改</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        );
    }
}

UpdateStaffPosition = Form.create()(UpdateStaffPosition);

export default UpdateStaffPosition;