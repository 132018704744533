import React, { Component } from 'react';
import { Message } from 'antd'
import { querySalaryBatchAudit } from 'api/RechargeController'
import SalaryPaySearchForm from '@/components/finance/SalaryPaySearchForm'
import HistoryQueryResult from '@/components/finance/HistoryQueryResult'


class HistoryQuery extends Component {
    constructor(){
        super();
        this.state = {
            loading : false,
            searchParam : {},
            sourceData : [],
            totalNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            showReturnSalaryPayBtn : true,
        }
    }

    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage,
            searchParam : params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params){
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam : params
        })
        this.searchPost(params);
    }

    searchPost(params){
        this.setState({
            loading : true
        })
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        
        querySalaryBatchAudit(params).then(res => {
            let {errorCode, errorMessage} = res.data;
            if(errorCode === '0000'){
                this.setState({
                    sourceData : res.data.salaryPayList,
                    totalNum : res.data.totalRow
                })
            }else{
                Message.error(errorMessage);
            }
            this.setState({
                loading : false
            })
        })
    }

    refreshSubmit(){
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    resetForm(){
        this.setState({
            searchParam : {}
        })
    }

    componentDidMount(){
        this.searchFormSubmit({});
    }


    render() {
        return (
            <div>
                <SalaryPaySearchForm 
                    loading={this.state.loading}
                    showReturnSalaryPayBtn={this.state.showReturnSalaryPayBtn} 
                    searchFormSubmit={this.searchFormSubmit.bind(this)} 
                    resetForm={this.resetForm.bind(this)}
                />

                <HistoryQueryResult 
                    resultData={this.state.sourceData} 
                    totalNum={this.state.totalNum} 
                    loading={this.state.loading} 
                    refreshSubmit={this.refreshSubmit.bind(this)} 
                    currentPageChange={this.currentPageChange.bind(this)}
                    pageSizeChange={this.pageSizeChange.bind(this)}
                    currentPage={this.state.currentPage}
                    pageSizeOptions={this.state.pageSizeOptions}
                    pageSize={this.state.pageSize}
                />
            </div>
        );
    }
}

export default HistoryQuery;