import React, { Component } from 'react';
import { Form, Row, Col, Input, Button } from 'antd'
import moment from 'moment';
class SimplerDepartStaffSiteForm extends Component {
    constructor(){
        super();
        this.state = {
            
        }
    }

    refreshSubmit(e){
        this.handlerSubmit(e);
    }

    handlerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset(){
        this.props.form.resetFields();
        this.props.resetForm();
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                 <Form className="ant-search-form" onSubmit={this.handlerSubmit.bind(this)} >
                    <Row gutter={24} >
                        <Col span={6} >
                            <Form.Item label="姓名" >
                            { getFieldDecorator("username",{
                            })(
                                <Input placeholder="姓名" allowClear ></Input>
                            ) }
                            </Form.Item>
                        </Col>
                        <Col span={15} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" style={{marginLeft : 50}}  loading={this.props.loading} >查询</Button>
                                <Button type="primary" style={{marginLeft : 30}} onClick={this.handlerReset.bind(this)} >重置</Button>
                                <Button type="primary" style={{marginLeft : 30}} onClick={this.refreshSubmit.bind(this)} >刷新</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                 </Form>
            </div>
        );
    
    }
}
SimplerDepartStaffSiteForm = Form.create()(SimplerDepartStaffSiteForm)
export default SimplerDepartStaffSiteForm;