import React, { Component } from 'react';
import { baseHttpUrl } from '../../assets/settings';
import axios from 'axios';

class DownloadTemp extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem("user")).enterpriseid,
        }
    }

    downloadTemp(){
        let params = {
            enterpriseid: this.state.enterpriseid,
            templateid : this.props.record.id,
            bustype : this.props.record.bustype,
        }
        
        axios({
            method: 'GET',
            url: baseHttpUrl+'/api/salary/downloadSalaryTmp',
            params: params,
            responseType: 'blob'
        }).then(function(res) {
            const content = res.data
            const blob = new Blob([content],{type: "application/x-msdownload"})
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = res.headers['filename'];
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })
    }

    render() {
        return (
            <span style={{ display: 'inline-flex' }} >

                <span className="span-click" onClick={this.downloadTemp.bind(this)}>下载</span>

            </span>
        );
    }
}

export default DownloadTemp;