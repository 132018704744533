import React, { Component } from 'react';
import { Form, Message, Divider, Icon } from 'antd';
import moment from 'moment';
import Search from '@/components/Search';
import { formatDate } from 'utils/Date';
import { ModalWrapper } from 'wrapper';
import {publicDesensitization} from '@/utils/String'
import axios from 'axios';
import { baseHttpUrl } from '@/assets/settings';
import { getTokenStr } from '@/utils/http';
import { salaryBatchRecordList, salaryBatchRecordDetail } from 'api/transPlatform';

@Form.create()
class TransPayRecord extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            salaryBatchRecordList: [],
            total: 0,
            loading: false,
            startDate: '',
            endDate: '',
            detailVisiable: false,
            record: {},

        }
    }

    onSubmit = values => {
        this.setState({
            loading: true,
        })
        values.eid = this.state.enterpriseid;
        values.startDate = values.startDate && moment(values.startDate).format('YYYYMMDD');
        values.endDate = values.endDate && moment(values.endDate).format('YYYYMMDD');
        salaryBatchRecordList(values).then(res => {
            let { errorCode, errorMessage, data } = res.data;
            if (errorCode === '0000') {
                this.setState({
                    total: data.totalCount,
                    salaryBatchRecordList: data.list,
                })
            } else {
                Message.error(errorMessage);
            }
            this.setState({
                loading: false,
            })
        })
    }
    onRefresh = () => {
        this.child.onRefresh();
    }

    onRef = (ref) => {
        this.child = ref
    }

    onStartChange = value => {
        this.setState({
            startDate: value,
        });
    };
    disabledEndDate = endDate => {
        const { startDate } = this.state;
        if (!endDate || !startDate) {
            return false;
        }
        return moment(endDate.format('YYYY-MM-DD'),'YYYY-MM-DD').valueOf() < moment(startDate.format('YYYY-MM-DD'),'YYYY-MM-DD').valueOf();
    };

    onEndChange = value => {
        this.setState({
            endDate: value,
        });
    };
    disabledStartDate = startDate => {
        const { endDate } = this.state;
        if (!startDate || !endDate) {
            return false;
        }
        return moment(startDate.format('YYYY-MM-DD'),'YYYY-MM-DD').valueOf() > moment(endDate.format('YYYY-MM-DD'),'YYYY-MM-DD').valueOf();
    };

    onShowDetail = (record) => {
        this.setState({
            detailVisiable: true,
            record: record,
        })
    }
    onShowDetailCancel = () => {
        this.setState({
            detailVisiable: false
        })
    }

    download(salaryBatchCode) {
        let params = {
            batchNo: salaryBatchCode,
            enterpriseId: this.state.enterpriseid,
        }
        axios({
            method: 'GET',
            url: baseHttpUrl + '/superviseSalary/downloadSalaryBatchDetail',
            params: params,
            responseType: 'blob',
            headers: {
                'Token': getTokenStr(),
            },
        }).then(function (res) {
            const content = res.data
            const blob = new Blob([content], { type: "application/x-msdownload" })
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = res.headers['filename'];
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })

    }

    render() {
        const { total, salaryBatchRecordList, detailVisiable, loading, record } = this.state;
        const search = [
            {
                'id': 'fileName',
                'label': '文件名称',
                'type': 'input',
                'col': 1,
                'restProps': {

                },
                'initialValue': '请输入文件名称',
                'rule': [

                ]
            },
            {
                'id': 'checkStatus',
                'label': '校验状态',
                'type': 'select',
                'col': 1,
                'options': [
                    { value: '', label: '全部' },
                    { value: '0', label: '成功' },
                    { value: '1', label: '失败' },

                ],
                'attr': {
                    initialValue: '',
                },
            },
            {
                'id': 'parseStatus',
                'label': '解析状态',
                'type': 'select',
                'col': 1,
                'options': [
                    { value: '', label: '全部' },
                    { value: '0', label: '成功' },
                    { value: '1', label: '失败' },
                ],
                'attr': {
                    initialValue: '',
                },
            },
            {
                'id': 'startDate',
                'label': '开始日期',
                'type': 'date-picker',
                'col': 1,
                'attr': {
                    // initialValue: moment(new Date()).subtract(29, "days"),
                    rules: [
                    ]
                },
                'restProps': {
                    disabledDate: (value) => this.disabledStartDate(value),
                    onChange: (value) => this.onStartChange(value)
                },
            },
            {
                'id': 'endDate',
                'label': '结束日期',
                'type': 'date-picker',
                'col': 1,
                'attr': {
                    // initialValue: moment(new Date()),
                    rules: [
                    ]
                },
                'restProps': {
                    disabledDate: (value) => this.disabledEndDate(value),
                    onChange: (value) => this.onEndChange(value)
                },
            },
            {
                'id': 'salaryType',
                'label': '发放类别',
                'type': 'select',
                'col': 1,
                'options': [
                    { value: 'advance,supervise', label: '全部' },
                    { value: 'advance', label: '预付金' },
                    { value: 'supervise', label: '结算监管' },
                ],
                'attr': {
                    initialValue: 'advance,supervise',
                },
            },
        ];
        const _this = this;
        const columns = [
            {
                key: 'salaryBatchCode',
                title: '发放批次号',
                align: 'center',
                dataIndex: 'salaryBatchCode',
                width: '15%',
            },
            {
                key: 'fileName',
                title: '文件名称',
                align: 'center',
                dataIndex: 'fileName',
                ellipsis : true,
                width: '13%',
            },
            {
                key: 'importDate',
                title: '导入时间',
                align: 'center',
                dataIndex: 'importDate',
                width: '10%',
                render(val) {
                    return formatDate(new Date(val), 'yyyy-MM-dd hh:mm:ss');
                }
            },
            {
                key: 'totalAmount',
                title: '总金额',
                align: 'center',
                dataIndex: 'totalAmount',
                width: '7%',

            },
            {
                key: 'totalNum',
                title: '总条数',
                align: 'center',
                dataIndex: 'totalNum',
                width: '7%',

            },
            {
                key: 'succNum',
                title: '成功条数',
                align: 'center',
                dataIndex: 'succNum',
                width: '8%',

            },
            {
                key: 'failNum',
                title: '失败条数',
                align: 'center',
                dataIndex: 'failNum',
                width: '8%',

            },
            {
                key: 'parseStatus',
                title: '解析状态',
                align: 'center',
                dataIndex: 'parseStatus',
                width: '10%',
                ellipsis : true,
                render(text, record, index) {
                    if (record.parseStatus === '0') {
                        return '成功';
                    } else if (record.parseStatus === '1') {
                        return record.parseErrorMsg;
                    } else {
                        return '--';
                    }
                }
            },
            {
                key: 'checkStatus',
                title: '校验状态',
                align: 'center',
                dataIndex: 'checkStatus',
                width: '8%',
                render(val) {
                    if (val === '0') {
                        return '成功';
                    } else if (val === '1') {
                        return '失败';
                    } else {
                        return '--';
                    }
                }
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                width: '10%',
                align: 'center',
                render: (text, record, index) => {
                    return (
                        <div>
                            {
                            record.totalNum&&record.totalNum > 0 ? <span className="span-click" onClick={() => _this.onShowDetail(record)}><Icon type="profile" theme="twoTone" /></span>
                                : <span className="span-click-disabled" ><Icon type="profile" theme="twoTone" twoToneColor="#d9d9d9" /></span> 
                            }
                            <Divider type="vertical" />
                            {
                            record.totalNum&&record.totalNum > 0 ? <span className="span-click" onClick={() => _this.download(record.salaryBatchCode)}><Icon type="download" /></span>
                                : <span className="span-click-disabled" ><Icon type="download"  /></span> 
                            }
                        </div>

                    )
                }
            },
        ];
        const tools = {
        };


        return (
            <div>
                <div >
                    <Search
                        {...this.props}
                        search={search}
                        loading={loading}
                        submit={this.onSubmit}
                        isLoad={true}
                        tools={tools}
                        columns={columns}
                        data={salaryBatchRecordList}
                        total={total}
                        pagination={true}
                        // drawer={drawer}
                        onRef={this.onRef}
                    />
                </div>
                <div>
                    {
                        detailVisiable && <ModalWrapper
                            // footer={null}
                            width={1000}
                            // maskClosable={false}

                            // bodyStyle={{ padding: '50px 40px 48px' }}
                            destroyOnClose
                            title="发放单记录详情"
                            visible={detailVisiable}
                            footer={false}
                            onCancel={this.onShowDetailCancel.bind(this)}
                        >
                            <SalaryPayRecordDetail onShowDetailCancel={this.onShowDetailCancel.bind(this)} onRefresh={this.onRefresh} record={record} />
                        </ModalWrapper>
                    }
                </div>
            </div>

        );
    }
}
@Form.create()
class SalaryPayRecordDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            userId: JSON.parse(global._sessionStorage.getItem('user')).userId,
            loading: false,
            total: 0,
            user: JSON.parse(global._sessionStorage.getItem("user")),
            payRecordDetailList: [],
        }
    }
    onRefresh = () => {
        this.child.onRefresh();
    }

    onRef = (ref) => {
        this.child = ref
    }

    onSubmit = values => {
        this.setState({
            loading: true,
        })
        values.eid = this.state.enterpriseid;
        values.operatorId = this.state.userId;
        values.id = this.props.record.id;
        salaryBatchRecordDetail(values).then(res => {
            let { errorCode, errorMessage, data } = res.data;
            if (errorCode === '0000') {
                this.setState({
                    total: data.totalCount,
                    payRecordDetailList: data.list,
                })
            } else {
                Message.error(errorMessage);
            }
            this.setState({
                loading: false,
            })
        })
    }


    render() {
        const { total, loading, payRecordDetailList } = this.state;

        const columns = [
            {
                key: 'userName',
                title: '姓名',
                align: 'center',
                dataIndex: 'userName',
                width: '10%',

            },
            {
                key: 'certNo',
                title: '身份证号',
                align: 'center',
                dataIndex: 'certNo',
                width: '30%',
                render(val) {
                    return publicDesensitization(val);
                }
            },
            {
                key: 'realSalary',
                title: '实发工资',
                align: 'center',
                dataIndex: 'realSalary',
                width: '15%',
            },
            {
                key: 'checkStatus',
                title: '校验状态',
                align: 'center',
                dataIndex: 'checkStatus',
                width: '15%',
                render(val) {
                    if (val === '0') {
                        return '成功';
                    } else if (val === '1') {
                        return '失败';
                    } else {
                        return '--';
                    }
                }
            },
            {
                key: 'checkErrorMsg',
                title: '校验失败原因',
                align: 'center',
                dataIndex: 'checkErrorMsg',
                width: '30%',

            },
        ];
        const tools = {};
        return (
            <div >
                <Search
                    {...this.props}
                    loading={loading}
                    submit={this.onSubmit}
                    isLoad={true}
                    tools={tools}
                    columns={columns}
                    data={payRecordDetailList}
                    total={total}
                    pagination={true}
                    onRef={this.onRef}
                />
            </div>
        );
    }
}
export default TransPayRecord;