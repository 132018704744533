import React, { Component } from 'react';
import { Form, Input, Button, DatePicker, Select, Radio, InputNumber, Card, Message, Row, Col, Divider } from 'antd';
import { formatDate } from 'utils/Date'
import { selectDept, checkCertNo, addAppUser, checkWxMobile } from '../../api/staff';
import {Link} from 'react-router-dom' 
const { Option } = Select;
class StaffAddOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            bankCode: JSON.parse(global._sessionStorage.getItem('user')).bankCode,
            versions: JSON.parse(global._sessionStorage.getItem('user')).versions,
            type: JSON.parse(global._sessionStorage.getItem("type")),
            department: [],
            buttonLoading: false,
            cardFlag: true,
            paymethodList: [{ "key": "00", "value": "记账户" }],
            sexList : [{ "key": "1", "value": "男" }, { "key": "2", "value": "女" }],
            certFlag : false,
        }
    }

    handlerReset() {
        this.props.form.resetFields();
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({
                    buttonLoading: true,
                })

                params.eid = this.state.enterpriseid;
                addAppUser(params).then(res => {
                    let { statusCode, message } = res.data;
                    if ("200" == statusCode) {
                        Message.success(message);
                        this.setState({
                            buttonLoading: false,
                        })
                        this.handlerReset();
                    } else {
                        Message.error(message);
                        this.setState({
                            buttonLoading: false,
                        })
                    }
                });

            }
        })
    }

    componentWillMount() {

        if (this.state.bankCode === '0001') {
            this.setState({
                cardFlag: false,
                paymethodList: [{ "key": "00", "value": "记账户" }]
            })
        } else {
            this.setState({
                cardFlag: true,
                paymethodList: [{ "key": "00", "value": "记账户" }, { "key": "01", "value": "银行卡" }],
            })

        }
        //初始化项目
        let params = {
            eid: this.state.enterpriseid,
        }
        selectDept(params).then(res => {
            this.setState({
                department: res.data
            })
        });
    }

    selectChange(value) {
        this.setState({
            partnerName: value.label,
        })
    }

    //身份格式自定义校验
    certNoValidator = (rule, val, callback) => {
        let params = {
            eid: this.state.enterpriseid,
            certNo: val
        }

        if (!val) {
            callback();
        }
        //15位和18位身份证号码的正则表达式
        let regIdCard = /^(^\d{15}$)|(^\d{17}[\dXx]$)$/;
        if (regIdCard.test(val)) {
            checkCertNo(params).then(res => {
                if (res.data.isExist == "true") {
                    this.setState({
                        certFlag: false,
                    })
                    callback(res.data.msg);
                } else {
                    if(res.data.sex==='0'){
                        this.setState({
                            sexList : [{ "key": "1", "value": "男" }, { "key": "2", "value": "女" },{ "key": "0", "value": "未知" }],
                            certFlag: false,
                        })
                        this.props.form.setFieldsValue({
                            "sex": res.data.sex,
                            "age": res.data.age,
                        })
                    }else{
                        this.props.form.setFieldsValue({
                            "sex": res.data.sex,
                            "age": res.data.age,
                        })
                        this.setState({
                            certFlag: true,
                        })
                    }
                    callback();
                }
            });
        } else {
            callback("身份证格式不正确!");
        }
    }

    wxMobileValidator = (rule, val, callback) => {
        let params = {
            eid: this.state.enterpriseid,
            wxMobile: val
        }
        checkWxMobile(params).then(res =>{
            let {errorCode,errorMessage} = res.data;
            if(errorCode === '0000'){
                callback();
            }else{
                callback(errorMessage);
            }
        })
    }

    cardNoValidator = (rule, val, callback) => {
        try {
            if (!val) {
                callback();
            }
            if ((val.replace(/(^\s*)|(\s*$)/g, "")) === '') {
                callback("请输入银行卡号！");
            }
            callback();
        } catch (err) {
            callback(err);
        }
    }

    inputCardChange(e) {
        if (this.state.bankCode === '0001') {
            if (e.target.value.length === 0) {
                this.props.form.setFieldsValue({
                    "paymethod": '00',
                })
                this.setState({
                    paymethodList: [{ "key": "00", "value": "记账户" }],
                })
            } else {
                this.setState({
                    paymethodList: [{ "key": "00", "value": "记账户" }, { "key": "01", "value": "银行卡" }],
                })
            }
        }

    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 12 }
        };
        const departmentOptions = this.state.department.map(department => {
            return <Option key={department.did} value={department.did} >{department.dname}</Option>
        });

        const paymethodListOptions = this.state.paymethodList.map(type => {
            return <Option key={type.key} value={type.key} >{type.value}</Option>
        })

        const sexOptions = this.state.sexList.map(type => {
            return <Option key={type.key} value={type.key} >{type.value}</Option>
        })
        return (
            <div style={{ width: '100%', marginTop: 20 }}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit.bind(this)}>
                    <Card title="开户信息" bordered={false}>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="姓名">
                                    {getFieldDecorator('username', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入姓名！',
                                            }
                                        ]
                                    })(<Input placeholder='姓名' />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="身份证号">
                                    {
                                        getFieldDecorator('certNo', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入正确格式的身份证号！',
                                                }, {
                                                    validator: this.certNoValidator.bind(this)
                                                }
                                            ]
                                        })(<Input placeholder='身份证号' />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="性别">
                                    {getFieldDecorator('sex', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入性别！',
                                            }
                                        ]
                                    })(<Select style={{ width: '100%' }} placeholder="性别" disabled={this.state.certFlag}>
                                            {sexOptions}
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="年龄">
                                    {
                                        getFieldDecorator('age', {
                                            rules: [
                                                {
                                                    required: true,
                                                    pattern: new RegExp(/^(?:[1-9][0-9]?|1[01][0-9]|200)$/, "g"),
                                                    message: '请输入年龄！',
                                                }
                                            ]
                                        })(<Input placeholder='年龄' disabled={this.state.certFlag} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="银行卡号">
                                    {getFieldDecorator('cardNo', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入银行卡号！',
                                            }, {
                                                validator: this.cardNoValidator.bind(this)
                                            }
                                        ]
                                    })(<Input placeholder='银行卡号' onChange={(e) => this.inputCardChange(e)} />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="银行预留手机号">
                                    {
                                        getFieldDecorator('mobile', {
                                            rules: [
                                                {
                                                    required: true,
                                                    pattern: new RegExp(/^0?(1)[0-9]{10}$/, "g"),
                                                    message: '请输入正确格式手机号码！',
                                                }
                                            ]
                                        })(<Input placeholder='银行预留手机号' />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="证件起始日">
                                    {getFieldDecorator('certStartStr', {
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入证件起始日！',
                                            }
                                        ]
                                    })(<DatePicker placeholder='证件起始日' style={{ width: '100%' }} />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="证件终止日">
                                    {
                                        getFieldDecorator('certEndStr', {
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入证件终止日！',
                                                }
                                            ]
                                        })(<DatePicker placeholder='证件终止日' style={{ width: '100%' }} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="发放方式">
                                    {
                                        getFieldDecorator('paymethod', {
                                            initialValue: '00',
                                            rules: [
                                                {

                                                    required: true,
                                                    message: '请输入发放方式！',
                                                }
                                            ]
                                        })(<Select style={{ width: '100%' }} placeholder="发放方式">
                                            {paymethodListOptions}
                                        </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="常用手机号">
                                    {
                                        getFieldDecorator('wxMobile', {
                                            rules: [
                                                {
                                                    pattern: new RegExp(/^0?(1)[0-9]{10}$/, "g"),
                                                    message: '请输入正确格式手机号码！',
                                                }
                                            ]
                                        })(<Input placeholder='常用手机号' />)}
                                </Form.Item>
                            </Col> 
                        </Row>
                        <Row>
                            
                            <Col span={12}>
                                <Row type="flex" justify="space-between" align="top">
                                
                            <Col span={22}>
                                <Form.Item label="所属项目" className="222222" style={{ marginLeft: '3.3%' }} >
                                    {getFieldDecorator('department', {
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入项目！',
                                            }
                                        ]
                                    })(<Select style={{ width: '112%' }} placeholder="项目">
                                        {departmentOptions}
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <div style={{ marginLeft: '-150%',marginTop: '15%' }} >
                                    {this.state.versions==='0' ? 
                                        this.state.type==='0'?
                                            <Link to={{ pathname : '/simplerPayUser' , state : {  type:'0'  }}} >维护</Link>
                                            :
                                            <Link to={{ pathname : '/simplerFacUser' , state : {  type:'0'  }}} >维护</Link>
                                        :
                                        <Link to={{ pathname : '/classQuery' }} >维护</Link>
                                    }
                                    
                                </div>
                            </Col>
                            
                            </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Card title="个人信息" bordered={false}>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="邮箱">
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                required: false,
                                                pattern: new RegExp(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/, "g"),
                                                message: '邮箱格式错误',
                                            }
                                        ]
                                    })(<Input placeholder='邮箱' />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="住址">
                                    {
                                        getFieldDecorator('certAddr', {
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入住址！',
                                                }
                                            ]
                                        })(<Input placeholder='住址' />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="直系亲属联系人">
                                    {getFieldDecorator('contractperson', {
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入直系亲属联系人！',
                                            }
                                        ]
                                    })(<Input placeholder='直系亲属联系人' />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="其他紧急联系人">
                                    {
                                        getFieldDecorator('outherContractperson', {
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入其他紧急联系人！',
                                                }
                                            ]
                                        })(<Input placeholder='其他紧急联系人' />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="与直系亲属关系">
                                    {getFieldDecorator('contractype', {
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入与直系亲属关系！',
                                            }
                                        ]
                                    })(<Select style={{ width: '100%' }} placeholder="与直系亲属关系">
                                        <Option value=""></Option>
                                        <Option value="00">配偶</Option>
                                        <Option value="01">父母</Option>
                                        <Option value="02">兄弟姐妹</Option>
                                        <Option value="03">子女</Option>
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="与其他联系人关系">
                                    {
                                        getFieldDecorator('outherContractype', {
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入与其他联系人关系！',
                                                }
                                            ]
                                        })(<Select style={{ width: '100%' }} placeholder="与其他联系人关系">
                                            <Option value=""></Option>
                                            <Option value="04">同事</Option>
                                            <Option value="05">同学</Option>
                                            <Option value="06">朋友</Option>
                                        </Select>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="直系亲属联系电话">
                                    {
                                        getFieldDecorator('contractphone', {
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入直系亲属联系电话！',
                                                }
                                            ]
                                        })(<Input placeholder='直系亲属联系电话' />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="其他关系联系电话">
                                    {
                                        getFieldDecorator('outherContractphone', {
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入其他关系联系电话！',
                                                }
                                            ]
                                        })(<Input placeholder='其他关系联系电话' />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Card title="薪酬信息" bordered={false}>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="薪酬属性">
                                    {
                                        getFieldDecorator('salarymethod', {
                                            initialValue: 'month',
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请选择薪酬属性',
                                                }
                                            ]
                                        })(<Select style={{ width: '100%' }} placeholder="薪酬属性">
                                            <Option value="month">月薪</Option>
                                            <Option value="week">日薪</Option>
                                        </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="考勤时长核算方式">
                                    {getFieldDecorator('atdWay',{
                                         initialValue: 'D',
                                    })
                                    (
                                        <Radio.Group>
                                            <Radio value="D">天</Radio>
                                            <Radio value="H">小时</Radio>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="考勤核算单价">
                                        {
                                        getFieldDecorator('atdPrice', {
                                            rules: [
                                                
                                            ]
                                        })(<InputNumber style={{width:220}} min={0} precision={2} placeholder='请输入考勤核算单价（元）' />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>                    
                    <Card title="职位信息" bordered={false}>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="工号">
                                    {getFieldDecorator('jobNumber', {
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入工号！',
                                            }
                                        ]
                                    })(<Input placeholder='工号' />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="就职类型">
                                    {
                                        getFieldDecorator('state', {
                                            initialValue: "00",
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入就职类型！',
                                                }
                                            ]
                                        })(<Select style={{ width: '100%' }} placeholder="就职类型">
                                            <Option value="00">正式</Option>
                                            <Option value="01">试用</Option>
                                            <Option value="02">实习</Option>
                                            <Option value="03">兼职</Option>
                                            <Option value="04">离职</Option>
                                        </Select>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="薪酬关系">
                                    {getFieldDecorator('salarystatus', {
                                        initialValue: "00",
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入薪酬关系！',
                                            }
                                        ]
                                    })(<Select style={{ width: '100%' }} placeholder="薪酬关系">
                                        <Option value="00">发放</Option>
                                        <Option value="01">不发放</Option>
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="职位">
                                    {
                                        getFieldDecorator('position', {
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入职位！',
                                                }
                                            ]
                                        })(<Input placeholder='职位' />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>

                            <Col span={12}>
                                <Form.Item label="转正工资">
                                    {
                                        getFieldDecorator('regularsalary', {
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入转正工资！',
                                                }
                                            ]
                                        })(<Input placeholder='转正工资' />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="转正时间">
                                    {getFieldDecorator('qualifiedDateStr', {
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入转正时间！',
                                            }
                                        ]
                                    })(<DatePicker placeholder='转正时间' style={{ width: '100%' }} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="试用工资">
                                    {
                                        getFieldDecorator('trainingsalary', {
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入试用工资！',
                                                }
                                            ]
                                        })(<Input placeholder='试用工资' />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="合同到期日">
                                    {getFieldDecorator('contractTerminationDateStr', {
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入合同到期日！',
                                            }
                                        ]
                                    })(<DatePicker placeholder='合同到期日' style={{ width: '100%' }} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="离职时间">
                                    {
                                        getFieldDecorator('leavetimeStr', {
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入离职时间！',
                                                }
                                            ]
                                        })(<DatePicker placeholder='离职时间' style={{ width: '100%' }} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    <div style={{ width: '100%' }}>
                        <div style={{ margin: 'auto', width: '215px' }}>
                            <Button type="primary" icon="redo" onClick={this.handlerReset.bind(this)}>重置</Button>
                            <Button type="primary" icon="form" htmlType="submit" style={{ marginLeft: 50 }} loading={this.state.buttonLoading}>保存</Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

StaffAddOne = Form.create()(StaffAddOne);

export default StaffAddOne;