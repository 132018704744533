import React, { Component } from 'react';
import {Button,Message,Modal,Form,Input,Icon } from 'antd'
import { salaryAudit } from 'api/RechargeController'
import { payMoneyCheck } from 'api/RechargeController'
import { pay } from 'api/RechargeController'
import { checkTpwd } from 'api/RechargeController'
import { getTpwd } from 'api/RechargeController'
const { confirm } = Modal;
const { TextArea } = Input;


const RejectModalForm = Form.create({ name: 'rejectform_in_modal' })(
     class extends React.Component {

      render() {
        const { visible, onCancel, onCreate, form } = this.props;
        const { getFieldDecorator } = form;
        return (
          <Modal
            visible={visible}
            title="填写驳回原因"
            okText="驳回"
            onCancel={onCancel}
            onOk={onCreate} 
            destroyOnClose
          >
            批次号:{this.props.record.salarybatchcode}的驳回原因:
            <Form>
                <Form.Item style={{margin:0}}>
                    {getFieldDecorator('remark', {
                    rules: [{ required: true, message: '请输入驳回原因!' }],
                    })(<TextArea placeholder="输入驳回原因"/>)}
                </Form.Item>
                <Form.Item style={{margin:0}}>
                    { getFieldDecorator("id",{
                        initialValue : this.props.record.id,
                    })(
                        <Input hidden />
                    ) }
                </Form.Item>
            </Form>
          </Modal>
        );
      }
    },
  );

  const SalaryPayModalForm = Form.create({ name: 'salarypayform_in_modal' })(
    class extends React.Component {
        onPaste(e){
            e.preventDefault();
        }
     render() {
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 14 },
            },
        };
       const { visible, onCancel, onCreate, form } = this.props;
       const { getFieldDecorator } = form;
       return (
         <Modal
           visible={visible}
           title="输入发薪密码"
           okText="确定"
           onCancel={onCancel}
           onOk={onCreate} 
           okButtonProps={{ disabled: !this.props.iSSetSalaryPwd,loading : this.props.salaryPayLoading }}
           destroyOnClose
         >
           <div style={{color:'red',marginLeft:60}}>
                {this.props.iSSetSalaryPwd ? <p>提示：发薪密码超级管理员已设置，请您妥善保管！</p>:
                <p>提示：您还未设置发薪密码，<br></br>请联系超级管理员在【用户设置-发薪密码设置】菜单中设置！</p>}
           </div>
           <Form {...formItemLayout}>
                <Form.Item label="批次号" >
                    { getFieldDecorator("salarybatchcode",{
                        initialValue : this.props.record.salarybatchcode,
                    })(
                        <Input readOnly />
                    ) }
                </Form.Item> 
               <Form.Item label="输入密码" style={{margin:0}}>
                   {getFieldDecorator('pass_word', {
                   rules: [{ required: true, message: '请输入发薪密码!' }],
                   })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="输入6-12位数字或字母的发薪密码" onPaste={this.onPaste}/>)}
               </Form.Item>
               <Form.Item style={{margin:0}}>
                   { getFieldDecorator("id",{
                       initialValue : this.props.record.id,
                   })(
                       <Input hidden />
                   ) }
               </Form.Item>
           </Form>
         </Modal>
       );
     }
   },
 );




class SimplerSalaryPayOperateCheck extends Component {
    constructor(){
        super();
        this.state = {
            passLoading:  false,        //审核通过按钮载入
            rejectLoading:  false,      //审核驳回按钮载入
            rejectVisible: false,       //审核驳回弹出窗口显示控制
            salaryPayLoading: false,    //发薪按钮载入
            salaryPayVisible:false,     //发薪输入密码窗口显示
            iSSetSalaryPwd: false,
            user: JSON.parse(global._sessionStorage.getItem("user")),
            salaryBatchInfo: {},

        }
    }

    //审核通过
    pass=(record,e)=>{
        let salarBatch = record;
        let params = {
            enterpriseid: this.state.user.enterpriseid,
            username: this.state.user.username,
            status: "01",
            id : salarBatch.id,
            remark : ' ',
        }
        const _this = this;
        confirm({
            title: '是否确认审核通过该批次?',
            content: '批次号:'+salarBatch.salarybatchcode,
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() { 
                _this.setState({
                    passLoading: true,
                })
                salaryAudit(params).then(res => {
                    let { errorCode, errorMessage } = res.data;
                    if (errorCode === '0000') {
                        Message.success(errorMessage);
                        _this.setState({
                            passLoading: false,
                        })
                        _this.props.refreshSubmit();
                        
                        
                        _this.props.history.push({pathname:"/simplerFacPay",state : { current : 3, salaryBatchCode:_this.props.batchCode }});
                        window.location.reload()

                    } else {
                        Message.error(errorMessage);
                        _this.props.refreshSubmit();
                    }
                })
                _this.setState({
                    passLoading: false,
                })

            },

          });
        
    }

    //审核驳回
    check_back=()=>{
        const { form } = this.formRef2.props;
        const _this = this;
        form.validateFields((err, values) => {
            if (err) {
              return;
            }
            let params = {
                enterpriseid: _this.state.user.enterpriseid,
                username: _this.state.user.username,
                status: "05",
                id : values.id,
                remark: values.remark,
            }
            _this.setState({
                rejectLoading: true,
            })
            salaryAudit(params).then(res => {
                let { errorCode, errorMessage } = res.data;
                if (errorCode === '0000') {
                    Message.success(errorMessage);
                    _this.setState({
                        rejectLoading: false,
                    })
                    _this.props.refreshSubmit();

                    _this.props.history.push({pathname:"/simplerFacPay",state : { current : 3, salaryBatchCode:_this.props.batchCode }});
                    window.location.reload()
                    
                } else {
                    Message.error(errorMessage);
                    _this.props.refreshSubmit();
                }
            })
            _this.setState({
                rejectLoading: false,
            })

            form.resetFields();
            this.setState({ rejectVisible: false });

        })

    }

    

    //显示驳回原因输入窗口
    showrejectModal = (record,e) =>{
        this.setState({
            rejectVisible : true,
        })
    }

    //关闭驳回原因输入窗口
    rejectModalCancel = () => {
        this.setState({
            rejectVisible: false,
        });
      };

    //关闭输入发薪密码窗口
    salaryPayModalCancel = () => {
        this.setState({
            salaryPayVisible: false,
        });
        this.props.refreshSubmit();
    }  

    //发放密码是否设置
    giveo = () => {
        const _this = this;
        let params = {
            enterpriseid: this.state.user.enterpriseid,
            username: this.state.user.username,
        }
        getTpwd(params).then(res => {
            let { errorCode, errorMessage } = res.data;
            if (errorCode === '0000') {
                _this.setState({
                    salaryPayVisible: true,
                    iSSetSalaryPwd:true,
                })
            }else{
                _this.setState({
                    salaryPayVisible : true,
                    iSSetSalaryPwd: false,
                })
            }
        })

    }

    sure_pass= () => {
        const { form } = this.formRef.props;
        const _this = this;
        form.validateFields((err, values) => {
            if (err) {
              return;
            }
            let params = {
                enterpriseid: _this.state.user.enterpriseid,
                username: _this.state.user.username,
                id : values.id,
                pwd : values.pass_word,
            }
            _this.setState({
                //rejectLoading: true,
            })
            checkTpwd(params).then(res => {
                let { errorCode, errorMessage } = res.data;
                if (errorCode === '0000') {
                    _this.paySalaryReal(values.id);
                } else {
                    Message.error(errorMessage);
                }
            })

        })

    }

    //发放薪资
    paySalaryReal= (batchId) => {
        this.setState({
            salaryPayLoading : true,
        });
        let salaryBatchId = batchId;
        let params = {
            enterpriseid: this.state.user.enterpriseid,
            username: this.state.user.username,
            batchId : salaryBatchId,
        }
        const _this = this;
        pay(params).then(res => {
            let { statusCode, message,result } = res.data;
            if(statusCode === "200" && result=== "true"){
                Message.success(message);
            }else{
                Message.error(message);
            }
            _this.setState({
                salaryPayVisible: false,
                salaryPayLoading : false,
            });
            _this.props.refreshSubmit();
        })

    }
    
    
    //点击发放按钮，判断是否弹出查验是否需要企业发新户划拨资金至保理融资户 窗口
    payAmountCheckFunction= (record,e) =>{
        let salarBatch = record;
        let params = {
            enterpriseid: this.state.user.enterpriseid,
            username: this.state.user.username,
            batchId : salarBatch.id,
        }
        const _this = this;
        payMoneyCheck(params).then(res => {
            let { statusCode, message } = res.data;
            if(statusCode==='0000'){
                _this.giveo();
            }else if(statusCode==='1000'){
                confirm({
                    title: '温情提示',
                    content: <span>企业融资/回款户发薪，如点击确认，将从企业发薪户划款差额：{res.data.amount}元至企业融资/回款户</span>,
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() { 
                        _this.giveo();
                    },
        
                  });

            }else{
                Message.error(message);
            }
        })

    }

    
    rejectFormRef = formRef2 => {
        this.formRef2 = formRef2;
    };

    salaryPayFormRef = formRef => {
        this.formRef = formRef;
    };


    render() {
        
        const _this=this.props;
        const checkstatus = _this.record.checkstatus;
        let operateCode;
        const { getFieldDecorator } = this.props.form;
        if((checkstatus === "00" && this.state.user.enterpriseid === _this.record.enterpriseid)
            || (checkstatus === "A0" && this.state.user.enterpriseid === _this.record.joinentid)){
                if(checkstatus === "A0"){
                    operateCode=(
                        <div>
                            <Button size="small"  type="primary" loading={this.state.passLoading} onClick={this.pass.bind(this,_this.record)} >审核通过</Button>
                        </div>
                    )
                }else{
                    operateCode=(
                        <div>
                            <Button size="small"  type="primary" loading={this.state.passLoading} onClick={this.pass.bind(this,_this.record)} >审核通过</Button>
                            <Button size="small"  type="danger" loading={this.state.rejectLoading} onClick={this.showrejectModal.bind(this,_this.record)}
                             style={{marginLeft : 8}}
                             >审核驳回</Button>
                        </div>
                    )
                }
            
        }

        return (
            <div>
                {operateCode} 

                <div>
                    <RejectModalForm 
                        visible={this.state.rejectVisible}
                        onCancel={this.rejectModalCancel}
                        onCreate={this.check_back}
                        record={_this.record}
                        wrappedComponentRef={this.rejectFormRef}
                    />

                    <SalaryPayModalForm 
                        visible={this.state.salaryPayVisible}
                        onCancel={this.salaryPayModalCancel} 
                        onCreate={this.sure_pass}
                        record={_this.record}
                        iSSetSalaryPwd={this.state.iSSetSalaryPwd}
                        salaryPayLoading={this.state.salaryPayLoading}
                        wrappedComponentRef={this.salaryPayFormRef}
                    />
                </div>

            </div>
        );
    }
}

SimplerSalaryPayOperateCheck = Form.create()(SimplerSalaryPayOperateCheck);

export default SimplerSalaryPayOperateCheck;