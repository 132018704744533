import React, { Component } from 'react';
import { List, Avatar, Button, Typography } from 'antd';
import closeIcon from 'assets/img/mail-close.png';
import openIcon from 'assets/img/mail-open.png'

const ButtonGroup = Button.Group;

class NoticeItem extends Component {

    constructor(){
        super();
        this.state = {
            message : ''
        }
    }

    noticeDelete(key){
        this.props.handlerNoticeDelete(key);
    }

    changeStatus(key){
        this.props.handlerChangeStatus(key);
    }


    render() {
        return (
            <div>
                <div style={{height : 300,overflowY :'auto'}} >
                    <List
                    itemLayout="horizontal"
                    dataSource={this.props.noticeLists}
                    renderItem={item => {
                        return (
                        <List.Item key={item.id}>
                        <Typography.Text mark>
                        <div style={{marginRight : 20,marginLeft : 20}} >
                            { item.infoOpen ? <Avatar src={openIcon} /> : <Avatar src={closeIcon} /> }
                            <div>
                                <span className="boldFont">{item.date}</span>
                            </div>
                        </div>
                        </Typography.Text>
                        <List.Item.Meta
                            title={ item.infoOpen ? 
                            <div><span onClick={() => this.changeStatus(item.id)} className="span-cursor" >{item.title}</span></div> : 
                            <div><span onClick={() => this.changeStatus(item.id)} className="boldFont span-cursor" >{item.title}</span></div>}
                            description={item.infoOpen ? 
                            <span >{ item.collapsed ? item.body : item.bodyShow}</span> : 
                            <span className="boldFont" >{item.collapsed ? item.body : item.bodyShow}</span>}
                        />
                        <span style={{marginLeft: 20, marginRight :20}} className="span-click" onClick={() => this.noticeDelete(item.id)} >删除</span>
                        </List.Item>
                    )}}
                    />
                </div>
                <div>
                <ButtonGroup style={{width : '100%'}}  >
                    <Button style={{width : '50%',height : '50px'}} >全部 已读</Button>
                    <Button  style={{width : '50%',height : '50px'}} >全部删除</Button>
                </ButtonGroup>
                </div>
                
            </div>
            
        );
    }
}

export default NoticeItem;