import React, { Component } from 'react';
import { Button, Modal, Message } from 'antd';
import ModalDrag from '../Common/ModalDrag';
import { submitSalaryBatch} from 'api/weeksalary';
import { queryWords} from 'api/coresalary';


class ShowSingleTips extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            submitbutton:'',
            word:'',
        }
    }

    modelShow(){
        this.setState({
            visible: true,
          });
    }

    handlerCancel() {
        this.setState({
            visible: false,
          });
    }

    submitSalaryData(){ 
        this.setState({
            submitbutton: 'true',
        });       
        let params = {};
        params.id = this.props.batchid;
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        params.phone=user.username;
        submitSalaryBatch(params).then(res =>{
            if(res.data.result=="true"){
                this.setState({
                    visible: false,
                    submitbutton: 'false',
                });
                this.props.history.push('/corePayRollTabs');
            }else{
                Message.error(res.data.message);
            }
        })
    }


    queryWords(){
        let params = {};
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        params.batchid=this.props.batchid;
        queryWords(params).then(res =>{

            this.setState({           
                word: res.data.word,
                submitbutton : res.data.submitbutton
            });

        })
    }
       

    componentDidMount(){
        this.queryWords();   
    }

    render() {

        const title = <ModalDrag title="温馨提示" />;
        const _this = this;

        return (
            <span>
                <Button type="primary" disabled={(this.state.submitbutton != 'true')} onClick={this.modelShow.bind(this)}>提交数据</Button>
                <Modal
                    visible={this.state.visible}
                    title={title}
                    onCancel={this.handlerCancel.bind(this)}
                    onOk={this.submitSalaryData.bind(this)}
                    footer={null}
                >
                    <div >
                        <p>{this.state.word}</p>
                    </div>
                    <div style={{ marginTop: 20 }} >
                        <Button type="primary" onClick={this.submitSalaryData.bind(this)} style={{ marginLeft: '30%' }}  >确定</Button>
                        <Button type="primary" style={{ marginLeft: '15%', marginBottom: 10 }} onClick={this.handlerCancel.bind(this)} >关闭</Button>
                    </div>
                </Modal>


            </span>
        );
    }
}

export default ShowSingleTips;