import React, { Component } from 'react';
import { Table, Tag ,PageHeader, Button, Descriptions} from 'antd'

class StaffAddBatchQueryDetailShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
        }
    }

    desensitization(str) {
        if (str && str != '' && typeof str == 'string') { //参数为字符串类型
            let ruten = str.substring(4, str.length-4); //提取字符串下标之间的字符。
            return str.replace(ruten, '*****'); //字符串中用字符替换另外字符，或替换一个与正则表达式匹配的子串。
        }

    }

    render() {
        const _this = this;
        const columns = [
            {
                title: '姓名', dataIndex: 'username', key: 'username', align: 'center'
            },
            {
                title: '身份证号', dataIndex: 'certNo', key: 'certNo', align: 'center',
                render(val) {
                    return _this.desensitization(val);
                }
            },
            {
                title: '银行卡号', dataIndex: 'bankCardNo', key: 'bankCardNo', align: 'center',
                render(val) {
                    return _this.desensitization(val);
                }
            },
            {
                title: '手机号', dataIndex: 'mobile', key: 'mobile', align: 'center'
            },
            {
                title: '项目', dataIndex: 'departName', key: 'departName', align: 'center'
            },
            {
                title: '在职状态', dataIndex: 'userState', key: 'userState', align: 'center'
            },
            {
                title: '发薪方式', dataIndex: 'paymethod', key: 'paymethod', align: 'center',
            },
            {
                title: '开户状态', dataIndex: 'accountState', key: 'accountState', align: 'center',
                render(val, record) {
                    if (val === '00') {
                        return "未开户";
                    }else if (val === '01') {
                        return "开户成功";
                    }else if (val === '02') {
                        return "开户中";
                    }else{
                        if(record.state === "0"){
                            return "开户成功";
                        }else if(record.state === "1"){
                            return "开户失败";
                        }else if(record.state === "2"){
                            return "开户中";
                        }else{
                            return "";
                        }
                    }
                    return "";
                }
            },
            {
                title: '绑卡状态', dataIndex: 'cardBindState', key: 'cardBindState', align: 'center',
                render(val, record) {
                    if (val === '00') {
                        return "未绑卡";
                    }else if (val === '01') {
                        return "绑卡成功";
                    }else if (val === '02') {
                        return "绑卡失败";
                    }else if (val === '03') {
                        return "绑卡中";
                    }
                    return "";
                }
            },
            {
                title: '常用手机号', dataIndex: 'wxMobile', key: 'wxMobile', align: 'center',
            },
            {
                title: '开户失败原因', dataIndex: 'failReason', key: 'failReason', align: 'center',
            },
        ]
        const pagination = {
            current: parseInt(this.props.currentPage),
            total: this.props.pageTotal,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.props.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }
        return (
            <div>
                <PageHeader
                    title="录入结果："
                >
                    <Descriptions size="middle" column={4}>
                        <Descriptions.Item label="总条数"><span className="span-click" onClick={this.props.getAllInfo}>{this.props.totalNum}</span></Descriptions.Item>
                        <Descriptions.Item label="成功"><span>{this.props.successNum}</span></Descriptions.Item>
                        <Descriptions.Item label="失败"><span className="span-click"  onClick={this.props.getFailInfo}>{this.props.failNum}</span></Descriptions.Item>
                    </Descriptions>
                </PageHeader>
                <Table style={{ marginTop: '10px' }}
                    rowKey={record => record.id}
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData}
                    columns={columns}
                    bordered
                    pagination={pagination}
                    scroll={{ x: 1500 }}
                >
                </Table>

            </div>
        );
    }
}

export default StaffAddBatchQueryDetailShow;