import React, { Component } from 'react';
import { Form, Row, Col, Input, Select, Button } from 'antd'
import moment from 'moment';

const { Option } = Select;
class DepartStaffUncheckSearchForm extends Component {
    constructor(){
        super();
        this.state = {
            
        }
    }

    refreshSubmit(e){
        this.handlerSubmit(e);
    }

    handlerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset(){
        this.props.form.resetFields();
        this.props.resetForm();
    }

    departmentInsert(){
        // return <Link to={{ pathname : '/simplerDepartIncrease' }} > </Link>
        window._ROUTER_.push('/departIncrease');
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const dateFormat = 'YYYY-MM-DD';

        return (
            <div>
                 <Form className="ant-search-form" onSubmit={this.handlerSubmit.bind(this)} >
                    <Row gutter={12} >
                        <Col span={5} >
                            <Form.Item label="请输入" >
                            { getFieldDecorator("nameOrMobile",{
                                // rules : [
                                //     { required: true, message: '请输入手机号' }
                                // ]
                            })(
                                <Input placeholder="搜索姓名/手机号" allowClear ></Input>
                            ) }
                            </Form.Item>
                        </Col>
                        <Col span={5} >
                            <Form.Item label="审核状态" >
                                {getFieldDecorator("auditState")(
                                    <Select placeholder="审核状态"
                                        showSearch
                                        placeholder="请选择"
                                        optionFilterProp="children"
                                    >
                                        <Option value="00">审核中</Option>
                                        <Option value="01">审核通过</Option>
                                        <Option value="02">审核驳回</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>

                        <Col span={10} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" style={{marginLeft : 50}}  loading={this.props.loading} >查询</Button>
                                <Button type="primary" style={{marginLeft : 30}} onClick={this.handlerReset.bind(this)} >重置</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                 </Form>
                 <div style={{marginTop : 10}} >
                    <Button type="primary" onClick={this.refreshSubmit.bind(this)} >刷新</Button>
                </div>
            </div>
        );
    
    }
}
DepartStaffUncheckSearchForm = Form.create()(DepartStaffUncheckSearchForm)
export default DepartStaffUncheckSearchForm;