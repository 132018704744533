import React, { Component } from 'react';
import { Row, Col, Icon, Button, Spin, Upload, Message, Divider } from 'antd'
import { staffInviteBatch } from '../../api/staff';
import { baseHttpUrl } from '../../assets/settings';
const Dragger = Upload.Dragger;

class StaffInviteBatch extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            modalShow: false,
            uploadDisabled: false,
            confirmLoading: false,
            fileList: [],
            spinning: false,
        }
    }

    handlerReset() {
        this.setState({
            fileList: [],
            uploadDisabled: false,
            confirmLoading: false,
        })
    }
    uploadSubmit() {
        if (this.state.fileList.length === 0) {
            Message.error("请先上传文件！");
            return;
        }
        this.setState({
            spinning: true,
        })
        let formData = new FormData();
        formData.append("file", this.state.fileList[0]);
        formData.append("eid", this.state.enterpriseid);

        staffInviteBatch(formData).then(res => {
            let data = res.data;

            if (data.totalNum === 0) {

                if (data.msg === "导入失败") {
                    Message.error("批量邀请失败。")
                } else {
                    Message.error(data.msg);
                }
            } else {
                if (data.totalNum === data.successNum) {
                    Message.success(data.totalNum+"条数据邀请成功！");
                } else {
                    Message.warning("共"+data.totalNum+"条数据，"+data.successNum+"条邀请成功，"+data.noUser+"用户信息匹配失败， "+data.cardNoBind+"条未绑卡，"+data.isUnioon+"条已与企业关联，"+data.isInvite+"条已邀请，"+data.sendFail+"条短信发送失败。");
                }
            }
            this.setState({
                modalShow: false,
                uploadDisabled: false,
                confirmLoading: false,
                fileList: [],
                spinning: false,
            })
            this.props.refreshSubmit();
            

        })

    }

    render() {
        const _this = this;
        const { fileList } = this.state;
        const props = {
            accept: ".xls,.xlsx",
            multiple: false,
            beforeUpload: (file) => {
                if (this.state.fileList.length !== 0) {
                    Message.error("仅支持单个文件上传！");
                    _this.setState({
                        fileList: [],
                        uploadDisabled: false,
                        confirmLoading: false,
                    });
                    return false;
                }
                _this.setState(state => ({
                    fileList: [...state.fileList, file],
                    uploadDisabled: true,
                }));
            },
            onRemove: (file) => {
                _this.setState({
                    fileList: [],
                    uploadDisabled: false,
                    confirmLoading: false,
                });
            },
            fileList,
        };
        return (
            <div style={{ width: '100%', marginTop: 20 }}>
                <Spin tip="数据解析中..." spinning={this.state.spinning}>
                    <b>温馨提示：</b>
                    <p></p>
                    <p>1.请<a href={baseHttpUrl + "/invite.xls"}><Icon type="download" />点击此处</a>下载标准模板；</p>
                    <p>2.按照模板内容填写邀请员工信息。</p>
                    <Divider />
                    <div style={{ width: '100%', marginTop: 20 }}>
                        <Dragger {...props} >
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">单击或拖动文件到此区域</p>
                            <p className="ant-upload-hint">仅支持单个文件</p>
                        </Dragger>
                    </div>
                </Spin>
                <div style={{ width: '100%', marginTop: 50 }}>
                    <Row>
                        <Col span={12} offset={8}>
                            <Button type="primary" icon="form" onClick={this.uploadSubmit.bind(this)}>上传</Button>
                            <Button type="primary" icon="redo" onClick={this.handlerReset.bind(this)} style={{ marginLeft: 20 }}>重置</Button>
                        </Col>
                    </Row>
                </div>
            </div>

        );
    }
}

export default StaffInviteBatch;