import React, { Component } from 'react';
import { Tabs, Button } from 'antd';
import ReleaseDetailResult from '@/components/finance/ReleaseDetailResult'
import { salaryBatchQuery } from 'api/RechargeController'
import axios from 'axios';
import { baseHttpUrl } from '../../assets/settings';
import { getTokenStr } from '../../utils/http'
import { Link } from "react-router-dom";
const { TabPane } = Tabs;

class SimplerReleaseDetail extends Component {

    constructor() {
        super();    
        this.state = {
            tabPaneKey: 'P',
            user: JSON.parse(global._sessionStorage.getItem("user")),
            countPer: 0,
            countBus: 0,
            totalCount: 0,
            salarydate: "",
            employeenum: 0,
            coreName: "",
            gotoLinkUrl: "/simplerFacPay",
            gotoLinkParms: 6,
        }
    }

    downloadExcel = (e) => {
        let params = {
            enterpriseid: this.state.user.enterpriseid,
            username: this.state.user.username,
            batchid: this.props.location.state.record.id,
            bustype: this.state.tabPaneKey,
        }

        axios({
            method: 'GET',
            url: baseHttpUrl + '/api/pay/downloadSalaryBatch',
            headers: {
                'Token': getTokenStr()
            },
            params: params,
            responseType: 'blob'
        }).then(function (res) {
            const content = res.data
            const blob = new Blob([content], { type: "application/x-msdownload" })
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = res.headers['filename'];
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })

    }

    tabsChange = (e) => {
        this.setState({
            tabPaneKey: e,
        })
    }

    showcoreEnName = (enterprise) => {
        this.setState({
            coreName: enterprise,
        });
    }

    componentDidMount() {
        this.gotoLink();
        let params = {
            enterpriseid: this.state.user.enterpriseid,
            username: this.state.user.username,
            id: this.props.location.state.record.id,
        }
        salaryBatchQuery(params).then(res => {
            let { errorCode, errorMessage } = res.data;
            if (errorCode === '0000') {
                this.setState({
                    countPer: res.data.countPer,
                    countBus: res.data.countBus,
                    totalCount: res.data.totalCount,
                    salarydate: res.data.salarydate,
                    employeenum: res.data.employeenum,
                });
            }
            if (this.state.countPer === 0 && this.state.countBus > 0) {
                this.setState({
                    tabPaneKey: "C",
                })
            } else {
                this.setState({
                    tabPaneKey: "P",
                })
            }
        })
    }

    gotoLink() {

        if(this.props.location.state.historyFlag){
            this.setState({
                gotoLinkUrl: '/simplerHistoryQuery',
                gotoLinkParms: this.props.location.state.currentFlag,
            });
            // if (this.props.location.state.currentFlag === 0) {
            //     this.setState({
            //         gotoLinkUrl: '/simplerHistoryQuery',
            //         gotoLinkParms: this.props.location.state.currentFlag,
            //     });
      
            // }  else {
            //     this.setState({
            //         gotoLinkUrl: '/simplerHistoryQuery',
            //         gotoLinkParms: this.props.location.state.currentFlag,
            //     });

            // }
        }else{
            if (this.props.location.state.currentFlag === 0) {
                this.setState({
                    gotoLinkUrl: '/simplerPay',
                    gotoLinkParms: 4,
                });      
            } else if (this.props.location.state.currentFlag === 1) {
                this.setState({
                    gotoLinkUrl: '/simplerFacPay',
                    gotoLinkParms: 3,
                });
            } else {
                this.setState({
                    gotoLinkUrl: '/simplerFacPay',
                    gotoLinkParms: 6,
                });
            }
        }      
    }

    render() {
        const operations = <Button type="primary" icon="download" onClick={this.downloadExcel.bind(this)}>{this.state.tabPaneKey === 'P' ? '对私' : '对公'}发放单导出</Button>;
        const _this = this;
        return (
            <div style={{ paddingTop: 68,paddingLeft:24,paddingRight:24  }}>
                <div style={{ marginBottom: 16 }}>
                    <b>发放单详情-></b> <label><b style={{ marginRight: 16, marginLeft: 16 }}>{this.state.salarydate}</b>
                        已上传：<span>{this.state.employeenum}</span>条记录</label>
                    <label style={{ marginLeft: 50 }}>{this.state.coreName}</label>
                </div>
                <Tabs defaultActiveKey={this.state.tabPaneKey}
                    activeKey={this.state.tabPaneKey}
                    tabBarExtraContent={operations}
                    onChange={this.tabsChange.bind(this)}
                >
                    {this.state.countPer > 0 ?
                        <TabPane tab={"对私发放单明细(" + _this.state.countPer + "笔)"} key="P">
                            <ReleaseDetailResult
                                bustype="P"
                                orderNo={this.props.location.state.record.id}
                                showcoreEnName={this.showcoreEnName}
                            />
                        </TabPane>
                        : ""}

                    {this.state.countBus > 0 ?
                        <TabPane tab={"对公发放单明细(" + _this.state.countBus + "笔)"} key="C">
                            <ReleaseDetailResult
                                bustype="C"
                                orderNo={this.props.location.state.record.id}
                                showcoreEnName={this.showcoreEnName}
                            />
                        </TabPane>
                        : ""}
                </Tabs>

                <Button style={{ marginLeft: '45%' }} type="primary" >
                    <Link to={{ pathname: this.state.gotoLinkUrl, state: {currentFlag:this.state.gotoLinkParms, current: this.state.gotoLinkParms } }}>返回上一级 </Link>
                </Button>
            </div>

        );
    }
}

export default SimplerReleaseDetail;