import React, { Component } from 'react';
import { Form, Input, Button, Message, Row, Col, Table,Select } from 'antd';
import { queryRelatedUpEnterpris,queryUnRelatedUser } from '@/api/supplierManage';
const { Option } = Select;

class AddRelationByChoose extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            relatedEnterpris: [],
            totalNum: '',
            pageSizeOptions: global.pageInfo.sizeOptions,
            currentPage: '1',
            pageSize: '10',
            sourceData: [],
            resultLoad: false,
            searchParam: {},
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.props.addChooseLoadingChange(true);
                if(this.props.selectedUser.length == 0){
                    Message.error("请选择员工！");
                    this.props.addChooseLoadingChange(false);
                    return;
                }

                params.eid = this.state.enterpriseid;
                this.props.addRelationByChoose(params);

            }
        })
    }

    handlerReset() {
        this.props.form.resetFields();
        this.props.getSelectUserKeys([]);
    }

    componentWillMount() {
        //查询关联企业
        let params = {
            eid: this.state.enterpriseid,
        }
        queryRelatedUpEnterpris(params).then(res => {
            this.setState({
                relatedEnterpris: res.data.list
            })
        });
    }

    currentPageChange(currentPage) {
        let params = this.state.searchParam;
        params.pageCurrent = currentPage;
        this.setState({
            currentPage: currentPage,
            searchParam: params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize) {
        let params = this.state.searchParam;
        params.pageCurrent = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage: '1',
            pageSize,
            searchParam: params
        });
        this.searchPost(params);
    }

    searchPost(params) {
        params.eid = this.state.enterpriseid;
        
        queryUnRelatedUser(params).then(res => {
            this.setState({
                sourceData: res.data.list,
                totalNum: res.data.totalRow,
            })
        }).catch(err => {
            Message.error("服务异常");
        })
    }
    queryUnRelatedUser(value) {
        let params={};
        params.relatedEid=value;
        this.searchFormSubmit(params);
    }
    searchFormSubmit(params) {
        params.pageCurrent = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam: params
        })
        this.searchPost(params);
    }
    desensitization(str) {
        if (typeof str == 'string') { //参数为字符串类型
            let ruten = str.substring(4, str.length - 4); //提取字符串下标之间的字符。
            return str.replace(ruten, '*****'); //字符串中用字符替换另外字符，或替换一个与正则表达式匹配的子串。
        }

    }

    render() {
        const _this = this;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 7 },
            wrapperCol: { span: 12 }
        };
        const columns = [
            {
                title: '姓名', dataIndex: 'username', key: 'username', align: 'center'
            },
            {
                title: '手机号', dataIndex: 'mobile', key: 'mobile', align: 'center'
            },
            {
                title: '身份证号', dataIndex: 'certinfo.certNo', key: 'certinfo.certNo', align: 'center',
                render(val) {
                    return _this.desensitization(val);
                }
            },
        ]
        const rowSelection = {
            selectedRowKeys: _this.props.selectedUser,
            onChange: (selectedRowKeys, selectedRows) => {
                _this.props.getSelectUserKeys(selectedRowKeys);
            },
        };
        const pagination = {
            current: parseInt(this.state.currentPage),
            total: this.state.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.state.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.currentPageChange(current);
            }
        }

        const relatedOptions = this.state.relatedEnterpris.map(iterm => {
            return <Option key={iterm.upCompanyNo} value={iterm.upCompanyNo} >{iterm.upCompanyNo + "/" + iterm.upCompanyName}</Option>
        });
        return (
            <div style={{ width: '100%', marginTop: 20 }}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit.bind(this)}>
                    <Row>
                        <Col>
                            <Form.Item label="关联企业商户号/名称">
                                {getFieldDecorator('relatedEid', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择关联企业',
                                        }
                                    ]
                                })(<Select style={{ width: "100%" }} placeholder="关联企业商户号/名称" onChange={this.queryUnRelatedUser.bind(this)}>
                                    {relatedOptions}
                                </Select>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Table
                            rowKey={record => record.id}
                            size="middle "
                            dataSource={this.state.sourceData}
                            columns={columns}
                            rowSelection={rowSelection}
                            bordered
                            pagination={pagination}
                        >
                        </Table>
                    </Row>
                    <div style={{ width: '100%', marginTop: 20 }}>
                    <Row>
                        <Col span={12} offset={9}>
                            <Button type="primary" icon="form" htmlType="submit" loading={this.props.addChooseLoading}>确定</Button>
                            <Button type="primary" icon="redo" style={{ marginLeft: 20 }} onClick={this.handlerReset.bind(this)}>重置</Button>
                        </Col>
                    </Row>
                    </div>
                </Form>
            </div>
        );
    }
}

AddRelationByChoose = Form.create()(AddRelationByChoose);

export default AddRelationByChoose;