import React, { Component } from 'react';
import { Form, Row, Col, Input, Button, DatePicker ,Select,Cascader,Messagess,  } from 'antd';
import moment from 'moment';
import UploadSupplierPayRoll from './UploadSupplierPayRoll';
import DownloadSupplierTemp from './DownloadSupplierTemp';


const { Option } = Select;
const { RangePicker,MonthPicker } = DatePicker;

class CreateSupplierPayRollSearch extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    refreshSubmit(e){
        this.handlerSubmit(e);
    }

    handlerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                if(params.salarydate){
                    params.salarydate = moment(params.salarydate).format('YYYY-MM');
                }
                if(params.dateRange && params.dateRange.length > 0 ){
                    params.beginDate = moment(params.dateRange[0]).format('YYYYMMDD');
                    params.endDate = moment(params.dateRange[1]).format('YYYYMMDD');
                }
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset(){
        this.props.form.resetFields();
        this.props.resetForm();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const dateFormat = 'YYYY-MM-DD';
        const employerOptions = this.props.employerList.map( employer => {
            return <Option key={employer.upstreamCorporate} value={employer.upstreamCorporate} >{employer.upCompanyName}/{employer.upstreamCorporate}</Option>
        })
        return (
            <div>
                <Form className="ant-search-form" onSubmit={this.handlerSubmit.bind(this)} >
                <Row gutter={24} >
                     <Col  span={11}>
                        <Form.Item label="用工企业商户号/名称" >
                         { getFieldDecorator("joinId",{
                             })(
                                <Select
                                showSearch
                                placeholder="请选择"
                                optionFilterProp="children" 
                               
                              >
                                {employerOptions}
                              </Select>
                        ) }
                        </Form.Item>
                    </Col>
                    <Col  span={6}>
                        <Form.Item label="发放月份" >
                         { getFieldDecorator("salarydate",{
                             })(
                                <MonthPicker  />
                        ) }
                        </Form.Item>
                    </Col>
                    
                    <Col span={7} >
                        <Form.Item label="发放单批次编号" >
                            { getFieldDecorator("batchcode")(
                                <Input allowClear placeholder="发放单批次编号" ></Input>
                            ) }
                        </Form.Item>
                    </Col> 
                    </Row>
                    <Row gutter={24} >
                        <Col  span={11} style={{paddingLeft:90}}>
                            <Form.Item label="日期范围"  >
                            { getFieldDecorator("dateRange",{
                                // initialValue : [moment('2019/01/01', dateFormat), moment(new Date())]
                            })(
                                <RangePicker format={dateFormat}  />
                            ) }
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="审核状态" >
                                { getFieldDecorator("status")(
                                    <Select placeholder='审核状态'>
                                        <Option value=''>全部</Option>
                                        <Option value='07'>已发放</Option>
                                        <Option value='01'>审核通过</Option>
                                        <Option value='05'>审核拒绝</Option>
                                        <Option value='00'>待审核</Option>
                                        <Option value='03'>已提交</Option>
                                        <Option value='06'>正在发放</Option>
                                        <Option value='04'>未提交</Option>
                                        <Option value='08'>发放失败</Option>
                                    </Select>
                                ) }
                            </Form.Item>
                        </Col>
                        <Col  span={7} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" style={{marginLeft : 10}}  loading={this.props.loading} >查询</Button>
                                <Button style={{marginLeft : 5}} onClick={this.handlerReset.bind(this)} >重置</Button>
                            </Form.Item>
                        </Col>                   
                </Row>
            </Form>
            <div style={{marginTop : 10}} >
            <Row gutter={24}>
            <Col xxl={{ span: 4}} lg={{ span: 4}}><Button type="primary"  onClick={this.refreshSubmit.bind(this)} >刷新</Button></Col>
            <Col xxl={{ span: 6, offset: 14 }} xl={{ span: 8, offset: 12 }} lg={{ span: 12, offset: 8 }}>
                <div style ={{float:'right'}}>
                    <DownloadSupplierTemp />&nbsp;&nbsp;&nbsp;&nbsp;<UploadSupplierPayRoll  createPayUpload={this.props.createPayUpload}/>
                </div>
            </Col>
            </Row>
            </div>  
            </div>
        );
    }
}
CreateSupplierPayRollSearch = Form.create()(CreateSupplierPayRollSearch) 
export default CreateSupplierPayRollSearch;