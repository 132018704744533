import {http} from '../utils/http'

//发放工资总和
export function selectsummoney(data){
    return http.post("/util/selectsummoney",data);
}
//发放平均工资
export function selectavgmoney(data){
    return http.post("/util/selectavgmoney",data);
}

//发放工资总和与平均工资
export function selectSumAndAvg(data){
    return http.post("/util/selectSumAndAvg",data);
}

//获取企业的所有员工
export function queryAppUser(data){
    return http.post("/api/index/queryAppUser",data);
}

//查询审核发放中工资批次
export function salaryBatchAudit(data){
    return http.post("/api/index/salaryBatchAudit",data);
}

//查询审核发放中工资批次详情
export function simplerSalaryBatchAudit(data){
    return http.post("/api/index/simplerSalaryBatchAudit",data);
}

export function uploadFile(data) {
    return http.post("/util/uploadFile", data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}