import React, { Component } from 'react';
import ConfirmPrpover from '../Common/ConfirmPrpover'
import { Message, Spin, Icon } from 'antd'
import { operateSalaryBatch } from 'api/weeksalary'

class CreatePayRollOperate extends Component {
    constructor(){
        super();
        this.state={
            loading : false
        }
    }

    handlerEmployeeComplete(recordid){
        this.setState({
            loading : true
        })
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        let params = {
            id : recordid,
            enterpriseid : user.enterpriseid
        }
        operateSalaryBatch(params)
        .then(res => {
            if(res.data.result==='true'){
                this.setState({
                    loading : false
                })
                Message.success(res.data.message);
                this.props.refreshSubmit();
            }else{
                this.setState({
                    loading : false
                })
                Message.error(res.data.message);
            }
        })
    }


    render() {
        const loadIcon = <Icon type="loading" spin />;
        const htmlText = <span className="span-click" >发送审核</span>;
            return (
                <span style={{display :'inline-flex' }} >
                    <Spin size="small" indicator={loadIcon} spinning={this.state.loading}>
                    {
                        <ConfirmPrpover record={this.props.record} placement="topRight"  content="确实发送审核吗？"
                            confirmText="确定" cancelText="取消" htmlText={htmlText} confirmSubmit={this.handlerEmployeeComplete.bind(this)} />
                    }
                    </Spin>
                </span>
            );
    }
}

export default CreatePayRollOperate;