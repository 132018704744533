export function formatAmount(amount){
    amount = (amount || 0).toString();
    let result = '';
    while (amount.length > 3) {
        result = ',' + amount.slice(-3) + result;
        amount = amount.slice(0, amount.length - 3);
    }
    if (amount) { result = amount + result; }
    return result;
}

export function formatPercent(number){
    if(number!=null){
        var m=0,s1=number.toString();  
        try{m+=s1.split(".")[1].length}catch(e){}  
        return Number(s1.replace(".",""))*100 /Math.pow(10,m) + '%';
    }
    return ''
}

export function parsePercent(percent){
    if(percent!=null){
        var m=0, s1 = percent.split("%")[0]  
        try{m+=s1.split(".")[1].length}catch(e){}  
        return Number(s1.replace(".","")) /100 /Math.pow(10,m) ;
    }
    return ''
}

export function AmountCompare(number1, number2){
    if(parseFloat(number1) > parseFloat(number2)){
        return '1';
    } 
    if(parseFloat(number1) === parseFloat(number2)){
        return '0';
    } 
    if(parseFloat(number1) < parseFloat(number2)){
        return '-1';
    }
    
}

export function parseAmount(amount){
    let result = '';
    result = amount.replace(/,/g,"");
    return result;
}

export function toFixed( dight, bits = 3 ) {
    try{
        return Math.round( dight * Math.pow( 10, bits ) ) / Math.pow( 10, bits )
    }catch(e){
        return "";
    }
    
}