import React, { Component } from 'react';
import { Message, Modal, Form, Row, Col, Input, Button, Select } from 'antd'
import { addUserRelation } from '@/api/userRelatedEnterpise';
import { queryRelatedUpEnterpris} from '@/api/supplierManage';
const { Option } = Select;

class RelateOperate extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem("user")).enterpriseid,
            username: JSON.parse(global._sessionStorage.getItem("user")).username,
            showRelate: false,
            relatedEnterpris: [],
            buttonLoading:false,
        }
    }

    relateEnterprise(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({
                    buttonLoading:true,
                })
                params.eid = this.state.enterpriseid;
                params.uid = this.props.record.id;
                params.operator = this.state.username;
                addUserRelation(params).then(res => {
                    if("200" === res.data.status){
                        Message.success(res.data.message);
                        this.setState({
                            showRelate: false,
                            relatedEnterpris: [],
                            buttonLoading:false,
                        })
                        this.props.refreshSubmit();
                    }else{
                        Message.error(res.data.message);
                        this.setState({
                            buttonLoading:false,
                        })
                    }

                });
            }
        })
    }

    handlerReset() {
        this.props.form.resetFields();
    }

    desensitization(str) {
        if (typeof str == 'string') { //参数为字符串类型
            let ruten = str.substring(4, str.length - 4); //提取字符串下标之间的字符。
            return str.replace(ruten, '*****'); //字符串中用字符替换另外字符，或替换一个与正则表达式匹配的子串。
        }

    }

    showModal() {
        this.setState({
            showRelate: true
        })

        //查询关联企业
        let params = {
            eid: this.state.enterpriseid,
        }
        queryRelatedUpEnterpris(params).then(res => {
            this.setState({
                relatedEnterpris: res.data.list
            })
        });
    }
    showRelateCancel() {
        this.setState({
            showRelate: false
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 }
        };

        const relatedOptions = this.state.relatedEnterpris.map(iterm => {
            return <Option key={iterm.upCompanyNo} value={iterm.upCompanyNo} >{iterm.upCompanyNo + "/" + iterm.upCompanyName}</Option>
        });
        return (
            <span style={{ display: 'inline-flex' }} >
                <span className="span-click" onClick={this.showModal.bind(this)} >关联企业</span>
                <Modal
                    title="关联企业"
                    visible={this.state.showRelate}
                    onCancel={this.showRelateCancel.bind(this)}
                    footer={false}
                    width={800}
                >
                    <div>
                        <Form {...formItemLayout} onSubmit={this.relateEnterprise.bind(this)}>
                            <Form.Item label="姓名">
                                {getFieldDecorator('username', {
                                    initialValue: this.props.record.username,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入姓名！',
                                        }
                                    ]
                                })(<Input placeholder='姓名' disabled />)}
                            </Form.Item>
                            <Form.Item label="手机号">
                                {getFieldDecorator('mobile', {
                                    initialValue: this.props.record.mobile,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入手机号！',
                                        }
                                    ]
                                })(<Input placeholder='手机号' disabled />)}
                            </Form.Item>
                            <Form.Item label="身份证号">
                                {
                                    getFieldDecorator('certNo', {
                                        initialValue: this.desensitization(this.props.record.certinfo.certNo),
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入身份证号！',
                                            }
                                        ]
                                    })(<Input placeholder='身份证号' disabled />)}
                            </Form.Item>
                            <Form.Item label="关联企业商户号/名称">
                                {
                                    getFieldDecorator('relatedEid', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择关联企业！',
                                            }
                                        ]
                                    })(<Select style={{ width: '100%' }} placeholder="关联企业">
                                        {relatedOptions}
                                    </Select>)}
                            </Form.Item>
                            <Row>
                                <Col span={3} offset={8}>
                                    <Button type="primary" icon="form" htmlType="submit" loading={this.state.buttonLoading}>提交</Button>
                                </Col>
                                <Col span={3}>
                                    <Button type="primary" icon="redo" style={{ marginLeft: 50 }} onClick={this.handlerReset.bind(this)}>重置</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
            </span>
        );
    }
}

RelateOperate = Form.create()(RelateOperate);

export default RelateOperate;