import React, { Component } from 'react';
import { Layout, Steps } from 'antd'
import IncreaseBaseInfo from '../../components/Role/IncreaseBaseInfo'
import IncreaseMenuInfo from '../../components/Role/IncreaseMenuInfo'
import IncreaseResult from '../../components/Role/IncreaseResult'


import './role.less'


const { Sider } = Layout

const { Step } = Steps

class RoleIncrease extends Component {

    constructor(){
        super();
        this.state = {
            curStep : 0,
            roleForm : {},
            expandedKeys : [],
            checkedKeys : ["M1000"]
        }
    }

    handlerFirstEnter(checkedKeys){
        this.setState({
            curStep : 0,
            checkedKeys
        })
    }

    handlerFirstStepClick( value ){
        this.setState({
            roleForm : value,
            curStep : 1
        })
    }

    handlerSecondStepClick( expandedKeys, checkedKeys ){

        this.setState({
            curStep : 2
        })
    }


    handlerShowSiderChild(){
        let key = this.state.curStep;
        switch(key){
            case 0 : return (<IncreaseBaseInfo stepClick={this.handlerFirstStepClick.bind(this)} roleForm={this.state.roleForm} />);
            case 1 : return (<IncreaseMenuInfo {...this.state} checkedKeys={this.state.checkedKeys} stepClick={this.handlerSecondStepClick.bind(this)} prevClick={this.handlerFirstEnter.bind(this)} /> );
            case 2 : return (<IncreaseResult />);
            default : return ( <div></div> );
        }

    }
    
    render() {
        return (
            <Layout className="role-layout" >
                <Sider className='role-sider-menu' >
                    <div>
                    <Steps style={{marginTop : 60}} direction="vertical" current={this.state.curStep}>
                        <Step title="角色信息" description={ <div><br/><br/></div> } />
                        <Step title="菜单分配" description={ <div><br/><br/></div> } />
                        <Step title="提交结果" description={ <div><br/><br/></div> }/>
                    </Steps>
                    </div>
                </Sider>
                <Layout className="role-content-layout" >
                    <div className="role-content" >
                        {this.handlerShowSiderChild()}
                    </div>
                </Layout>
                
            </Layout>
        );
    }
}

export default RoleIncrease;