import React, { Component } from 'react';
import { Table, Alert, Divider } from 'antd';
import CreatePayDetailResult from './CreatePayDetailResult';
import CreatePayDetailDownload from './CreatePayDetailDownload';
import { formatDate } from 'utils/Date'
import numeral from 'numeral';


class CreatePayRecordResult extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const _this = this;
        const columns = [
            { title: '文件名', dataIndex: 'fileName', key: 'fileName', align: 'center',},
            { title: '批次', dataIndex: 'salaryBatchCode', key: 'salaryBatchCode', align: 'center',},
            { title: '文件类型', dataIndex: 'fileType', key: 'fileType', align: 'center',
                render(val){
                    if(val==='P'){
                        return "对私";
                    }else if(val==='C'){
                        return "对公"
                    }else{
                        return val;
                    }
                }
            },
            { title: '薪资类型', dataIndex: 'salaryType', key: 'salaryType', align: 'center',
                render(val){
                    if(val==='week'){
                        return "日薪";
                    }else if(val==='month'){
                        return "月薪"
                    }else{
                        return val;
                    }
                }
            },
            { title: '总金额', dataIndex: 'totalAmount', key: 'totalAmount', align: 'center',
                render(val){
                    return numeral(val).format('0,0.00');
                }
            },
            { title: '总条数', dataIndex: 'totalNum', key: 'totalNum', align: 'center',},
            { title: '成功条数', dataIndex: 'succNum', key: 'succNum', align: 'center',},
            { title: '失败条数', dataIndex: 'failNum', key: 'failNum', align: 'center',},
            { title: '文件大小', dataIndex: 'fileSize', key: 'fileSize', align: 'center',
                render(val){
                    if(val!==null && val !==""){
                        return val+"KB";
                    }
                }
            },
            { title: '导入时间', dataIndex: 'importDate', key: 'importDate', align: 'center',
                render(val){
                    if(val===null ||val ===""){
                        return "--";
                    }else{
                        return formatDate(new Date(val),'yyyy-MM-dd hh:mm:ss');
                    }
                }
            },
            { title: '解析状态',  dataIndex : 'parseStatus', key : 'parseStatus', align : 'center',
                render(val){
                    if(val!==null && val !==""){
                        return val==='0' ? "成功" : "失败";
                    }else{
                        return '';
                    }
                }
            },
            { title: '校验状态', dataIndex: 'checkStatus', key: 'checkStatus', align: 'center',
                render(val){
                    if(val!==null && val !==""){
                        return val==='0' ? "成功" : "失败";
                    }else{
                        return '';
                    }
                }
            },
            { title: '操作', align: 'center',
                render(text, record, index) {
                    return (
                        <div>
                            <CreatePayDetailResult disabled={(record.parseStatus!=='0')} record={record} />
                            <Divider type="vertical" />
                            <CreatePayDetailDownload disabled={(record.parseStatus!=='0')} record={record} />
                        </div>
                    )
                }
            },
        ]
        const pagination = {
            current: parseInt(this.props.currentPage),
            total: this.props.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.props.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }

        return (
            <div style={{ marginTop: '20px' }}>
                <Alert style={{ marginTop: '10px' }} message={(
                    <span>查询结果</span>
                )} type="info" showIcon />
                <Table style={{ marginTop: '20px' }}
                    rowKey={record => record.id}
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData}
                    columns={columns}
                    bordered
                    pagination={pagination}
                >
                </Table>
            </div>
        );
    }
}

export default CreatePayRecordResult;