export function strToByte(str){
    var bytes = [];   
    var len,c;  
    len = str.length;  
    for(var i = 0; i < len; i++){  
        c = str.charCodeAt(i);  
        if(c >= 0x010000 && c <= 0x10FFFF){  
            bytes.push(((c >> 18) & 0x07) | 0xF0);  
            bytes.push(((c >> 12) & 0x3F) | 0x80);  
            bytes.push(((c >> 6) & 0x3F) | 0x80);  
            bytes.push((c & 0x3F) | 0x80);  
        }else if(c >= 0x000800 && c <= 0x00FFFF){  
            bytes.push(((c >> 12) & 0x0F) | 0xE0);  
            bytes.push(((c >> 6) & 0x3F) | 0x80);  
            bytes.push((c & 0x3F) | 0x80);  
        }else if(c >= 0x000080 && c <= 0x0007FF){  
            bytes.push(((c >> 6) & 0x1F) | 0xC0);  
            bytes.push((c & 0x3F) | 0x80);  
        }else{  
            bytes.push(c & 0xFF);  
        }  
    }  
    return bytes;  
}


/**
 * 获取随机字符串
 */
export function randomString(len) {
    len = len || 32;
    const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    let maxPos = $chars.length;
    let pwd = '';
    for ( let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

export function publicDesensitization(){
    try{
        //先将内置的 arguments 转换为真正的数组
        var dataArr = Array.prototype.slice.apply(arguments);
        for (var i = 0; i < dataArr.length; i++) {
            var data = dataArr[i];
            // 正则判断返回相应数据
            if(/(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(data) || /^(13[0-9]|16[0-9]|19[0-9]|147|15[0-9]|17[6-8]|18[0-9])\d{8}|17[0-9]\d{8}$/.test(data) || /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/.test(data)){
                //身份证号 || 手机号  ||  营业执照    前三后四
                data = data.substr(0,3) + "****" + data.substr(-4);
            }else if(/^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/.test(data)){
                //邮箱号码  前二 后以 @ 分割
                data = data.substr(0,2) + "****" + data.substr(data.indexOf('@'));
            }else if(/^\d{16}|\d{19}$/.test(data)){
                //银行卡号  后四位
                data = "****" + data.substr(-4);
            }else if(data.indexOf('公司') > -1){
                //企业名称  前二后四
                data = data.substr(0,2) + "****" + data.substr(-4);
            }else{
                return  data = data.substr(0,3) + "****" + data.substr(-4);;
            }
            dataArr[i] = data;
        }

        return dataArr;
    }catch(e){
        return "";
    }


}

//字符串脱敏
export function desensitization(str,start,end) {
    if (str && str != "" && typeof str == "string") {
      //参数为字符串类型
      let returnStr = str.substring(start, str.length - end); //提取字符串下标之间的字符。
      return str.replace(returnStr, "*****"); //字符串中用字符替换另外字符，或替换一个与正则表达式匹配的子串。
    }
  }