import React, { Component } from 'react';
import { Form, Input, Button, Icon, Message, Row, Col, Layout } from 'antd'
import { rsaEncrypt } from 'utils/EncryptRSA'
import 'assets/css/login.less'
import PhoneSvg from '@/assets/img/phone.svg';
import PasswordSvg from '@/assets/img/password.svg';
import MobileCodeSvg from '@/assets/img/mobileCode.svg';
import { sendVerificationCode, loginCollective, synchTime } from 'api/login'

const FormItem = Form.Item;

class LoginForm extends Component {
    constructor() {
        super();
        this.state = {
            loginLoad: false,
            disabledBtn: false,
            mobileLoading: false,
            count: 60,
            mobileBtnText: '获取验证码',
            loginDisabled: false,
        }
    }

    componentWillMount() {
        global._sessionStorage.removeItem('user');
        global._sessionStorage.removeItem('token');
    }


    handlerSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({
                    loginLoad: true
                })
                
                let loginParam = {
                    mobile: params.mobile,
                    password: rsaEncrypt.encrypt(params.password),
                    cip: global._sessionStorage.getItem("cip"),
                    messageCode: params.mobileCode,
                }
                loginCollective(loginParam).then(res => {
                    let { errorCode, errorMessage, data } = res.data;
                    if (errorCode === "0000") {
                        let loginParam = {}
                        global._sessionStorage.setItem('userHeadId',data.userHeadId);
                        global._sessionStorage.setItem('loginBusiness',data.loginBusiness);
                        global._sessionStorage.setItem('token',data.token);
                        global._sessionStorage.setItem('random',data.randomKey);
                        global._sessionStorage.setItem('businessList',JSON.stringify(data.businessList));
                        global._sessionStorage.setItem('loginInfoMap',JSON.stringify(data.loginInfoMap));
                        if(data.loginInfoMap[data.loginBusiness]){
                            let loginInfo = data.loginInfoMap[data.loginBusiness];
                            loginParam.enterpriseid = data.loginBusiness;
                            loginParam.userId = loginInfo.userId;
                            loginParam.username = params.mobile;
                            loginParam.isChangePass = data.isChangePass;
                            loginParam.lastLoginTime = data.lastLoginTime;
                            // loginParam.personalSign = loginInfo.personalSign;
                            // loginParam.companySign = loginInfo.companySign;
                            loginParam.companyname = loginInfo.companyname;
                            loginParam.bankCode = loginInfo.bankCode;
                            loginParam.versions = loginInfo.versions;
                            global._sessionStorage.setItem('user', JSON.stringify(loginParam));
                            global._sessionStorage.setItem('businessEncrypt',loginInfo.businessEncrypt);
                            global._sessionStorage.setItem('menuList', JSON.stringify(loginInfo.menuTrees));
                            global._sessionStorage.setItem('menuSimplerPay', JSON.stringify(loginInfo.menuSimplerPay));
                            global._sessionStorage.setItem('menuSimplerFac', JSON.stringify(loginInfo.menuSimplerFac));
                            global._sessionStorage.setItem('menuSimplerSet', JSON.stringify(loginInfo.menuSimplerSet));
                            global._sessionStorage.setItem('menuSimplerNoCardFac', JSON.stringify(loginInfo.menuSimplerNoCardFac));
                            Message.success('登录成功');
                            this.clearInterval();
                            if (loginInfo.versions === '1') {
                                this.props.history.push('/index');
                            } else {
                                this.props.history.push('/indexSimpler');
                            }
                        }

                    } else {
                        this.setState({
                            loginLoad: false
                        });
                        Message.error(errorMessage);
                    }
                }).catch( err => {
                    Message.error("服务异常，请稍后再试！");
                    this.setState({
                        loginLoad : false
                    })
                })
            }
        })
    }

    sendMobile = () => {
        this.props.form.validateFields(['mobile', 'password'], (err, params) => {
            if (!err) {
                let postParam = {
                    mobile: params.mobile,
                    password: rsaEncrypt.encrypt(params.password)
                }
                this.setState({
                    mobileLoading: true,
                })
                sendVerificationCode(postParam).then(res => {
                    let { errorCode, errorMessage, data } = res.data;
                    if (errorCode == "0000") {
                        Message.success('验证码已发送，请于5分钟内输入！');
                        this.setState({
                            disabledBtn: true,
                            count: 60,
                            mobileBtnText: '重新获取',
                        })
                        this.setInterval();
                    } else {
                        Message.error(errorMessage);
                        this.setState({
                            mobileLoading: false,
                        })
                    }
                }).catch(error => {
                    this.setState({
                        mobileLoading: false,
                    })
                    Message.error('网络开小差了');
                });

            }
        })


    }

    setInterval = () => {
        this.timer = setInterval(this.countDown, 1000);
    }

    clearInterval = () => {
        clearInterval(this.timer);
    }

    countDown = () => {
        let count = this.state.count
        if (count === 1) {
            this.clearInterval();
            this.setState({
                disabledBtn: false,
                mobileLoading: false,
            })
        } else {
            this.setState({
                count: count - 1
            })
        }
    }

    getServerTime() {
        let params = {
            requestTime: new Date().getTime()
        }
        synchTime(params).then(res => {
            let { errorCode, data } = res.data;
            if (errorCode === '0000' && data.vaildTime) {
                global._sessionStorage.setItem('diffTime', data.systemTime);
            } else {
                this.setState({
                    loginDisabled: true
                });
            }
        })
    }

    componentDidMount() {
        // this.getServerTime();
    }


    onPaste(e) {
        e.preventDefault();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Layout style={{ height: '100%' }} >
                <div className="login" >
                    <div className="login-form" >
                        <div className="login-title"  >
                            薪企链
                        </div>
                        <div className="login-keyWords" >
                            人力资源产业供应链金融科技服务平台
                        </div>
                        <Form onSubmit={this.handlerSubmit.bind(this)} className="form"  >
                            <FormItem >
                                {getFieldDecorator('mobile', {
                                    rules: [{ required: true, message: '请输入手机号' }]
                                })(
                                    <Input className="form-placeholder" prefix={<Icon component={PhoneSvg} style={{ color: '#333333' }}/>}  placeholder='手机' disabled={this.state.loginDisabled} />
                                )}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('password', {
                                    rules: [
                                        { required: true, pattern: new RegExp('^.{6,12}$', "g"), message: '输入的密码不符合密码规则，请重新输入' },
                                    ]
                                })(
                                    <Input type="password" className="form-placeholder" prefix={<Icon component={PasswordSvg} style={{ color: '#333333' }}/>} style={{width: '100%'}} placeholder='密码' disabled={this.state.loginDisabled} id='password' onPaste={this.onPaste} />
                                )}
                            </FormItem>
                            <FormItem>
                                <div style={{width:'100%'}}>
                                    <Row type="flex" justify="space-around" align="middle" >
                                        {getFieldDecorator('mobileCode', {
                                            rules: [{ required: true, message: '请输入验证码' }]
                                        })(
                                            <Input  className="form-placeholder" prefix={<Icon component={MobileCodeSvg} style={{ color: '#333333' }}/>} style={{width: '55.755%'}} placeholder='短信验证码' maxLength={6} disabled={this.state.loginDisabled} />
                                        )}
                                        <div style={{width: '6.655%'}}></div>
                                        <Button type="primary" style={{width: '37.59%',color: '#341A8F',border: '0px'}} disabled={this.state.mobileLoading} onClick={this.sendMobile.bind(this)} className="form-placeholder">
                                            {this.state.disabledBtn ? `${this.state.count}秒` : this.state.mobileBtnText}
                                        </Button>
                                    </Row>
                                </div>
                            </FormItem>
                            <Button type='primary' loading={this.state.loginLoad} htmlType='submit' className="button-login" >登录</Button>
                        </Form>
                    </div>
                </div>
            </Layout>
        );
    }
}

const Login = Form.create()(LoginForm);
export default Login;