import React, { Component } from 'react';
import { Form, Input, Button } from 'antd'

const { TextArea } = Input;

class RoleInfoCommon extends Component {

    constructor(){
        super();
        this.state={
            departList : [],
            loading : false
        }
    }


    handlerReset(e){
        e.preventDefault();
        this.props.form.resetFields();
    }

    handlerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                params.isSys = '1';
                params.status = '0';
                this.setState({ loading : true });
                this.props.roleInfoSubmit(params);
                this.setState({ loading : false });
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form { ...this.props.formItemStyle } onSubmit={this.handlerSubmit.bind(this)} >
                <Form.Item label="角色名称" >
                    { getFieldDecorator("roleName",{
                        initialValue : this.props.roleForm.roleName,
                        rules : [
                            { required: true, message: '请输入角色名称' }
                        ]
                    })(
                        <Input placeholder="角色名称" />
                    ) }
                </Form.Item>
                <Form.Item label="角色描述" >
                    { getFieldDecorator("roleDescr",{
                        initialValue : this.props.roleForm.roleDescr,
                    })(
                        <TextArea  placeholder="角色描述" autosize={{ minRows: 2, maxRows: 4 }} />
                    ) }
                </Form.Item>
                    <div style={{width : '100%'}}>
                        <Button type="primary" onClick={this.handlerReset.bind(this)} style={{marginLeft : '30%'}} >{this.props.resetText}</Button>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} >{this.props.submitText}</Button>
                    </div>
                    
            </Form>
        );
    }
}

RoleInfoCommon = Form.create()(RoleInfoCommon)


export default RoleInfoCommon;