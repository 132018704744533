import React, { Component } from 'react';
import { Message } from 'antd'
import { querySalaryBatchAudit } from 'api/RechargeController'
import SimplerSalaryPaySearchForm from '@/components/finance/SimplerSalaryPaySearchForm'
import SimplerSalaryPayQueryResult from '@/components/finance/SimplerSalaryPayQueryResult'
import {Link} from 'react-router-dom' 

class simplerSalaryPay extends Component {

    constructor(){
        super();
        this.state = {
            loading : false,
            searchParam : {},
            sourceData : [],
            totalNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            showHistoryBtn : true,
        }
    }

    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage,
            searchParam : params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params){
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam : params
        })
        this.searchPost(params);
    }

    searchPost(params){
        this.setState({
            loading : true
        })
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        
        querySalaryBatchAudit(params).then(res => {
            let {errorCode, errorMessage} = res.data;
            if(errorCode === '0000'){
                this.setState({
                    sourceData : res.data.salaryPayList,
                    totalNum : res.data.totalRow
                })
            }else{
                Message.error(errorMessage);
            }
            this.setState({
                loading : false
            })
        })
    }

    refreshSubmit(){
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    resetForm(){
        this.setState({
            searchParam : {}
        })
    }

    componentDidMount(){
        let salaryBatchCode = this.props.salaryBatchCode;
        if(salaryBatchCode !== ""){
            let params = {};
            params.batchS = salaryBatchCode;
            this.searchFormSubmit(params);
        }else{
            this.searchFormSubmit({});
        }
    }

    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
        return;
        };
    }

    render() {
        return (
            <div>
                <SimplerSalaryPaySearchForm 
                    showHistoryBtn={this.state.showHistoryBtn}
                    loading={this.state.loading}
                    searchFormSubmit={this.searchFormSubmit.bind(this)} 
                    resetForm={this.resetForm.bind(this)}
                    currentFlag = {this.props.currentFlag}
                    history = {this.props.history}
                />

                <SimplerSalaryPayQueryResult 
                    loading={this.state.loading} 
                    resultData={this.state.sourceData} 
                    totalNum={this.state.totalNum} 
                    refreshSubmit={this.refreshSubmit.bind(this)} 
                    currentPageChange={this.currentPageChange.bind(this)}
                    pageSizeChange={this.pageSizeChange.bind(this)}
                    currentPage={this.state.currentPage}
                    pageSizeOptions={this.state.pageSizeOptions}
                    currentFlag = {this.props.currentFlag}
                    history = {this.props.history}
                    batchCode={this.props.salaryBatchCode}
                    pageSize={this.state.pageSize}
                />
            </div>
        );
    }
}

export default simplerSalaryPay;