import {http} from '../utils/http'

//查询审核发放中工资批次
export function querySalaryBatchAudit(data){
    return http.post("/api/pay/querySalaryBatchAudit",data);
}

//对私对公工资单批次明细
export function uploadSalaryQuery2(data){
    return http.post("/api/pay/uploadSalaryQuery2",data);
}

//审核薪资批次
export function salaryAudit(data){
    return http.post("/api/pay/salaryAudit",data);
}
//融资发薪，查询保理融资户资金是否充足
export function payMoneyCheck(data){
    return http.post("/api/pay/payMoneyCheck",data);
}

//发放
export function pay(data){
    return http.post("/api/pay/pay",data);
}
//比对交易密码
export function checkTpwd(data){
    return http.post("/api/pay/checkTpwd",data);
}
//获取交易密码
export function getTpwd(data){
    return http.post("/api/pay/getTpwd",data);
}

//查询工资发放失败人员信息
export function querySalaryPayFail(data){
    return http.post("/api/pay/querySalaryPayFail",data);
}
//薪资发放失败处理
export function salaryPayFail(data){
    return http.post("/api/pay/salaryPayFail",data);
}
//下载批次详情
export function downloadSalaryBatch(data){
    return http.post("/api/pay/downloadSalaryBatch",data);
}
//更具id查询工资单上传批次信息
export function salaryBatchQuery(data){
    return http.post("/api/pay/salaryBatchQuery",data);
}
//作废待发放的薪资单
export function deleteWaitPaySalary(data){
    return http.post("/api/pay/deleteWaitPaySalary",data);
}
//用工企业审核发放
export function supEnPay(data){
    return http.post("/api/pay/supEnPay",data);
}
//华夏发薪
export function payWithdraw(data){
    return http.post("/api/pay/payWithdraw",data);
}
//用工企业审核发放-华夏发薪
export function supEnPayWithdraw(data){
    return http.post("/api/pay/supEnPayWithdraw",data);
}
//薪资发放失败处理-华夏发薪
export function payWithdrawFail(data){
    return http.post("/api/pay/payWithdrawFail",data);
}
//发放单审核
export function salaryBatchCheck(data) {
    return http.post("/superviseSalary/salaryBatchCheck", data);
}
//发放单发放-交易平台
export function salaryPay(data) {
    return http.post("/superviseSalary/salaryPay", data);
}

