import { Icon } from 'antd';

const SelfIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1108830_fgod89ns4is.js', 
});


export default SelfIcon;


  