import React, { Component } from 'react';
import { Form, Input, Button, Select, Row, Message, Card, Col } from 'antd'
import { editeUser } from '../../api/staff';
const { Option } = Select;

class UpdateStaffUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            uid: this.props.record.id,
            loading:false,
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({
                    loading: true,
                })

                params.id = this.state.uid;
                params.eid = this.state.enterpriseid;
                editeUser(params).then(res => {
                    let { statusCode,message } = res.data;
                    if("200" === statusCode){
                        Message.success("修改个人信息成功");
                        this.props.refreshSubmit();
                    }else{
                        Message.error(message);
                    }
                    this.setState({
                        loading: false,
                    })
                })
            }
        })

    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 12 }
        };
        return (
            <div>
                <Card title="个人信息" bordered={false} size="small" >
                    <Form {...formItemLayout} onSubmit={this.handleSubmit.bind(this)}>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="邮箱">
                                    {getFieldDecorator('email', {
                                        initialValue: this.props.record.email,
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入邮箱！',
                                            }
                                        ]
                                    })(<Input placeholder='邮箱' disabled={this.props.detailFlag}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="住址">
                                    {
                                        getFieldDecorator('cert_addr', {
                                            initialValue: this.props.record.certinfo.certAddr,
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入住址！',
                                                }
                                            ]
                                        })(<Input placeholder='住址' disabled={this.props.detailFlag}/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="直系亲属联系人">
                                    {getFieldDecorator('contractperson', {
                                        initialValue: this.props.record.contractperson,
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入直系亲属联系人！',
                                            }
                                        ]
                                    })(<Input placeholder='直系亲属联系人' disabled={this.props.detailFlag}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="其他紧急联系人">
                                    {
                                        getFieldDecorator('outher_contractperson', {
                                            initialValue: this.props.record.outher_contractperson,
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入其他紧急联系人！',
                                                }
                                            ]
                                        })(<Input placeholder='其他紧急联系人' disabled={this.props.detailFlag}/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="与直系亲属关系">
                                    {getFieldDecorator('contractype', {
                                        initialValue: this.props.record.contractype,
                                        rules: [
                                            {
                                                required: false,
                                                message: '请输入与直系亲属关系！',
                                            }
                                        ]
                                    })(<Select style={{ width: '100%' }} placeholder="与直系亲属关系" disabled={this.props.detailFlag}>
                                        <Option value=""></Option>
                                        <Option value="00">配偶</Option>
                                        <Option value="01">父母</Option>
                                        <Option value="02">兄弟姐妹</Option>
                                        <Option value="03">子女</Option>
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="与其他联系人关系">
                                    {
                                        getFieldDecorator('outher_contractype', {
                                            initialValue: this.props.record.outher_contractype,
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入与其他联系人关系！',
                                                }
                                            ]
                                        })(<Select style={{ width: '100%' }} placeholder="与其他联系人关系" disabled={this.props.detailFlag}>
                                            <Option value=""></Option>
                                            <Option value="04">同事</Option>
                                            <Option value="05">同学</Option>
                                            <Option value="06">朋友</Option>
                                        </Select>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="直系亲属联系电话">
                                    {
                                        getFieldDecorator('contractphone', {
                                            initialValue: this.props.record.contractphone,
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入直系亲属联系电话！',
                                                }
                                            ]
                                        })(<Input placeholder='直系亲属联系电话' />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="其他关系联系电话">
                                    {
                                        getFieldDecorator('outher_contractphone', {
                                            initialValue: this.props.record.outher_contractphone,
                                            rules: [
                                                {

                                                    required: false,
                                                    message: '请输入其他关系联系电话！',
                                                }
                                            ]
                                        })(<Input placeholder='其他关系联系电话' disabled={this.props.detailFlag}/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align='middle'>
                            <Col span={1} offset={10}>
                                <Button type="primary" icon="form" htmlType="submit" loading={this.state.loading} hidden={this.props.detailFlag}>保存修改</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        );
    }
}

UpdateStaffUser = Form.create()(UpdateStaffUser);
export default UpdateStaffUser;