
import {http} from '../utils/http'

export function queryUserParam(data){
    return http.post("/sys/dictList",data);
}

export function queryUserList(data){
    return http.post("/api/enUser/query",data);
}

export function saveUser(data){
    return http.post("/api/enUser/create",data);
}

export function updateUserInfo(data){
    return http.post("/api/enUser/editEnUser",data);
}

export function updateLoginPwd(data){
    return http.post("/api/index/editUserPwd",data);
}

export function deleteUser(data){
    return http.post("/api/enUser/deleteEnUser",data);
}

export function resetLoginPwd(data){
    return http.post("/api/enUser/restPwdUsr",data);
}

export function queryRoleList(data){
    return http.post("/api/enUser/selectRoleByEid",data);
}

export function queryUserDetail(data){
    return http.post("/user/queryUserDetail",data);
}

export function listTpwd(data){
    return http.post("/api/enUser/listTpwd",data);
}

export function setTransPwd(data){
    return http.post("/api/enUser/setTransPwd",data);
}

export function editTransPwd(data){
    return http.post("/api/enUser/editTransPwd",data);
}

export function querySimplerRoleList(data){
    return http.post("/api/enUser/querySimplerRoleList",data);
}

