import React, { Component } from 'react';
import { Icon } from 'antd'
import { baseHttpUrl } from '@/assets/settings';
import axios from 'axios';

class CreateCorePayDetailDownload extends Component {
    constructor(props){
        super(props);
    }

    downloadTemp(){
        let params = {
            enterpriseId: this.props.record.enterpriseId,
            batchNo : this.props.record.id,
            detailType : 'P',
        }
        axios({
            method: 'GET',
            url: baseHttpUrl+'/supplierManage/downloadSupSalaryBatchDetail',
            params: params,
            responseType: 'blob'
        }).then(function(res) {
            const content = res.data
            const blob = new Blob([content],{type: "application/x-msdownload"})
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = res.headers['filename'];
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })
    }
    render() {
        return (
            <span style={{ display: 'inline-flex' }} >
            {
                this.props.disabled ? <span className="span-click-disabled" ><Icon type="download"/></span> 
                    : <span className="span-click" onClick={this.downloadTemp.bind(this)}><Icon type="download"/></span>
            }
            </span>
        );
    }
}

export default CreateCorePayDetailDownload;