import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd'
import classNames from 'classnames';
import Full from './Full'
import styles from './index.m.less'
import Container from './Container'

const index = ({title, width, className, children, visible, onClose, restProps}) => {
    return (
        <Drawer
                width={width || 400}
                className={classNames(className, styles['drawer-wrapper'])}
                mask={false}
                destroyOnClose={true}
                title={title}
                visible={visible}
                onClose={onClose}
                {...restProps}
                >
                    {children}
                </Drawer>
    );
};


index.propTypes = {
    title : PropTypes.string,
    width : PropTypes.oneOfType(
        [PropTypes.string,PropTypes.number]
    ),
    visible : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
};

index.FullDrawer = Full;
index.ContainerDrawer = Container;

export default index;
