import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { Layout, Row, Col, Button, Icon, Menu, Card, Affix, Dropdown, Message, Popover, Select, Tooltip } from 'antd'
import 'assets/css/home.less'
import 'assets/css/simplerHome.less'
import SimplerSiderMenu from '../components/Home/SimplerSiderMenu'
import SiderMenu from '../components/Home/SiderMenu'
import HomeRoutes from '../routes/HomeRoutes'
import HomeCrumb from '../components/Home/HomeCrumb'
import HomeMessage from '../components/Home/HomeMessage'
import ForceChangePassword from 'coms/Home/ForceChangePassword'
import auth from 'assets/img/auth.png'
import logo from 'assets/img/header-logo.png'
import return1 from 'assets/img/return1.png'
import return2 from 'assets/img/return2.png'
import xiala from 'assets/img/xiala.png'
import users from 'assets/img/user.png'
import message from 'assets/img/message.png'
import SimplerHomeMessage from '../components/Home/SimplerHomeMessage';
import { getUserInfo, updateLoginBusiness } from '../api/login';

const { Option } = Select;
const { Sider, Header, Content, Footer } = Layout;

Message.config({
    duration: 5,
    maxCount: 1,
});



class Home extends Component {


    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            current: '',
            username: '',
            RoutesMenu: [],
            RoutesLink: [],
            changePassVisible: false,
            changePassClosable: false,
            businessName: '',
            businessCode: '',
            businessList: [],
        }
    }

    getRoutesLink(items, crumbArr = []) {
        items.map(item => {
            let crumbArrTemp = [];
            crumbArrTemp.push(...crumbArr);
            crumbArrTemp.push(item.name);
            if (item.children) {
                this.getRoutesLink(item.children, crumbArrTemp);
            } else {
                item.crumbs = crumbArrTemp;
                this.state.RoutesLink.push(item);
            }
            return true;
        })
        this.setState({
            RoutesLink :  this.state.RoutesLink
        })

    }

    componentWillMount() {
        window._ROUTER_ = this.props.history;
        const sessionUser = JSON.parse(global._sessionStorage.getItem("user"));
        if (sessionUser !== null) {
            // let loginParam = { }
            // getUserInfo(loginParam).then(res => {
            //     let { errorCode, data } = res.data;
            //     if (errorCode === "0000") {
            //         global._sessionStorage.setItem('userHeadId', data.userHeadId);
            //         // global._sessionStorage.setItem('loginBusiness', data.loginBusiness);
            //         global._sessionStorage.setItem('businessList', JSON.stringify(data.businessList));
            //         global._sessionStorage.setItem('loginInfoMap', JSON.stringify(data.loginInfoMap));
            //         let loginBusiness = global._sessionStorage.getItem("loginBusiness");
            //         if (data.loginInfoMap[loginBusiness]) {
            //             let loginInfo = data.loginInfoMap[loginBusiness];
            //             loginParam.enterpriseid = loginBusiness;
            //             loginParam.userId = loginInfo.userId;
            //             loginParam.username = sessionUser.mobile;
            //             loginParam.isChangePass = data.isChangePass;
            //             loginParam.lastLoginTime = data.lastLoginTime;
            //             // loginParam.personalSign = loginInfo.personalSign;
            //             // loginParam.companySign = loginInfo.companySign;
            //             loginParam.companyname = loginInfo.companyname;
            //             loginParam.bankCode = loginInfo.bankCode;
            //             loginParam.versions = loginInfo.versions;
            //             global._sessionStorage.setItem('user', JSON.stringify(loginParam));
            //             global._sessionStorage.setItem('businessEncrypt',loginInfo.businessEncrypt);
            //             global._sessionStorage.setItem('menuList', JSON.stringify(loginInfo.menuTrees));
            //             global._sessionStorage.setItem('menuSimplerPay', JSON.stringify(loginInfo.menuSimplerPay));
            //             global._sessionStorage.setItem('menuSimplerFac', JSON.stringify(loginInfo.menuSimplerFac));
            //             global._sessionStorage.setItem('menuSimplerSet', JSON.stringify(loginInfo.menuSimplerSet));

                        let user = sessionUser;
                        let menuList = JSON.parse(global._sessionStorage.getItem("menuList"));
                        
                        let type = JSON.parse(global._sessionStorage.getItem("type"));
                        let menuSimplerPay = JSON.parse(global._sessionStorage.getItem("menuSimplerPay"));
                        let menuSimplerFac = JSON.parse(global._sessionStorage.getItem("menuSimplerFac"));
                        let menuSimplerSet = JSON.parse(global._sessionStorage.getItem("menuSimplerSet"));
                        let menuSimplerNoCardFac = JSON.parse(global._sessionStorage.getItem("menuSimplerNoCardFac"));
                        if (user.versions === '1') {
                            this.setState({
                                username: user.username,
                                RoutesMenu: menuList,
                                businessName: user.companyname
                            });
                            this.getRoutesLink(menuList);
                        } else {
                            if (type === '0') {
                                this.setState({
                                    username: user.username,
                                    RoutesMenu: menuSimplerPay,
                                    businessName: user.companyname
                                });
                                this.getRoutesLink(menuSimplerPay);
                            } else if (type === '1') {
                                this.setState({
                                    username: user.username,
                                    RoutesMenu: menuSimplerFac,
                                    businessName: user.companyname
                                });
                                this.getRoutesLink(menuSimplerFac);
                            } else if (type === '2') {
                                this.setState({
                                    username: user.username,
                                    RoutesMenu: menuSimplerSet,
                                    businessName: user.companyname
                                });
                                this.getRoutesLink(menuSimplerSet);
                            } else if (type === '3') {
                                this.setState({
                                    username: user.username,
                                    RoutesMenu: menuSimplerNoCardFac,
                                    businessName: user.companyname
                                });
                                this.getRoutesLink(menuSimplerNoCardFac);
                            }
                        }


                        if (user.isChangePass && user.isChangePass === '00') {
                            this.setState({
                                changePassVisible: true
                            })
                        }
            //         }
            //     }
            // })
        }

        this.setState({
            businessCode: global._sessionStorage.getItem("loginBusiness"),
            businessList: JSON.parse(global._sessionStorage.getItem("businessList")),
        })
    }

    businessChange(value) {
        global._sessionStorage.setItem('loginBusiness', value);
        let loginParam = JSON.parse(global._sessionStorage.getItem("user"));
        let loginInfoMap = JSON.parse(global._sessionStorage.getItem("loginInfoMap"));
        if (loginInfoMap[value]) {
            let loginInfo = loginInfoMap[value]
            loginParam.businessCode = value;
            loginParam.enterpriseid = value;
            loginParam.userId = loginInfo.userId;
            // loginParam.personalSign = loginInfo.personalSign;
            // loginParam.companySign = loginInfo.companySign;
            loginParam.companyname = loginInfo.companyname;
            loginParam.bankCode = loginInfo.bankCode;
            loginParam.versions = loginInfo.versions;
            global._sessionStorage.setItem('user', JSON.stringify(loginParam));
            global._sessionStorage.setItem('businessEncrypt',loginInfo.businessEncrypt);
            global._sessionStorage.setItem('menuList', JSON.stringify(loginInfo.menuTrees));
            global._sessionStorage.setItem('menuSimplerPay', JSON.stringify(loginInfo.menuSimplerPay));
            global._sessionStorage.setItem('menuSimplerFac', JSON.stringify(loginInfo.menuSimplerFac));
            global._sessionStorage.setItem('menuSimplerSet', JSON.stringify(loginInfo.menuSimplerSet));
            global._sessionStorage.setItem('menuSimplerNoCardFac', JSON.stringify(loginInfo.menuSimplerNoCardFac));

            this.setState({
                username: loginParam.username,
                RoutesMenu: loginInfo.menuTrees,
                RoutesLink: [],
                current: '/index'
            }, () => {
                this.getRoutesLink(loginInfo.menuTrees);
                if (loginInfo.versions === '1') {
                    this.props.history.push('/index');
                    this.refs.setOpenKeys.setOpenKeys();
                } else {
                    this.props.history.push('/indexSimpler');
                }
            });
        }

        let lastBusinessParam = {
            mobile: loginParam.username,
            loginBusiness: value,
        }
        updateLoginBusiness(lastBusinessParam).then(res => {

        })

    }

    siderHandleClick(key) {
        this.setState({
            current: key
        });
    }

    toggleCollapsed() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    handleCancel() {
        this.setState({
            changePassVisible: false,
            changePassClosable: false
        });
    }

    userInfo() {
        this.setState({
            changePassVisible: true,
            changePassClosable: true
        });
        return (
            <ForceChangePassword visible={this.state.changePassVisible} closable={this.state.changePassClosable} handleCancel={this.handleCancel.bind(this)}  {...this.props} />
        )
    }

    loginOut(e) {
        global._sessionStorage.removeItem('user');
        global._sessionStorage.removeItem('token');
        global._sessionStorage.removeItem('type');
        global._sessionStorage.removeItem('menuList');
        global._sessionStorage.removeItem('menuSimplerFac');
        global._sessionStorage.removeItem('menuSimplerPay');
        global._sessionStorage.removeItem('menuSimplerSet');
        global._sessionStorage.removeItem('menuSimplerNoCardFac');
        this.props.history.push('/login');
    }

    toIndex() {
        global._sessionStorage.removeItem('type');
        this.props.history.push('/indexSimpler');
    }

    render() {
        let user = global._sessionStorage.getItem("user");
        if (!user || "/login" === this.props.location.pathname) {
            global._sessionStorage.removeItem("user");
            global._sessionStorage.removeItem("token");
            global._sessionStorage.removeItem('type');
            global._sessionStorage.removeItem('menuList');
            global._sessionStorage.removeItem('menuSimplerFac');
            global._sessionStorage.removeItem('menuSimplerPay');
            global._sessionStorage.removeItem('menuSimplerSet');
            global._sessionStorage.removeItem('menuSimplerNoCardFac');
            return <Redirect to="/login" />
        }
        const user1 = JSON.parse(global._sessionStorage.getItem("user"));
        let type = JSON.parse(global._sessionStorage.getItem("type"));
        if ("/" === this.props.location.pathname) {
            if (user1 && user1.versions === '1') {
                return <Redirect to="/index" />
            } else {
                return <Redirect to="/indexSimpler" />
            }
        }
        var userMenu;
        if (user1 && user1.versions === '1' || type==='0' || type==='3') {
            userMenu = (
                <Menu style={{ marginTop: '3px', marginLeft: '5%', width: '120px' }}>
                    <Menu.Item onClick={this.userInfo.bind(this)} key="passmodify"> <span><Icon style={{ marginRight: 10 }} type="setting" />修改密码</span> </Menu.Item>
                    <Menu.Item onClick={this.loginOut.bind(this)} key="login"> <span><Icon style={{ marginRight: 10 }} type="logout" />退出 </span> </Menu.Item>
                </Menu>
            );
        } else {
            userMenu = (
                <Menu style={{ marginTop: '3px', marginLeft: '15%', width: '150px' }} >
                    <Menu.Item key="user"> <span><Icon style={{ marginRight: 10 }} type="user" />{this.state.username}</span> </Menu.Item>
                    <Menu.Item onClick={this.userInfo.bind(this)} key="passmodify"> <span><Icon style={{ marginRight: 10 }} type="setting" />修改密码</span> </Menu.Item>
                    <Menu.Item onClick={this.loginOut.bind(this)} key="login"> <span><Icon style={{ marginRight: 10 }} type="logout" />退出 </span> </Menu.Item>
                </Menu>
            );
        }

        const businessListOptions = this.state.businessList.map((type, index)  => {
            return <Option key={index} value={type.id} >{type.companyname}</Option>
        })

        return (
            <span>
                {user1 && user1.versions === '1'|| type==='0' || type==='3' ?
                    <Layout className="container-sider" >
                        <div style={{ position: 'fixed', height: '100%', zIndex: '99' }}>
                            <Sider
                                trigger={null}
                                collapsible
                                collapsed={this.state.collapsed}
                                style={{ height: '100%' }}
                            >
                                <div className="logo" style={this.state.collapsed ? {} : { width: '200px' }} ></div>
                                <SiderMenu current={this.state.current} collapsed={this.state.collapsed} routesMenu={this.state.RoutesMenu}
                                    siderClick={this.siderHandleClick.bind(this)} ref="setOpenKeys" />
                            </Sider>
                        </div>
                        <Layout style={!this.state.collapsed ? { paddingLeft: '200px' } : { paddingLeft: '80px' }} >
                        <Affix offsetTop={0} style={{ zIndex: 2000 }} >
                            <Header className="container-header">
                                <Row type="flex" justify="space-around" align="middle">
                                    {type==='0' || type==='3' ? 
                                    <Col lg={19} xxl={20} >
                                        <Button type="primary" onClick={this.toggleCollapsed.bind(this)}
                                            style={{ marginLeft: 5 }}>
                                            <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
                                        </Button>
                                        <Select style={{ marginLeft: '20px',minWidth: '10%', maxWidth: '40%' }} defaultValue={this.state.businessCode}
                                            onChange={this.businessChange.bind(this)} className='businessChange'>
                                            {businessListOptions}
                                        </Select>
                                        <img style={{ marginLeft: '10px', height: '15px' }} src={auth} alt="已认证" />
                                    </Col> 
                                    :
                                    <Col lg={19} xxl={21} >
                                        <Button type="primary" onClick={this.toggleCollapsed.bind(this)}
                                            style={{ marginLeft: 5 }}>
                                            <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
                                        </Button>
                                        <Select style={{ marginLeft: '20px',minWidth: '10%', maxWidth: '40%' }} defaultValue={this.state.businessCode}
                                            onChange={this.businessChange.bind(this)} className='businessChange'>
                                            {businessListOptions}
                                        </Select>
                                        <img style={{ marginLeft: '10px', height: '15px' }} src={auth} alt="已认证" />
                                    </Col> 
                                    }
                                    <Col span={1}  >
                                        <div style={{ cursor: 'pointer' }}>
                                            <HomeMessage />
                                        </div>
                                    </Col>
                                    {type==='0' || type==='3' ? <Col lg={4} xxl={3} >
                                        <Dropdown overlay={userMenu} placement="bottomCenter" >
                                            <div style={{ height: '50px', cursor: 'pointer',display: 'inline-block' }} >
                                                <span className="ant-dropdown-link" ><Icon style={{ marginRight: 10 }} type="user" />
                                                    {this.state.username}<Icon type="down" style={{ marginLeft: 10 }} /> </span>
                                            </div>
                                        </Dropdown>
                                        <Popover content='返回首页' placement="bottom">
                                            <div style={{ display: 'inline-block', position: 'absolute' }}>
                                                <img style={{ cursor: 'pointer', marginLeft: '10px' }} src={return2} onClick={this.toIndex.bind(this)} className="returnpng" />
                                            </div>
                                        </Popover>
                                    </Col>
                                    :
                                    <Col lg={4} xxl={2} >
                                        <Dropdown overlay={userMenu} placement="bottomCenter" >
                                            <div style={{ height: '50px', width: '100%', cursor: 'pointer'}} >
                                                <span className="ant-dropdown-link" ><Icon style={{ marginRight: 10 }} type="user" />
                                                    {this.state.username}<Icon type="down" style={{ marginLeft: 10 }} /> </span>
                                            </div>
                                        </Dropdown>
                                    </Col>}
                                </Row>
                            </Header>
                            </Affix>
                            <Content>
                                <Card bordered size="small" >
                                    <HomeCrumb RoutesLink={this.state.RoutesLink} currentPath={this.props.location.pathname} />
                                </Card>
                                <Card bordered={false} className="container-content" >
                                    <HomeRoutes RoutesLink={this.state.RoutesLink} />
                                </Card>

                            </Content>
                            <Footer style={{ textAlign: 'center' }}>
                                薪企链平台版权 © Created by 薪海科技
                        </Footer>
                            <ForceChangePassword visible={this.state.changePassVisible} closable={this.state.changePassClosable} handleCancel={this.handleCancel.bind(this)} {...this.props} />
                        </Layout>
                    </Layout>
                    :
                    <Layout >
                        <Affix offsetTop={0} style={{ zIndex: 2000 }} >
                            <Header className="simplerpay-header" >
                                <div>
                                    <Row type="flex" justify="space-around" align="middle">
                                        <Col xs={4} sm={4} lg={3} xxl={3} >
                                            <div className="simple-logo">
                                            </div>
                                        </Col>
                                        <Col xs={14} sm={14} lg={16} xxl={16} style={{ textAlign: 'center', marginTop: '13px' }}>
                                            <div style={{ width: '100%' }}>
                                                <SimplerSiderMenu current={this.state.currrent} collapsed={this.state.collapsed} routesMenu={this.state.RoutesMenu}
                                                    siderClick={this.siderHandleClick.bind(this)} history={this.props.history} />
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={6} lg={5} xxl={5} style={{ height: 85 }} >
                                            <div style={{ float: 'right', marginRight: '20px' }}>
                                                <div style={{ display: 'inline-block', cursor: 'pointer', marginRight: '10px', float: 'left' }}>
                                                    <SimplerHomeMessage history={this.props.history} />
                                                </div>
                                                <Dropdown overlay={userMenu} placement="bottomCenter" >
                                                    <div style={{ display: 'inline-block', width: 120, height: 80 }}>
                                                        <span className="ant-dropdown-link" style={{ color: '#fff', cursor: 'pointer' }}><img style={{ marginRight: 10, marginLeft: '5%' }} src={users} />
                                                            {this.state.username.length <= 5 ? this.state.username : this.state.username.substring(0, 5) + '...'
                                                            }<img style={{ marginLeft: 10 }} src={xiala} /> </span>
                                                    </div>
                                                </Dropdown>
                                                <Popover content='返回首页' placement="bottom">
                                                    <div style={{ display: 'inline-block', position: 'absolute' }}>
                                                        <img style={{ cursor: 'pointer', marginLeft: '10px' }} src={return1} onClick={this.toIndex.bind(this)} className="returnpng" />
                                                    </div>

                                                </Popover>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Header>
                        </Affix>
                        <Content>
                            <Card bordered={false} className="simplerpay-content" >
                                <HomeRoutes RoutesLink={this.state.RoutesLink} />
                            </Card>

                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                            薪企链平台版权 © Created by 薪海科技
                    </Footer>

                        <ForceChangePassword visible={this.state.changePassVisible} closable={this.state.changePassClosable} handleCancel={this.handleCancel.bind(this)} {...this.props} />
                    </Layout>
                }
            </span>
        );
    }
}

export default Home;