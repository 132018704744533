import React, { Component } from 'react';
import { Modal, Icon ,Button} from 'antd'
import CreateSupplierPayRollDetail from '@/components/supplierManage/SupplierPayRoll/CreateSupplierPayRollDetail'

class CreateSupplierPayRollBatch extends Component {

    constructor(){
        super();
        this.state={
            visiable : false,
        }
    }

    handlerCancel = () => {
        this.setState({
            visiable : false,
        })
    }

    handlerModalShow() {
        this.setState({
            visiable: true
        })
    }

    render() {
        const title = <span>发放单明细</span>;
        return (
            <div>
                <span className="span-click" onClick={this.handlerModalShow.bind(this)}>{this.props.record.salarybatchcode}</span>
                <Modal
                destroyOnClose={true}
                visible={this.state.visiable}
                title={title}
                onCancel={this.handlerCancel.bind(this)}
                footer={null}
                width={'80%'}
                >
                   <CreateSupplierPayRollDetail  
                        refreshSubmit={this.props.refreshSubmit} 
                        handlerCancel={this.handlerCancel.bind(this)} 
                        record={this.props.record}
                    /> 
                    {/* <Button type="primary" style={{marginLeft : '45%', marginTop : '20px'}} onClick={this.handlerCancel.bind(this)} >关闭</Button> */}
                    
                </Modal>
                
            </div>
            
        );
    }
}

export default CreateSupplierPayRollBatch;