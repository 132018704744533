/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{Fragment} from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import { Form, Input, Card, Row, Col, Button, Icon, Select, DatePicker, Switch,Tooltip } from 'antd'
import styles from './searchForm.m.less'

const FormItem = Form.Item
const { Option } = Select;
const { MonthPicker,RangePicker } = DatePicker;

const renderOptions = (options) => {
    return options.map((option, index) => <Option key={index} value={option.value}>{option.label}</Option>)
}

renderOptions.propTypes = {
    options : PropTypes.array.isRequired,
}

const getFormItem = (item, getFieldDecorator) => {
    const props = {
        allowClear: true,
        ...item.restProps,
        autoComplete : 'off',
    }
    switch(item.type){
        case "input":   return getFieldDecorator(item.id, {...item.attr})(<Input {...props}  placeholder={item.placeholder && item.placeholder} />);
        case "select":  return getFieldDecorator(item.id, {...item.attr})(<Select {...props} style={{ width: '100%' }} >{renderOptions(item.options)}</Select>); 
        case "date-picker":  return getFieldDecorator(item.id, {...item.attr})(<DatePicker {...item.restProps} style={{ width: '100%' }} placeholder={item.placeholder && item.placeholder} />);
        case "range-picker":  return getFieldDecorator(item.id, {...item.attr})(<RangePicker {...item.restProps} style={{ width: '100%' }} placeholder={item.placeholder && item.placeholder} />);
        case "month-picker":  return getFieldDecorator(item.id, {...item.attr})(<MonthPicker {...item.restProps} style={{ width: '100%' }} placeholder={item.placeholder && item.placeholder} />); 
        case "switch":  return getFieldDecorator(item.id, {...item.attr}, { valuePropName: 'checked' })(<Switch {...item.restProps} />);
        
        default:return null;
   }
}

const renderFormCol = ({
    onSubmit,
    resetSubmit,
    search,
    getFieldDecorator,
    resetFields,
    okText,
    restText,
    expand,
    onExpand,
    loading,
    col,
    restFlag
}) => {
    let list = [];
    if(expand){
        list = search;
    }else{
        list = search.filter(item => !item.hidden)
    }
    const num = parseInt(24 / col);
    let dataSource = []
    let data = [];
    list.forEach((item, index) => {
        data.push(item);
        if((index + 1) % num === 0){
            dataSource.push(data);
            data = []
        }
    })
    data.push('button')
    if(data.length !== 0){
        dataSource.push(data);
    }
    return dataSource.map((item, index) => {
        return  <Row key={index} gutter={{ md: 8, lg: 24, xl: 48 }}>
                {
                    item.map((it, key) => {
                        if(it === 'button'){
                            return renderFormBtn({
                                onSubmit,
                                resetSubmit,
                                key,
                                search,
                                resetFields,
                                okText,
                                restText,
                                expand,
                                onExpand,
                                loading,
                                col,
                                restFlag
                            })
                        }else{
                            return <Col key={key} span={col}>
                                        <FormItem label={it.label} >
                                            {getFormItem(it, getFieldDecorator)}
                                        </FormItem>
                                    </Col>
                        }
                    })
                }
                </Row>
        });
}

const renderFormBtn = ({
    onSubmit,
    resetSubmit,
    key,
    search,
    resetFields,
    okText,
    restText,
    expand,
    onExpand,
    col,
    restFlag
}) => {
    const hidden = search.filter(item => item.hidden).length !== 0
    return  <Col key={key} span={col}>
                <div style={{ marginBottom: 24 }}>
                    <Button type="primary" htmlType="submit" >
                    {okText}
                    </Button>
                    <Button style={{ marginLeft: 8 }} onClick={() => {resetFields();if(restFlag){resetSubmit()}}} >
                    {restText}
                    </Button>
                    {
                        hidden && <a style={{ marginLeft: 8 }} onClick={ () => onExpand && onExpand()} >
                            {
                                !expand ? <span>展开 <Icon type="down" /></span> : <span>收起 <Icon type="up" /></span> 
                            }
                    </a>
                    }
                </div>
            </Col>
}

const SearchForm = ({
    search,
    form: { resetFields, getFieldDecorator },
    xxlCol =4,
    lgCol =3,  
    okText = '查询',
    restText = '重置',
    expand = false,
    onExpand,
    onSubmit,
    resetSubmit,
    loading,
    restFlag = false,
}) => {

    return (
        <Card bordered={false} bodyStyle={{padding : '24px 24px 10px 24px'}} className={styles.searchForm} >
            <Form onSubmit={onSubmit} layout="inline"  >
                <Media queries={{
                    mini: "(max-width: 767px)",
                    small: "(min-width: 768px) and (max-width: 991px)",
                    medium: "(min-width: 992px) and (max-width: 1599px)",
                    large: "(min-width: 1600px)"
                }}>
                    {matches => { 
                        let col = 24;
                        if(matches.mini){
                            col =24;
                        }else if(matches.small){
                            col = 12;
                        }else if(matches.large){
                            col = xxlCol ? parseInt(24 / xxlCol) : 6;
                        }else{
                            col = lgCol ? parseInt(24 / lgCol) : 8;
                        }
                        return (
                        <Fragment>
                            {renderFormCol({
                                onSubmit,
                                resetSubmit,
                                search,
                                getFieldDecorator,
                                resetFields, 
                                okText,
                                restText,
                                expand,
                                onExpand,
                                loading,
                                col,
                                restFlag
                            })} 
                        </Fragment>
                    )}}
                </Media>
                {getFieldDecorator('dataTypeGroupValue_XINHAI', {
                        })(
                            <Input hidden={true} />
                )}
            </Form>
        </Card>
    );
};

SearchForm.propTypes = {
    search : PropTypes.arrayOf(PropTypes.shape({
        id : PropTypes.string.isRequired,
        label : PropTypes.string.isRequired,
        type : PropTypes.string.isRequired,
        options : function(props, propName, componentName) {
                if (props.type==='select' && !(props[propName] instanceof Array)) {
                    return new Error(
                        'Invalid prop `' + propName + '` is required' +
                        ' `' + componentName + '`, expected `array`.'
                    );
                }
            },
        col : PropTypes.number,
        rule : PropTypes.array,
    })).isRequired,
    expand : PropTypes.bool,
    xxlCol : PropTypes.oneOf([2,3,4]),
    lgCol : PropTypes.oneOf([2,3]),
    okText : PropTypes.string,
    restText : PropTypes.string,
    onExpand : PropTypes.func,
    submit : PropTypes.func.isRequired,
};

export default SearchForm;