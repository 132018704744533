import React, { Component } from 'react';
import { Message } from 'antd'
import StaffInviteForm from '../../components/Staff/StaffInviteForm';
import StaffInviteResult from '../../components/Staff/StaffInviteResult';
import { queryInvites, selectDept, inviteList } from '../../api/staff';

class simplerStaffInviteManage extends Component {
    constructor() {
        super();
        this.state = {
            user: JSON.parse(global._sessionStorage.getItem("user")),
            totalNum: '',
            pageSizeOptions: global.pageInfo.sizeOptions, 
            currentPage: '1',
            pageSize: '10',
            loading: false,
            resultLoad: false,
            searchParam: {},
            sourceData: [],
            department: [],
            buttonFlag: true,
            selectRows: [],
            selectFlowKeys: [],
            commitloading: false,
            showCommitFlag: false,
        }
    }

    currentPageChange(currentPage) {
        let params = this.state.searchParam;
        params.pageCurrent = currentPage;
        this.setState({
            currentPage: currentPage,
            searchParam: params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize) {
        let params = this.state.searchParam;
        params.pageCurrent = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage: '1',
            pageSize,
            searchParam: params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params) {
        params.pageCurrent = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam: params
        })
        this.searchPost(params);
    }

    searchPost(params) {
        params.eid = this.state.user.enterpriseid;
        this.setState({
            resultLoad: true,
            selectRows: [],
            selectFlowKeys: [],
        })
        queryInvites(params).then(res => {
            this.setState({
                sourceData: res.data.list,
                totalNum: res.data.totalRow,
                resultLoad: false,
                buttonFlag: true,
            })
        }).catch(err => {
            Message.error("服务异常");
            this.setState({
                resultLoad: false,
                buttonFlag: true,
            })
        })
    }

    refreshSubmit() {
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    resetForm() {
        this.setState({
            searchParam: {}
        })
    }

    getDepatmentList() {
        let params = {
            eid: this.state.user.enterpriseid
        }
        selectDept(params).then(res => {
            this.setState({
                department: res.data,
            })
        })

    }



    getSelectRows(selectRows) {
        if (selectRows.length > 0) {
            this.setState({
                buttonFlag: false
            })
        } else {
            this.setState({
                buttonFlag: true
            })
        }
        this.setState({
            selectRows: selectRows,
        })
    }

    getSelectFlowKeys(selectKeys) {

        this.setState({
            selectFlowKeys: selectKeys,
        })


    }


    cancelCommit() {
        this.setState({
            showCommitFlag: false
        })
    }

    showCommit() {
        this.setState({
            showCommitFlag: true
        })

    }


    commit() {
        let params = {
            eid: this.state.user.enterpriseid,
            list: this.state.selectRows,
        }
        this.setState({
            commitloading: true,
        })

        inviteList(params).then(res => {

            // let {errCode, errMsg} = res.data;

            if (res.data.code === "200") {
                Message.success("提交成功");
            } else {
                Message.error(res.data.msg);
            }
            this.setState({
                commitloading: false,
                buttonFlag: true

            })
            if (this.props.currentFlag === 0) {
                this.props.history.push({ pathname: "/simplerPay", state: { current: 1 } });
            } else if (this.props.currentFlag === 1) {
                this.props.history.push({ pathname: "/simplerPayUser", state: { current: 2 } });
            } else if (this.props.currentFlag === 2) {
                this.props.history.push({ pathname: "/simplerFacUser", state: { current: 2 } });
            }
            // this.searchFormSubmit({});
        }).catch(err => {
            Message.error("服务异常");
            this.setState({
                commitloading: false
            })
            this.props.history.push('/staffInvite');
            //  this.searchFormSubmit({});
        })

    }


    componentWillMount() {
        this.getDepatmentList();
        this.searchFormSubmit({});
    }

    render() {
        return (
            <div>
                <StaffInviteForm loading={this.state.loading} searchFormSubmit={this.searchFormSubmit.bind(this)}
                    resetForm={this.resetForm.bind(this)} department={this.state.department} refreshSubmit={this.refreshSubmit.bind(this)} />
                <StaffInviteResult loading={this.state.resultLoad} resultData={this.state.sourceData} totalNum={this.state.totalNum} refreshSubmit={this.refreshSubmit.bind(this)}
                    currentPageChange={this.currentPageChange.bind(this)} pageSizeChange={this.pageSizeChange.bind(this)} currentPage={this.state.currentPage}
                    selectRows={this.state.selectRows} selectFlowKeys={this.state.selectFlowKeys}
                    getSelectRows={(selectRows) => { this.getSelectRows(selectRows) }}
                    getSelectKeys={(selectKeys) => { this.getSelectFlowKeys(selectKeys) }}
                    commit={this.commit.bind(this)} buttonFlag={this.state.buttonFlag} commitloading={this.state.commitloading}
                    showCommit={this.showCommit.bind(this)} showCommitFlag={this.state.showCommitFlag}
                    cancelCommit={this.cancelCommit.bind(this)} selectRows={this.state.selectRows} 
                    pageSize={this.state.pageSize} pageSizeOptions={this.state.pageSizeOptions}/>
            </div>
        );
    }
}

export default simplerStaffInviteManage;