import React, { Component } from 'react';
import { Message,Spin,Icon } from 'antd'
import ConfirmPrpover from '../Common/ConfirmPrpover'
import { deleteSalaryTmp } from '../../api/salaryTemp';

class DeleteTemp extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem("user")).enterpriseid,
            loading : false
        }
    }
    deleteTemp() {
        this.setState({
            loading : true
        })
        let params = {
            id: this.props.record.id,
            bustype: this.props.record.bustype,
            enterpriseid: this.state.enterpriseid
        }
        deleteSalaryTmp(params).then(res => {
            let { statusCode, message } = res.data;
            if ("200" === statusCode) {
                Message.success(message);
                this.props.refreshSubmit();
            } else {
                Message.error(message);
            }
            this.setState({
                loading : false
            })
        });
    }
    render() {
        const loadIcon = <Icon type="loading" spin />;
        const htmlText = <span className="span-click">删除</span>
        return (
            <span style={{ display: 'inline-flex' }} >
                <Spin size="small" indicator={loadIcon} spinning={this.state.loading}>
                    <ConfirmPrpover record={this.props.record} placement="topRight" title="确认删除" content="确认删除该模板？"
                        confirmText="确定" cancelText="取消" htmlText={htmlText} confirmSubmit={this.deleteTemp.bind(this)} />
                </Spin>
            </span>
        );
    }
}

export default DeleteTemp;