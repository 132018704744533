import React, { Component } from 'react';
import { Table,Message,Tabs,Col,Button} from 'antd'
import { uploadSalaryQuery,salaryBatchQuery,submitSalaryBatch } from 'api/weeksalary'
import CreatePayRollDetailResult from './CreatePayRollDetailResult'
const { TabPane } = Tabs;

class CreatePayRollDetail extends Component {

    constructor(){
        super();
        this.state = {
            loading : false,
            tabPaneKey : 'P',
            searchParam : {},
            sourceData : [],
            totalNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            submitbutton :'',
            salarydate :'',
            employeenum :'',
            cyclecate:'',
            batchid:''
        }
    }

    batchQuery(params){

        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        salaryBatchQuery(params).then(res =>{
            this.setState({
                submitbutton : res.data.submitbutton,
                salarydate :res.data.salarydate,
                employeenum :res.data.employeenum

            });
        })
    }

    submitSalaryData(){
        let params = {};
        params.id = this.state.batchid;
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        params.phone=user.username;
        submitSalaryBatch(params).then(res =>{
            if(res.data.result=="true"){
                if(this.state.cyclecate=="month"){
                    this.props.history.push('/createPayRollTabs');
                }else{
                    this.props.history.push('/weeksalary');
                }
                
            }else{
                Message.error(res.data.message);
            }
        })
    }
    componentDidMount(){
        let params = this.state.searchParam;
        params.id = this.props.location.state.batchid;
        this.setState({
            searchParam : params,
            cyclecate : this.props.location.state.cyclecate,
            batchid :this.props.location.state.batchid
        });
        this.batchQuery(params);
        // this.searchFormSubmit(params);
    }
    tabsChange= (e) =>{
        this.setState({
            tabPaneKey : e,
        })
    }

    render() {

        const _this = this;
        const pagination ={
            current : parseInt(this.state.currentPage),
            total: this.state.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : this.state.pageSizeOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.currentPageChange(current);
            }
        }


        const columns = this.state.columns;
        
        const operations = <Button type="primary" disabled={this.state.submitbutton != 'true'} onClick={_this.submitSalaryData.bind(this)}>提交数据</Button>;
        return (
            <div>
                <div style={{ marginBottom: 16 }}>
                    <b>发放单详情-></b> <label><b style={{marginRight:16,marginLeft:16}}>{this.state.salarydate}</b>
                    已上传：<span>{this.state.employeenum}</span>条记录</label>
                </div>
                <Tabs tabBarExtraContent={operations} defaultActiveKey={this.state.tabPaneKey} activeKey={this.state.tabPaneKey} onChange={this.tabsChange.bind(this)}>
                     {this.state.cyclecate=="week"?  
                        <TabPane tab="对私发放单明细" key="P">
                            <CreatePayRollDetailResult orderNo={this.props.location.state.batchid} bustype="P"/>
                        </TabPane>
                    :""}
                    {this.state.cyclecate=="month"?   
                        <TabPane tab="对私发放单明细" key="P">
                            <CreatePayRollDetailResult orderNo={this.props.location.state.batchid} bustype="P"/>
                        </TabPane>
                     :""}
                    {this.state.cyclecate=="month"?
                    <TabPane tab="对公发放单明细" key="C">
                         <CreatePayRollDetailResult orderNo={this.props.location.state.batchid} bustype="C"/>
                    </TabPane>
                    :""}
                    
                    
                </Tabs>
            </div>
        );
    }
}

export default CreatePayRollDetail;