import React, { Component } from 'react';
import { Table, Alert, Modal, Tag, Message, Divider } from 'antd'
import { formatDate } from 'utils/Date'
import StaffAddBatchQueryDetail from './StaffAddBatchQueryDetail';
import StaffAddBatchQueryDownload from './StaffAddBatchQueryDownload';

class StaffAddBatchQueryResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
        }
    }

    render() {
        const _this = this;
        const columns = [
            {
                title: '文件名', dataIndex: 'fileName', key: 'fileName', align: 'center'
            },
            {
                title: '批次号', dataIndex: 'batchId', key: 'batchId', align: 'center'
            },
            {
                title: '总条数', dataIndex: 'totalNum', key: 'totalNum', align: 'center'
            },
            {
                title: '成功条数', dataIndex: 'successNum', key: 'successNum', align: 'center'
            },
            {
                title: '失败条数', dataIndex: 'failNum', key: 'failNum', align: 'center'
            },
            {
                title: '文件大小', dataIndex: 'size', key: 'size', align: 'center'
            },
            {
                title: '导入时间', dataIndex: 'createTime', key: 'createTime', align: 'center',
                render(val) {
                    return formatDate(new Date(val), 'yyyy/MM/dd hh:mm:ss');
                }
            },
            {
                title: '导入状态', dataIndex: 'state', key: 'state', align: 'center',
                render(val) {
                    if (val === '0') {
                        return "成功";
                    }
                    if (val === '1') {
                        return "失败";
                    }
                    if (val === '2') {
                        return "导入中";
                    }
                    if (val === '3') {
                        return "部分成功";
                    }
                    return "";
                }
            },
            {
                title: '导入失败原因', dataIndex: 'failReason', key: 'failReason', align: 'center'
            },
            {
                title: '操作', align: 'center',
                render(text, record, index) {
                    return (
                        <div>
                            <StaffAddBatchQueryDetail record={record} disabled={record.totalNum === 0}/>
                            <Divider type="vertical" />
                            <StaffAddBatchQueryDownload record={record} disabled={record.totalNum === 0} refreshSubmit={_this.props.refreshSubmit} />
                        </div>
                    )
                }
            },
        ]
        const pagination = {
            current: parseInt(this.props.currentPage),
            total: this.props.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.props.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys: selectedRowKeys,
                })
                if (selectedRowKeys.length === 0) {
                    this.setState({
                        hiddenDeleteButton: true,
                    })
                } else {
                    this.setState({
                        hiddenDeleteButton: false,
                    })
                }
            },
            getCheckboxProps: record => ({
                disabled: !(record.state === '0'), // Column configuration not to be checked
                name: record.policyId,
            }),
        };
        return (
            <div style={{ marginTop: '20px' }}>
                <Alert style={{ marginTop: '10px' }} message={(
                    <span>查询结果</span>
                )} type="info" showIcon />
                <Table style={{ marginTop: '20px' }}
                    rowKey={record => record.id}
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData}
                    // rowSelection={rowSelection}
                    columns={columns}
                    bordered
                    pagination={pagination}
                >
                </Table>

            </div>
        );
    }
}

export default StaffAddBatchQueryResult;