import React, { Component } from 'react';
import { Message } from 'antd'
import SupplierStaffForm from '@/components/supplierManage/SupplierStaffForm';
import SupplierStaffResult from '@/components/supplierManage/SupplierStaffResult';
import { queryUser } from '@/api/userRelatedEnterpise';
import axios from 'axios';
import { baseHttpUrl } from '@/assets/settings';

class supplierStaffQuery extends Component {
    constructor() {
        super();
        this.state = {
            user: JSON.parse(global._sessionStorage.getItem("user")),
            totalNum: '',
            pageSizeOptions: global.pageInfo.sizeOptions,
            currentPage: '1',
            pageSize: '10',
            loading: false,
            resultLoad: false,
            searchParam: {},
            sourceData: [],
        }
    }

    currentPageChange(currentPage) {
        let params = this.state.searchParam;
        params.pageCurrent = currentPage;
        this.setState({
            currentPage: currentPage,
            searchParam: params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize) {
        let params = this.state.searchParam;
        params.pageCurrent = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage: '1',
            pageSize,
            searchParam: params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params) {
        params.pageCurrent = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam: params
        })
        this.searchPost(params);
    }

    searchPost(params) {
        params.eid = this.state.user.enterpriseid;
        this.setState({
            resultLoad: false
        })
        
        queryUser(params).then(res => {
            this.setState({
                sourceData: res.data.list,
                totalNum: res.data.totalRow,
                resultLoad: false
            })
        }).catch(err => {
            Message.error("服务异常");
            this.setState({
                resultLoad: false
            })
        })
    }

    refreshSubmit() {
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    resetForm() {
        this.setState({
            searchParam: {}
        })
    }

    componentWillMount() {
        this.searchFormSubmit({});
    }

    userDataExport(){
        let params = this.state.searchParam;
        axios({
            method: 'GET',
            url: baseHttpUrl+'/api/userRelated/userDataExport',
            params: params,
            responseType: 'blob'
        }).then(function(res) {
            const content = res.data
            const blob = new Blob([content],{type: "application/x-msdownload"})
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = res.headers['filename'];
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })
    }

    render() {
        return (
            <div>
                <SupplierStaffForm loading={this.state.loading} searchFormSubmit={this.searchFormSubmit.bind(this)}
                    resetForm={this.resetForm.bind(this)} />
                <SupplierStaffResult loading={this.state.resultLoad} resultData={this.state.sourceData} totalNum={this.state.totalNum} refreshSubmit={this.refreshSubmit.bind(this)}
                    currentPageChange={this.currentPageChange.bind(this)} pageSizeChange={this.pageSizeChange.bind(this)} currentPage={this.state.currentPage} 
                    pageSizeOptions={this.state.pageSizeOptions} userDataExport={this.userDataExport.bind(this)}  pageSize={this.state.pageSize}
                    />
            </div>
        );
    }
}

export default supplierStaffQuery;