function mobile(rule, value, callback){
    var phone=value.replace(/\s/g, "");//去除空格
    //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、    176~178。14号段为上网卡专属号段
    let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
    if(value.length === 0){
        callback();
    }else{
        if(!regs.test(phone)){
            callback([new Error('请输入有效手机号')]);
        }else{
            callback();
        }
   }
}

function identity(value){
    let regs = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
    if(!value || value.length === 0){
        return true;
    }else{
        if(!regs.test(value)){
            return false;
        }else{
            return true
        }
   }
}

export default {mobile, identity}