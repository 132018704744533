import React, { Component } from 'react';
import { Table,Message} from 'antd'
import { uploadSalaryQuery,uploadBusSalaryQuery } from 'api/weeksalary'
import {publicDesensitization} from '../../utils/String'


class CreatePayRollDetailResult extends Component {

    constructor(){
        super();
        this.state = {
            loading : false,
            searchParam : {},
            sourceData : [],
            totalNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            columns :[],
            tableWidth : 110,
            bustype:''

        }
    }

    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage,
            searchParam : params
        });
        this.searchFormSubmit(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchFormSubmit(params);
    }

    searchFormSubmit(params){
        if(this.props.bustype=="P"){
            this.searchPostP(params);
        }else{
            this.searchPostC(params);
        }
        
    }

    searchPostP(params){
        this.setState({
            loading : true
        })
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        
        uploadSalaryQuery(params).then(res => {
            let {errorCode, errorMessage} = res.data;
                let columns=[];
                if(res.data.listhead){
                    res.data.listhead.map((head)=>{
                        if(head.salaryentity === 'identityid' || head.salaryentity === 'busvid'){
                            columns.push(
                                {
                                    title: head.salaryname,
                                    dataIndex: head.salaryentity,
                                    key: head.salaryentity,
                                    align : 'center',
                                    render(identityid,record){
                                        if(head.salaryentity === 'identityid'){
                                            return publicDesensitization(record.identityid);
                                        }else if(head.salaryentity === 'busvid'){
                                            return publicDesensitization(record.busvid);
                                        }
                                    }
                                }
                            )
                        }else{
                            columns.push(
                                {
                                    title: head.salaryname,
                                    dataIndex: head.salaryentity,
                                    key: head.salaryentity,
                                    align : 'center',
                                }
                            )
                        }
                    })
                    columns.push(
                        { title : '发放单状态', width : '10%', dataIndex : 'status', key : 'status', align : 'center',
                            render(val,record){
                                if (record.status == '00') {
                                    return '已发放';
                                } else if (record.status == '01') {
                                    return '未发放';
                                } else if (record.status == '02') {
                                    return '发放失败';
                                }  else {
                                    return '发放中';
                                }
                            }
                        }
                    )
                    this.setState({
                        sourceData : res.data.list,
                        totalNum : res.data.totalRow,
                        columns : columns,
                        tableWidth : columns.length * 100,
                    });
                    
                }

            
            this.setState({
                loading : false
            })
        })
    }
    searchPostC(params){
        this.setState({
            loading : true
        })
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        
        uploadBusSalaryQuery(params).then(res => {
            let {errorCode, errorMessage} = res.data;
                let columns=[];
                if(res.data.listhead){
                    res.data.listhead.map((head)=>{
                        columns.push(
                            {
                                title: head.salaryname,
                                dataIndex: head.salaryentity,
                                key: head.salaryentity,
                                align : 'center',
                            }
                        )
                    })
                    columns.push(
                        { title : '发放单状态', width : '10%', dataIndex : 'status', key : 'status', align : 'center',
                            render(val,record){
                                if (record.status == '00') {
                                    return '已发放';
                                } else if (record.status == '01') {
                                    return '未发放';
                                } else if (record.status == '02') {
                                    return '发放失败';
                                } 
                            }
                        }
                    )
                    this.setState({
                        sourceData : res.data.list,
                        totalNum : res.data.totalRow,
                        columns : columns,
                        tableWidth : columns.length * 100,
                    });
                    
                }

            
            this.setState({
                loading : false
            })
        })
    }


    componentDidMount(){
        let params = this.state.searchParam;
        params.currentPage = this.state.currentPage;
        params.pageSize = this.state.pageSize;
        params.id = this.props.orderNo;
        this.setState({
            searchParam : params,
            bustype:this.props.bustype
        });

        this.searchFormSubmit(params);
    }


    render() {

        const _this = this;
        const pagination ={
            current : parseInt(this.state.currentPage),
            total: this.state.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : this.state.pageSizeOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.currentPageChange(current);
            }
        }


        const columns = this.state.columns;
        return (
            <div>
                <Table style={{marginTop : '20px'}} 
                    rowKey = { record => record.id }
                    loading={this.state.loading}
                    size="middle "
                    dataSource={this.state.sourceData} 
                    columns={columns}
                    bordered
                    pagination={pagination}
                    scroll={{ x: this.state.tableWidth }}
                >
                </Table>
            </div>
        );
    }
}

export default CreatePayRollDetailResult;