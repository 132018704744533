import React, { Component } from 'react';
import { Message } from 'antd';
import { bindCardMsgListQuery,bindCard } from '../../api/staff';
import StaffBindCardQuery from '../../components/Staff/StaffBindCardQuery';
import StaffBindCardResult from '../../components/Staff/StaffBindCardResult';

class staffBindCard extends Component {
    constructor() {
        super();
        this.state = {
            user: JSON.parse(global._sessionStorage.getItem("user")),
            totalNum: '',
            pageSizeOptions: global.pageInfo.sizeOptions,
            currentPage: '1',
            pageSize: '10',
            loading: false,
            resultLoad: false,
            searchParam: {},
            sourceData: [],
            selectRows: [],
            selectFlowKeys: [],
            buttonFlag:true,
            commitloading:false,
        }
    }


    commit(){
        this.setState({
            commitloading:true,
        })
        let params  ={
            eid : this.state.user.enterpriseid,
            list:this.state.selectRows,
        }

        bindCard(params).then(res => {

            let {errCode, errMsg} = res.data;
          
            if(errCode === '0000'){             
                Message.success("提交成功");   
                this.setState({
                    buttonFlag:true
                })            
            }else{
                Message.error(errMsg);
            }
            this.setState({
                commitloading:false,
            })
            this.searchFormSubmit({});
        }).catch(err => {
            Message.error("服务异常");
            this.setState({
                commitloading:false
            })
            this.searchFormSubmit({});
        })

    }

    getSelectRows(selectRows) {
        if(selectRows.length>0){
            this.setState({
                buttonFlag:false
            })
        }else{
            this.setState({
                buttonFlag:true
            })
        }
        this.setState({
            selectRows: selectRows,
        })
    }

    getSelectFlowKeys(selectKeys) {

        this.setState({
            selectFlowKeys: selectKeys,
        })


    }

    handleSave = row => {
        const newData = [...this.state.sourceData];
        const index = newData.findIndex(item => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ sourceData: newData });
    };


    currentPageChange(currentPage) {
        let params = this.state.searchParam;
        params.pageCurrent = currentPage;
        this.setState({
            currentPage: currentPage,
            searchParam: params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize) {
        let params = this.state.searchParam;
        params.pageCurrent = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage: '1',
            pageSize,
            searchParam: params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params) {
        params.pageCurrent = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            searchParam: params,
            currentPage: "1"
        })
        this.searchPost(params);
    }

    searchPost(params) {
        params.eid = this.state.user.enterpriseid;
        this.setState({
            resultLoad: true,
            selectRows: [],
            selectFlowKeys: [],
        })
        bindCardMsgListQuery(params).then(res => {
            this.setState({
                sourceData: res.data.list,
                totalNum: res.data.totalNum,
                resultLoad: false
            })
        }).catch(err => {
            Message.error("服务异常");
            this.setState({
                resultLoad: false
            })
        })
    }

    refreshSubmit() {
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    resetForm() {
        this.setState({
            searchParam: {}
        })
    }

    componentWillMount() {
        this.searchFormSubmit({});
    }

    render() {
        const columns = [
            // {
            //     title: '工号', dataIndex: 'jobNumber', key: 'jobNumber', align: 'center',
            // },
            {
              title: '姓名', dataIndex: 'username', key: 'username', align: 'center',
            },
            {
              title: '身份证号', dataIndex: 'certNo', key: 'certNo', align: 'center',
            },
            {
                width:'300px',title: '银行卡号', dataIndex: 'cardNo', key: 'cardNo', align: 'center',editable: true,
            },
            {
                width:'200px', title: '手机号', dataIndex: 'mobile', key: 'mobile', align: 'center',editable: true,
            },            
            {
               title: '绑卡状态', dataIndex: 'cardBindState', key: 'cardBindState', align: 'center',
                render(val,record ) {
                    if (val === '00') {
                        record.editable = true;
                        return '未绑卡';
                    } else if (val === '01') {
                        record.editable = false;
                        return '已绑卡';
                    } else if (val === '02'){
                        record.editable = true;
                        return '绑卡失败';
                    } else if (val === '03'){
                        record.editable = false;
                        return '绑卡中';
                    } else if (val === '04') {
                        record.editable = false;
                        return '解绑中';
                    }
                    return "";

                } 
            },
            {
                title: '绑卡失败原因', dataIndex: 'cardBindErrmsg', key: 'cardBindErrmsg', align: 'center',
                render(val,record ) {
                    if (record.cardBindState === '03') {                     
                        return '';
                    } 
                    return val ;

                } 
            },
        
        ];
        return (
            <div>
                <StaffBindCardQuery loading={this.state.loading} searchFormSubmit={this.searchFormSubmit.bind(this)}
                    resetForm={this.resetForm.bind(this)} />
                <StaffBindCardResult handleSave={this.handleSave.bind(this)} columns={columns} loading={this.state.resultLoad} dataSource={this.state.sourceData} 
                    totalNum={this.state.totalNum} refreshSubmit={this.refreshSubmit.bind(this)}
                    currentPageChange={this.currentPageChange.bind(this)} pageSizeChange={this.pageSizeChange.bind(this)} currentPage={this.state.currentPage} 
                    selectRows={this.state.selectRows} selectFlowKeys ={this.state.selectFlowKeys} 
                    getSelectRows={(selectRows) => { this.getSelectRows(selectRows) }}
                    getSelectKeys={(selectKeys) => { this.getSelectFlowKeys(selectKeys) }}
                    commit = {this.commit.bind(this)} buttonFlag ={this.state.buttonFlag} commitloading = {this.state.commitloading} 
                    pageSizeOptions={this.state.pageSizeOptions} pageSize={this.state.pageSize}/>
            </div>
        );
    }
}

export default staffBindCard;