import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './index.m.less'
import { Modal, Icon, message } from 'antd'

class Confirm extends Component {
    
    constructor(){
        super();
        this.state={
            visible : false
        }
    }

    render() {
        const { text, title, content, onOk, loading } = this.props;
        const { visible } =this.state;

        const okButtonProps={
            loading
        }

        const ok = () =>{
            onOk().then(err => {
                if(!err){
                    
                }else{
                    message.error(err);
                }
                this.setState({
                    visible :false
                })
            })
        }

        return (
            <span>
                <span onClick={() => this.setState({visible : !visible})} >{text}</span>
                <Modal
                    // width={width}
                    className={styles['confirm-modal']}
                    destroyOnClose
                    title={title || '提示'}
                    visible={visible}
                    onOk={ok}
                    onCancel={() => this.setState({visible : false})}
                    okButtonProps={okButtonProps}
                >

                    <div className={styles['confirm-content']} >
                        <div>
                            <Icon style={{fontSize : 24}} type="exclamation-circle" theme="twoTone"  />
                        </div>
                        <div className={styles['confirm-content-text']} >
                            {content}
                        </div>
                        
                    </div>
                    
                    
                    
                </Modal>
            
            </span>
        );
    }
}

Confirm.propTypes = {
    onOk : PropTypes.func.isRequired,
    loading : PropTypes.bool.isRequired,

};

export default Confirm;