import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Form, Row, Col, Select, Input, DatePicker, Button, TreeSelect, message, Radio, Cascader, Switch, InputNumber, Icon, Upload } from 'antd'
import RichText from './RichText'
import Validate from 'utils/Validate'
import EmployeeItem from './EmployeeItem'

const FormItem = Form.Item
const { Option } = Select;
const { TreeNode } = TreeSelect ;
const { TextArea } = Input;
const { RangePicker} = DatePicker;

const renderOptions = (options) => {
    return options.map((option, index) => <Option key={index} value={option.value}>{option.label}</Option>)
}

const renderTree = (items) => {
    return items.map( item=> {
        if(item.children && item.children.length>0){
            return <TreeNode title={item.value} key={item.key} value={item.key} >
            {renderTree(item.children)}
        </TreeNode>
        }else{
            return <TreeNode title={item.value} key={item.key} value={item.key} >
            </TreeNode>
        }
    })
}

const renderRadio = (items) => {
    return items.map( (item, index)=> {
        return <Radio key={index} value={item.value}>{item.label}</Radio>
    })
}

const getFormItem = (item, form) => {
    const props = {
        allowClear: true,
        ...item.restProps,
        style : { width: '100%', ...item.style },
        autoComplete : 'off',
    }
    
    switch(item.type){
        case "input":   return form.getFieldDecorator(item.id, {...item.attr})(<Input { ...props } onPressEnter={(e) => e.preventDefault()} placeholder={item.placeholder && item.placeholder} allowClear />);
        case "input-number":   return form.getFieldDecorator(item.id, {...item.attr})(<InputNumber { ...props } onPressEnter={(e) => e.preventDefault()}  allowClear />);
        case "textArea":   return form.getFieldDecorator(item.id, {...item.attr})(<TextArea { ...props }  placeholder={item.placeholder && item.placeholder} allowClear />);
        case "select":  return form.getFieldDecorator(item.id, {...item.attr})(<Select { ...props } >{renderOptions(item.options)}</Select>); 
        case "select-tree":  return form.getFieldDecorator(item.id, {...item.attr})(<TreeSelect { ...props } >{renderTree(item.options)}</TreeSelect>); 
        case "date-picker":  return form.getFieldDecorator(item.id, {...item.attr})(<DatePicker { ...props } placeholder={item.placeholder && item.placeholder} />); 
        case "range-picker":  return form.getFieldDecorator(item.id, {...item.attr})(<RangePicker  { ...props } placeholder={item.placeholder && item.placeholder} />); 
        case "radio":   return form.getFieldDecorator(item.id, {...item.attr})(<Radio.Group {...item.restProps} >{renderRadio(item.options)}</Radio.Group>);
        case "cascader":   return form.getFieldDecorator(item.id, {...item.attr})(<Cascader options={item.options} { ...props }  />);
        case "switch":   return form.getFieldDecorator(item.id, {...item.attr})(<Switch {...item.restProps} />);
        case "upload-img":   return form.getFieldDecorator(item.id, {...item.attr})(<Upload listType="picture" { ...props } >{item.wrapper || <Button><Icon type="upload" /> 上传图片</Button>}</Upload>);
        case "phone":   
                    const phone_rules = item.attr && item.attr.rules ? item.attr.rules : [];
                    const phone_options = {
                        validateFirst : true,
                        // validateTrigger : 'onBlur',
                        rules : [
                            ...phone_rules,
                            { validator: Validate.mobile},
                        ],
                        getValueFromEvent: (event) => {
                            return event.target.value.replace(/\D/g,'')
                        },
                    }
                    return form.getFieldDecorator(item.id, {...item.attr, ...phone_options })(<Input { ...props } maxLength={11} onPressEnter={(e) => e.preventDefault()}  placeholder={item.placeholder && item.placeholder} allowClear />);
        case "identity":   
                    let identity_rules = item.attr && item.attr.rules ? item.attr.rules : [];
                    const identity_options = {
                        validateFirst : true,
                        rules : [
                            ...identity_rules,
                            { pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/, message: '请输入有效证件号' },
                        ],
                        getValueFromEvent: (event) => {
                            return event.target.value.replace(/[^0-9xX]/g,'')
                        },
                    }
                    return form.getFieldDecorator(item.id, {...item.attr, ...identity_options })(<Input { ...props } onPressEnter={(e) => e.preventDefault()}  maxLength={18} placeholder={item.placeholder && item.placeholder} allowClear />);
        case "email":   
                    let email_rules = item.attr && item.attr.rules ? item.attr.rules : [];
                    const email_options = {
                        validateFirst : true,
                        rules : [
                            ...email_rules,
                            { pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '邮箱格式不正确' },
                            { max: 40, message: '请输入40以内字符' },
                        ],
                        // getValueFromEvent: (event) => {
                        //     return event.target.value.replace(/[^0-9xX]/g,'')
                        // },
                    }
                    return form.getFieldDecorator(item.id, {...item.attr, ...email_options })(<Input onPressEnter={(e) => e.preventDefault()}  { ...props } maxLength={40} placeholder={item.placeholder && item.placeholder} allowClear />);
        
        case "employee-item":   return <EmployeeItem form={form} id={item.id} attr={item.attr} restProps={item.restProps} />;
        default:return null;
   }
}

const getFormItemCustom = (item, form, labelProps) => {
    
    switch(item.type){
        case "rich-text":   return <RichText {...item} form={form} labelProps={labelProps} />;
        default:return null;
   }
}

const renderForm = ({
    form,
    data,
    attributes,
    col
}) => {
    const num = parseInt(24 / col);
    let count = 0;

    let dataSource = []
    attributes.forEach((item, index) => {
        if(item.attr){
            if(data && item.attr.initialValue){
                item.attr.initialValue = data[item.id]
            }else{
                // delete item.attr.initialValue;
            }
        }
        
        const key = parseInt((index-count) / num) + count;
        if(item.row || item.span > 12){
            count ++;
        }
        dataSource[key] = dataSource[key] || [];
        dataSource[key].push(item)
    })

    return dataSource.map((item, index) => {
        return  <Row key={index} 
        // gutter={{ md: 8, lg: 24, xl: 48 }}
        >
                {
                    item.map((val, key) => {
                        const itemProps  = val.span > 12 ? {
                            labelCol : {
                                xs: { span: 24 },
                                sm: { span: 4 },
                            },
                            wrapperCol : {
                                xs: { span: 24 },
                                sm: { span: val.span > 19 ? 19 : val.span },
                            }
                        } : {}
                        return <Col key={key} span={item.row ||  val.span > 12 ? (val.span > 12 ? 24 : 12 ): col}>
                                    {
                                        (() => {
                                            switch(val.type){
                                                case "rich-text": return getFormItemCustom(val, form, itemProps);
                                                default: return <FormItem label={val.label} {...itemProps}> {getFormItem(val, form)}</FormItem>
                                        }})()
                                        }
                                </Col>
                    })
                }
                </Row>
        });
}

const renderFormBtn = ({
    form : {resetFields},
    loading,
    okText,
    onReset
}) => {
    const resetForm = () => {
        resetFields();
        if(onReset){
            onReset();
        }
    }
    
    return  <Row key={index} gutter={{ md: 8, lg: 24, xl: 48 }}>
                <Col span={24}>
                    <div style={{width : '160px', margin : 'auto'}}>
                        <Button onClick={resetForm} >重置</Button>
                        <Button type="primary" loading={loading} htmlType="submit" style={{float : 'right'}} >确定</Button>
                    </div>
                    
                </Col>
            </Row>
}

const index = ({ data, attributes, form, onSubmit, loading, cols=2, onReset }) => {

    const formItemStyle = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
    }
    const formSubmit = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) return;
            onSubmit(values).then(err => {
                if(err){
                    message.error(err);
                }
            });
        });
    }
    return (
        <div>
            <Form {...formItemStyle} onSubmit={formSubmit} >
                <Media queries={{
                    mini: "(max-width: 767px)",
                    small: "(min-width: 768px) and (max-width: 991px)",
                    medium: "(min-width: 992px) and (max-width: 1599px)",
                    large: "(min-width: 1600px)"
                }}>
                    {matches => { 
                        let col = 24;
                        if(matches.mini || matches.small){
                            col =24;
                        }else{
                            col = col /cols;
                        }
                        return (
                        <Fragment>
                            {renderForm({form, data, attributes, col})}
                            {renderFormBtn({form, loading, col : parseInt(24 / col), onReset})}
                        </Fragment>
                    )}}
                </Media>
                
            </Form>
        </div>
    );
};

index.propTypes = {
    data : PropTypes.object,
    attributes : PropTypes.arrayOf(PropTypes.shape({
        id : PropTypes.string.isRequired,
        label : PropTypes.string.isRequired,
        type : PropTypes.string.isRequired,
        options : function(props, propName, componentName) {
                if (props.type==='select' && !(props[propName] instanceof Array)) {
                    return new Error(
                        'Invalid prop `' + propName + '` is required' +
                        ' `' + componentName + '`, expected `array`.'
                    );
                }
            },
        restProps : PropTypes.object,
        attr : PropTypes.object,
    })).isRequired,
    loading : PropTypes.bool.isRequired,
    onSubmit : PropTypes.func.isRequired,
    cols : PropTypes.oneOf([1, 2]),
    
};

export default index;