import React from 'react';
import { Col, Row } from 'antd';
import './index.less';
import responsive from './responsive';

const Description = ({ term, column, children }) => (
  <Col {...responsive[column]} >
    {
      term ? 
      <Row>
        <Col span={10} >
          {term && <div className="term" ><span>{term}</span></div>}
        </Col>
        <Col span={14} >
          {children !== null && children !== undefined && <div className="detail">{children}</div>}
        </Col>
      </Row>
      : 
      <Row>
        <Col span={10} >
          <div className="term-hidden" ></div>
        </Col>
        <Col span={14} >
          <div className="detail"></div>
        </Col>
      </Row>
    }
    
  </Col>
);

export default Description;
