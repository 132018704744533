import {http} from '../utils/http'

export function saveDepart(data){
    return http.post("/api/department/create",data);
}

export function queryDepartList(data){
    return http.post("/api/department/query",data);
}

export function updateDepart(data){
    return http.post("/api/department/edit",data);
}

export function deleteDepart(data){
    return http.post("/api/department/del",data);
}

export function queryDeptInitGroup(data){
    return http.post("/api/department/queryDeptInitGroup",data);
}

export function queryPublicNumber(data){
    return http.post("/api/department/queryPublicNumber",data);
}

export function creatQrCode(data){
    return http.post("/api/department/creatQrCode",data);
}

export function employeeReviewList(data){
    return http.post("/api/department/employeeReviewList",data);
}

export function employeeReview(data){
    return http.post("/api/department/employeeReview",data);
}