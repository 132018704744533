import React, { Component } from 'react';
import CreatePayRollSearch from '@/components/payrollmanage/SimplerCreatePayRollSearch2';
import CreatePayRollResult from '@/components/payrollmanage/SimplerCreatePayRollResult2';
import {querySalaryBatch} from 'api/weeksalary'
import { Message } from 'antd';
import {uploadSalarys} from 'api/weeksalary'


class SimplerCreatePayRoll extends Component {
    constructor(props) {
        super(props);
        this.state={
            loading : false,
            searchParam : {},
            resultData : [],         
            totalNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            visible: false,
            errorMessage: undefined

        }
    }

    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage :currentPage,
            searchParam : params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params){
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam : params
        })
        this.searchPost(params);
    }

    resetForm(){
        this.setState({
            searchParam : {}
        })
    }

    refreshSubmit(){
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    searchPost(params){
        
        this.setState({
            loading : true
        })
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        params.userId = user.userId;
        params.versions = user.versions;
        querySalaryBatch(params).then(res => {
            // let { errorCode, data } =res.data;
            // if(errorCode === '0000'){
                this.setState({
                    totalNum : Number(res.data.totalRow),                   
                    resultData : res.data.list
                })
            // }
            this.setState({
                loading : false
            })
        })
        .catch(error => {
            this.setState({
                loading : false
            })
            Message.error('error');
        });
    }

    componentWillMount(){ 
        let params = {currentPage:this.state.currentPage,pageSize:this.state.pageSize,salarydate:"",batchcode:"",status:""};
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        params.userId = user.userId;
      
        this.setState({
            searchParam : params
        })
        this.searchPost(params);
    }
    createPayUpload(formData){
        uploadSalarys(formData).then(res => {
            if(res.data.errorCode === '0000'){
                Message.success(res.data.msg);
            }else{
                Message.error(res.data.msg);
            }  


        })
    }

    handleCancel(){
        this.setState({
            visible:false,
            errorMessage:''
        })
    }


    render() {
        return (
            <div>
                <CreatePayRollSearch 
                    searchFormSubmit={this.searchFormSubmit.bind(this)} 
                    resetForm={this.resetForm.bind(this) }
                    departList={this.state.departList}
                    createPayUpload={this.createPayUpload.bind(this)}
       //           currentFlag ={this.props.currentFlag}
                />
                <CreatePayRollResult 
                    resultData={this.state.resultData} 
                    totalNum={this.state.totalNum} 
                    currentPage={this.state.currentPage} 
                    loading={this.state.loading} 
                    pageSizeOptions={this.state.pageSizeOptions} 
                    pageSize={this.state.pageSize}
                    currentPageChange={this.currentPageChange.bind(this)}
                    pageSizeChange={this.pageSizeChange.bind(this)} 
                    refreshSubmit={this.refreshSubmit.bind(this)} 
                    currentFlag = {this.props.currentFlag}
                />
            </div>
        );
    }
}


export default SimplerCreatePayRoll;