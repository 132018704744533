import React, { Component } from 'react';
import { Form, Row, Col, Input, Select, Button, DatePicker } from 'antd'
import moment from 'moment';
import { queryRelatedUpEnterpris } from '@/api/supplierManage';

const { RangePicker } = DatePicker;
const { Option } = Select;
class SupplierStaffForm extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem("user")).enterpriseid,
            relatedEnterpris: [],
            stateshow:false,
            enterpriseshow:false,
        }
    }

    refreshSubmit(e) {
        this.handlerSubmit(e);
    }

    handlerSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                if (params.relatedTime && params.relatedTime.length > 0) {
                    params.relatedStartTime = moment(params.relatedTime[0]).format('YYYY-MM-DD');
                    params.relatedEndTime = moment(params.relatedTime[1]).format('YYYY-MM-DD');
                }
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset() {
        this.props.form.resetFields();
        this.props.resetForm();
        this.setState({
            stateshow:false,
            enterpriseshow:false,
        })
    }

    componentWillMount() {
        //查询关联企业
        let params = {
            eid: this.state.enterpriseid,
        }
        queryRelatedUpEnterpris(params).then(res => {
            this.setState({
                relatedEnterpris: res.data.list
            })
        });
    }
    enterpriseshowChange(){
        this.setState({
            stateshow:true,
        })
    }
    stateshowChange(){
        this.setState({
            enterpriseshow:true,
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const dateFormat = 'YYYY-MM-DD';
        const relatedOptions = this.state.relatedEnterpris.map(iterm => {
            return <Option key={iterm.upCompanyNo} value={iterm.upCompanyNo} >{iterm.upCompanyNo + "/" + iterm.upCompanyName}</Option>
        });
        return (
            <div>
                <Form className="ant-search-form" onSubmit={this.handlerSubmit.bind(this)} >
                    <Row gutter={24}>
                        <Col span={6} >
                            <Form.Item label="姓名" >
                                {getFieldDecorator("userName", {
                                })(
                                    <Input placeholder="姓名"></Input>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="身份证号" >
                                {getFieldDecorator("certNo")(
                                    <Input placeholder="身份证号"></Input>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="用工企业商户号/名称" >
                                {getFieldDecorator("relatedEid")(
                                    <Select placeholder="用工企业商户号/名称" disabled={this.state.enterpriseshow} onChange={this.enterpriseshowChange.bind(this)}>
                                        {relatedOptions}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6} >
                            <Form.Item label="手机号" >
                                {getFieldDecorator("mobile")(
                                    <Input placeholder="手机号"></Input>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="关联日期" >
                                {getFieldDecorator("relatedTime", {
                                })(
                                    <RangePicker format={dateFormat} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="关联状态" >
                                {getFieldDecorator("relatedState")(
                                    <Select style={{ width: "100%" }} placeholder="关联状态" disabled={this.state.stateshow} onChange={this.stateshowChange.bind(this)} >
                                        <Option value="00">未关联</Option>
                                        <Option value="01">已关联</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" style={{ marginLeft: 50 }} loading={this.props.loading} >查询</Button>
                                <Button style={{ marginLeft: 30 }} onClick={this.handlerReset.bind(this)} >重置</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div >
        );
    }
}

SupplierStaffForm = Form.create()(SupplierStaffForm)

export default SupplierStaffForm;