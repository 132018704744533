import React, { Component } from 'react';
import { Message } from 'antd'
import { queryUserInfoByBranch } from '../../api/staff';
import DepartStaffResult from '@/components/department/DepartStaffResult';

class DepartStaffInfo extends Component {

    constructor() {
        super();
        this.state = {
            user: JSON.parse(global._sessionStorage.getItem("user")),
            totalNum: '',
            pageSizeOptions: global.pageInfo.sizeOptions,
            currentPage: '1',
            pageSize: '10',
            loading: false,
            resultLoad: false,
            searchParam: {},
            sourceData: [],
        }
    }

    
    currentPageChange(currentPage) {
        let params = this.state.searchParam;
        params.pageCurrent = currentPage;
        this.setState({
            currentPage: currentPage,
            searchParam: params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize) {
        let params = this.state.searchParam;
        params.pageCurrent = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage: '1',
            pageSize,
            searchParam: params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params) {
        params.pageCurrent = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam: params
        })
        this.searchPost(params);
    }

    searchPost(params) {
        params.eid =   this.state.user.enterpriseid;
        params.dname =this.props.location.state.record.dname
        this.setState({
            resultLoad: true
        })
        queryUserInfoByBranch(params).then(res => {
            this.setState({
                sourceData: res.data.list,
                totalNum: res.data.totalRow,
                resultLoad: false
            })
        }).catch(err => {
            Message.error("服务异常");
            this.setState({
                resultLoad: false
            })
        })
    }

    refreshSubmit() {
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    resetForm() {
        this.setState({
            searchParam: {}
        })
    }

    componentWillMount() {
        this.searchFormSubmit({});
    }


    render() {
        return (
            <div>
                 <DepartStaffResult loading={this.state.resultLoad} resultData={this.state.sourceData} totalNum={this.state.totalNum} refreshSubmit={this.refreshSubmit.bind(this)}
                    currentPageChange={this.currentPageChange.bind(this)} pageSizeChange={this.pageSizeChange.bind(this)} currentPage={this.state.currentPage}
                    pageSizeOptions={this.state.pageSizeOptions} pageSize={this.state.pageSize}/>
            </div>
        );
    }
}

export default DepartStaffInfo;