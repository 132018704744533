import React, { Component } from 'react';
import { Form, Row, Col, Input, Button, DatePicker, Select } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

class CreatePayRecordSearch extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    refreshSubmit(e){
        this.handlerSubmit(e);
    }

    handlerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                if(params.dateRange && params.dateRange.length > 0 ){
                    params.beginDate = moment(params.dateRange[0]).format('YYYYMMDD');
                    params.endDate = moment(params.dateRange[1]).format('YYYYMMDD');
                }
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset(){
        this.props.form.resetFields();
        this.props.resetForm();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const dateFormat = 'YYYY/MM/DD';
        return (
            <div>
                <Form className="ant-search-form" onSubmit={this.handlerSubmit.bind(this)} >
                    <Row gutter={24} >
                        <Col lg={7} xxl={5} >
                            <Form.Item label="文件名称" >
                                { getFieldDecorator("fileName")(
                                    <Input allowClear placeholder="文件名称" ></Input>
                                ) }
                            </Form.Item>
                        </Col> 
                        <Col lg={7} xxl={5} >
                            <Form.Item label="批次号" >
                                { getFieldDecorator("batchNo")(
                                    <Input allowClear placeholder="批次号" ></Input>
                                ) }
                            </Form.Item>
                        </Col> 
                        <Col lg={7} xxl={6} >
                            <Form.Item label="录入日期" >
                            { getFieldDecorator("dateRange")(
                                <RangePicker format={dateFormat} />
                            ) }
                            </Form.Item>
                        </Col>
                        <Col  lg={7} xxl={4} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" style={{marginLeft : 10}}  loading={this.props.loading} >查询</Button>
                                <Button style={{marginLeft : 5}} onClick={this.handlerReset.bind(this)} >重置</Button>
                            </Form.Item>
                        </Col>                   
                    </Row>
                    <Row gutter={24} >
                        <Col lg={7} xxl={5} >
                            <Form.Item label="解析状态" >
                                { getFieldDecorator("parseStatus")(
                                    <Select allowClear placeholder='解析状态'>
                                        <Option value='0'>成功</Option>
                                        <Option value='1'>失败</Option>
                                    </Select>
                                ) }
                            </Form.Item>
                        </Col> 
                        <Col lg={7} xxl={5} >
                            <Form.Item label="校验状态" >
                                { getFieldDecorator("checkStatus")(
                                    <Select allowClear placeholder='校验状态'>
                                        <Option value='0'>成功</Option>
                                        <Option value='1'>失败</Option>
                                    </Select>
                                ) }
                            </Form.Item>
                        </Col>                
                    </Row>
                </Form>
                <div >
                    <Button type="primary" onClick={this.refreshSubmit.bind(this)} >刷新</Button>
                </div>  
            </div>
        );
    }
}

CreatePayRecordSearch = Form.create()(CreatePayRecordSearch) 

export default CreatePayRecordSearch;