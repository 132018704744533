import React, { Component } from 'react';
import ConfirmPrpover from '../Common/ConfirmPrpover'
import { Message, Spin, Icon } from 'antd'
import { submitSalaryBatch } from 'api/weeksalary'

class CreatePayRollSubmit extends Component {
    constructor(){
        super();
        this.state={
            loading : false
        }
    }

    submitSalaryData(){
        this.setState({
            loading : true,
        })
        let params = {};
        params.id = this.props.record.id;
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        params.phone=user.username;
        submitSalaryBatch(params).then(res =>{
            if(res.data.result!="true"){
                Message.error(res.data.message);
            }
            this.props.refreshSubmit();
            this.setState({
                loading : false,
            })
        })
    }

    render() {
        const loadIcon = <Icon type="loading" spin />;
        const htmlText = <span className="span-click" >提交</span>;
        return (
            <span style={{display :'inline-flex' }} >
                <Spin size="small" indicator={loadIcon} spinning={this.state.loading}>
                {
                    <ConfirmPrpover record={this.props.record} placement="topRight"  content="确认提交吗？"
                        confirmText="确定" cancelText="取消" htmlText={htmlText} confirmSubmit={this.submitSalaryData.bind(this)} />
                }
                </Spin>
            </span>
        );
    }
}

export default CreatePayRollSubmit;