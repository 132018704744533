import React, { Component } from 'react';
import { Message,Table, Button,Form,Input,Icon,Modal} from 'antd'
import { getTpwd } from 'api/RechargeController'
import { checkTpwd } from 'api/RechargeController'
import { salaryPayFail } from 'api/RechargeController'
import {publicDesensitization} from '../../utils/String'


const SalaryPayModalForm = Form.create({ name: 'salarypayform_in_modal' })(
    class extends React.Component {
        onPaste(e){
            e.preventDefault();
        }
     render() {
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 14 },
            },
        };
       const { visible, onCancel, onCreate, form } = this.props;
       const { getFieldDecorator } = form;
       return (
         <Modal
           visible={visible}
           title="输入发薪密码"
           okText="确定"
           onCancel={onCancel}
           onOk={onCreate} 
           okButtonProps={{ disabled: !this.props.iSSetSalaryPwd,loading : this.props.salaryPayLoading }}
           destroyOnClose
         >
           <div style={{color:'red',marginLeft:60}}>
                {this.props.iSSetSalaryPwd ? <p>提示：发薪密码超级管理员已设置，请您妥善保管！</p>:
                <p>提示：您还未设置发薪密码，<br></br>请联系超级管理员在【财务管理-发放密码设置】菜单中设置！</p>}
           </div>
           <Form {...formItemLayout}>
               <Form.Item label="输入密码" style={{margin:0}}>
                   {getFieldDecorator('pass_word', {
                   rules: [{ required: true, message: '请输入发薪密码!' }],
                   })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="输入6-12位数字或字母的发薪密码" onPaste={this.onPaste}/>)}
               </Form.Item>
               {/* <Form.Item style={{margin:0}}>
                   { getFieldDecorator("id",{
                       initialValue : this.props.record.id,
                   })(
                       <Input hidden />
                   ) }
               </Form.Item> */}
           </Form>
         </Modal>
       );
     }
   },
 );

class SimplerSalaryPayFailQueryResult extends Component {
    constructor(){
        super();
        this.state = {
            columns :[],
            selectedRowKeys: [],
            salaryArr:[],
            loading: false,
            salaryPayLoading: false,    //发薪按钮载入
            salaryPayVisible:false,     //发薪输入密码窗口显示
            iSSetSalaryPwd: false,
            user: JSON.parse(global._sessionStorage.getItem("user")),
            
        }
    }

    //关闭输入发薪密码窗口
    salaryPayModalCancel = () => {
        this.setState({
            salaryPayVisible: false,
        });
        this.props.refreshSubmit();
    } 

    //发放密码是否设置
    giveo = () => {
        const _this = this;
        let params = {
            enterpriseid: this.state.user.enterpriseid,
            username: this.state.user.username,
        }
        getTpwd(params).then(res => {
            let { errorCode, errorMessage } = res.data;
            if (errorCode === '0000') {
                _this.setState({
                    salaryPayVisible: true,
                    iSSetSalaryPwd:true,
                })
            }else{
                _this.setState({
                    salaryPayVisible : true,
                    iSSetSalaryPwd: false,
                })
            }
        })

    }

    
    showSalaryPayModal = (salaryArrValue,e) => {
        this.giveo();
        this.setState({
            salaryArr : salaryArrValue,
        });

    }

    salaryPayModalOk = () =>{
        this.setState({ 
            loading: true,
            salaryPayLoading : true,
         });
        const { form } = this.formRef.props;
        const _this = this;
        form.validateFields((err, values) => {
            if (err) {
              return;
            }
            let params = {
                enterpriseid: _this.state.user.enterpriseid,
                username: _this.state.user.username,
                pwd : values.pass_word,
            }
            checkTpwd(params).then(res => {
                let { errorCode, errorMessage } = res.data;
                if (errorCode === '0000') {
                    _this.paySalaryReal(_this.state.salaryArr);
                } else {
                    Message.error(errorMessage);
                    this.setState({ 
                        salaryPayLoading : false,
                     });
                }
            })
        })

    }

    //发放薪资
    paySalaryReal= (salaryArrValue) => {
        this.setState({
            salaryPayLoading : true,
        });
        let salaryIdArr = salaryArrValue;
        let params = {
            enterpriseid: this.state.user.enterpriseid,
            username: this.state.user.username,
            batchIdArr : salaryIdArr,
            busType : this.props.busType,
        }
        const _this = this;
        salaryPayFail(params).then(res => {
            let { statusCode, message,result } = res.data;
            if(statusCode === "200" && result=== "true"){
                Message.success(message);
            }else{
                Message.error(message);
            }
            _this.setState({
                salaryPayVisible: false,
                salaryPayLoading : false,
                selectedRowKeys: [],
                loading: false,
                salaryPayLoading : false,
            });
            _this.props.refreshSubmit();
        })

    }


    salaryPayFormRef = formRef => {
        this.formRef = formRef;
    };

    render() {
        const _this = this;
        const columns = [

            { title : this.props.busType==='C'?'对公户名':'员工姓名',  
                dataIndex : this.props.busType==='C'?'busvname':'name', 
                key : this.props.busType==='C'?'busvname':'name', 
                width: 200,
                align : 'center',fixed: 'left',
            },
            { title : this.props.busType==='C'?'对公账号':'员工身份证号',
                dataIndex : this.props.busType==='C'?'busvid':'identityid', 
                key : this.props.busType==='C'?'busvid':'identityid', 
                width: 200,
                align : 'center',fixed: 'left', 
                render(text, record){
                    if(_this.props.busType==='C'){
                        return publicDesensitization(record.busvid);
                    }else{
                        return publicDesensitization(record.identityid);
                    }
                    
                }
            },

            { title : '实发金额', width: 130,  dataIndex : 'realsalary', key : 'realsalary', align : 'center',fixed: 'left',},
            { title : '发放属性', width: 130,   dataIndex : 'salaryCycleCateEnum', key : 'salaryCycleCateEnum', align : 'center'},
            { title : '发放方式', width: 130, dataIndex : 'consumeType', key : 'consumeType', align : 'center',
                render(text, record){
                    if(record.consumeType=='01' || record.consumeType=='10'){
						return '记账户';
					}else if(record.consumeType=='05'){
						return '银行卡';
					}else if(record.consumeType=='11'){
						return '对公账户';
					}else if(record.consumeType=='00'){
						return '白名单发放';
					}else{
                        return '未知';
                    }
                }
            },
            { title : '发薪批次号', width: 200,  dataIndex : 'salarybatchcode', key : 'salarybatchcode', align : 'center'},
            { title : '提交日期时间', width: 200,  dataIndex : 'createtime', key : 'createtime', align : 'center'},
            { title : '发放日期时间', width: 200,  dataIndex : 'edittime', key : 'edittime', align : 'center'},
           
            { title : '错误信息', width: 200,  dataIndex : 'returnMsg', key : 'returnMsg', align : 'center'},
            { title : '失败次数', width: 130, dataIndex : 'transFailCnt', key : 'transFailCnt', align : 'center'},
            { title : '状态', width: 130,  dataIndex : 'status', key : 'status', align : 'center',fixed: 'right',
                render(text, record){
                    if(record.status == '00'){
                        return '支付成功';
                    }else if(record.status == '02'){
                        return '支付失败';
                    }else{
                        return '处理中';
                    }
                }
            },
            { title : '操作',  align : 'center',fixed: 'right', width: 150,
                render(text, record, index){
                    return (
                        <div>
                            <Button disabled={record.status != '02'} loading={loading} onClick={_this.showSalaryPayModal.bind(this,[record.id])} size="small" type="primary">
                                再次发放
                            </Button>
                        </div>
                    )
                }
            },
        ];

        
        const pagination ={
            current : parseInt(this.props.currentPage),
            total: this.props.totalNum,
            pageSize : parseInt(this.props.pageSize), 
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : this.props.pageSizeOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }

        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
              //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({ 
              disabled: record.status != '02', // Column configuration not to be checked
              id: record.id,
            }),
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                <div style={{ marginBottom: 5, marginTop:16 }}>
                    <Button type="primary" onClick={this.showSalaryPayModal.bind(this,this.state.selectedRowKeys)} disabled={!hasSelected} loading={loading}>
                        批量发放
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `选中 ${selectedRowKeys.length} 笔工资单` : ''}
                    </span>
                </div>
                <Table style={{marginTop : '10px'}} 
                    rowKey = { record => record.id }
                    loading={this.props.loading}
                    size="middle"
                    dataSource={this.props.resultData} 
                    columns={columns}
                    bordered
                    pagination={pagination}
                    rowSelection={rowSelection}
                    scroll={{ x: 2060 }}
                >
                </Table>

                <SalaryPayModalForm 
                        visible={this.state.salaryPayVisible}
                        onCancel={this.salaryPayModalCancel} 
                        onCreate={this.salaryPayModalOk}
                        iSSetSalaryPwd={this.state.iSSetSalaryPwd}
                        salaryPayLoading={this.state.salaryPayLoading}
                        wrappedComponentRef={this.salaryPayFormRef}
                    />

            </div>
        );
    }
}

export default SimplerSalaryPayFailQueryResult;