import React from 'react';
import { DragSource } from 'react-dnd';
import DragTypes from "../DragTypes"

const type = DragTypes.FORM;
const spec = { 
    /**
	 * 开始拖拽时触发当前函数
	 * @param {*} props 组件的 props
	 */
	beginDrag(props) {
        // 返回的对象可以在 monitor.getItem() 中获取到
        const {item} = props;
		return {
			item: item,
		}
	},
 
	/**
	 * 拖拽结束时触发当前函数
	 * @param {*} props 当前组件的 props
	 * @param {*} monitor DragSourceMonitor 对象
	 */
	endDrag(props, monitor) {
		// 当前拖拽的 item 组件
        // const item = monitor.getItem()
		// 拖拽元素放下时，drop 结果
        const dropResult = monitor.getDropResult()
		// 如果 drop 结果存在，就弹出 alert 提示
		if (dropResult) {
			// alert(`You dropped ${item.name} into ${dropResult.name}!`)
		}
	},
};
 
const collect = (connect, monitor) =>( {
	// 这里返回一个对象，会将对象的属性都赋到组件的 props 中去。这些属性需要自己定义。
	connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
})


const DragField = (props) => {
    const { isDragging, connectDragSource, children, className } = props;
    const opacity = isDragging ? 0.4 : 1;

    return connectDragSource && connectDragSource((
      <div className={className} style={{ opacity }}>
        {children}
      </div>)
    );
};

export default DragSource(type, spec, collect)(DragField);