import React, { Component } from 'react';
import { Message } from 'antd'
import { operateSalaryTmp } from '../../api/salaryTemp';

class StartTemp extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem("user")).enterpriseid,
        }
    }
    startTemp() {
        let params = {
            id:this.props.record.id,
            bustype:this.props.record.bustype,
            enterpriseid:this.state.enterpriseid
        }
        operateSalaryTmp(params).then(res => {
            let { statusCode,message } = res.data;
            if("200" === statusCode){
                Message.success(message);
                this.props.refreshSubmit();
            }else{
                Message.error(message);
            }
        });
    }
    render() {
        return (
            <span style={{ display: 'inline-flex' }} >
                {this.props.show?<span className="span-click" onClick={this.startTemp.bind(this)} >启用</span>:""}
            </span>
        );
    }
}

export default StartTemp;