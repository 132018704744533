import { tencentMapKey } from '@/assets/settings'

export function TMapGL() {
    if (window.TMap) return Promise.resolve()
    return new Promise(function(resolve, reject) {
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://map.qq.com/api/gljs?v=1.exp&libraries=tools&key='+tencentMapKey
        script.onerror = () => reject()
        script.onload = () => resolve()
        document.head.appendChild(script)
    })
}