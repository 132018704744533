import React, { Component } from 'react';
import { Form, Select, Tooltip, Icon, Modal, Message } from 'antd'
import ModalDrag from '../../Common/ModalDrag';
import StaffAddBatchQueryDetailShow from './StaffAddBatchQueryDetailShow';
import { selectUserAddDetail } from '../../../api/staff';
const { Option } = Select;

class StaffAddBatchQueryDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            pageTotal:'',
            totalNum: '',
            successNum: '',
            failNum: '',
            pageSizeOptions : global.pageInfo.sizeOptions,
            currentPage : '1',
            pageSize : '10',
            loading : false,
            resultLoad : false,
            searchParam : {},
            sourceData : [],
        }
    }

    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage : currentPage,
            searchParam : params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params){
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam : params
        })
        this.searchPost(params);
    }

    searchPost(params){
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        params.batchId = this.props.record.batchId;
        this.setState({
            resultLoad : true
        })
    
        selectUserAddDetail(params).then(res => {
            let {errorCode, errorMessage} = res.data;
            let data = res.data;
            if(errorCode === '0000'){
                this.setState({
                    sourceData : data.list,
                    pageTotal : data.pageTotal,
                    totalNum : data.totalNum,
                    successNum: data.successNum,
                    failNum:data.failNum,
                })
            }else{
                Message.error(errorMessage);
            }
            this.setState({
                resultLoad : false
            })
        }).catch( err => {
            Message.error("服务异常");
            this.setState({
                resultLoad : false
            })
        })
    }

    handlerCancel() {
        this.setState({
            visible: false
        })
    }

    handlerModalShow() {
        this.setState({
            visible: true
        })
        this.searchFormSubmit({});

    }

    getFailInfo(){
        let params = {};
        params.state='1';
        this.searchFormSubmit(params);
    }

    render() {
        const title = <ModalDrag title="批量录入详情" />;
        return (
            <span>
                {
                    this.props.disabled ?
                        <span className="span-click-disabled" ><Icon type="profile" theme="twoTone" twoToneColor="#d9d9d9" /></span>
                        : <Tooltip placement="top" title="详情" ><span className="span-click" onClick={this.handlerModalShow.bind(this)} ><Icon type="profile" theme="twoTone" /></span></Tooltip>
                }
                {
                    this.state.visible
                    &&
                    <Modal
                        visible={this.state.visible}
                        title={title}
                        onCancel={this.handlerCancel.bind(this)}
                        footer={null}
                        width={1200}
                    >
                     <StaffAddBatchQueryDetailShow resultData={this.state.sourceData} currentPageChange={this.currentPageChange.bind(this)}
                     totalNum={this.state.totalNum} successNum={this.state.successNum} failNum={this.state.failNum} checkNum={this.state.checkNum}
                     pageSizeChange={this.pageSizeChange.bind(this)} currentPage={this.state.currentPage} pageSizeOptions={this.state.pageSizeOptions}
                     getFailInfo={this.getFailInfo.bind(this)} getAllInfo={this.searchFormSubmit.bind(this)} pageTotal={this.state.pageTotal} 
                     pageSize={this.state.pageSize}/>
                     
                    </Modal>
                }
            </span>
        );
    }
}
StaffAddBatchQueryDetail = Form.create()(StaffAddBatchQueryDetail);

export default StaffAddBatchQueryDetail;