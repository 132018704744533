import React, { Component } from 'react';
import { Form, Input, Button, Message, Select, Switch } from 'antd'

import {  saveUser, queryRoleList} from 'api/user'

const { Option } = Select; 

class UserIncreInfo extends Component {

    constructor(){
        super();
        this.state={
            enterpriseid : JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            createUser : JSON.parse(global._sessionStorage.getItem('user')).username,
            roleTypeList : [],
            loading : false,
        }
    }

    componentDidMount(){
        this.queryParam();
    }

    queryParam(){
        queryRoleList({enterpriseid : this.state.enterpriseid}).then(res => {
            let data = res.data;
            if(data.errorCode==='0000'){
                this.setState({
                    roleTypeList : data.list,
                })
            }
        })
        
    }

    handlerReset(){
        this.props.form.resetFields();
    }

    handerSubmit(e){
        this.setState({
            loading : true
        })
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                // let personalSign = params.personalSign ? '0' : '1';
                // let companySign = params.companySign ? '0' : '1';
                params.enterpriseid = this.state.enterpriseid;
                params.createUser = this.state.createUser;
                // params.personalSign = personalSign;
                // params.companySign = companySign;
                params.certType = '1';
                this.setState({
                    loading : false
                })
                saveUser(params).then(res =>{
                    let data = res.data;
                    if( data.errorCode === '0000' ){
                        this.setState({
                            loading : false
                        })
                        Message.success('添加成功');
                        this.handlerReset();
                    }else{
                        this.setState({
                            loading : false
                        })
                        Message.error(data.errorMessage);
                    }
                })
            }else{
                this.setState({
                    loading : false
                })
            }
        })
    }

    validateCert = (rule, value, callback) =>{
        callback();

    }

    render() {
        const formItemStyle = {
            labelCol : { span : 4 },
            wrapperCol : { span : 8 }
        }
        const { getFieldDecorator } = this.props.form;
        const roleTypeOptions = this.state.roleTypeList.map( type =>{
            return <Option key={type.id} value={type.id} >{type.role_name}</Option>
        } )
        return (
            <Form { ...formItemStyle } onSubmit={this.handerSubmit.bind(this)} >
                <Form.Item label="登录名" >
                    { getFieldDecorator("loginName",{
                        rules : [
                            { required: true, message: '请输入登录名' }
                        ]
                    })(
                        <Input placeholder="登录名" />
                    ) }
                </Form.Item>
                <Form.Item label="用户角色" >
                    { getFieldDecorator("roleId",{
                        rules : [
                            { required: true, message: '请输入用户角色' }
                        ],
                    })(
                        <Select>
                            {  roleTypeOptions }
                        </Select>
                    ) }
                </Form.Item>
                <Form.Item label="用户姓名"  >
                    { getFieldDecorator("username",{
                        rules : [
                            { required: true, message: '请输入用户姓名' }
                        ],
                    })(
                        <Input placeholder="用户姓名" />
                    )}
                </Form.Item>
                <Form.Item label="证件号码"  >
                    { getFieldDecorator("userCert",{
                        rules : [
                            { required: true, message: '请输入证件号码' },
                            {validator : this.validateCert},
                        ],
                        validateTrigger: 'onBlur'
                    })(
                        <Input placeholder="证件号码" />
                    )}
                </Form.Item>
                <Form.Item label="手机"  >
                    { getFieldDecorator("mobile",{
                        rules : [
                            { required: true, message: '请输入手机号' }
                        ],
                    })(
                        <Input placeholder="手机" />
                    )}
                </Form.Item>
                {/* <Form.Item label="法人签署权限"  >
                    { getFieldDecorator("personalSign",{
                        initialValue : false,
                    })(
                        <Switch unCheckedChildren="禁止" checkedChildren="允许"  defaultChecked={false} />
                    )}
                </Form.Item>
                <Form.Item label="企业签署权限"  >
                    { getFieldDecorator("companySign",{
                        initialValue : false,
                    })(
                        <Switch unCheckedChildren="禁止" checkedChildren="允许"  defaultChecked={false} />
                    )}
                </Form.Item> */}
                <Form.Item label="邮箱"  >
                    { getFieldDecorator("email",{
                        rules : [
                            { type: 'email', message: '请输入正确的邮箱格式' }
                        ],
                    })(
                        <Input placeholder="邮箱" />
                    )}
                </Form.Item>
                    <div style={{ marginTop:20 }} >
                        <Button type="primary" onClick={this.handlerReset.bind(this)} style={{marginLeft : 300}} >重置</Button>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} style={{marginLeft : 50}}  >提交</Button>
                    </div>
                
            </Form>
        );
    }
}

UserIncreInfo = Form.create()(UserIncreInfo);

export default UserIncreInfo;