import React, { Component } from 'react';
import { Icon } from 'antd'

class IncreaseResult extends Component {
    render() {
        return (
            <div className="result" >
                <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{ fontSize : 50 }} />
                <div className="result-span" >
                    <span>
                        操作成功
                    </span>
                </div>
            </div>
        );
    }
}

export default IncreaseResult;