import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd'

const Full = ({triggerContainer, title, children}) => {

    const [visible, onVisible] = useState(false);
   
    return (
        <>
            <span onClick={() => onVisible(true)} >{triggerContainer}</span>
            <Drawer
            width='100%'
            destroyOnClose={true}
            bodyStyle={{height : '100%', padding : '0px 24px'}}
            title={title}
            visible={visible}
            onClose={() => onVisible(false)}
            >
                {children}
            </Drawer>
        </>
    );
};


Full.propTypes = {
    triggerContainer : PropTypes.oneOfType(
        [PropTypes.string,PropTypes.node]
    ).isRequired,
    title : PropTypes.oneOfType(
        [PropTypes.string,PropTypes.node]
    ),
};

export default Full;
