import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd'


const Container = (({visible = false, onClose, title, children}) => {
    let drawerWith = 'calc(100% - 200px)' ;
    return (
       <Drawer
        width={drawerWith}
        style={{height: 'calc(100% - 50px)', marginTop: 50 }}
        destroyOnClose={true}
        title={title}
        visible={visible}
        onClose={onClose}
        >
            {children}
        </Drawer>
    );
});


Container.propTypes = {
    visible : PropTypes.bool.isRequired,
    title : PropTypes.oneOfType(
        [PropTypes.string,PropTypes.node]
    ),
    onClose : PropTypes.func.isRequired,
};

export default Container;
