import {http} from '../utils/http'

export function querySalaryTemplate(data){
    return http.post("/api/salary/querySalaryTemplate",data);
}

export function querySalaryParts(data){
    return http.post("/api/salary/querySalaryParts",data);
}

export function createSalaryTmp(data){
    return http.post("/api/salary/createSalaryTmp",data);
}

export function operateSalaryTmp(data){
    return http.post("/api/salary/operateSalaryTmp",data);
}

export function deleteSalaryTmp(data){
    return http.post("/api/salary/deleteSalaryTmp",data);
}
