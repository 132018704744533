import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from '../views/Home'
import Login from '../views/Login'
import NoMatch from '../views/error/NoMatch'
import IndexSimpler from '../views/IndexSimpler'

class AppRoutes extends Component {

    constructor(){
        super();
        this.state={
            openKeys : [],
        }
    }

    render() {
         
        return (
            <BrowserRouter basename="/enym">
                <Switch>
                    <Route exact path='/login' component={Login} />
                    <Route path='/indexSimpler' component={IndexSimpler} />
                    <Route path='/' component={Home} />
                    <Route component={NoMatch} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default AppRoutes;