import React, { Component } from 'react';
import {Form,DatePicker,Input,Select,Button} from 'antd'
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

class SimplerSalaryPayFailQueryForm extends Component {
    constructor(){
        super();
        this.state = {
            selectType : "P",
        }
    }
    refreshSubmit(e){
        this.handlerSubmit(e);
    }

    handlerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                if(params.createTime && params.createTime.length > 0 ){
                    params.starttime = moment(params.createTime[0]).format('YYYY-MM-DD');
                    params.endtime = moment(params.createTime[1]).format('YYYY-MM-DD');
                }
                //params.busType=this.state.busType;
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset(){
        this.setState({
            selectType : "P",
        });
        let params ={};
        params.recordType = "P";
        this.props.form.resetFields();
        this.props.resetForm();
        this.props.searchFormSubmit(params);
    }

    handelBusType=(e)=>{
        this.props.form.validateFields((err, params) => {
            if(!err){
                if(params.createTime && params.createTime.length > 0 ){
                    params.starttime = moment(params.createTime[0]).format('YYYY-MM-DD');
                    params.endtime = moment(params.createTime[1]).format('YYYY-MM-DD');
                }
                params.busType=e;
                this.setState({
                    selectType : e,
                });
                this.props.searchFormSubmit(params);
            }
        })
    }

    handelrecordType=(e)=>{
        this.props.form.validateFields((err, params) => {
            if(!err){
                if(params.createTime && params.createTime.length > 0 ){
                    params.starttime = moment(params.createTime[0]).format('YYYY-MM-DD');
                    params.endtime = moment(params.createTime[1]).format('YYYY-MM-DD');
                }
                params.recordType=e;
                this.props.searchFormSubmit(params);
            }
        })
    }

    render() {

        const { getFieldDecorator } = this.props.form;
        const _this = this;
        return (
            <div>
                <Form layout="inline" onSubmit={this.handlerSubmit.bind(this)}>
                    <Form.Item>
                    { getFieldDecorator("busType",{
                        })(
                            <Select placeholder="发放账户性质" style={{ width: 130 }} onChange={this.handelBusType}>
                                <Option value ="P">对私</Option> 
                                <Option value ="C">对公</Option>
                            </Select>
                        ) }
                    </Form.Item>
                    <Form.Item>
                    { getFieldDecorator("recordType",{
                        })(
                            <Select placeholder="查询记录性质" style={{ width: 135 }} onChange={this.handelrecordType}>
                                <Option value ="58">全部</Option> 
                                <Option value ="01">失败已处理记录</Option> 
                                <Option value ="00">失败未处理记录</Option>
                            </Select>
                        ) }
                    </Form.Item>
                    <Form.Item>
                        { getFieldDecorator("strName",{
                        })(
                            <Input placeholder={_this.state.selectType==='C'?"发放失败对公户名":"发放失败员工姓名"} style={{ width: 140 }} />
                        ) }
                    </Form.Item> 
                    <Form.Item>
                        { getFieldDecorator("strNo",{
                        })(
                            <Input placeholder={_this.state.selectType==='C'?"发放失败对公账号":"发放失败员工身份证号"} style={{ width: 165 }}  />
                        ) }
                    </Form.Item> 
                    <Form.Item>
                        { getFieldDecorator("batchNo",{
                        })(
                            <Input placeholder={"发薪批次号"} style={{ width: 165 }}  />
                        ) }
                    </Form.Item> 
                    <Form.Item>
                        { getFieldDecorator("createTime",{
                            })(
                                <RangePicker style={{ width: 220 }}
                                    ranges={{
                                        '当日': [moment(), moment()],
                                        '当月': [moment().startOf('month'), moment().endOf('month')],
                                    }}
                                 />
                            ) }
                    </Form.Item>
                    <Form.Item>
                        <Button style={{marginLeft : 3}} type="primary" htmlType="submit">搜索</Button>
                        <Button style={{ marginLeft: 6}} onClick={this.handlerReset.bind(this)} >重置</Button>
                    </Form.Item>
                </Form>
                
            </div>
        );
    }
}

SimplerSalaryPayFailQueryForm = Form.create()(SimplerSalaryPayFailQueryForm);

export default SimplerSalaryPayFailQueryForm;