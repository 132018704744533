import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Tooltip } from 'antd'
import styles  from './index.m.less'

const index = ({label, labelCol =6, isTip=false, children}) => {
    const labelHtml = <div className={styles.ellipsis} >{label}</div>
    return (
        <Row gutter={8} align="middle" className={styles.main} >
            <Col span={labelCol} style={{display : 'flex'}} >
                    {isTip ? <Tooltip placement="top" title={label}>{labelHtml}</Tooltip> : labelHtml}：
            </Col>
            <Col span={24 - labelCol} >
                {children}
            </Col>
        </Row>
    );
};

index.propTypes = {
    label : PropTypes.string.isRequired,
    isTip : PropTypes.bool,
};

export default index;