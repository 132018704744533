import React, { Component } from 'react';
import { Table, Alert, Button, Modal, Tabs, Icon, Col, Row } from 'antd'
import { invite, inviteCancel } from '../../api/staff';
import InviteOrCancelUser from './InviteOrCancelUser';
import StaffInviteOne from './StaffInviteOne';
import StaffInviteBatch from './StaffInviteBatch';
import StaffInviteListCommit from './StaffInviteListCommit';


const { TabPane } = Tabs;
class StaffInviteResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
        }
    }

    showInviteModal() {
        this.setState({
            showModal: true,
        })
    }

    handleCancel() {
        this.setState({
            showModal: false,
        })
    }

    cancelCommit() {
        this.props.cancelCommit();
    }

    commit() {
        this.props.commit();
    }

    showCommit() {
        this.props.showCommit();
    }

    render() {
        const _this = this;
        const columns = [
            {
                title: '姓名', dataIndex: 'username', key: 'username', align: 'center',
                render(text, record, index) {
                    let jobNum = record.userjoinenterprise.jobNumber;
                    let userName = record.username;
                    return  userName;
                }
            },
            {
                title: '手机号', dataIndex: 'mobile', key: 'mobile', align: 'center'
            },
            { title: '身份证号', dataIndex: 'certinfo.certNo', key: 'certinfo.certNo', align: 'center' },
            {
                title: '邀请状态', dataIndex: 'userjoinenterprise.unionstatus', key: 'userjoinenterprise.unionstatus', align: 'center',
                render(val) {
                    if (val == '00') {
                        return '已关联';
                    } else if (val == '01') {
                        return '未邀请';
                    } else if (val == '02') {
                        return '已邀请';
                    } else if (val == '03') {
                        return '已失效';
                    } else if (val == '04') {
                        return '已取消';
                    } else if (val == '05') {
                        return '邀请中';
                    };
                    return "";
                }
            },
            {
                title: '操作', align: 'center',
                render(text, record, index) {
                    return (
                        <div>
                            <InviteOrCancelUser record={record} refreshSubmit={_this.props.refreshSubmit} />
                        </div>
                    )
                }
            },
        ]
        const pagination = {
            current: parseInt(_this.props.currentPage),
            total: _this.props.totalNum,
            pageSize : this.props.pageSize, 
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: _this.props.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }
        const rowSelection = {

            selectedRowKeys:this.props.selectFlowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys: selectedRowKeys
                })
                let rows = [];
                if (selectedRowKeys.length === selectedRows.length) {
                    rows = selectedRows;
                }
                if (selectedRowKeys.length > selectedRows.length) {
                    let allRows = [];
                    allRows = this.props.selectRows;
                    for (let i = 0; i < selectedRows.length; i++) {
                        allRows.push(selectedRows[i]);
                    }
                    let allRowsMap = new Map();
                    for (let i = 0; i < allRows.length; i++) {
                        allRowsMap.set(allRows[i].id, allRows[i]);
                    }
                    for (let i = 0; i < selectedRowKeys.length; i++) {
                        rows.push(allRowsMap.get(selectedRowKeys[i]));
                    }
                }

                if (selectedRowKeys.length != 0) {
                    this.props.getSelectKeys(selectedRowKeys);
                    this.props.getSelectRows(rows);
                } else {
                    rows = [];
                    this.props.getSelectKeys(selectedRowKeys);
                    this.props.getSelectRows(rows);
                }

            },
            getCheckboxProps: record => ({
                name: record.id,
                disabled: record.userjoinenterprise.unionstatus === '02' || record.userjoinenterprise.unionstatus === '05'
            }),
        };
        return (
            <div style={{ marginTop: '20px' }}>
                <Alert style={{ marginTop: '10px' }} message={(
                    <span>查询结果</span>
                )} type="info" showIcon />

                <div style={{ marginTop: 10 }} >
                    <Row gutter={24}>
                        <Col span={4}> <StaffInviteListCommit resultData={this.props.selectRows} cancelCommit={this.cancelCommit.bind(this)}
                            showCommit={this.showCommit.bind(this)} showCommitFlag={this.props.showCommitFlag} commit={this.commit.bind(this)}  buttonFlag={this.props.buttonFlag}/></Col>
                        {/* <Col span={3}  offset={17}> <Button type="primary" icon="plus-circle" onClick={this.showInviteModal.bind(this)} >员工邀请</Button></Col> */}
                        {/* <Button type="primary" disabled={this.props.buttonFlag} onClick={this.showCommitList.bind(this)} loading={this.props.commitloading}>提交邀请</Button>
                    */}
                    </Row>
                </div>

                <Table style={{ marginTop: '20px' }}
                    rowKey={record => record.id}
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData}
                    columns={columns}
                    rowSelection={rowSelection}
                    bordered
                    pagination={pagination}
                >
                </Table>
{/* 
                <div style={{ marginTop: 10 }} >
                    <Modal
                        title="员工邀请"
                        visible={this.state.showModal}
                        onCancel={this.handleCancel.bind(this)}
                        footer={null}
                        width={600}
                    >
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={<span>
                                <Icon type="plus-circle" />
                                员工邀请
                            </span>} key="1">
                                <StaffInviteOne refreshSubmit={this.props.refreshSubmit} />
                            </TabPane>
                            <TabPane tab={<span>
                                <Icon type="file-excel" />
                                员工批量邀请
                            </span>} key="2">
                                <StaffInviteBatch refreshSubmit={this.props.refreshSubmit} />
                            </TabPane>
                        </Tabs>
                    </Modal>
                </div> */}


            </div>
        );
    }
}

export default StaffInviteResult;