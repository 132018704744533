import React, { Component } from 'react';
import { Table, Alert, Button, Modal, Tabs, Icon, Message } from 'antd'
import { formatDate } from 'utils/Date'
import RelateOperate from './RelateOperate';
import AddRelationByChoose from '@/components/supplierManage/AddRelationByChoose';
import { addUserRelationByChoose } from '@/api/userRelatedEnterpise';
import AddRelationByExcel from './AddRelationByExcel';
const { TabPane } = Tabs;

class SupplierStaffResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            username: JSON.parse(global._sessionStorage.getItem("user")).username,
            selectedUser: [],
            addChooseLoading: false,
        }
    }

    showAddModal() {
        this.setState({
            showModal: true,
        })
    }

    handleCancel() {
        this.setState({
            showModal: false,
            selectedUser: [],
        })
    }

    getSelectUserKeys(selectUserKeys) {
        this.setState({
            selectedUser: selectUserKeys
        })
    }

    addChooseLoadingChange(state) {
        this.setState({
            addChooseLoading: state
        })
    }

    addRelationByChoose(params) {
        params.userList = this.state.selectedUser;
        params.operator = this.state.username;
        addUserRelationByChoose(params).then(res => {
            if ("200" === res.data.status) {
                Message.success(res.data.message);
                this.setState({
                    showModal: false,
                    selectedUser: [],
                    addChooseLoading: false,
                })
                this.props.refreshSubmit();
            } else {
                Message.error(res.data.message);
                this.setState({
                    addChooseLoading: false,
                })
            }

        });
    }

    desensitization(str) {
        if (typeof str == 'string') { //参数为字符串类型
            let ruten = str.substring(4, str.length - 4); //提取字符串下标之间的字符。
            return str.replace(ruten, '*****'); //字符串中用字符替换另外字符，或替换一个与正则表达式匹配的子串。
        }

    }

    userDataExport(){
        this.props.userDataExport();
    }
    render() {
        const _this = this;
        const columns = [
            {
                title: '姓名', width:'10%', dataIndex: 'username', key: 'username', align: 'center'
            },
            {
                title: '手机号', width:'10%', dataIndex: 'mobile', key: 'mobile', align: 'center'
            },
            {
                title: '身份证号', width:'15%', dataIndex: 'certinfo.certNo', key: 'certinfo.certNo', align: 'center',
                render(val) {
                    return _this.desensitization(val);
                }
            },
            {
                title: '用工企业商户号', width:'9%', dataIndex: 'enUserRelatedEnterprise.relatedEid', key: 'enUserRelatedEnterprise.relatedEid', align: 'center'
            },
            {
                title: '用工企业名称', width:'22%', dataIndex: 'enUserRelatedEnterprise.relatedEname', key: 'enUserRelatedEnterprise.relatedEname', align: 'center'
            },
            {
                title: '关联日期', width:'15%', dataIndex: 'enUserRelatedEnterprise.createTime', key: 'enUserRelatedEnterprise.createTime', align: 'center'
            },
            {
                title: '关联状态', width:'9%', dataIndex: 'relatedState', key: 'relatedState', align: 'center',
                render(val) {
                    if (val == '00') {
                        return '未关联';
                    } else if (val == '01') {
                        return '已关联';
                    }
                    return "";

                }
            },
            {
                title: '操作', width:'10%', align: 'center',
                render(text, record, index) {
                    return (
                        <div>
                            <RelateOperate record={record} refreshSubmit={_this.props.refreshSubmit} />
                        </div>
                    )
                }
            },
        ]
        const pagination = {
            current: parseInt(this.props.currentPage),
            total: this.props.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.props.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }
        return (
            <div style={{ marginTop: '20px' }}>
                <Alert style={{ marginTop: '10px' }} message={(
                    <span>查询结果</span>
                )} type="info" showIcon />

                <div style={{ marginTop: 10 }} >
                    <Button type="primary" icon="plus-circle" onClick={this.showAddModal.bind(this)} >关联企业</Button>
                    <Button type="primary" icon="export" style={{ marginLeft: 20 }} onClick={this.userDataExport.bind(this)} >数据导出</Button>
                </div>

                <Table style={{ marginTop: '20px' }}
                    rowKey={record => record.id}
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData}
                    columns={columns}
                    bordered
                    pagination={pagination}
                >
                </Table>

                <div>
                   { this.state.showModal&&<Modal
                        title="新增关联关系"
                        visible={this.state.showModal}
                        onCancel={this.handleCancel.bind(this)}
                        footer={null}
                        width={800}
                    >
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={<span>
                                <Icon type="plus-circle" />
                                选择关联
                            </span>} key="1">
                                <AddRelationByChoose 
                                    selectedUser={this.state.selectedUser} 
                                    getSelectUserKeys={this.getSelectUserKeys.bind(this)}
                                    addRelationByChoose={ this.addRelationByChoose.bind(this) } 
                                    addChooseLoading={this.state.addChooseLoading}
                                    addChooseLoadingChange={this.addChooseLoadingChange.bind(this)} 
                                />

                            </TabPane>
                            <TabPane tab={<span>
                                <Icon type="file-excel" />
                                文件导入
                            </span>} key="2">
                                <AddRelationByExcel refreshSubmit={this.props.refreshSubmit}/>
                            </TabPane>
                        </Tabs>
                    </Modal>}
                </div>

            </div>
        );
    }
}

export default SupplierStaffResult;