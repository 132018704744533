import React, { Component } from 'react';
import { Message } from 'antd'
import { querySalaryPayFail } from 'api/RechargeController'
import SimplerSalaryPayFailQueryForm from '@/components/finance/SimplerSalaryPayFailQueryForm'
import SimplerSalaryPayFailQueryResult from '@/components/finance/SimplerSalaryPayFailQueryResult'

class SimplersalaryPayFail extends Component {
    constructor(){
        super();
        this.state = {
            loading : false,
            searchParam : {},
            sourceData : [],
            totalNum: '',
            pageSizeOptions: global.pageInfo.sizeOptions, 
            currentPage : '1',
            pageSize : '10',
            busType : 'P',
        }
    }

    currentPageChange(currentPage){
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage,
            searchParam : params,
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize){
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage : '1',
            pageSize,
            searchParam : params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params){
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;

        this.setState({
            currentPage: '1',
            searchParam : params
        })
        this.searchPost(params);
    }

    searchPost(params){

        this.setState({
            loading : true,
            busType : params.busType,
        })
        
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        querySalaryPayFail(params).then(res => {
            let {errorCode, errorMessage} = res.data;
            if(errorCode === '0000'){
                this.setState({
                    sourceData : res.data.list,
                    totalNum : res.data.totalRow,
                    busType : res.data.busType,
                })
            }else{
                Message.error(errorMessage);
            }
            this.setState({
                loading : false
            })
        })
    }

    refreshSubmit(){
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    resetForm(){
        this.setState({
            searchParam : {}
        })
    }

    componentDidMount(){
        this.searchFormSubmit({});
    }


    render() {
        return (
            <div>
                <SimplerSalaryPayFailQueryForm 
                    loading={this.state.loading}
                    searchFormSubmit={this.searchFormSubmit.bind(this)} 
                    resetForm={this.resetForm.bind(this)}
                />
                
                <SimplerSalaryPayFailQueryResult 
                    loading={this.state.loading} 
                    resultData={this.state.sourceData} 
                    totalNum={this.state.totalNum} 
                    refreshSubmit={this.refreshSubmit.bind(this)} 
                    currentPageChange={this.currentPageChange.bind(this)}
                    pageSizeChange={this.pageSizeChange.bind(this)}
                    currentPage={this.state.currentPage}
                    busType={this.state.busType}
                    pageSizeOptions={this.state.pageSizeOptions} 
                    pageSize={this.state.pageSize}
                />

            </div>
        );
    }
}

export default SimplersalaryPayFail;