import React, { Component } from 'react';
import { Form, Message, Upload, Button, Icon, DatePicker, message, Descriptions, Select } from 'antd';
import moment from 'moment';
import { formatDate } from 'utils/Date';
import { uploadSalary } from 'api/transPlatform';
import { querySalaryAccount} from 'api/weeksalary'
const { Option } = Select;

@Form.create()
class CreatePayRollUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            loading: false,
            fileList: [],
            payChannelFlag:false,
            ortherChannelFlag:false,
            ortherPayChannel: [],
        }
    }
    componentWillMount(){
        let params = {
            enterpriseid : JSON.parse(global._sessionStorage.getItem("user")).enterpriseid ,
        };
        querySalaryAccount(params).then(res => {
            let { errorCode, data } =res.data;
            if(errorCode === '0000' ){
                if(data && data.length > 0){
                    this.setState({              
                        payChannelFlag : true,          
                        ortherPayChannel : data,
                    })
                }
            }
        })
    }

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }

    changePayChannel(value){
        this.setState({
            ortherChannelFlag: value==='2'
        })
    }

    handlerSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({ loading: true })
                if (this.state.fileList.length === 0) {
                    message.error('请上传发放单文件')
                    this.setState({ loading: false })
                    return;
                }

                let formData = new FormData();
                formData.append("file", this.state.fileList[0]);
                formData.append("eid", this.state.enterpriseid);
                formData.append("operatorId", JSON.parse(global._sessionStorage.getItem('user')).username);
                formData.append("salaryDate", moment(params.salaryDate).format('YYYY-MM-DD'));
                formData.append("payChannel", params.payChannel);
                params.otherChannelId && formData.append("otherChannelId", params.otherChannelId);
                formData.append("businessOrderNo", this.props.record.outOrderNo);
                formData.append("cycleCate", this.props.businessType);
                formData.append("busType", 'B');

                uploadSalary(formData).then(res => {
                    if (res.data.errorCode === '0000') {
                        this.props.onCancelCreate();
                        if (this.props.onCancel) {
                            this.props.onCancel();
                        }
                        this.props.onRefresh();
                        Message.success(res.data.errorMessage);
                    } else {
                        Message.error(res.data.errorMessage);
                    }
                    this.setState({ loading: false });
                })
            }
        })
        // this.setState({ loading: false })
    }

    render() {
        const { fileList, loading } = this.state;
        const { record } = this.props;
        const { getFieldDecorator } = this.props.form;
        const _this = this;
        const formItemStyle = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 }
        }
        const otherChannelOptions = this.state.ortherPayChannel.map( type =>{
            return <Option key={type.id} value={type.id} >{type.bankName}</Option>
        } )
        const props = {
            accept: ".xls,.xlsx",
            multiple: false,
            beforeUpload: (file) => {
                if (fileList.length !== 0) {
                    Message.error("仅支持单个文件上传！");
                    return false;

                }
                _this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            onRemove: (file) => {
                _this.setState({
                    fileList: []
                });
            },
            fileList: _this.state.fileList,
        }

        return (
            <div>
                {/* <div style={{ marginBottom: 20 }}>
                    <Descriptions title="订单信息">
                        <Descriptions.Item label="订单编号">{record.outOrderNo}</Descriptions.Item>
                        <Descriptions.Item label="剩余金额">{record.balance}</Descriptions.Item>
                    </Descriptions>
                </div> */}
                {/* <Divider /> */}
                <div className="user-modify-form" >
                    <Form {...formItemStyle} onSubmit={this.handlerSubmit} >
                        <Form.Item label="发放日期">
                            {
                                getFieldDecorator('salaryDate', {
                                    rules: [
                                        { required: true, message: '请选择发放日期' }
                                    ]
                                })(<DatePicker
                                    format="YYYY-MM-DD"
                                />)
                            }
                        </Form.Item>
                        <Form.Item label="发放单文件上传" >
                            {
                                (<Upload {...props}>
                                    <Button>
                                        <Icon type="upload" /> 上传文件
                                    </Button>
                                </Upload>)
                            }
                        </Form.Item>

                        <Form.Item label="发放平台" >
                            { getFieldDecorator("payChannel",{
                                rules : [
                                    { required: true, message: '请选择发放平台' }
                                ]
                            })(
                                <Select placeholder="发放平台" style={{width:"65%"}} onChange={this.changePayChannel.bind(this)}>
                                    <Option value='1'>薪企链</Option>
                                    {this.state.payChannelFlag && <Option value='2'>其他平台</Option>}
                                </Select>
                            ) }
                        </Form.Item>
                        <div hidden={!this.state.ortherChannelFlag}>
                            <Form.Item  label="其他平台">
                                { getFieldDecorator("otherChannelId",{
                                    rules : [
                                        { required: this.state.ortherChannelFlag, message: '请选择平台'}
                                    ]
                                })(     
                                    <Select placeholder="发放平台" style={{width:"65%"}}>
                                        {otherChannelOptions}
                                    </Select>
                                ) }
                            </Form.Item>
                        </div>
                        <div style={{ marginTop: 20, marginLeft: '15%' }} >
                            <Button type="primary" onClick={this.handlerSubmit.bind(this)} loading={loading} style={{ marginLeft: 50 }} >确认</Button>
                            <Button type="primary" onClick={this.props.onCancelCreate} style={{ marginLeft: 50 }} >取消</Button>
                        </div>
                    </Form>
                </div>
            </div>

        );
    }
}
export default CreatePayRollUpload;