import {http} from '../utils/http'

export function queryRoleList(data){
    return http.post("/api/role/selectRole",data);
}

export function queryBranchList(data){
    return http.post("/branch/query",data);
}

export function queryMenuList(data){
    return http.post("/api/role/menuTree",data);
}

export function saveRole(data){
    return http.post("/api/role/createRole",data);
}

export function deleteRole(data){
    return http.post("/api/role/deleteRoleById",data);
}

export function queryRoleParam(data){
    return http.post("/sys/dictList",data);
}

export function updateRoleInfo(data){
    return http.post("/api/role/updateRoleById",data);
}

export function updateRoleMenu(data){
    return http.post("/api/role/updateMenu",data);
}
