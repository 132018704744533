import React, { Component } from 'react';
import { Table, Alert,Divider } from 'antd';
import moment from 'moment';
import {Link} from 'react-router-dom' 
import CreatePayRollDelete from './CreatePayRollDelete';
import CreatePayRollOperate from './CreatePayRollOperate';
import CreatePayRollSubmit from './CreatePayRollSubmit';

class SimplerCreatePayRollResult2 extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const _this = this;
        const columns = [
           
             { title : '批次',  dataIndex : 'salarybatchcode', key : 'salarybatchcode', align : 'center',
                render(text, record, index){
                    return (
                        <div>
                            <Link to={{ pathname : '/simplerCreatePayRollDetail2' , state : {currentFlag:_this.props.currentFlag, batchid: record.id,cyclecate:'month' }}} >{record.salarybatchcode}</Link>
                        </div>
                    )
                }
             },
             { title : '发放月份',  dataIndex : 'salarydate', key : 'salarydate', align : 'center',
                
             },
             { title : '提交时间',  dataIndex : 'submittime', key : 'submittime', align : 'center',
                
             },
             { title : '实发总额',  dataIndex : 'totalnum', key : 'totalnum', align : 'center',
                
             },
             { title : '总笔数',  dataIndex : 'employeenum', key : 'employeenum', align : 'center',
                
            },
            { title : '发放属性',  dataIndex : 'salaryCycleCateEnum', key : 'salaryCycleCateEnum', align : 'center',
                
            },
            { title : '发放平台', dataIndex : 'payChannel', key : 'payChannel', align : 'center',
                render(val){
                    if(val==='1' ){
                        return '薪企链';
                    }else{
                        return '虎符发放';
                    }
                } 
            },
            { title : '摘要',  dataIndex : 'roundup', key : 'roundup', align : 'center',
                
            },
            { title : '状态',  dataIndex : 'checkstatus', key : 'checkstatus', align : 'center',
                render(text, record, index){
                    let status=record.checkstatus;
                    if(status=="00"){
                        status="待审核";
                    }else if(status=="01"){
                        status="审核通过";
                    }else if(status=="03"){
                        status="已提交";
                    }else if(status=="04"){
                        status="未提交";
                    }else if(status=="05"){
                        status="审核拒绝";
                    }else if(status=="06"){
                        status="正在发放";
                    }else if(status=="07"){
                        status="已发放";
                    }else if(status=="08"){
                        status="发放失败";
                    }else{
                        status="";
                    }
                    return status;
                }
            },
             { title : '操作', align : 'center',
             render(text, record, index){
                let status=record.checkstatus;
                if(status=='00' ||status=='05'){
                    return (
                        <div>
                               <CreatePayRollDelete record={record} refreshSubmit={_this.props.refreshSubmit}/> 
                        </div>
                    ) 
                }else if(status=='04'){
                    return (
                        <div>  
                               <CreatePayRollSubmit record={record} refreshSubmit={_this.props.refreshSubmit}/>
                               <Divider type="vertical" />
                               <CreatePayRollDelete record={record} refreshSubmit={_this.props.refreshSubmit}/> 
                        </div>
                    ) 
                }else if(status=='03'){
                    return (
                        <div>  
                               <CreatePayRollOperate record={record} refreshSubmit={_this.props.refreshSubmit}/>
                               <Divider type="vertical" />
                               <CreatePayRollDelete record={record} refreshSubmit={_this.props.refreshSubmit}/> 
                        </div>
                    ) 
                }
                       
                return (
                    <div>
                       
                    </div>
                ) 
             }
         },
             

        ]
      const pagination ={
            current : parseInt(this.props.currentPage),
            total: this.props.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : this.props.pageSizeOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }

        return (
            <div style={{marginTop : '20px'}}>

                <Alert style={{marginTop : '10px'}} message={(
                                    <span>查询结果</span>
                                )} type="info" showIcon />
                <Table style={{marginTop : '20px'}} 
                    rowKey = { record => record.id }
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData} 
                    columns={columns}
                    bordered
                    pagination={pagination}
                >
                </Table>
                   
            </div>
        );
    }
}

export default SimplerCreatePayRollResult2;