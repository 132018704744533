import React, { Component } from 'react';
import { Form, DatePicker, Input, Select, Button } from 'antd'
import moment from 'moment';
import { Link } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Option } = Select;


class SimplerSalaryPaySearchForm extends Component {

    constructor() {
        super();
        this.state = {
            link: true,
            gotoLinkUrl: "/simplerFacPay",
            gotoLinkParms: 6,
        }
    }

    refreshSubmit(e) {
        this.handlerSubmit(e);
    }

    handlerSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            console.log(err);
            if (!err) {
                if (params.createTime && params.createTime.length > 0) {
                    params.audittimeS = moment(params.createTime[0]).format('YYYY-MM-DD');
                    params.audittimeE = moment(params.createTime[1]).format('YYYY-MM-DD');
                }
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset() {
        this.props.form.resetFields();
        this.props.resetForm();
    }

    componentDidMount(){
        this.gotoLink();
    }

    gotoLink() {
        if (this.props.currentFlag === 0) {
            this.setState({
                gotoLinkUrl: '/simplerPay',
                gotoLinkParms : 4,
            });
            //this.props.history.push({ pathname: '/simplerPay', state: { current: 4 } });          
        } else if (this.props.currentFlag === 1){
            this.setState({
                gotoLinkUrl: '/simplerFacPay',
                gotoLinkParms : 3,
            });
            //this.props.history.push({ pathname: '/simplerFacPay', state: { current: 3 } });
        }else {
            this.setState({
                gotoLinkUrl: '/simplerFacPay',
                gotoLinkParms : 6,
            });
            //this.props.history.push({ pathname: '/simplerFacPay', state: { current: 6 } });
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Form layout="inline" onSubmit={this.handlerSubmit.bind(this)}>
                    <Form.Item>
                        {getFieldDecorator("createTime", {
                        })(
                            <RangePicker style={{ width: 250 }}
                                ranges={{
                                    '当日': [moment(), moment()],
                                    '当月': [moment().startOf('month'), moment().endOf('month')],
                                }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator("batchS", {
                        })(
                            <Input placeholder="批次号" style={{ width: 145 }} />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator("status", {
                        })(
                            <Select placeholder="发放单状态" style={{ width: 155 }}>
                                <Option value ="">全部</Option>
                                <Option value ="00">待审核</Option> 
                                <Option value ="01">审核通过</Option>
                                <Option value="05">审核拒绝</Option>
                                <Option value="06">正在发放</Option>
                                <Option value="07">已发放</Option>
                                <Option value="08">发放失败</Option>
                                <Option value="10">保理划款成功</Option>
                                <Option value="11">保理划款中</Option>
                                <Option value="12">保理划款失败</Option>
                                <Option value="A0">关联企业待审核</Option>
                                <Option value="A1">关联企业审核通过</Option>
                                <Option value="A5">关联企业审核拒绝</Option>
                                <Option value="A8">关联企业发放失败</Option>
                                <Option value="30">甲方企业待审核</Option>
                                <Option value="35">甲方企业审核拒绝</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator("cyclecate", {
                        })(
                            <Select placeholder="发放属性" style={{ width: 105 }}>
                                <Option value="">全部</Option>
                                {/* <Option value="week">周薪</Option>
                                <Option value="day">日薪</Option> */}
                                <Option value="month">月薪</Option>
                                <Option value="coreEn">关联企业</Option>
                                <Option value="supEn">用工企业</Option>
                                <Option value="advance">预付金</Option>
                                <Option value="supervise">结算监管</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">搜索</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handlerReset.bind(this)} >重置</Button>
                        {this.props.showHistoryBtn ?
                            <Button style={{ marginLeft: 8 }} type="primary">
                                <Link to={{pathname :"/simplerHistoryQuery" , state:{ currentFlag:this.props.currentFlag}}}>审核历史查询</Link>
                            </Button>
                            : ""}
                        {this.props.showReturnSalaryPayBtn ?
                            < Button  style={{ marginLeft: 8 }} type="primary">
                                <Link to={{pathname : this.state.gotoLinkUrl , state:{ current:this.state.gotoLinkParms}}}>返回薪资发放 </Link>                              
                            </Button>
                            : ""}
                    </Form.Item>
                </Form>


            </div >
        );
    }
}

SimplerSalaryPaySearchForm = Form.create()(SimplerSalaryPaySearchForm);

export default SimplerSalaryPaySearchForm;