import React, { Component } from 'react';
import { Form, Button, message, Table, Select } from 'antd';
import { ModalWrapper } from 'wrapper';
import { formatDate } from 'utils/Date';
import { businessOrderList } from 'api/transPlatform';
import CreatePayRollUpload from './CreatePayRollUpload';

const { Option } = Select;

@Form.create()
class BusinessOrderList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            bankCode: JSON.parse(global._sessionStorage.getItem('user')).bankCode,
            loading: false,
            selectedRowKeys: [],
            selectedRows: {},
            createVisiable: false,
            record: {},
            fianceList: [],
            businessType: undefined,
        }
    }

    componentDidMount() {
        this.onSelect("advance");
    }

    onSelect = (value) => {
        let params = {};
        params.eid = this.state.enterpriseid;
        params.bankCode = this.state.bankCode;
        // params.eid = 10840001
        params.businessType = value;
        this.setState({ loading: true })
        this.setState({businessType: value})
        businessOrderList(params).then(res => {
            if (res.data.errorCode === '0000') {
                let list = res.data.data.list;
                this.setState({ 
                    selectedRowKeys: [],
                    selectedRows: {},
                    fianceList: list ,
                })
            } else {
                message.error(res.data.errorMessage)
            }
            this.setState({ loading: false })
        })
    }

    onCancelCreate = () => {
        this.setState({ createVisiable: false })
    }


    showCreate = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.error('请选择一笔业务订单')
            return
        }
        this.setState({
            createVisiable: true,
            record: this.state.selectedRows[0]
        })
    }

    handleChange = (value) => {
        this.onSelect(value)
    }
    render() {
        const { loading, createVisiable, record, fianceList, businessType } = this.state;


        const columns = [
            {
                key: 'outOrderNo',
                title: '订单号',
                align: 'center',
                dataIndex: 'outOrderNo',
                width: '40%',
            },
            {
                key: 'virAccountType',
                title: '发放类型',
                align: 'center',
                dataIndex: 'virAccountType',
                width: '30%',
                render(val) {
                    if (val === '1') {
                        return '预付款';
                    } else if (val === '2') {
                        return '结算';
                    } else {
                        return '--';
                    }
                }
            },
            {
                key: 'balance',
                title: '剩余金额',
                align: 'center',
                dataIndex: 'balance',
                width: '30%',
            },

        ];

        const rowSelection = {
            type: 'radio',
            checkStrictly: false,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys: selectedRowKeys })
                this.setState({ selectedRows: selectedRows })
            },
        };
        return (
            <div style={{ marginBottom: 20 }}>
                <div>
                    <h4>订单列表</h4>
                    <div>
                        <Select defaultValue="advance" style={{ width: 120 }} onChange={(value)=>this.handleChange(value)}>
                            <Option value="advance">预付金</Option>
                            <Option value="supervise">结算监管</Option>
                        </Select>
                    </div>
                    {this.state.businessType==='advance' && <Table style={{ marginTop: '20px' }}
                        rowKey={record => record.outOrderNo}
                        loading={loading}
                        size="middle "
                        dataSource={fianceList}
                        rowSelection={rowSelection}
                        columns={columns}
                        bordered
                        pagination={false}
                    >
                    </Table>}
                    {this.state.businessType==='supervise' && <Table style={{ marginTop: '20px' }}
                        rowKey={record => record.outOrderNo}
                        loading={loading}
                        size="middle "
                        dataSource={fianceList}
                        rowSelection={rowSelection}
                        columns={columns}
                        bordered
                        pagination={false}
                    >
                    </Table>}
                    <div style={{ marginTop: 20, textAlign: 'center' }} >
                        <Button type="primary" onClick={(this.showCreate.bind(this))} style={{ marginLeft: 50 }} >确认</Button>
                        <Button type="primary" onClick={this.props.onCancel} style={{ marginLeft: 50 }} >取消</Button>
                    </div>
                </div>
                <div>
                    {
                        createVisiable && <ModalWrapper
                            width={600}
                            bodyStyle={{ padding: '50px 40px 48px' }}
                            destroyOnClose
                            title="新增发放"
                            visible={createVisiable}
                            footer={false}
                            onCancel={this.onCancelCreate.bind(this)}
                        >
                            <CreatePayRollUpload onCancelCreate={this.onCancelCreate.bind(this)} onCancel={this.props.onCancel} 
                                record={record} onRefresh={this.props.onRefresh} businessType={businessType} />
                        </ModalWrapper>
                    }
                </div>
            </div>


        );
    }
}
export default BusinessOrderList;