import React from 'react';
import classNames from 'classnames';
import { Row } from 'antd';
import './index.less';

const DescriptionList = ({
  className,
  title,
  col = 3,
  gutter = 32,
  children,
  size,
}) => {
  const clsString = classNames("descriptionList",className);
  const column = col > 4 ? 4 : col;

  return (
    <div className={clsString} >
      {title ? <div className="title">{title}</div> : null}
      <Row gutter={gutter} style={{marginLeft : 16}}>
        {React.Children.map(children, child =>
          child ? React.cloneElement(child, { column }) : child
        )}
      </Row>
    </div>
  );
};

export default DescriptionList;
