import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Tooltip } from 'antd';
import SelfIcon  from 'coms/Common/SelfIcon'
import '@/assets/css/simplerMenu.less'


const SubMenu = Menu.SubMenu;

class SimplerSiderMenu extends Component {

    constructor(){
        super();
        this.state={
            openKeys : [],
            subOpenKeys : [],
        }
    }

    menuClick(e) {
        this.props.siderClick(e.key);
    }



    handlerOnOpenChange(openKeys){
        if(openKeys.length !== 0){
            const item = this.props.routesMenu.filter( menu => menu.path === openKeys[openKeys.length-1]);
            if( item.length !== 0 ){
                this.setState({
                    openKeys : [openKeys[openKeys.length-1]]
                })
            }else{
                const itemRoot = this.props.routesMenu.filter( menu => menu.path === openKeys[0]);
                if(itemRoot.length !== 0){
                    const subItem = itemRoot[0].children.filter( menu => menu.path === openKeys[openKeys.length-1]);
                    if(subItem !==0 ){
                        this.setState({
                            openKeys : [openKeys[0], openKeys[openKeys.length-1]]
                        })
                        
                    }else{
                        this.setState({
                            openKeys : openKeys
                        })
                    }
                }else{
                    this.setState({
                        openKeys : []
                    })
                }
            }
        }else{
            this.setState({
                openKeys : openKeys
            })
        }
    }

    getIcon = (icon) =>{
        if(icon.indexOf("xh-") === 0) {
            return <SelfIcon className='self-icon' type={icon} />
        }else{
            return <Icon type={icon} />
        }

    }

    render() {
        const menuHtml = this.props.routesMenu.map((menu, index) => {
            
                return <Menu.Item key={menu.path} style ={{ backgroundColor: '#072667',marginLeft: index!== 0 && '2%',marginRight: index!== this.props.routesMenu.length && '2%',lineHeight: '35px' }} className="simplerMenu" >
                    <Link to={menu.path}>
                    <div className="menu-img"><img src={menu.icon}/></div>
                    <div className="menu-name">{menu.name}</div></Link>
                </Menu.Item>
            
        });

        return (
            <Menu  theme="dark" className="menu"
            onClick={this.menuClick.bind(this)}
            // openKeys={this.state.openKeys.concat(this.state.subOpenKeys)}
            openKeys={this.state.openKeys}
            // selectedKeys = {[this.props.routesMenu[0].path] }
            defaultSelectedKeys={[this.props.history.location.pathname]}
            mode="horizontal"
            // inlineCollapsed={this.props.collapsed}
            onOpenChange = {this.handlerOnOpenChange.bind(this)}
            style ={{ backgroundColor: '#072667',color:'#fff' }}
            >
            {menuHtml}
            </Menu>
        );
    }
}

export default SimplerSiderMenu;