export const baseHttpUrl = "https://xql.51xinhai.com/enymxh/";

export const httpTimeOut = 60000;

export const fastDsfUrl = "https://ffds.51xinhai.com/";

export const kkFileUrl = "https://ffds.51xinhai.com/kkfile/"

export const kkFileKey = "XHapp803&!@7899";

export const publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAn5CbiuaiLG9Qg4eUJibbm1eOqau1T0uG9q6CvuNJjHZNogOlVVnYb1sAULko6Sk2ooy6/KVdypYanzAR0SYHIoejqIGvUhLUcOlZ3ftlDorVltQvIF12mJIE49Jn2wyaOjVGwF4fLajQaD7yQyFoEUM8oG8mbSF+RbAkfpYtXNSWE88K3tqcaRCCuXlO9DyiUcRbwg6N/hj2b2aN/elb5lFXRjaoX/kH3z/DXbHvPAiHDWPPK1AlmAkZp32AXJGsR/EBKc7+++gU9sAw/xdDE41SHyL6cDQkNYtAuaKFgPX4+nyImv21UAOBsgkkTgeHG8ucrYtsasjxf+wBFz/0swIDAQAB`;

//腾讯地图key
export const tencentMapKey = "QOGBZ-4PGYR-H6WWW-WG7MV-RZJGQ-JBFIH";