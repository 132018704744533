import React, { Component } from 'react';
import { Table, Message, Tabs, Col, Button } from 'antd'
import { uploadSalaryQuery, salaryBatchQuery, submitSalaryBatch } from 'api/weeksalary';
import CreatePayRollDetailResult from './CreatePayRollDetailResult';
import SimplerPay from 'views/simpler/simplerPay/SimplerPay';
import { Link } from 'react-router-dom'
const { TabPane } = Tabs;



class SimplerCreatePayRollDetail2 extends Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            tabPaneKey: 'P',
            searchParam: {},
            sourceData: [],
            totalNum: '',
            pageSizeOptions: global.pageInfo.sizeOptions,
            currentPage: '1',
            pageSize: '10',
            submitbutton: '',
            salarydate: '',
            employeenum: '',
            cyclecate: '',
            batchid: '',
            currentFlag : '',
            gotoLinkUrl: "",
            gotoLinkParms: "",
        }
    }

    batchQuery(params) {

        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        salaryBatchQuery(params).then(res => {
            this.setState({
                submitbutton: res.data.submitbutton,
                salarydate: res.data.salarydate,
                employeenum: res.data.employeenum

            });
        })
    }

    submitSalaryData() {
        let params = {};
        params.id = this.state.batchid;
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        params.phone = user.username;
        submitSalaryBatch(params).then(res => {
            if (res.data.result == "true") {
                if (this.state.cyclecate == "month") {
                    
   //                 console.log(this.state.currentFlag);
                    if(0===this.state.currentFlag){
                        this.props.history.push({ pathname: '/simplerPay', state: { current: 3 } });
                    }else{
                        this.props.history.push({ pathname: '/simplerFacPay', state: { current: 2 } });
                    }
                   
                   
                } else {
                    // this.props.history.push('/weeksalary');
                }

            } else {
                Message.error(res.data.message);
            }
        })
    }
    componentDidMount() {
        this.gotoLink();
        let params = this.state.searchParam;
        params.id = this.props.location.state.batchid;
        this.setState({
            searchParam: params,
            cyclecate: this.props.location.state.cyclecate,
            batchid: this.props.location.state.batchid,
            currentFlag : this.props.location.state.currentFlag,
        });
        this.batchQuery(params);
        // this.searchFormSubmit(params);
    }
    tabsChange = (e) => {
        this.setState({
            tabPaneKey: e,
        })
    }

    gotoLink() {
        if (this.props.location.state.currentFlag === 0) {
            this.setState({
                gotoLinkUrl: '/simplerPay',
                gotoLinkParms: 3,
            });
                
        } else  {
            this.setState({
                gotoLinkUrl: '/simplerFacPay',
                gotoLinkParms: 2,
            });
          
        } 
    }
    render() {

        const _this = this;
        const pagination = {
            current: parseInt(this.state.currentPage),
            total: this.state.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.state.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.currentPageChange(current);
            }
        }


        const columns = this.state.columns;

        const operations = <Button type="primary" disabled={this.state.submitbutton != 'true'} onClick={_this.submitSalaryData.bind(this)}>提交数据</Button>;
        return (
            <div style={{ paddingTop: 68,paddingLeft:24,paddingRight:24  }}>
                <div style={{ marginBottom: 16 }}>
                    <b>发放单详情-></b> <label><b style={{ marginRight: 16, marginLeft: 16 }}>{this.state.salarydate}</b>
                        已上传：<span>{this.state.employeenum}</span>条记录</label>
                </div>
                <Tabs tabBarExtraContent={operations} defaultActiveKey={this.state.tabPaneKey} activeKey={this.state.tabPaneKey} onChange={this.tabsChange.bind(this)}>
                    {this.state.cyclecate == "week" ?
                        <TabPane tab="对私发放单明细" key="P">
                            <CreatePayRollDetailResult orderNo={this.props.location.state.batchid} bustype="P" />
                        </TabPane>
                        : ""}
                    {this.state.cyclecate == "month" ?
                        <TabPane tab="对私发放单明细" key="P">
                            <CreatePayRollDetailResult orderNo={this.props.location.state.batchid} bustype="P" />
                        </TabPane>
                        : ""}
                    {this.state.cyclecate == "month" ?
                        <TabPane tab="对公发放单明细" key="C">
                            <CreatePayRollDetailResult orderNo={this.props.location.state.batchid} bustype="C" />
                        </TabPane>
                        : ""}


                </Tabs>
                <Button style={{ marginLeft: '45%' }} type="primary" >
                    <Link to={{ pathname: this.state.gotoLinkUrl, state: { current: this.state.gotoLinkParms } }}>返回上一级 </Link>
                </Button>
            </div>
        );
    }
}

export default SimplerCreatePayRollDetail2;