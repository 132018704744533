import React, { Component } from 'react';

import EnDepartIncreInfo from '@/components/department/EnDepartIncreInfo'

class SimplerDepartIncrease extends Component {
    render() {
        return (
            <div style={ {width : '80%', marginTop : 50, marginLeft : 30 } } >
                <EnDepartIncreInfo/>
            </div>
            
        );
    }
}

export default SimplerDepartIncrease;