import React, { Component } from 'react';
import { Message } from 'antd';
import { querySupplierSalaryBatch, queryEmployerList, uploadSupplierSalarys } from 'api/supplierManage';
import CreateSupplierPayRollSearch from './CreateSupplierPayRollSearch';
import CreateSupplierPayRollResult from './CreateSupplierPayRollResult';


class CreateSupplierPayRoll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            searchParam: {},
            resultData: [],
            totalNum: '',
            pageSizeOptions: global.pageInfo.sizeOptions,
            currentPage: '1',
            pageSize: '10',
            employerList: [],


        }
    }

    currentPageChange(currentPage) {
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage: currentPage,
            searchParam: params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize) {
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage: '1',
            pageSize,
            searchParam: params
        });
        this.searchPost(params);
    }

    searchFormSubmit(params) {
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam: params
        })
        this.searchPost(params);
    }

    resetForm() {
        this.setState({
            searchParam: {}
        })
    }

    refreshSubmit() {
        let params = this.state.searchParam;
        this.searchPost(params);
    }

    searchPost(params) {

        this.setState({
            loading: true
        })
        let user = JSON.parse(global._sessionStorage.getItem("user"));
        params.enterpriseid = user.enterpriseid;
        params.userId = user.userId;
        querySupplierSalaryBatch(params).then(res => {

            // let { errorCode, data } =res.data;
            // if(errorCode === '0000'){
            this.setState({
                totalNum: Number(res.data.totalRow),
                resultData: res.data.list
            })
            // }
            this.setState({
                loading: false
            })
        })
            .catch(error => {
                this.setState({
                    loading: false
                })
                Message.error('error');
            });
    }

    componentWillMount() {
        let user = JSON.parse(global._sessionStorage.getItem("user"));

        let params = {
            enterpriseid: user.enterpriseid,
            userId: user.userId,
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            batchcode: "",
            status: "",
            salarydate: "",
            joinId: "",
        };


        this.setState({
            searchParam: params
        })

        queryEmployerList(params).then(res => {
            this.setState({
                employerList: res.data.list
            })

        })

        this.searchPost(params);
    }
    createPayUpload(formData) {
        uploadSupplierSalarys(formData).then(res => {
            if (res.data.errorCode === '0000') {
                Message.success(res.data.msg);
            } else {
                Message.error(res.data.msg);
            }


        })
    }



    render() {
        return (
            <div>
                <CreateSupplierPayRollSearch
                    searchFormSubmit={this.searchFormSubmit.bind(this)}
                    resetForm={this.resetForm.bind(this)}
                    createPayUpload={this.createPayUpload.bind(this)}
                    employerList={this.state.employerList}
                />
                <CreateSupplierPayRollResult
                    resultData={this.state.resultData}
                    totalNum={this.state.totalNum}
                    currentPage={this.state.currentPage}
                    loading={this.state.loading}
                    pageSizeOptions={this.state.pageSizeOptions}
                    pageSize={this.state.pageSize}
                    currentPageChange={this.currentPageChange.bind(this)}
                    pageSizeChange={this.pageSizeChange.bind(this)}
                    refreshSubmit={this.refreshSubmit.bind(this)}
                    employerList={this.state.employerList}
                />

            </div>
        );
    }
}

export default CreateSupplierPayRoll;