import React, { Component, Fragment } from 'react';
import { Form, Message, Spin, Icon, Divider } from 'antd';
import ConfirmPrpover from '@/components/Common/ConfirmPrpover';
import { ModalWrapper } from 'wrapper';
import moment from 'moment';
import Search from '@/components/Search';
import { formatDate } from 'utils/Date';
import { salaryBatchList, submitSalaryBatch, deleteSalaryBatch } from 'api/transPlatform';
import { baseHttpUrl } from '@/assets/settings';
import axios from 'axios';
import { getTokenStr } from '@/utils/http';
import SalaryBatchDetails from './SalaryBatchDetails';
import BusinessOrderList from './BusinessOrderList';
import styles from './index.m.less';

@Form.create()
class CreateTransPayRoll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            username: JSON.parse(global._sessionStorage.getItem('user')).username,
            salaryBatchList: [],
            total: 0,
            loading: false,
            startDate: '',
            endDate: '',
            visible: false,
            orderDetailVisiable: false,
            record: {},
            downloadTmpLoading: false
        }
    }

    onSubmit = values => {
        this.setState({
            loading: true,
        })
        values.eid = this.state.enterpriseid;
        values.startDate = values.startDate && moment(values.startDate).format('YYYYMMDD');
        values.endDate = values.endDate && moment(values.endDate).format('YYYYMMDD');
        salaryBatchList(values).then(res => {
            let { errorCode, errorMessage, data } = res.data;
            if (errorCode === '0000') {
                this.setState({
                    total: data.totalCount,
                    salaryBatchList: data.list,
                })
            } else {
                Message.error(errorMessage);
            }
            this.setState({
                loading: false,
            })
        })
    }
    onRefresh = () => {
        this.child.onRefresh();
    }

    onRef = (ref) => {
        this.child = ref
    }

    onStartChange = value => {
        this.setState({
            startDate: value,
        });
    };
    disabledEndDate = endDate => {
        const { startDate } = this.state;
        if (!endDate || !startDate) {
            return false;
        }
        return moment(endDate.format('YYYY-MM-DD'),'YYYY-MM-DD').valueOf() < moment(startDate.format('YYYY-MM-DD'),'YYYY-MM-DD').valueOf();
    };

    onEndChange = value => {
        this.setState({
            endDate: value
        });
    };
    disabledStartDate = startDate => {
        const { endDate } = this.state;
        if (!startDate || !endDate) {
            return false;
        }
        return moment(startDate.format('YYYY-MM-DD'),'YYYY-MM-DD').valueOf() > moment(endDate.format('YYYY-MM-DD'),'YYYY-MM-DD').valueOf();
    };
    onCancel = () => {
        this.setState({
            visible: false
        })
    }
    onShowOrderDetail = (record) => {
        this.setState({
            orderDetailVisiable: true,
            record: record,
        })
    }
    onOrderDetailCancel = () => {
        this.setState({
            orderDetailVisiable: false
        })
    }

    downloadTmp() {
        let params = {
            eid: this.state.enterpriseid,
        }
        this.setState({ downloadTmpLoading: true })
        axios({
            method: 'GET',
            url: baseHttpUrl + '/superviseSalary/downloadSalaryTmp',
            params: params,
            responseType: 'blob',
            headers: {
                'Token': getTokenStr(),
            },
        }).then(function (res) {
            const content = res.data
            const blob = new Blob([content], { type: "application/x-msdownload" })
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = res.headers['filename'];
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })
        this.setState({ downloadTmpLoading: false })
    }

    deleteRecord = (salarybatchcode) => {
        let params = {
            salaryBatchId: salarybatchcode,
            eid: this.state.enterpriseid,
            operatorId: this.state.username,
        }
        this.setState({
            loading: true,
        })
        deleteSalaryBatch(params).then(res => {
            let { errorCode, errorMessage } = res.data;
            if (errorCode === '0000') {
                Message.success('发放单删除成功');
            } else {
                Message.error(errorMessage);
            }
            this.onRefresh()
            this.setState({
                loading: false,
            })
        })
    }
    submitRecord = (salarybatchcode) => {
        let params = {
            salaryBatchId: salarybatchcode,
            eid: this.state.enterpriseid,
            operatorId: this.state.username,
        }
        this.setState({
            loading: true,
        })
        submitSalaryBatch(params).then(res => {
            let { errorCode, errorMessage } = res.data;
            if (errorCode === '0000') {
                Message.success('提交申请成功');
            } else {
                Message.error(errorMessage);
            }
            this.onRefresh()
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        const { total, salaryBatchList, loading, downloadTmpLoading, visible, orderDetailVisiable, record } = this.state;
        const antIcon = <Icon type="loading" spin />;
        const submitText = <span className="span-click" >提交</span>;
        const htmlText = <span className="span-click" >删除</span>;
        const loadIcon = <Icon type="loading" spin />;
        const search = [
            {
                'id': 'businessOrderNo',
                'label': '业务订单号',
                'type': 'input',
                'col': 1,
                'restProps': {

                },
            },
            {
                'id': 'batchCode',
                'label': '发放批次号',
                'type': 'input',
                'col': 1,
                'restProps': {

                },
            },
            {
                'id': 'checkStatus',
                'label': '发放状态',
                'type': 'select',
                'col': 1,
                'options': [
                    { value: '', label: '全部' },
                    { value: '00', label: '待审核' },
                    { value: '01', label: '审核通过' },
                    { value: '03', label: '已提交' },
                    { value: '04', label: '未提交' },
                    { value: '05', label: '审核拒绝' },
                    { value: '06', label: '正在发放' },
                    { value: '07', label: '已发放' },
                    { value: '08', label: '发放失败' },
                    { value: '30', label: '甲方企业待审核' },
                    { value: '35', label: '甲方企业审核拒绝' },

                ],
                'attr': {
                    initialValue: '',
                },
            },
            {
                'id': 'startDate',
                'label': '开始日期',
                'type': 'date-picker',
                'col': 1,
                'attr': {
                    // initialValue: moment(new Date()).subtract(29, "days"),
                    rules: [
                    ]
                },
                'restProps': {
                    disabledDate: (value) => this.disabledStartDate(value),
                    onChange: (value) => this.onStartChange(value)
                },
            },
            {
                'id': 'endDate',
                'label': '结束日期',
                'type': 'date-picker',
                'col': 1,
                'attr': {
                    // initialValue: moment(new Date()),
                    rules: [
                    ]
                },
                'restProps': {
                    disabledDate: (value) => this.disabledEndDate(value),
                    onChange: (value) => this.onEndChange(value)
                },
            },
            {
                'id': 'cycleCate',
                'label': '发放类别',
                'type': 'select',
                'col': 1,
                'options': [
                    { value: 'advance,supervise', label: '全部' },
                    { value: 'advance', label: '预付金' },
                    { value: 'supervise', label: '结算监管' },
                ],
                'attr': {
                    initialValue: 'advance,supervise',
                },
            },
        ];
        const _this = this;
        const columns = [
            {
                key: 'salarybatchcode',
                title: '发放批次号',
                align: 'center',
                dataIndex: 'salarybatchcode',
                // ellipsis : true,
                width: '14%',
                render(val, record) {
                    return <span className={styles.spanClick} onClick={() => _this.onShowOrderDetail(record)}>{val}</span>;
                }
            },
            {
                key: 'originalfilenamep',
                title: '文件名称',
                align: 'center',
                dataIndex: 'originalfilenamep',
                ellipsis : true,
                width: '8%',
            },
            {
                key: 'businessOrderNo',
                title: '业务订单号',
                align: 'center',
                dataIndex: 'businessOrderNo',
                width: '10%',
            },
            {
                key: 'totalnum',
                title: '发放金额',
                align: 'center',
                dataIndex: 'totalnum',
                width: '8%',
            },
            {
                key: 'otherChannelId',
                title: '发放平台',
                align: 'center',
                dataIndex: 'otherChannelId',
                width: '8%',
                render(val) {
                    if(val==='huaxia'){
                        return '虎符发放';
                    }else{
                        return '薪企链';
                    }
                }
            },
            {
                key: 'releasetime',
                title: '发放时间',
                align: 'center',
                dataIndex: 'releasetime',
                width: '9%',
                render(val) {
                    return val? formatDate(new Date(val), 'yyyy-MM-dd hh:mm:ss') : '--';
                }
            },
            {
                key: 'salarydate',
                title: '发放单日期',
                align: 'center',
                dataIndex: 'salarydate',
                width: '10%',
                // render(val) {
                //     return moment(val).format('YYYY-MM-DD');
                // }
            },
            {
                key: 'checkstatus',
                title: '发放状态',
                align: 'center',
                dataIndex: 'checkstatus',
                width: '8%',
                render(val) {
                    if (val === '00') {
                        return '待审核';
                    } else if (val === '01') {
                        return '审核通过';
                    } else if (val === '03') {
                        return '已提交';
                    } else if (val === '04') {
                        return '未提交';
                    } else if (val === '05') {
                        return '审核拒绝';
                    } else if (val === '06') {
                        return '正在发放';
                    } else if (val === '07') {
                        return '已发放';
                    } else if (val === '08') {
                        return '发放失败';
                    } else if (val === '30') {
                        return '甲方企业待审核';
                    } else if (val === '35') {
                        return '甲方企业审核拒绝';
                    } else {
                        return '--';
                    }
                }
            },
            {
                key: 'cyclecate',
                title: '发放类别',
                align: 'center',
                dataIndex: 'cyclecate',
                width: '8%',
                render(val, record) {
                    if (val === 'advance') {
                        return '预付金';
                    } else if (val === 'supervise') {
                        return '结算监管';
                    } else {
                        return '--';
                    }
                }
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                width: '10%',
                align: 'center',
                render: (text, record, index) => {
                    return (
                        <div>
                            <Fragment>
                                <Spin indicator={antIcon} spinning={loading} >
                                    {
                                        record.checkstatus === '04' ?
                                            <span style={{ display: 'inline-flex' }} >
                                                <Spin size="small" indicator={loadIcon} spinning={loading}>
                                                    {
                                                        <ConfirmPrpover record={record} placement="topRight" title="提交审核" content="确实提交审核吗？"
                                                        confirmText="确定" cancelText="取消" htmlText={submitText} confirmSubmit={() => _this.submitRecord(record.id)} />
                                                    }
                                                </Spin>
                                            </span> : <span className="span-click-disabled">提交</span>
                                    }
                                    <Divider type="vertical" />
                                    {
                                        record.checkstatus === '04' ?
                                            <span style={{ display: 'inline-flex' }} >
                                                <Spin size="small" indicator={loadIcon} spinning={loading}>
                                                    {
                                                        <ConfirmPrpover record={record} placement="topRight" title="删除发放单" content="确实要删除吗？"
                                                            confirmText="确定" cancelText="取消" htmlText={htmlText} confirmSubmit={() => _this.deleteRecord(record.id)} />
                                                    }
                                                </Spin>
                                            </span> : <span className="span-click-disabled">删除</span>
                                    }

                                </Spin>
                            </Fragment>
                        </div>

                    )
                }
            },
        ];
        const tools = {
            buttons: [
                {
                    label: '模板下载',
                    props: {
                        loading: downloadTmpLoading,
                        type: "primary",
                        onClick: () => this.downloadTmp()
                    }
                },
                {
                    label: '新增发放单',
                    props: {
                        type: "primary",
                        onClick: () => this.setState({ visible: true })
                    }
                },
            ],
        };


        return (
            <div >
                <Search
                    {...this.props}
                    search={search}
                    loading={loading}
                    submit={this.onSubmit}
                    isLoad={true}
                    tools={tools}
                    columns={columns}
                    data={salaryBatchList}
                    total={total}
                    pagination={true}
                    // drawer={drawer}
                    onRef={this.onRef}
                />
                <div>
                    {
                        visible && <ModalWrapper
                            // footer={null}
                            width={1000}
                            // maskClosable={false}

                            bodyStyle={{ padding: '50px 40px 48px' }}
                            destroyOnClose
                            title="订单列表"
                            visible={visible}
                            footer={false}
                            onCancel={this.onCancel.bind(this)}
                        >
                            <BusinessOrderList onCancel={this.onCancel.bind(this)} onRefresh={this.onRefresh} />
                        </ModalWrapper>
                    }
                </div>

                <div>
                    {
                        orderDetailVisiable && <ModalWrapper
                            // footer={null}
                            width={1000}
                            // maskClosable={false}

                            bodyStyle={{ padding: '50px 40px 48px' }}
                            destroyOnClose
                            title="发放单详情"
                            visible={orderDetailVisiable}
                            footer={false}
                            onCancel={this.onOrderDetailCancel.bind(this)}
                        >
                            <SalaryBatchDetails onOrderDetailCancel={this.onOrderDetailCancel.bind(this)} onRefresh={this.onRefresh} record={record} />
                        </ModalWrapper>
                    }
                </div>
            </div>
        );
    }
}

export default CreateTransPayRoll;