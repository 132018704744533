import {http} from '../utils/http'

export function relationQuery(data){
    return http.post("/supplierManage/supplierRelationQuery",data);
}
export function upEnterpriseQuery(data){
    return http.post("/supplierManage/upEnterpriseQuery",data);
}

export function queryRelatedUpEnterpris(data){
    return http.post("/supplierManage/queryRelatedUpEnterpris",data);
}

export function queryEmployerList(data){
    return http.post("/supplierManage/queryEmployerList",data);
}
export function querySupplierSalaryBatch(data){
    return http.post("/supplierManage/querySupplierSalaryBatch",data);
}
export function uploadSupplierSalarys(data){
    return http.post("/supplierManage/uploadSupplierSalarys",data);
}
export function supplierSalaryQuery(data){
    return http.post("/supplierManage/supplierSalaryQuery",data);
}
export function querySupSalaryBatchRecordList(data){
    return http.post("/supplierManage/querySupSalaryBatchRecordList",data);
}
export function queryUnRelatedUser(data){
    return http.post("/supplierManage/queryUnRelatedUser",data);
}
