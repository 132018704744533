import React, { Component } from 'react';
import { Table, Alert, Button } from 'antd';
import { EditableFormRow } from './EditableFormRow'
import EditableCell from './EditableCell'


class StaffBindCardResult extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataSource: this.props.dataSource,
            selectedRowKeys: [],
        };
    }

    commit() {
        this.props.commit();
    }

    handleSave = row => {
        this.props.handleSave(row)
    };

    isEditing(record) {
        if (record.cardBindState == '00') {
            return true;
        } else if (record.cardBindState == '01') {
            return false;
        } else if (record.cardBindState == '02') {
            return true;
        } else if (record.cardBindState == '03') {
            return false;
        }
    }


    render() {
        const _this = this;
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.props.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: this.isEditing(record),
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                    selectedRowKeys: this.state.selectedRowKeys
                }),
            };
        });
        const pagination = {
            current: parseInt(this.props.currentPage),
            total: this.props.totalNum,
            pageSize : this.props.pageSize,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.props.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }
        const rowSelection = {
            selectedRowKeys: this.props.selectFlowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys: selectedRowKeys
                })
                let rows = [];
                if (selectedRowKeys.length === selectedRows.length) {
                    rows = selectedRows;
                }
                if (selectedRowKeys.length > selectedRows.length) {
                    let allRows = [];
                    allRows = this.props.selectRows;
                    for (let i = 0; i < selectedRows.length; i++) {
                        allRows.push(selectedRows[i]);
                    }
                    let allRowsMap = new Map();
                    for (let i = 0; i < allRows.length; i++) {
                        allRowsMap.set(allRows[i].hostFlowNo, allRows[i]);
                    }
                    for (let i = 0; i < selectedRowKeys.length; i++) {
                        rows.push(allRowsMap.get(selectedRowKeys[i]));
                    }
                }

                if (selectedRowKeys.length != 0) {
                    this.props.getSelectKeys(selectedRowKeys);
                    this.props.getSelectRows(rows);
                } else {
                    rows = [];
                    this.props.getSelectKeys(selectedRowKeys);
                    this.props.getSelectRows(rows);
                }

            },
            getCheckboxProps: record => ({
                disabled: ! (record.cardNo&&( record.cardBindState === '00' || record.cardBindState === '02')),
                name: record.id,
            }),
        };
        return (
            <div style={{ marginTop: '20px' }}>
                <Alert style={{ marginTop: '10px' }} message={(
                    <span>查询结果</span>
                )} type="info" showIcon />

                <div><Button style={{marginTop: '10px'}} type="primary" disabled={this.props.buttonFlag} onClick={this.commit.bind(this)} loading={this.props.commitloading}
                //   style={{marginLeft : '50%'}} 
                >提交绑卡</Button></div>


                <Table style={{ marginTop: '20px' }}
                    rowClassName={() => 'editable-row'}
                    components={components}
                    rowKey={record => record.id}
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.dataSource}
                    rowSelection={rowSelection}
                    columns={columns}
                    bordered
                    pagination={pagination}
                >
                </Table>





            </div>
        );
    }
}

export default StaffBindCardResult;