import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Table } from 'antd'
import styles from './table.m.less'


const TableList = ({
    size,
    columns,
    data,
    filters,
    rowKey,
    onRow,
    pagination,
    // header = '查询结果',
    rowSelection,
    loading=false,
    fixedCols,
    scroll,
    isMobile
}) => {
    let tabCols = filters ? columns.filter(item => filters.indexOf(item.dataIndex) === -1) : columns;
    // fixedCols.forEach(val => {
    //     tabCols.forEach(item => {
    //         if(val.key === item.key && val.value === 'L'){
    //             item.fixed = 'left';
    //         }else if(val.key === item.key && val.value === 'R'){
    //             item.fixed = 'right'
    //         }
    //     })
    // })
    if(isMobile){
        scroll = {}
        tabCols = columns.map(item => {
            delete item.fixed;
            return item
        })
    }
    if(data.length === 0 && scroll){
        scroll.y = 0;
    }

    return (
        <div className={styles.main}>
            {/* <div className={styles.head} >
                <Alert
                    message={
                        header
                    }
                    type="info"
                    showIcon
                />
            </div> */}
            <Table 
            loading={loading }
            rowKey={rowKey}
            onRow={onRow}
            rowSelection={rowSelection}
            columns={tabCols} 
            dataSource={data} 
            size={size} 
            pagination={pagination}
            scroll={scroll}
            />
        </div>
        
    );
};

TableList.propTypes = {
    rowKey : PropTypes.oneOfType(
        [PropTypes.string,PropTypes.func]
    ).isRequired,
    columns : PropTypes.arrayOf(PropTypes.shape({
        key : PropTypes.string.isRequired,
        title : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        dataIndex : PropTypes.string.isRequired,
        width : PropTypes.string.isRequired,
        align : PropTypes.oneOf(['center','left','right']),
    })).isRequired,
    data : PropTypes.array.isRequired,
    filters : PropTypes.array,
    size : PropTypes.string,
};

export default TableList;
// export default connect()(props => (
//     <Media query="(max-width: 575px)">
//       {isMobile => <TableList {...props} isMobile={isMobile} />}
//     </Media>
//   ));