import { http } from '../utils/http'

//发放申请、审核列表分页查询
export function salaryBatchList(data) {
    return http.post("/superviseSalary/salaryBatchList", data);
}
//发放审核-详情分页查询
export function salaryBatchDetail(data) {
    return http.post("/superviseSalary/salaryBatchDetail", data);
}
//业务列表
export function businessOrderList(data) {
    return http.post("/superviseSalary/businessOrderList", data);
}
//日结发放-发放单创建
export function uploadSalary(data) {
    return http.post("/superviseSalary/uploadSalary", data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
//提交申请
export function submitSalaryBatch(data) {
    return http.post("/superviseSalary/submitSalaryBatch", data);
}
//删除发放单
export function deleteSalaryBatch(data) {
    return http.post("/superviseSalary/deleteSalaryBatch", data);
}
//发放单记录
export function salaryBatchRecordList(data) {
    return http.post("/superviseSalary/salaryBatchRecordList", data);
}
//发放单详情
export function salaryBatchRecordDetail(data) {
    return http.post("/superviseSalary/salaryBatchRecordDetail", data);
}