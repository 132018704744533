import React, { Component } from 'react';
import { baseHttpUrl } from '@/assets/settings';
import axios from 'axios';

import { Col, Button } from 'antd';


class DownloadCoreTemp extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem("user")).enterpriseid,
            phone : JSON.parse(global._sessionStorage.getItem("user")).username,
        }
    }

    downloadTemp(){
        let params = {
            enterpriseid: this.state.enterpriseid,
            phone: this.state.phone,
            bustype: 'S',
            salaryType: 'sup',
        }
        
        axios({
            method: 'GET',
            url: baseHttpUrl+'/supplierManage/downloadSupplierSalaryTmp',
            params: params,
            responseType: 'blob'
        }).then(function(res) {
            const content = res.data
            const blob = new Blob([content],{type: "application/x-msdownload"})
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = res.headers['filename'];
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })
    }
    render() {
        return (

           <Button type="primary"  onClick={this.downloadTemp.bind(this)} >下载发放单模板</Button>

        );
    }
}

export default DownloadCoreTemp;