import React, { Component } from 'react';
import { Form, Input, Button, Modal, Message } from 'antd'
import { updateLoginPwd } from 'api/user.js'
import { rsaEncrypt } from 'utils/EncryptRSA'

class PassModify extends Component {
    constructor(){
        super();
        this.state = {
            modifyLoad : false
        }
    }

    handlerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                this.setState({
                    modifyLoad : true
                })
                const user = JSON.parse(global._sessionStorage.getItem('user'));
                let postParam = { 
                    enterpriseid : user.enterpriseid,
                    userHeadId: global._sessionStorage.getItem('userHeadId'),
                    username: user.username,
                    newLoginPwd: rsaEncrypt.encrypt(params.newPass),
                    oldLoginPwd: rsaEncrypt.encrypt(params.oldPass),
                    confirmLoginPwd: rsaEncrypt.encrypt(params.confirmPass),
                }
                updateLoginPwd(postParam).then( res => {
                    let data = res.data;
                    if(data.errorCode === '0000'){
                        global._sessionStorage.setItem("isEnterLogin", true)
                        this.handlerShowModal();
                    }else{
                        Message.error(data.errorMessage);
                        this.setState({
                            modifyLoad : false
                        })
                    }
                })
            }
        })
    }

    handlerShowModal(){
        let secondsToGo = 5;
        const modal = Modal.success({
          title: <div style={{marginLeft:10}} ><span> 密码修改成功</span></div>,
          content:   <div style={{marginLeft:10,marginTop:20}} ><span> {secondsToGo}秒后进入登陆页面</span></div>  ,
          okText: '重新登录',
          onOk : () =>{ this.props.history.push('/login');}
        });
        const timer = setInterval(() => {
          secondsToGo -= 1;
          modal.update({
            content: <div style={{marginLeft:10,marginTop:20}} ><span> {secondsToGo}秒后进入登陆页面</span></div>,
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          modal.destroy();
          this.props.history.push('/login');
          global._sessionStorage.removeItem("isEnterLogin")
        }, secondsToGo * 1000);
    }

    componentDidMount(){
        if(global._sessionStorage.getItem("isEnterLogin")){
            this.handlerShowModal();
        }
    }

    validateNewPassword = (rule, value, callback) =>{
        if (value && this.props.form.getFieldValue('confirmPass')) {
            this.props.form.validateFields(['confirmPass'], { force: true });
        }
        callback();
    }

    validateConfirmPassword = (rule, value, callback) =>{
        let newPass = this.props.form.getFieldValue('newPass')
        if (value && newPass && value !== newPass) {
            callback('两次密码不一致');
        }
        callback();
    }

    onPaste(e){
        e.preventDefault();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemStyle = this.props.formItemStyle || {
            labelCol : {  span: 4 },
            wrapperCol : { span: 6 }
        }
        const rexpOld = '^.{6,12}$';
        const rexp = '^.{8,12}$';
        return (
            <div>
                <Form {...formItemStyle} onSubmit={this.handlerSubmit.bind(this)} >
                    <Form.Item label="原密码" >
                        { getFieldDecorator('oldPass',{
                            rules : [
                                { required: true, message: '请输入原密码' },
                                { pattern: new RegExp(rexpOld, "g"), message: '请输入6-12位密码' }
                            ]
                        })(
                            <Input type="password" onPaste={this.onPaste}/>
                        ) }
                    </Form.Item>
                    <Form.Item label="新密码" >
                        { getFieldDecorator('newPass',{
                            rules : [
                                { required: true, message: '请输入新密码' },
                                {validator : this.validateNewPassword},
                                { pattern: new RegExp(rexp), message: '请输入8-12位密码' },
                            ]
                        })(
                            <Input type="password" onPaste={this.onPaste}/>
                        ) }
                    </Form.Item>
                    <Form.Item label="确认密码" >
                        { getFieldDecorator('confirmPass',{
                            rules : [
                                { required: true, message: '请输入确认密码' },
                                {validator : this.validateConfirmPassword}
                            ]
                        })(
                            <Input type="password" onPaste={this.onPaste}/>
                        ) }
                    </Form.Item>
                    <Button type="primary" htmlType="submit" style={{marginLeft : '25%'}} loading={this.state.modifyLoad}  > 提交 </Button>
                </Form>
            </div>
        );
    }
}

PassModify = Form.create()(PassModify);

export default PassModify;