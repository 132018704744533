import React, { Component } from 'react';
import { Modal, Form, Message,Input, Select, Button ,Upload,Icon,DatePicker } from 'antd';
import ModalDrag from '@/components/Common/ModalDrag'
import moment from 'moment';
import { querySalaryAccount} from 'api/weeksalary'
const { MonthPicker } = DatePicker;

const { Option } = Select;

class UploadSupplierPayRoll extends Component {
    constructor(props){
        super(props);
        this.state={
            visible : false,
            loading : false,
            fileList: [],
            payChannelFlag:false,
            ortherChannelFlag:false,
            ortherPayChannel: [],
        }
    }
    
    componentWillMount(){ 
        let params = {
            enterpriseid : JSON.parse(global._sessionStorage.getItem("user")).enterpriseid ,
        };
        querySalaryAccount(params).then(res => {
            let { errorCode, data } =res.data;
            if(errorCode === '0000'){
                if(data && data.length > 0){
                    this.setState({              
                        payChannelFlag : true,          
                        ortherPayChannel : data,
                    })
                }
            }
        })
    }
   
    handlerModalShow(){
        this.setState({
            visible : true
        })
    }

    handlerCancel(){
        this.setState({
            fileList:[],
            ortherChannelFlag:false,
        })
        this.props.form.resetFields();
        this.setState({
            visible : false
        })
    }

    handlerReset(e){
        e.preventDefault();
        this.setState({
            fileList:[],
            ortherChannelFlag:false,
        })
        this.props.form.resetFields();
    }
    handlerSubmit(e){
        const _this=this;
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                let formData = new FormData();
                formData.append("file", this.state.fileList[0]);
                formData.append("salarydate", moment(params.salarydate).format('YYYY-MM'));
                let user = JSON.parse(global._sessionStorage.getItem("user"));
                formData.append("enterpriseid",user.enterpriseid);
                formData.append("cyclecate",'supEn');
                formData.append("payChannel",params.payChannel);
                params.otherChannelId && formData.append("otherChannelId", params.otherChannelId);

                this.setState({ loading : true });
                this.props.createPayUpload(formData);
                this.setState({
                    loading : false,
                    visible :false,
                    fileList: [],
                    ortherChannelFlag:false,
                })
                
                
            }
        })
    }

    changePayChannel(value){
        this.setState({
            ortherChannelFlag: value==='2'
        })
    }

    render() {
        const formItemStyle = {
            labelCol : { span : 6 },
            wrapperCol : { span : 14 }
        }
        const title = <ModalDrag title="创建关联发放单" />;
        const otherChannelOptions = this.state.ortherPayChannel.map( type =>{
            return <Option key={type.id} value={type.id} >{type.bankName}</Option>
        } )
        const _this = this;
        const { fileList } = this.state;
        const { getFieldDecorator } = this.props.form;
        const props = {
            accept: ".xls,.xlsx",
            multiple: false,
            beforeUpload: (file) => {
                if (this.state.fileList.length != 0) {
                    Message.error("仅支持单个文件上传！");
                    _this.setState({
                        fileList: []
                    });
                    return false;
                }
                _this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;

            },
            onRemove: (file) => {
                _this.setState({
                    fileList: []
                });
            },
            fileList,
        }
        return (
            <span>
                <Button  type="primary"   onClick={this.handlerModalShow.bind(this)} >创建关联发放单</Button>
                {
                    this.state.visible
                    && 
                    <Modal
                        visible={this.state.visible}
                        title={title}
                        onCancel={this.handlerCancel.bind(this)}
                        footer={null}
                        >
                        <div className="user-modify-form" >
                            <Form { ...formItemStyle } onSubmit={this.handlerSubmit.bind(this)} >
                                <Form.Item label="发薪日期" >
                                    { getFieldDecorator("salarydate",{
                                        rules : [
                                            { required: true, message: '请输入发薪日期' }
                                        ]
                                    })(
                                        <MonthPicker  />
                                    ) }
                                </Form.Item>
                                <Form.Item label="发薪文件" >
                                    {  (
                                        <Upload {...props}>
                                            <Button>
                                                <Icon type="upload" /> 上传文件
                                            </Button>
                                        </Upload>
                                    )}
                                </Form.Item>
                                <Form.Item label="发放平台" >
                                    { getFieldDecorator("payChannel",{
                                       rules : [
                                            { required: true, message: '请选择发放平台' }
                                       ]
                                        })(
                                        <Select placeholder="发放平台" style={{width:"65%"}} onChange={this.changePayChannel.bind(this)} >
                                            <Option value='1'>薪企链</Option>
                                            {this.state.payChannelFlag && <Option value='2'>其他平台</Option>}
                                        </Select>
                                    ) }
                                </Form.Item>
                                <div hidden={!this.state.ortherChannelFlag}>
                                    <Form.Item  label="其他平台">
                                        { getFieldDecorator("otherChannelId",{
                                            rules : [
                                                { required: this.state.ortherChannelFlag, message: '请选择平台'}
                                            ]
                                        })(     
                                            <Select placeholder="发放平台" style={{width:"65%"}}>
                                                {otherChannelOptions}
                                            </Select>
                                        ) }
                                        <div style={{lineHeight: '20px'}}>
                                            {this.state.ortherChannelFlag && <span style={{color: 'red'}}>华夏银行：平台发放完成后，资金将发放至华夏银行对公账户，请登录“华夏银行-放薪管家”平台完成后续发放操作。</span>}
                                        </div>
                                    </Form.Item>
                                </div>
                                <div style={{ marginTop:20 }} >
                                        <Button type="primary" onClick={this.handlerReset.bind(this)} style={{ marginLeft: '30%' }}  >重置</Button>
                                        <Button type="primary" onClick={this.handlerSubmit.bind(this)} loading={this.state.loading} style={{ marginLeft: '15%', marginBottom: 10 }}   >提交</Button>
                                 </div>
                            </Form>
                        </div>
                    </Modal> 
                }
            </span>
        );
    }
}

UploadSupplierPayRoll = Form.create()(UploadSupplierPayRoll)
export default UploadSupplierPayRoll;