import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import styles  from './index.m.less'
import {Input, Checkbox, Icon, Button, Switch, Message} from 'antd'
import {ModalWrapper} from 'wrapper'
import {queryDepartEmpList} from 'api/workattend'

const { Search } = Input;


class EmployeeItem extends Component {

    constructor(){
        super();
        this.state ={
            enterpriseid : JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            checkedValues : [],
            step : '1',
            current : undefined,
            visible : false,
            subVisible : false, 
            subArray : [],
            serachText : undefined,
            list : [],
        }
    }

    componentDidMount(){
        let values={
            eid : this.state.enterpriseid
        }
        queryDepartEmpList(values).then(res => {
            let {errorCode, errorMessage, data} = res.data;
            if(errorCode === '0000'){
                this.setState({
                    list : data.list,
                })
            }else{
                Message.error(errorMessage);
            }
        })
    }

    onSearch = (e) => {
        this.setState({
            serachText : e.target.value
        })
    }


    onStepEnter = (current) => {
        this.setState({
            step: '2',
            current
        })
    }

    onStepOut = () => {
        this.setState({
            step: '1',
            current : undefined
        })
    }

    onCheckBox = (e) => {
        const arr = this.state.checkedValues;
        if(e.target.checked){
            this.setState({
                checkedValues : [...arr, {key : e.target.value, label : e.target.label, level : e.target.level, parent : e.target.parent}]
            })
        }else{

            this.setState({
                checkedValues : arr.filter(item => item.key !== e.target.value)
            })
        }
    }

    onDelete = (key) => {
        const arr = this.state.checkedValues;
        this.setState({
            checkedValues : arr.filter(item => item.key !== key)
        })
    }


    onModal = (visible) => {
        let checkedValues = []
        if(visible){
            const { id, form: {getFieldValue} } = this.props;
            const checkArray =  getFieldValue(id);
            checkArray && checkArray.forEach(item => {
                if(item.checked){
                    checkedValues.push({key : item.key, label : item.label, level : item.level, parent : item.parent})
                }
                if(item.children){
                    item.children.forEach(it=>{
                        checkedValues.push({key : it.key, label : it.label, level : it.level, parent : it.parent})
                    })
                }
            })
        }
        
        this.setState({
            visible,
            checkedValues : checkedValues,
            step : '1'
        })
    }

    onSubModal = (visible) => {
        this.setState({
            subVisible : visible
        }, ()=> {
            if(!visible){
                this.setState({
                    visible : true,
                    subArray : []
                })
            }
        })
    }

    onConfirm = () => {
        const {checkedValues, list } = this.state;
        let parentArray = []
        // parentArray = checkedValues.filter(item => item.level === '1');
        //循环所有一级
        checkedValues.forEach(item => {
            if(item.level === '1'){
                parentArray.push({...item, checked : true, autoJoin : true, children : []});
            }
        })
        checkedValues.forEach(item => {
            if(item.level === '2'){
                let exits = false;
                //判断一级是否存在
                parentArray.forEach(value => {
                    //存在处理
                    if(value.key === item.parent){
                        if(value.children){
                            value.children.push(item)
                        }else{
                            value.children=[item]
                        }
                        exits=true;
                    }
                })
                //不存在
                if(!exits){
                    //插入数据
                    list.forEach(li => {
                        if(li.did === item.parent){
                            parentArray.push({key : li.did, label : li.dname, level : '1', parent : undefined, checked: false, autoJoin : true, children : [item]})
                        }
                    })

                }
            }
        })

        this.setState({
            visible : false,
            step : '1'
        }, ()=>{
            this.setState({
                subVisible : true,
                subArray : parentArray
            })
        })

    }

    onAutojoin = (key, checked, ) => {
        const {subArray} = this.state;
        subArray.forEach(item => {
            if(item.key === key){
                item.autoJoin = checked
            }
        })
    }

    onSubConfirm = () => {
        const {subArray} = this.state;
        const {id, form : {setFieldsValue}} = this.props;
        let selectValue = {};
        selectValue[id] = subArray;
        setFieldsValue(selectValue)
        this.setState({
            subVisible : false,
            step : '1',
            current : undefined,
            subArray : [],
        })
    }

    render() {

        const { list, step, current, checkedValues, visible, subVisible, subArray, serachText } = this.state;
        const { id, attr={}, form: {getFieldDecorator, getFieldError, getFieldValue} } = this.props;
        const error = getFieldError(id);

        let treeData = [];
        if(!serachText){
            if(step === '1'){
                treeData = list.map((item, index) => {
                    const isDown = item.appUserList && item.appUserList.length !== 0;
                    let disabled = false;
                    checkedValues.forEach(val => {
                        if(val.key === item.did){
                            disabled=true;
                        }
                    })
                    
                    return <div key={index} className={styles['list-item']} >
                        <Checkbox value={item.did} level={step} label={item.dname} style={ isDown ? {width : '75%'} : {width : '100%'}} onChange={e => this.onCheckBox(e)}>
                            <Icon type="solution" style={{marginRight : '5px'}}  />{item.dname}
                        </Checkbox>
                        {
                        isDown && <div className={ disabled ? classNames(styles['icon-disabled'], styles['list-item-icon']) : styles['list-item-icon']} onClick={disabled ? null : () => this.onStepEnter(item.did)} ><Icon type="apartment" /> 下级</div>
                        }
                        </div>
                })
            }else{
                let items = [];
                let parent = '返回上级'
                
                list.forEach(item => {
                    if(item.did === current){
                        items = item.appUserList;
                        parent = `返回上级`;
                    }
                })
                const back = <div key={'back'} className={styles['list-item']} onClick={() => this.onStepOut()} >./ {parent}</div>
                treeData = items.map((item, index) => {
                    return <div key={index} className={styles['list-item']} >
                        <Checkbox value={item.id} level={step} parent={current} label={item.username} style={{width : '100%'}} onChange={e => this.onCheckBox(e)} >
                            <Icon type="user" style={{marginRight : '5px'}} />{item.username}
                        </Checkbox>
                        </div>
                })
                treeData.unshift(back)
            }
        }else{
            list.forEach(item => {
                if(item.appUserList){
                    item.appUserList.forEach(it => {
                        if(it.username.includes(serachText)){
                            const data =  <div key={it.id} className={styles['list-item']} >
                            <Checkbox value={it.id} level='2' parent={item.did} label={it.username} style={{width : '100%'}} onChange={e => this.onCheckBox(e)} >
                                <Icon type="user" style={{marginRight : '5px'}} />{it.username}
                                <span style={{color : '#1890FF', marginLeft : '5px'}} >({item.dname})</span>
                            </Checkbox>
                            </div>
                            treeData.push(data)
                        }
                    })
                }
            })
        }

        const checkedData = checkedValues.map((item, index) => {
            const icon = item.level === '1' ? <Icon type="solution" style={{marginRight : '5px'}}  /> : <Icon type="user" style={{marginRight : '5px'}} />;
            const closeIcon = <Icon type="close-circle" style={{fontSize : 16}} onClick={() => this.onDelete(item.key)} />;
            return <div key={index} className={styles['checked-item']} >
                    {icon}{item.label}
                    <div className={styles['item-close-icon']} >{closeIcon}</div>
                    </div> 
        })

        const checkedArray = checkedValues.map(item => item.key);


        const subItem = subArray.map((item, index) => {
            let childs =[];
            if(item.checked){
                list.forEach(i => {
                    if(i.did === item.key){
                        childs = i.appUserList && i.appUserList.map(it =>{ return {key : it.id, label : it.username, level : '2', parent : item.key}})
                    }
                })

            }else{
                childs = item.children
            }
            const number = childs.length;
            const desc =number > 0 && (number > 1 ? `${childs[0].label}等` : `${childs[0].label}`)
            return <div key={index} className={styles['subitem-list-item']} >
                <div className={styles['subitem-content']} >
                    <div className={styles['subitem-title']} >
                        {item.label}
                    </div>
                    <div>
                        已选择{desc}{number}人
                    </div>
                </div>
                <div className={styles['subitem-checkbox']} >
                    <Switch defaultChecked onChange={this.onAutojoin.bind(this, item.key)} />
                </div>
                
            </div>
        })

        const initArray =  getFieldValue(id);
        let checkTotal = 0
        initArray && initArray.forEach(item => {
            if(item.checked){
                list.forEach(it => {
                    if(it.did === item.key){
                        checkTotal = checkTotal+  it.appUserList.length
                    }
                })
            }else{
                checkTotal = checkTotal+  item.children.length
            }
        })

        return (
            <div>
                {getFieldDecorator(id, {...attr})(<div onClick={() => this.onModal(true)} className={styles['_employee_item_box']} >共{checkTotal}人
                </div>)}
                {
                    error && <div className={styles['employee-multi-input-error']} style={{color :' red', position : 'absolute', marginTop: '-5px'}} >{error}</div>
                }
                <ModalWrapper
                width={850}
                bodyStyle={{ padding: '0px 10px 20px'}}
                destroyOnClose
                title="选择人员"
                visible={visible}
                footer={false}
                onCancel={() =>this.onModal(false)}
                >
                    <div className={styles['_employee_item'] } >
                        <div className={styles['item-list']} >
                            <div className={styles['list-head']} >
                                选择：
                            </div>
                            <div className={classNames(styles['list-body'], "scroll")} >
                                <div className={styles['employee-search']}>
                                    <Search
                                        style={{width : '100%'}}
                                        placeholder="搜索"
                                        onChange={this.onSearch}
                                        onPressEnter={(e) => e.preventDefault()} 
                                        />
                                </div>

                                <div className={styles['item-tree']}>
                                    <Checkbox.Group style={{ width: '100%' }} value={checkedArray}
                                    >
                                        {treeData}
                                    </Checkbox.Group>
                                </div>

                            </div>
                            
                        </div>
                        <div className={classNames(styles['item-list'], styles['item-check-list'])} >
                            <div className={styles['list-head']} >
                                已选：
                            </div>
                            <div className={classNames(styles['list-body'], "scroll")} >
                                
                                <div className={styles['checked-list']} >
                                    {checkedData}
                                </div>

                            </div>
                        </div >

                    </div>
                    <div style={{width : '150px', margin: 'auto'}} >
                        <Button onClick={() =>this.onModal(false)} >取消</Button>
                        <Button type='primary' style={{marginLeft : 20}} onClick={this.onConfirm} >确定</Button>
                    </div>
                </ModalWrapper>

                <ModalWrapper
                width={600}
                bodyStyle={{ padding: '0px 10px 20px'}}
                destroyOnClose
                title="后续加入以下项目的人员自动进入考勤组"
                visible={subVisible}
                footer={false}
                onCancel={() =>this.onSubModal(false)}
                >

                    <div className={styles['_employee_subitem']}  >
                        {subItem}
                    </div>
                       
                    <div style={{width : '170px', margin: 'auto'}} >
                        <Button onClick={() =>this.onSubModal(false)} >上一步</Button>
                        <Button type='primary' style={{marginLeft : 20}} onClick={this.onSubConfirm} >确定</Button>
                    </div>
                </ModalWrapper>
            </div>
            
        );
    }
}

EmployeeItem.propTypes = {
    id : PropTypes.string.isRequired,
    list : PropTypes.array,
    attr : PropTypes.shape({
        initialValue : PropTypes.arrayOf(PropTypes.shape({
            key : PropTypes.string,
            label : PropTypes.string,
            level : PropTypes.oneOf(['1', '2']),
            checked : PropTypes.bool,
            autoJoin : PropTypes.bool
        })),
        rules : PropTypes.array
    })
};

export default EmployeeItem;