import React, { Component } from 'react';
import { Table, Alert, Divider } from 'antd'
import StartTemp from './StartTemp';
import DownloadTemp from './DownloadTemp';
import { baseHttpUrl } from '../../assets/settings';
import DeleteTemp from './DeleteTemp';

class SalaryTempResult extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem("user")).enterpriseid,
        }
    }

    render() {
        const _this = this;
        const columns = [
            // {
            //     title: '模板编号', dataIndex: 'id', key: 'id', align: 'center'
            // },
            {
                title: '模板名称', dataIndex: 'templatename', key: 'templatename', align: 'center'
            },
            {
                title: '类型', dataIndex: 'bustype', key: 'bustype', align: 'center',
                render(val) {
                    if (val === "P") {
                        return "对私";
                    }
                    if (val === "C") {
                        return "对公";
                    }
                    return "";
                }
            },
            {
                title: '生成日期', dataIndex: 'createtime', key: 'createtime', align: 'center'
            },
            {
                title: '启用状态', dataIndex: 'unionstatus', key: 'unionstatus', align: 'center',
                render(val) {
                    if (val === "01") {
                        return "禁用";
                    } else {
                        return "启用";
                    }
                }
            },
            {
                title: '操作', align: 'center',
                render(text, record, index) {
                    let show="";
                    if(record.unionstatus==="01"){
                        show = <Divider type="vertical" />
                    }
                    return (
                        <div>
                            <StartTemp record={record} show={record.unionstatus==="01"} refreshSubmit={_this.props.refreshSubmit}/>
                            {show}
                            <DownloadTemp record={record}/>
                            <Divider type="vertical" />
                            <DeleteTemp record={record} refreshSubmit={_this.props.refreshSubmit}/>
                        </div>
                    )
                }
            },
        ]
        const pagination = {
            current: parseInt(this.props.currentPage),
            total: this.props.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.props.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }
        return (
            <div style={{ marginTop: '20px' }}>
                <Alert style={{ marginTop: '10px' }} message={(
                    <span>查询结果</span>
                )} type="info" showIcon />
                <Table style={{ marginTop: '20px' }}
                    rowKey={record => record.id}
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData}
                    columns={columns}
                    bordered
                    pagination={pagination}
                >
                </Table>

            </div>
        );
    }
}

export default SalaryTempResult;