import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Modal } from 'antd'
import classNames from 'classnames';
import styles from './index.m.less'
import Confirm from './Confirm'

const Index = ({
    width,
    className,
    bodyStyle,
    title,
    visible,
    children,
    childrenClassName,
    footer,
    onCancel,
    mask,
    style
}) => {
    return (
        <Modal
            width={width}
            className={classNames(styles['modal-wrapper'], className )}
            bodyStyle={bodyStyle}
            destroyOnClose
            maskClosable={false}
            title={title}
            visible={visible}
            footer={footer}
            onCancel={onCancel}
            mask={mask}
            style={style}
        >
            <div className={classNames(styles['modal-content'], 'scroll', childrenClassName)} >
                {children}
            </div>
        </Modal>
    );
};

Index.propTypes = {
    title : PropTypes.string,

};

const _export = props => (
    <Media queries={{
        mini: "(max-width: 575px)",
        small: "(min-width: 576px) and (max-width: 991px)",
        medium: "(min-width: 992px) and (max-width: 1599px)",
        large: "(min-width: 1600px)"
    }}>
        {matches => {
            let width = props.width;
            if(matches.mini){
                width = width || 300;

            }else if(matches.small){
                width = width || 500;
            }else if(matches.large){
                width = width || 1000;
            }else{
                width = width || 800;
            }
            const restProps ={ 
                ...props,
                width,
            }
            return <Index {...restProps} /> }
        }
    </Media>
  );

_export.Confirm = Confirm;

export default _export;