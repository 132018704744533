import React, { Component } from 'react';
import { Form, Row, Col, Input, Button, Modal, Tabs, Icon ,Select} from 'antd'
import moment from 'moment';
import StaffInviteOne from './StaffInviteOne';
import StaffInviteBatch from './StaffInviteBatch';

const { TabPane } = Tabs;
const { Option } = Select;
class StaffInviteForm extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            
        }
    }

    showInviteModal() {
        this.setState({
            showModal: true,
        })
    }
    handleCancel() {
        this.setState({
            showModal: false,
        })
    }


    handlerSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                if (params.createTime && params.createTime.length > 0) {
                    params.createBeginDate = moment(params.createTime[0]).format('YYYY-MM-DD');
                    params.createEndDate = moment(params.createTime[1]).format('YYYY-MM-DD');
                }
                if (params.issueDate && params.issueDate.length > 0) {
                    params.issueBeginDate = moment(params.issueDate[0]).format('YYYY-MM-DD');
                    params.issueEndDate = moment(params.issueDate[1]).format('YYYY-MM-DD');
                }
                this.props.searchFormSubmit(params);
            }
        })
    }

    handlerReset() {
        this.props.form.resetFields();
        this.props.resetForm();
    }
    render() {
       
        const { getFieldDecorator } = this.props.form;

        const departmentOptions = this.props.department!='' ? this.props.department.map(department => {
            return <Option key={department.did} value={department.did} >{department.dname}</Option>
        }):'';
        return (
            <div>
                <Form className="ant-search-form" onSubmit={this.handlerSubmit.bind(this)} >
                    <Row gutter={12} >
                        <Col span={5} >
                            <Form.Item label="姓名" >
                                {getFieldDecorator("search", {
                                })(
                                    <Input placeholder="姓名"></Input>
                                )}
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item label="项目" >
                                {getFieldDecorator("depart")(
                                    <Select
                                        showSearch
                                        placeholder="请选择"
                                        optionFilterProp="children"                                      
                                    >
                                        {departmentOptions}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={10} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" style={{ marginLeft: 50 }} loading={this.props.loading} >查询</Button>
                                <Button style={{ marginLeft: 30 }} onClick={this.handlerReset.bind(this)} >重置</Button>
                            </Form.Item>
                        </Col>
                        <Col span={4} >
                            <Form.Item >
                                <Button   style={{ marginLeft:90 }} type="primary" icon="plus-circle" onClick={this.showInviteModal.bind(this)} >员工邀请</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div style={{ marginTop: 10 }} >
                    <Modal
                        title="员工邀请"
                        visible={this.state.showModal}
                        onCancel={this.handleCancel.bind(this)}
                        footer={null}
                        width={600}
                        destroyOnClose = {true}
                    >
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={<span>
                                <Icon type="plus-circle" />
                                员工邀请
                            </span>} key="1">
                                <StaffInviteOne refreshSubmit={this.props.refreshSubmit} />
                            </TabPane>
                            <TabPane tab={<span>
                                <Icon type="file-excel" />
                                员工批量邀请
                            </span>} key="2">
                                <StaffInviteBatch refreshSubmit={this.props.refreshSubmit} />
                            </TabPane>
                        </Tabs>
                    </Modal>
                </div>
            </div >
        );
    }
}

StaffInviteForm = Form.create()(StaffInviteForm)
export default StaffInviteForm;