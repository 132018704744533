import React, { Component } from 'react';
import DragM from "dragm";

class ModalDrag extends Component {

    updateTransform = transformStr => {
        this.modalDom.style.transform = transformStr;
    };


    componentDidMount() {
        this.modalDom = !this.props.index ? document.getElementsByClassName(
            "ant-modal-wrap" //modal的class是ant-modal-wrap
        )[0] : document.getElementsByClassName(
            "ant-modal-wrap" //modal的class是ant-modal-wrap
            )[this.props.index];
    }

    render() {
        const { title } = this.props;
        return (
            <DragM updateTransform={this.updateTransform}>
                <div>{title}</div>
            </DragM>
        );
    }
}

export default ModalDrag;