import React, { Component } from 'react'
import { Form, Button, Select, Row, Card, Col,Message,Radio,InputNumber } from 'antd'
import { updateAppUserSalaryMethod } from '../../api/staff';

const { Option } = Select;


class UpdateStaffSalaryAttr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            uid: this.props.record.id,
            loading: false,
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if (!err) {
                this.setState({
                    loading: true,
                })

                params.id = this.state.uid;
                params.eid = this.state.enterpriseid;
                updateAppUserSalaryMethod(params).then(res => {
                    let { statusCode,message } = res.data;
                    if("200" === statusCode){
                        Message.success("修改薪酬信息成功");
                        this.props.refreshSubmit();
                    }else{
                        Message.error(message);
                    }
                    this.setState({
                        loading: false,
                    })
                })
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 12 }
        };
        return (
            <div>
                <Card title="薪酬信息" bordered={false}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit.bind(this)}>
                    <Row>
                            <Col span={12}>
                            <Form.Item label="薪酬属性">
                                    {
                                        getFieldDecorator('salarymethod', {
                                            initialValue: this.props.record.userjoinenterprise.salarymethod,
                                            rules: [
                                                {

                                                    required: true,
                                                    message: '请输入薪酬属性',
                                                }
                                            ]
                                        })(<Select style={{ width: '100%' }} placeholder="薪酬属性"  disabled={this.props.detailFlag}>
                                            <Option value="month">月薪</Option>
                                            <Option value="week">日薪</Option>
                                        </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="考勤时长核算方式">
                                    {getFieldDecorator('atdWay',{
                                         initialValue: this.props.record.userjoinenterprise.atdWay,
                                    })
                                    (
                                        <Radio.Group  disabled={this.props.detailFlag}>
                                            <Radio value="D">天</Radio>
                                            <Radio value="H">小时</Radio>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="考勤核算单价">
                                        {
                                        getFieldDecorator('atdPrice', {
                                            initialValue: this.props.record.userjoinenterprise.atdPrice,
                                            rules: [
                                                
                                            ]
                                        })(<InputNumber disabled={this.props.detailFlag} style={{width:220}} min={0} precision={2} placeholder='请输入考勤核算单价（元）' />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align='middle'>
                            <Col span={1} offset={10}>
                                    <Button type="primary" icon="form" htmlType="submit" loading={this.state.loading} hidden={this.props.detailFlag}>保存修改</Button>
                            </Col>
                        </Row>
                    </Form>
                    </Card> 
                
            </div>
        )
    }
}

UpdateStaffSalaryAttr = Form.create()(UpdateStaffSalaryAttr);
export default UpdateStaffSalaryAttr;