import React, { Component } from 'react';
import {Input, Form, Button,Row, Col,Modal,AutoComplete, Icon, Message, message} from 'antd'
import * as mapUtils from '@/utils/TMapGL'
import '@/assets/css/mapComment.less'
import {searchTMap,geocoderTMapAddr,getTMapLocationByIp} from 'api/workattend'
import {nanoid} from 'nanoid';


let map, editor, activeType = 'polygon';//默认为多边形选区

/************搜索控件下拉框内容 开始************/
function renderOption(item) {
  return (
    <AutoComplete.Option key={item.id} text={item.title}>
      <div className="global-search-item">
        <span className="global-search-item-desc">
            {item.title} -
        </span>
        <span className="global-search-item-count">{item.province}{item.city}{item.district}</span>
      </div>
    </AutoComplete.Option>
  );
}
/************搜索控件下拉框内容 结束************/

class MapComment extends Component{
    constructor(props){
        super(props);
        this.state={
            user: JSON.parse(global._sessionStorage.getItem("user")),
            visible : false,
            subVisible : false, 
            poisName : '',
            address :'',

            markers : [],   //搜索后的地图标注
            mapZoom : 15,   //地图缩放比例,
            initPoint : {lat:37.453, lng:121.482},
            dataSource: [],

            markType : '',          //围栏范围形状(1:圆形;2:多边形)
            circleMarker : [],      //圆形围栏数据
            polygonMarker : [],     //多边形围栏数据
            centerPoint : '',       //中心点位置

            editMapId: '',          //地图编辑ID

        }
    }

    initMap = (record)=>{
        const _this = this;
        mapUtils
        .TMapGL() // 开始加载腾讯地图gl文件
        .then(() => { // 完成加载后，开始渲染地图
            let center = new window.TMap.LatLng(this.state.initPoint.lat, this.state.initPoint.lng)
            map = new window.TMap.Map(document.querySelector('#container'), {
                center,
                zoom: this.state.mapZoom,
                baseMap: [
                { type: 'vector' }, //设置矢量底图
                // { type: 'traffic' }, //设置路况底图
                ],
            });
            map.removeControl(window.TMap.constants.DEFAULT_CONTROL_ID.ROTATION);//移除罗盘
            let zoomControl = map.getControl(window.TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
            zoomControl.setPosition(window.TMap.constants.CONTROL_POSITION.TOP_LEFT);
            // 初始化几何图形及编辑器
            if(editor){
                editor.disable();
                editor.enable();
            }
            if(record){
                let polygonMarker = [];
                let circleMarker = [];
                if(record.siteType === "1"){
                    circleMarker = [{
                        id : record.id,
                        radius : Number(record.scope),
                        styleId: "default",
                        center : new window.TMap.LatLng(record.centerPoint.split(",")[0], record.centerPoint.split(",")[1])
                    }]
                }else{
                    let pointArr = record.lngLat.split(";");
                    let latLngArr =[];
                    for(var i =0 ;i<pointArr.length;i++){
                        if(pointArr[i]){
                            latLngArr.push(new window.TMap.LatLng(pointArr[i].split(",")[1], pointArr[i].split(",")[0]))
                        }
                    }
                    polygonMarker = [{
                        id : record.id,
                        styleId: "default",
                        paths : latLngArr
                    }]
                }
                this.setState({
                    markType : record.siteType,
                    polygonMarker : polygonMarker,
                    circleMarker : circleMarker,
                    centerPoint: record.centerPoint,
                })

            }
            editor = new window.TMap.tools.GeometryEditor({
                map, // 编辑器绑定的地图对象
                overlayList: [ // 可编辑图层
                    {
                        overlay: new window.TMap.MultiPolygon({
                            map,
                            geometries :  _this.state.polygonMarker,
                            styles: {
								highlight: new window.TMap.PolygonStyle({
									color: 'rgba(255, 255, 0, 0.6)'
								})
							},

                        }),
                        id: 'polygon',
                        selectedStyleId: 'highlight'
                    },
                    {
                        overlay: new window.TMap.MultiCircle({
                            map,
                            geometries : _this.state.circleMarker,
                        }),
                        id: 'circle'
                    }
                ],
                // 绘制模式，该模式下用户可绘制新图形
                actionMode: window.TMap.tools.constants.EDITOR_ACTION.INTERACT, 
                selectable: true, // 开启点选功能
                snappable: true // 开启吸附
            });

            // 监听绘制结束事件，获取绘制几何图形
            editor.on('draw_complete', (geometry) => { 
                _this.initMapData(geometry);
                editor.setActionMode(window.TMap.tools.constants.EDITOR_ACTION.INTERACT);
            });
            // 监听绘制编辑事件，获取绘制几何图形
            editor.on('adjust_complete', (geometry) => {
                _this.initMapData(geometry);
            });
            // 监听绘制删除事件，获取绘制几何图形
            editor.on('delete_complete', (geometry) => { 
                _this.initMapData(null);
                editor.setActionMode(window.TMap.tools.constants.EDITOR_ACTION.DRAW);
            });
            // 切换激活图层
            document.getElementById('toolControl').addEventListener('click', (e) => {
                let id = e.target.id;
                e.stopPropagation();//阻止点击穿透
                if (id !== 'toolControl') {
                    document.getElementById(activeType).className = "toolItem";
                    document.getElementById(id).className = "toolItem active";
                    activeType = id;
                    editor.setActiveOverlay(id);
                    if(_this.state.circleMarker.length === 0 && _this.state.polygonMarker.length === 0){
                        editor.setActionMode(window.TMap.tools.constants.EDITOR_ACTION.DRAW);
                    }else{
                        editor.setActionMode(window.TMap.tools.constants.EDITOR_ACTION.INTERACT);
                    }
                }
            });

            // 切换激活图层
            document.getElementById('toolControl').addEventListener('dblclick', (e) => {
                e.stopPropagation();//阻止点击穿透
            });

            document.getElementById('autoComplete').addEventListener('mouseup', (e) => {
                e.stopPropagation();//阻止点击穿透
            });

            document.getElementById('autoComplete').addEventListener('mousedown', (e) => {
                e.stopPropagation();//阻止点击穿透
            });

            document.getElementById('autoComplete').addEventListener('dblclick', (e) => {
                e.stopPropagation();//阻止点击穿透
            });

            //监听鼠标右键单击事件，取消当前绘制（原为esc，和关闭弹窗按键冲突）
            document.getElementById('container').onmouseup = function(e){ 
                if(e.button===2){
                    editor.disable();
                    editor.enable();
                }
            }
        })
    }
    
    //计算位置
    getCenterPoint = (paths) => {
        let lng = 0.0, lat = 0.0;
        for(var i=0; i<paths.length; i++){
            lng = lng + parseFloat(paths[i]['lng']);
            lat = lat + parseFloat(paths[i]['lat']);
           }
        lng = lng / paths.length;
        lat = lat / paths.length;
        return lat  + "," +  lng;
    }

    //初始化数据
    initMapData = (geometry) =>{
        let geometries = [];
        if(geometry){
            if(geometry.center){
                 geometries.push({
                    id: geometry.id,
                    styleId: geometry.styleId,
                    center: geometry.center,//地图显示中心点, //圆形中心点坐标 
                    radius: geometry.radius,	//半径（单位：米）
                 })
                 this.setState({
                    markType : '1',
                    circleMarker : geometries,
                    centerPoint : geometry.center.lat + "," + geometry.center.lng,  //中心点的位置
                 })
            }else{
                 if(geometry.paths){
                    geometries.push({
                        id: geometry.id,
                        styleId: geometry.styleId,
                        paths: geometry.paths,    //多边形轮廓
                     })
                     this.setState({
                        markType : '2',
                        polygonMarker: geometries,
                        centerPoint : this.getCenterPoint(geometry.paths),
                     })
                 }
            }
         }else{
             this.setState({
                markType : '',
                polygonMarker : [],
                circleMarker : [],
                centerPoint: '',
             })
         }
    }


    onModal = (visible,record) => {
        this.setState({
            visible,
        })
        if(visible){
            if(record){
                this.setState({
                    initPoint : {lat:record.centerPoint.split(",")[0], lng:record.centerPoint.split(",")[1]},
                    editMapId : record.id,
                })
                this.initMap(record);
            }else{
                this.setState({
                    markType : '',
                    polygonMarker : [],
                    circleMarker : [],
                    centerPoint: '',
                    editMapId : '',
                })
                let parm = {};
                parm.ip = global._sessionStorage.getItem("cip").split('[')[0]
                getTMapLocationByIp(parm).then(res => {
                    if (res.data.status === 0) {
                        this.setState({
                            initPoint : {lat:res.data.result.location.lat, lng:res.data.result.location.lng},
                        })
                        this.initMap();
                    }else{
                        this.initMap();
                    }
                }).catch(error => {
                    this.initMap();
                    message.error('IP定位失败')
                });
            }   
        }else{
            activeType = 'polygon'
            editor.setActiveOverlay(activeType);
        }
    }

    onSubModal = (visible) => {
        this.setState({
            subVisible : visible
        }, ()=> {
            if(!visible){
                this.setState({
                    visible : true,
                })
            }
        })
        this.initMap();
    }

    onConfirm = () => {
        const {markType,centerPoint} = this.state;
        if(!markType){
            message.error('还没有设置围栏');
            return;
        }
        if(!centerPoint){
            message.error('还没有设置围栏');
            return;
        }
        let parm = {};
        parm.location = centerPoint
        geocoderTMapAddr(parm).then(res => {
            if (res.data.status === 0) {
                this.setState({
                    address: res.data.result.address,
                    poisName: res.data.result.formatted_addresses.rough,
                    visible : false,
                }, ()=>{
                    this.setState({
                        subVisible : true,
                    })
                })
            }
        }).catch(error => {
            Message.error('error');
        });
    }

    onSubConfirm = () => {
        const {getMapTableList,mapData,editMapTableList} = this.props;
        const {markType,polygonMarker,circleMarker,centerPoint} = this.state;
        let selectValue={};
        let newPoisName = this.props.form.getFieldValue("newPoisName");
        
        if(!newPoisName){
            message.error('位置名称不能为空');
            return;
        }

        if(!markType){
            message.error('还没有设置围栏');
            return;
        }
        let key = nanoid();
        if(this.state.editMapId){
            key = this.state.editMapId;
        }

        if(markType ==='1'){
            if(circleMarker.length ===0){
                message.error('还没有设置围栏');
                return;
            }
            selectValue={
                id: key,
                lngLat : circleMarker[0].center.lng+","+circleMarker[0].center.lat,       //坐标位置
                scope : circleMarker[0].radius,                                           //半径
                siteType : '1',                                                           //地址范围形状(1:圆形;2:多边形)
                centerPoint : centerPoint,                                                //中心点的位置
                poisName : newPoisName,                                                   //位置名称
                siteName : this.state.address,                                            //详细地址
                
            }
        }else if(markType ==='2'){
            if(polygonMarker.length===0){
                message.error('还没有设置围栏');
                return;
            }
            let lnglatStr = '';
            let paths = polygonMarker[0].paths;
            for(var i=0; i< paths.length; i++){
                let lng =  parseFloat(paths[i]['lng']);
                let lat =  parseFloat(paths[i]['lat']);
                lnglatStr = lnglatStr + lng + "," + lat + ";";
            }
            selectValue={
                id: key,
                lngLat : lnglatStr,       //坐标位置
                siteType : '2',                                                 //地址范围形状(1:圆形;2:多边形)
                centerPoint : centerPoint,            //中心点的位置
                poisName : newPoisName,                                         //位置名称
                siteName : this.state.address,                                //详细地址
            }
        }
        if(this.state.editMapId){
            editMapTableList(selectValue);
        }else{
            mapData.push(selectValue);
            getMapTableList(mapData);
        }
        
        this.setState({
            subVisible : false,
        })
        
    }

    componentDidMount (){
        this.props.onRef(this)
    }
        
    handleSearch = value => {
        let parm = {};
        parm.keyword = value
        searchTMap(parm).then(res => {
            if (res.data.status === 0) {
                this.setState({ 
                    dataSource: res.data.data,
                })
            }
        }).catch(error => {
            Message.error('error');
        });
    };

    handleSelect= (value) => {
        let select = this.state.dataSource.filter((item) => item.id === value)
        if (select.length > 0 && select[0].location) {
            let pointItem = select[0]
            let point = {
                latitude: pointItem.location.lat,
                longitude: pointItem.location.lng
            }
            map.setCenter(new window.TMap.LatLng(point.latitude,point.longitude));
            editor.setActionMode(window.TMap.tools.constants.EDITOR_ACTION.DRAW);
        }
    }

    
    render() {
        
        const _this = this;
        const { visible, subVisible, dataSource} = this.state;
        const { form: {getFieldDecorator} } = this.props;


        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 16 },
        };

        
        return (  
            <div>
                <Row>
                    <Col span={12}><a onClick={() => this.onModal(true,null)}>添加考勤地点</a></Col>
                </Row>
                
                <Modal
                    width='80%'
                    style ={{marginTop:this.state.user.versions ==='0'?"88px":"0"}}
                    bodyStyle={{ padding: '0px 0px 8px 0px',margin : '0',}}
                    centered={true}
                    destroyOnClose
                    title="添加考勤地点（1/2）"
                    visible={visible}
                    footer={false}
                    onCancel={() =>this.onModal(false)}
                >

                    <div style={{width: '100%', height: '100%',margin : '0', padding : '0px 0px 0px 0px'}}  id="container">

                    <div id="autoComplete">
                        <AutoComplete
                        className="global-search"
                        size="large"
                        style={{ width: '100%' }}
                        dataSource={dataSource.map(renderOption)}
                        onSelect={_this.handleSelect}
                        onSearch={_this.handleSearch}
                        placeholder="input here"
                        optionLabelProp="text"
                        >
                        <Input
                            suffix={
                            <Button
                                className="search-btn"
                                style={{ marginRight: -12 }}
                                size="large"
                                type="primary"
                            >
                                <Icon type="search" />
                            </Button>
                            }
                        />
                        </AutoComplete>
                    </div>

                    <div id="toolControl">
                        <div className="toolItem" id="polygon" title="多边形"></div>
                        <div className="toolItem" id="circle" title="圆形"></div>
                    </div>
                            
                    </div>
                    <div style={{margin: '8px 0 0 16px'}} >
                        <b>使用说明：</b>
                        <div>※ 在顶部选中图形，单击左键开始绘制</div>
                        <div>※ 双击左键完成多边形绘制，单击左键完成圆形绘制,单击右键取消当前图形绘制</div>
                        <div>※ 在已画的图形上单击左键选择图形进行编辑，按delete按键对选中的图形删除</div>
                    </div>
                    <div style={{width : '150px', margin: 'auto'}} >
                        <Button onClick={() =>this.onModal(false)} >取消</Button>
                        <Button type='primary' style={{marginLeft : 20}} onClick={this.onConfirm} >确定</Button>
                    </div>
                </Modal>


                <Modal
                    width={600}
                    bodyStyle={{ padding: '0px 10px 20px'}}
                    destroyOnClose
                    title="添加考勤地点（2/2）"
                    visible={subVisible}
                    footer={false}
                    onCancel={() =>this.onSubModal(false)}
                >

                    <div>
                        <Form.Item {...formItemLayout} colon={false} label="考勤地点名称">
                            {getFieldDecorator('newPoisName', {
                                    initialValue :this.state.poisName
                                })(<Input />)}
                                <p>
                                输入名称方便员工识别打卡地点
                                <br />考勤地点:{this.state.address}
                                </p>
                        </Form.Item>
                    </div>
                       
                    <div style={{width : '170px', margin: 'auto'}} >
                        <Button onClick={() =>this.onSubModal(false)} >上一步</Button>
                        <Button type='primary' style={{marginLeft : 20}} onClick={this.onSubConfirm} >确定</Button>
                    </div>

                </Modal>

            </div>
        )
    }


}


export default MapComment;