import React, { Component } from 'react';
import { Form, Message, Table } from 'antd';
import { salaryBatchDetail } from 'api/transPlatform';
import {publicDesensitization} from '@/utils/String'
@Form.create()
class SalaryBatchDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            searchParam: {},
            sourceData: [],
            totalNum: 0,
            pageSizeOptions: global.pageInfo.sizeOptions,
            currentPage: '1',
            pageSize: '10',
            columns: [],
            tableWidth: 110,
            user: JSON.parse(global._sessionStorage.getItem("user")),
        }
    }

    currentPageChange(currentPage) {
        let params = this.state.searchParam;
        params.currentPage = currentPage;
        this.setState({
            currentPage,
            searchParam: params
        });
        this.searchPost(params);
    }

    pageSizeChange(pageSize) {
        let params = this.state.searchParam;
        params.currentPage = '1';
        params.pageSize = pageSize;
        this.setState({
            currentPage: '1',
            pageSize,
            searchParam: params
        });
        this.searchPost(params);
    }
    componentDidMount() {
        let params = this.state.searchParam;
        params.eid = this.state.enterpriseid;
        params.salaryBatchId = this.props.record.id;
        this.setState({
            searchParam: params
        });

        this.searchFormSubmit(params);
    }

    searchFormSubmit(params) {
        params.currentPage = "1";
        params.pageSize = this.state.pageSize;
        this.setState({
            currentPage: '1',
            searchParam: params
        })
        this.searchPost(params);
    }

    searchPost = params => {
        this.setState({
            loading: true,
        })
        salaryBatchDetail(params).then(res => {
            let { errorCode, errorMessage } = res.data;
            if (errorCode === '0000') {
                let columns = [];
                if (res.data.data.partList) {
                    // const _this = this;
                    res.data.data.partList.map((head) => {
                        columns.push(
                            {
                                title: head.salaryname,
                                dataIndex: head.salaryentity,
                                key: head.salaryentity,
                                align: 'center',
                                width : '10%',
                                render(text, record, index) {
                                    if(head.salaryentity === 'identityid'){
                                        return publicDesensitization(record.identityid);
                                    }else{
                                        return text;
                                    }
                                }
                            }
                        )
                    })
                    this.setState({
                        sourceData: res.data.data.detailVoList,
                        totalNum: res.data.data.totalCount,
                        columns: columns,
                        tableWidth: columns.length * 100,
                    });

                }
                columns.push(
                    { title : '发放状态', width : '10%', dataIndex : 'status', key : 'status', align : 'center',
                        render(val,record){
                            if (record.status === '00') {
                                return '已发放';
                            } else if (record.status === '01') {
                                return '未发放';
                            } else if (record.status === '02') {
                                return '发放失败';
                            } else {
                                return '发放中';
                            }
                        }
                    }
                )
                columns.push(
                    { title : '响应信息', width : '10%', dataIndex : 'respmsg', key : 'respmsg', align : 'center'}
                )
            } else {
                Message.error(errorMessage);
            }
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const { totalNum, loading, columns, sourceData } = this.state;
        const _this = this;
        const pagination = {
            current: parseInt(this.state.currentPage),
            total: totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.state.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.currentPageChange(current);
            }
        }

        return (
            <div >
                <Table style={{ marginTop: '20px' }}
                    rowKey={record => record.identityid}
                    loading={loading}
                    size="middle "
                    dataSource={sourceData}
                    columns={columns}
                    total={totalNum}
                    // bordered
                    pagination={pagination}
                    scroll={{ x: this.state.tableWidth }}
                >
                </Table>
            </div>
        );
    }
}
export default SalaryBatchDetails;