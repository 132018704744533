import React, { Component } from 'react';
import { Table, Alert} from 'antd'
import {Link} from 'react-router-dom' 

class HistoryQueryResult extends Component {
    render() {
        const _this = this;
        const columns = [
            { title : '批次', width : '10%', dataIndex : 'salarybatchcode', key : 'salarybatchcode', align : 'center',
                render(text, record, index){
                    return (
                        <div>
                            <Link to={{ pathname : '/releaseDetail' , state : { record: record }}} >{record.salarybatchcode}</Link>
                        </div>
                    )
                }
            },
            { title : '提交时间', width : '10%', dataIndex : 'submittime', key : 'submittime', align : 'center'},
            { title : '总条数', width : '10%', dataIndex : 'employeenum', key : 'employeenum', align : 'center'},
            { title : '总金额', width : '10%', dataIndex : 'totalnum', key : 'totalnum', align : 'center'},
            { title : '是否申请融资', width : '10%', dataIndex : 'factorstatus', key : 'factorstatus', align : 'center',
                render(val,record){
                    if(record.factorstatus==='04' || record.factorstatus==='01'){
						return '已申请';
					}else{
						return '未申请';
					}
                }
            },
            { title : '发放属性', width : '10%', dataIndex : 'salaryCycleCateEnum', key : 'salaryCycleCateEnum', align : 'center'},
            { title : '发放单状态', width : '10%', dataIndex : 'checkStatusTxt', key : 'checkStatusTxt', align : 'center',},
            { title : '驳回原因', width : '10%', dataIndex : 'remark', key : 'remark', align : 'center'},
            { title : '审核通过时间', width : '10%', dataIndex : 'checktime', key : 'checktime', align : 'center'},
        ]

        const pagination ={
            current : parseInt(this.props.currentPage),
            total: this.props.totalNum,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions : this.props.pageSizeOptions,
            showTotal(total){
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }
        
        return (
            <div style={{marginTop : '20px'}}>
                <Alert style={{marginTop : '10px'}} message={(
                                    <span>查询结果</span>
                                )} type="info" showIcon />
                <Table style={{marginTop : '20px'}} 
                    rowKey = { record => record.id }
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData} 
                    columns={columns}
                    bordered
                    pagination={pagination}
                >
                </Table>
                   
            </div>
        );

    }
}

export default HistoryQueryResult;