import React, { Component, Fragment } from 'react';
import { Table, Alert, Button, Spin, Divider, Icon, Message, Modal } from 'antd'
import {Link} from 'react-router-dom' 
import ConfirmPrpover from '@/components/Common/ConfirmPrpover';
import { employeeReview } from 'api/enDepart';

class SimplerDepartStaffUncheckResult extends Component {

    constructor() {
        super();    
        this.state = {
            user: JSON.parse(global._sessionStorage.getItem("user")),
            loading: false,
            gotoLinkUrl: "",
            showMessage: false,
            errMessage: ''
        }
    }

    componentDidMount() {
        this.gotoLink();
    }

    gotoLink() {
        if (this.props.currentFlag === 0) {
            this.setState({
                gotoLinkUrl: '/simplerPayUser',
                gotoLinkParms: 0,
            });      
        } else if (this.props.currentFlag === 1) {
            this.setState({
                gotoLinkUrl: '/simplerFacUser',
                gotoLinkParms: 0,
            });
        } 
    }

    onSubmit = (exId,state) => {
        this.setState({
            loading: true,
        })
        let params = {operName:this.state.user.username};
        params.exId = exId;
        params.auditState = state;
        employeeReview(params).then(res => {
            let { errorCode, errorMessage } = res.data;
            if (errorCode === '0000') {
                Message.success(state=="01"?"已通过":"已驳回");
                this.props.refreshSubmit();
            } else {
                // Message.error(errorMessage);
                this.setState({ errMessage: errorMessage })
                this.setState({ showMessage: true })
            }
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        const _this = this;
        const { loading } = this.state;
        const antIcon = <Icon type="loading" spin />;
        const submitText = <span className="span-click" >通过</span>;
        const htmlText = <span className="span-click" >驳回</span>;
        const loadIcon = <Icon type="loading" spin />;
        const columns = [
            {
                title: '姓名', dataIndex: 'name', key: 'name', align: 'center',
            },
            {
                title: '性别', dataIndex: 'sex', key: 'sex', align: 'center',
                render(val) {
                    if (val == 0) {
                        return '未知';
                    } else if (val == 1) {
                        return '男';
                    } else if (val == 2) {
                        return '女';
                    } 
                    return "";
                }
            },
            { title: '年龄', dataIndex: 'age', key: 'age', align: 'center' },
            { title: '常用手机号', dataIndex: 'mobile', key: 'mobile', align: 'center' },
            { title: '项目', dataIndex: 'projectName', key: 'projectName', align: 'center' },
            {
                title: '在职状态', dataIndex: 'jobState', key: 'jobState', align: 'center',
                render(val) {
                    if (val == '00') {
                        return '正式';
                    } else if (val == '01') {
                        return '试用';
                    } else if (val == '02') {
                        return '实习';
                    } else if (val == '03') {
                        return '兼职';
                    } else if (val == '04') {
                        return '离职';
                    }
                    return "--";
                }
            },
            {
                title: '发放方式', dataIndex: 'payMethod', key: 'payMethod', align: 'center',
                render(val) {
                    if (val == '00') {
                        return '记账户';
                    } else if (val === '01') {
                        return '银行卡';
                    } else if (val === '02') {
                        return '白名单发放';
                    }
                    return "--";
                }
            },
            {
                title: '开户状态', dataIndex: 'accountState', key: 'accountState', align: 'center',
                render(val) {
                    if (val == '00') {
                        return '未开户';
                    } else if (val == '01') {
                        return '已开户';
                    } else if (val == '02') {
                        return '开户中';
                    }
                    return "--";
                }
            },
            {
                title: '绑卡状态', dataIndex: 'cardBindState', key: 'cardBindState', align: 'center',
                render(val) {
                    if (val === '00') {
                        return '未绑卡';
                    } else if (val === '01') {
                        return '已绑卡';
                    } else if (val === '02') {
                        return '绑卡失败';
                    } else if (val === '03') {
                        return '绑卡中';
                    }else if (val === '04') {
                        return '解绑中';
                    }
                    return "--";

                }
            },
            {
                title: '审核状态', dataIndex: 'auditState', key: 'auditState', align: 'center',
                render(val) {
                    if (val === '00') {
                        return '审核中';
                    } else if (val === '01') {
                        return '审核通过';
                    } else if (val === '02') {
                        return '审核驳回';
                    }
                    return "--";

                }
            },
            {
                title: "操作",
                align: "center",
                width: 90,
                fixed: 'right',
                render(text, record, index) {
                    return (
                        <div>
                            {
                                record.auditState!=="00"?
                                <div style={{color:"grey"}}>已审核</div>:
                                <Fragment>
                                    <Spin indicator={antIcon} spinning={loading} >
                                        <span style={{ display: 'inline-flex' }} >
                                            <Spin size="small" indicator={loadIcon} spinning={loading}>
                                                {
                                                    <ConfirmPrpover record={record} placement="topRight" title="驳回" content="驳回员工后，员工将不可在项目中进行打卡，请谨慎操作。"
                                                    confirmText="驳回" cancelText="取消" htmlText={htmlText} confirmSubmit={() => _this.onSubmit(record.exId, "02")} />
                                                }
                                            </Spin>
                                        </span>
                                        <Divider type="vertical" />
                                        <span style={{ display: 'inline-flex' }} >
                                            <Spin size="small" indicator={loadIcon} spinning={loading}>
                                                {
                                                    <ConfirmPrpover record={record} placement="topRight" title="通过" content="通过后，员工可在项目中进行打卡，请确认。"
                                                        confirmText="通过" cancelText="取消" htmlText={submitText} confirmSubmit={() => _this.onSubmit(record.exId, "01")} />
                                                }
                                            </Spin>
                                        </span>
                                    </Spin>
                                </Fragment>
                            }
                        </div>
                    );
                },
            },
        ]
        const pagination = {
            current: parseInt(this.props.currentPage),
            total: Number(this.props.totalNum),
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: this.props.pageSizeOptions,
            showTotal(total) {
                return `共 ${total} 条数据`
            },
            onShowSizeChange(current, pageSize) {
                _this.props.pageSizeChange(pageSize);
            },
            onChange(current) {
                _this.props.currentPageChange(current);
            }
        }
        return (
            <div style={{ marginTop: '20px' }}>
                <Alert style={{ marginTop: '10px' }} message={(
                    <span>查询结果</span>
                )} type="info" showIcon />
                <Table style={{ marginTop: '20px' }}
                    rowKey={record => record.id}
                    loading={this.props.loading}
                    size="middle "
                    dataSource={this.props.resultData}
                    columns={columns}
                    bordered
                    pagination={pagination}
                >
                </Table>

                <Button style={{ marginLeft: '45%' }} type="primary" >
                <Link to={{ pathname: this.state.gotoLinkUrl, state: { current: this.state.gotoLinkParms, record: this.props.record } }}>返回上一级</Link>
                </Button>

                {this.state.showMessage && (
                    <Modal
                    visible={this.state.showMessage}
                    title="温馨提示"
                    closable={true}
                    maskClosable={true}
                    onCancel={() => this.setState({ showMessage: false })}
                    footer={null}
                    width={600}
                    destroyOnClose
                    >
                    <div
                        style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        }}
                    >
                        <div style={{ textAlign: "left", width: "70%" }}>
                        <span style={{ fontSize: 14, fontWeight: 400 }}>
                            {this.state.errMessage}
                        </span>
                        </div>
                        <div style={{ marginTop: 32 }}>
                        <Button
                            type="primary"
                            onClick={() => this.setState({ showMessage: false })}
                        >
                            知道了
                        </Button>
                        </div>
                    </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default SimplerDepartStaffUncheckResult;