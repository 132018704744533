import React, { Component } from 'react';
import {Button,Message } from 'antd'
import { invite, inviteCancel } from '../../api/staff';
class InviteOrCancelUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            uid:"",
        }
    }

    invite() {
        let params = {
            uid:this.state.uid,
            enterpriseid:this.state.enterpriseid
        }
        invite(params).then(res => {
            if(res.data.code === "200"){
                Message.success(res.data.msg);
                this.props.refreshSubmit();
            }else{
                Message.error(res.data.msg);
                this.props.refreshSubmit();
            }
        });
    }

    inviteCancel() {
        let params = {
            uid:this.state.uid,
            enterpriseid:this.state.enterpriseid
        }
        inviteCancel(params).then(res => {
            if(res.data.code === "200"){
                Message.success(res.data.msg);
                this.props.refreshSubmit();
            }else{
                Message.error(res.data.msg);
                this.props.refreshSubmit();
            }
        });

    }

    componentWillMount(){
        this.setState({
            uid:this.props.record.id,
        })
    }

    render() {
        let show = "";
        if (this.props.record.userjoinenterprise.unionstatus === '01') {
            show = <Button type="primary" onClick={this.invite.bind(this)} >邀请</Button>
        } else if (this.props.record.userjoinenterprise.unionstatus === '02') {
            show = <Button type="danger" onClick={this.inviteCancel.bind(this)} >取消邀请</Button>
        } else if (this.props.record.userjoinenterprise.unionstatus === '03') {
            show = <Button type="primary" onClick={this.invite.bind(this)} >邀请</Button>
        } else if (this.props.record.userjoinenterprise.unionstatus === '04') {
            show = <Button type="primary" onClick={this.invite.bind(this)} >邀请</Button>
        }else if (this.props.record.userjoinenterprise.unionstatus === '05') {
            show = <Button type="primary" disabled={true} onClick={this.invite.bind(this)} >邀请</Button>
        }

        return (
            <div>
                {show}
            </div>
        );
    }
}

export default InviteOrCancelUser;