import React, { Component } from 'react';
import { Row, Col, Icon, Button, Spin, Upload, Message, Divider, Select } from 'antd'
import { baseHttpUrl } from '@/assets/settings';
import { addUserRelationByExcel } from '@/api/userRelatedEnterpise';
import { queryRelatedUpEnterpris } from '@/api/supplierManage';
const Dragger = Upload.Dragger;
const { Option } = Select;

class AddRelationByExcel extends Component {
    constructor() {
        super();
        this.state = {
            enterpriseid: JSON.parse(global._sessionStorage.getItem('user')).enterpriseid,
            username: JSON.parse(global._sessionStorage.getItem("user")).username,
            modalShow: false,
            uploadDisabled: false,
            confirmLoading: false,
            relatedEnterpris:[],
            fileList: [],
            spinning: false,
            selectedEid:"",
        }
    }

    handlerReset() {
        this.setState({
            fileList: [],
            selectedEid:"",
            uploadDisabled: false,
            confirmLoading: false,
        })
    }
    uploadSubmit() {
        if(this.state.selectedEid === ""){
            Message.error("请选择关联企业！");
            return;
        }
        if (this.state.fileList.length === 0) {
            Message.error("请先上传文件！");
            return;
        }
        this.setState({
            spinning: true,
        })
        let formData = new FormData();
        formData.append("file", this.state.fileList[0]);
        formData.append("eid", this.state.enterpriseid);
        formData.append("operator", this.state.username);
        formData.append("relatedEid", this.state.selectedEid);


        addUserRelationByExcel(formData).then(res => {
            if ("200" === res.data.status) {
                Message.success(res.data.msg);
                this.setState({
                    modalShow: false,
                    uploadDisabled: false,
                    confirmLoading: false,
                    fileList: [],
                    spinning: false,
                    selectedEid:"",
                })
                this.props.refreshSubmit();
            } else {
                Message.error(res.data.msg);
                this.setState({
                    spinning: false,
                    fileList: [],
                    selectedEid:"",
                })
            }
        })

    }

    relatedEidChanged(value){
        this.setState({
            selectedEid:value
        })
    }

    componentWillMount() {
        //查询关联企业
        let params = {
            eid: this.state.enterpriseid,
        }
        queryRelatedUpEnterpris(params).then(res => {
            this.setState({
                relatedEnterpris: res.data.list
            })
        });
    }
    render() {
        const _this = this;
        const { fileList } = this.state;
        const relatedOptions = this.state.relatedEnterpris.map(iterm => {
            return <Option key={iterm.upCompanyNo} value={iterm.upCompanyNo} >{iterm.upCompanyNo + "/" + iterm.upCompanyName}</Option>
        });
        const props = {
            accept: ".xls,.xlsx",
            multiple: false,
            beforeUpload: (file) => {
                if (this.state.fileList.length !== 0) {
                    Message.error("仅支持单个文件上传！");
                    _this.setState({
                        fileList: [],
                        uploadDisabled: false,
                        confirmLoading: false,
                    });
                    return false;
                }
                _this.setState(state => ({
                    fileList: [...state.fileList, file],
                    uploadDisabled: true,
                }));
            },
            onRemove: (file) => {
                _this.setState({
                    fileList: [],
                    uploadDisabled: false,
                    confirmLoading: false,
                });
            },
            fileList,
        };
        return (
            <div style={{ width: '100%', marginTop: 20 }}>
                <Spin tip="数据解析中..." spinning={this.state.spinning}>
                    <div style={{ width: '100%', marginTop: 20 }}>
                        <Row>
                            <Col span={5} offset={2}>
                                <h4>关联企业商户号/名称：</h4>
                            </Col>
                            <Col span={12}>
                                <Select style={{ width: "100%" }} placeholder="关联企业商户号/名称" onChange={this.relatedEidChanged.bind(this)} value={this.state.selectedEid}>
                                    {relatedOptions}
                                </Select>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ width: '100%', marginTop: 20 }}>

                        <b>温馨提示：</b>
                        <p></p>
                        <p>1.请<a href={baseHttpUrl + "/userRelate.xlsx"}><Icon type="download" />点击此处</a>下载标准模板；</p>
                        <p>2.按照模板内容填写需要关联的员工信息。</p>
                    </div>
                    <Divider />
                    <div style={{ width: '100%', marginTop: 20 }}>
                        <Dragger {...props} >
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">单击或拖动文件到此区域</p>
                            <p className="ant-upload-hint">仅支持单个文件</p>
                        </Dragger>
                    </div>
                </Spin>
                <div style={{ width: '100%', marginTop: 50 }}>
                    <Row>
                        <Col span={12} offset={8}>
                            <Button type="primary" icon="form" onClick={this.uploadSubmit.bind(this)}>上传</Button>
                            <Button type="primary" icon="redo" onClick={this.handlerReset.bind(this)} style={{ marginLeft: 20 }}>重置</Button>
                        </Col>
                    </Row>
                </div>
            </div>

        );
    }
}

export default AddRelationByExcel;